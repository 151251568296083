<template>
    <div>
        <div class="main-content-wrap sidenav-open d-flex flex-column">
            <div class="main-header pl-4" style="z-index: 60;">
                <div class="row">
                    <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
                        <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
                            <i @click="hasHistory() ? $router.go(-1) : $router.push('/')" style="cursor: pointer; position: relative; top: 2px; color: #069;" class="fal fa-arrow-alt-circle-left mr-1"></i>
                            <span class="ml-2" style="top: 4%; position: relative;">Back</span>
                        </div>
                    </div>
                </div>
                <div style="margin: auto;"></div>
                <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-0' : 'pr-5'">
                    <div class="dropdown">
                        <button class="btn ripple m-1" type="button" @click="refresh_page()" :class="refreshing_page ? 'rotate-spinner' : ''">
                            <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <div class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row">
                        <h1 class="mt-0 mb-2 col-md-12 pb-0">
                            <span>Performance</span>
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="text-left" style="width: 100%;">
                        <div class="card-body py-5">
                            <div class="row">
                                <ProfileSummary 
                                :get_dept_name="get_dept_name" 
                                />
                                <div class="col-12">
                                    <div class="tab-content py-0 px-0" id="nav-tabContent">
                                        <div class="tab-pane fade active show" id="onboarding-reports" role="tabpanel" aria-labelledby="onboarding-reports-info">
                                            <div class="row">
                                                <!-- ICON BG-->
                                                <div class="col-12">
                                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                        <li 
                                                        v-for="(tabName, index) in performanceTabs"
                                                        :key="'tab-name-'+index"
                                                        class="nav-item">
                                                            <a
                                                                v-if='isViewable(tabName)'
                                                                @click="view = convertToSnakeCase(tabName)"
                                                                :class="view == convertToSnakeCase(tabName) ? 'active' : null"
                                                                class="nav-link show"
                                                                data-toggle="tab"
                                                                role="tab"
                                                                :aria-controls="convertToSnakeCase(tabName)"
                                                                aria-selected="true"
                                                            >
                                                                {{ tabName }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="card card-icon-bg card-icon-bg-primary mt-4">
                                                <!-- Manage all goals -->
                                                <GoalsByAdmin 
                                                v-if="view == 'goals'" 
                                                :getTrueEmployeeAssignedGoals='getTrueEmployeeAssignedGoals'
                                                :getGoalName='getGoalName' 
                                                :get_employee_name='get_employee_name'
                                                :get_employee_id='get_employee_id'
                                                :employeeObject='employeeObject'
                                                :designationObject='designationObject'
                                                :getEmployeeByName='getEmployeeByName'
                                                :get_dept_name='get_dept_name'
                                                :availableDepartmentsNames='availableDepartmentsNames'
                                                :availableDesignationNames='availableDesignationNames'
                                                :availableCommitteeNames='availableCommitteeNames'
                                                :getEmployeeCommitteeNames='getEmployeeCommitteeNames'
                                                />
                                                <!-- Manage all goals -->

                                                <!-- Manage your goals -->
                                                <GoalsForEmployee 
                                                v-if="view == 'your_goals'" 
                                                :getTrueEmployeeAssignedGoals='getTrueEmployeeAssignedGoals'
                                                :getGoalName='getGoalName' 
                                                :getKRAsInGoal='getKRAsInGoal'
                                                :getKRAsInGoalWithUnapproved='getKRAsInGoalWithUnapproved'
                                                :getEmployeeKRAInfo='getEmployeeKRAInfo'
                                                :getGoalCompletionPercentage='getGoalCompletionPercentage'
                                                />
                                                <!-- Manage your goals -->

                                                <!-- Manage appraisals -->
                                                <Appraisals 
                                                v-if="view == 'appraisals'" 
                                                :get_employee_name='get_employee_name'
                                                :getReportingManagerName='getReportingManagerName'
                                                :singleEmployeeObject='singleEmployeeObject'
                                                :get_employee_id='get_employee_id'
                                                :employeeObject='employeeObject'
                                                :designationObject='designationObject'
                                                :goalIsInAppraisal='goalIsInAppraisal'
                                                :getEmployeeByName='getEmployeeByName'
                                                :get_dept_name='get_dept_name'
                                                :availableDepartmentsNames='availableDepartmentsNames'
                                                :availableDesignationNames='availableDesignationNames'
                                                :availableCommitteeNames='availableCommitteeNames'
                                                :getEmployeeCommitteeNames='getEmployeeCommitteeNames'
                                                :getEmployeeReviewers='getEmployeeReviewers'
                                                />
                                                <!-- Manage appraisals -->

                                                <!-- Manage self appraisals -->
                                                <SelfAppraisal
                                                v-if="view == 'self_appraisal'" 
                                                :getTrueEmployeeAppraisals='getTrueEmployeeAppraisals'
                                                :getAppraisalById='getAppraisalById'
                                                :getEmployeeFinalScore='getEmployeeFinalScore'
                                                :getEmployeeFinalRating='getEmployeeFinalRating'
                                                :getReportingManagerName='getReportingManagerName'
                                                :singleEmployeeObject='singleEmployeeObject'
                                                :getEmployeeReviewers='getEmployeeReviewers'
                                                :getEmployeeAppraisalReview='getEmployeeAppraisalReview'
                                                :getResponseByQuestionId='getResponseByQuestionId'
                                                :getEmployeeGoalsInfo='getEmployeeGoalsInfo'
                                                :getEmployeeKRAInfo='getEmployeeKRAInfo'
                                                :get_employee_id='get_employee_id'
                                                :employeeAppraisalIsEnded='employeeAppraisalIsEnded'
                                                :getEmployeeByName='getEmployeeByName'
                                                :get_dept_name='get_dept_name'
                                                :availableDepartmentsNames='availableDepartmentsNames'
                                                :availableDesignationNames='availableDesignationNames'
                                                :availableCommitteeNames='availableCommitteeNames'
                                                :getEmployeeCommitteeNames='getEmployeeCommitteeNames'
                                                :getGoalCompletionPercentage='getGoalCompletionPercentage'
                                                />
                                                <!-- Manage self appraisals -->

                                                <!-- Manage reviews history -->
                                                <ReviewsHistory
                                                v-if="view == 'reviews_history'" 
                                                :getTrueEmployeeAppraisals='getTrueEmployeeAppraisals'
                                                :getAppraisalById='getAppraisalById'
                                                :getEmployeeFinalScore='getEmployeeFinalScore'
                                                :getEmployeeFinalRating='getEmployeeFinalRating'
                                                :singleEmployeeObject='singleEmployeeObject'
                                                :get_dept_name='get_dept_name'
                                                :getReviewerFinalScore='getReviewerFinalScore'
                                                :getReviewerFinalRating='getReviewerFinalRating'
                                                :getEmployeeReviewers='getEmployeeReviewers'
                                                :getFeedbacksByAppraisalId='getFeedbacksByAppraisalId'
                                                :getEmployeeAppraisalReview='getEmployeeAppraisalReview'
                                                :getResponseByQuestionId='getResponseByQuestionId'
                                                :getEmployeeGoalsInfo='getEmployeeGoalsInfo'
                                                :getEmployeeKRAInfo='getEmployeeKRAInfo'
                                                :employeeObject='employeeObject'
                                                :getFeedbackResponses='getFeedbackResponses'
                                                :employeeAppraisalIsEnded='employeeAppraisalIsEnded'
                                                :getGoalCompletionPercentage='getGoalCompletionPercentage'
                                                />
                                                <!-- Manage reviews history -->

                                                <!-- Manage appraisal reviews -->
                                                <AppraisalReviews
                                                v-if="view == 'appraisal_reviews'"
                                                :employeeIdsUnderReportingManager='employeeIdsUnderReportingManager'
                                                :getTrueEmployeeAppraisals='getTrueEmployeeAppraisals'
                                                :getKRAsInGoal='getKRAsInGoal'
                                                :singleEmployeeObject='singleEmployeeObject'
                                                :getAppraisalById='getAppraisalById'
                                                :getEmployeeFinalScore='getEmployeeFinalScore'
                                                :getEmployeeFinalRating='getEmployeeFinalRating'
                                                :getEmployeeAppraisalReview='getEmployeeAppraisalReview'
                                                :getResponseByQuestionId='getResponseByQuestionId'
                                                :getEmployeeGoalsInfo='getEmployeeGoalsInfo'
                                                :getEmployeeKRAInfo='getEmployeeKRAInfo'
                                                :getReviewerFinalScore='getReviewerFinalScore'
                                                :getReviewerFinalRating='getReviewerFinalRating'
                                                :getEmployeeReviewers='getEmployeeReviewers'
                                                :getFeedbacksByAppraisalId='getFeedbacksByAppraisalId'
                                                :getFeedbackResponses='getFeedbackResponses'
                                                :employeeObject='employeeObject'
                                                :get_dept_name='get_dept_name'
                                                :employeeAppraisalIsEnded='employeeAppraisalIsEnded'
                                                :availableDepartmentsNames='availableDepartmentsNames'
                                                :availableDesignationNames='availableDesignationNames'
                                                :employeeIsUnderReportingManager='employeeIsUnderReportingManager'
                                                :employeeIdsOfReviewees='employeeIdsOfReviewees'
                                                :getGoalCompletionPercentage='getGoalCompletionPercentage'
                                                />
                                                <!-- Manage appraisal reviews -->

                                                <!-- Manage 360 feedback -->
                                                <ThreeSixtyFeedback
                                                v-if="view == '360_feedback'"
                                                :singleEmployeeObject='singleEmployeeObject'
                                                :getFeedbacksByEmployeeId='getFeedbacksByEmployeeId'
                                                :get_dept_name='get_dept_name'
                                                :availableDepartmentsNames='availableDepartmentsNames'
                                                :availableDesignationNames='availableDesignationNames'
                                                />
                                                <!-- Manage 360 feedback -->

                                                <!-- Manage publishment -->
                                                <Publishment 
                                                v-if="view == 'publishment'"
                                                :employeeIdsUnderReportingManager='employeeIdsUnderReportingManager'
                                                :getTrueEmployeeAppraisals='getTrueEmployeeAppraisals'
                                                :singleEmployeeObject='singleEmployeeObject'
                                                :getAppraisalById='getAppraisalById'
                                                :getEmployeeFinalScore='getEmployeeFinalScore'
                                                :getEmployeeFinalRating='getEmployeeFinalRating'
                                                :getAppraisalByEmployeeId='getAppraisalByEmployeeId'
                                                :get_dept_name='get_dept_name'
                                                :availableDepartmentsNames='availableDepartmentsNames'
                                                :availableDesignationNames='availableDesignationNames'
                                                />
                                                <!-- Manage publishment -->

                                                <!-- Manage your skill sets -->
                                                <SkillSetByEmployee
                                                v-if="view == 'your_skill_sets'"
                                                />
                                                <!-- Manage your skill sets -->

                                                <!-- Manage employee skill sets -->
                                                <SkillSetForAdmin
                                                v-if="view == 'skill_sets'"
                                                :employeeIdsUnderReportingManager='employeeIdsUnderReportingManager'
                                                :singleEmployeeObject='singleEmployeeObject'
                                                :get_dept_name='get_dept_name'
                                                :availableDepartmentsNames='availableDepartmentsNames'
                                                :availableDesignationNames='availableDesignationNames'
                                                />
                                                <!-- Manage employee skill sets -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer Start -->
            <div class="flex-grow-1"></div>
            <!-- fotter end -->
        </div>
    </div>
</template>

<script>
    import ProfileSummary from "../Modules/Performance/ProfileSummary";
    import GoalsByAdmin from "../Modules/Performance/GoalsByAdmin";
    import GoalsForEmployee from "../Modules/Performance/GoalsForEmployee";
    import Appraisals from "../Modules/Performance/Appraisals";
    import SelfAppraisal from "../Modules/Performance/SelfAppraisal";
    import ReviewsHistory from "../Modules/Performance/ReviewsHistory";
    import AppraisalReviews from "../Modules/Performance/AppraisalReviews";
    import ThreeSixtyFeedback from "../Modules/Performance/360Feedback";
    import Publishment from "../Modules/Performance/Publishment";
    import SkillSetByEmployee from "../Modules/Performance/SkillSetByEmployee";
    import SkillSetForAdmin from "../Modules/Performance/SkillSetForAdmin";
    import { 
        mapActions, 
        mapGetters 
    } from "vuex";
    export default {
        components: {
            ProfileSummary,
            GoalsByAdmin,
            GoalsForEmployee,
            Appraisals,
            SelfAppraisal,
            ReviewsHistory,
            AppraisalReviews,
            ThreeSixtyFeedback,
            Publishment,
            SkillSetByEmployee,
            SkillSetForAdmin
        },
        data() {
            return {
                performanceTabs: [
                    'Goals',
                    'Your Goals',
                    'Appraisals',
                    'Self Appraisal',
                    'Reviews History',
                    'Appraisal Reviews',
                    '360 Feedback',
                    'Publishment',
                    'Your Skill Sets',
                    'Skill Sets'
                ],
                refreshing_page: false,
                view: "goals",
            };
        },
        computed: {
            ...mapGetters([
                "validEmployeeId",
                "authCompanyData",
                "authCompanyDepartments",
                "authDesignations",
                "authCompanyEmployees",
                "authCompanyGoals",
                "authDesignations",
                "authCompanyKRAs",
                "authCompanyAppraisals",
                "authEmployeeAssignedGoals",
                "authCompanyGoalsInAppraisals",
                "authEmployeeAppraisals",
                "authEmployeesKRAs",
                "authCompanyReviewers",
                "authCompanyQuestionnaireResponses",
                "authEmployeeAppraisalReviews",
                "authEmployeeGoalReviews",
                "authCompanyFeedbacks",
                "authCompanyAppraisalFeedbacks",
                "authCompanyCommittees",
                "authEmployeeCommittees",
                "authEmployeeReporters",
                "userType"
            ]),
            employeeIdsUnderReportingManager() {
                let _this = this, result = [];
                result = _this.authCompanyEmployees.filter(e => e.reporting_to == _this.validEmployeeId).map(e => e.id);
                return result;
            },
            availableDepartmentsNames() {
                const _this = this;
                let departmentNames = ['All'];
                _this.authCompanyDepartments.forEach(DEPT => {
                    departmentNames.splice(departmentNames.length, 0, DEPT.department_name);
                });
                return departmentNames;
            },
            availableDesignationNames() {
                const _this = this;
                let designationNames = ['All'];
                _this.authDesignations.forEach(DESIGNATION => {
                    designationNames.splice(designationNames.length, 0, DESIGNATION.title);
                });
                return designationNames;
            },
            availableCommitteeNames() {
                const _this = this;
                let committeeNames = ['All'];
                _this.authCompanyCommittees.forEach(COMMITTEE => {
                    committeeNames.splice(committeeNames.length, 0, COMMITTEE.committee_name);
                });
                return committeeNames;
            },
            idsOfReporters() {
                return this.authEmployeeReporters.map(reporter => reporter.id);
            },
            employeeIdsOfReviewees() {
                let _this = this, idsOfReviewees = [], allIds;
                _this.authCompanyReviewers.forEach(reviewer => {
                    if (
                        reviewer.reviewer_id == _this.validEmployeeId 
                        && 
                        _this.idsOfReporters.indexOf(reviewer.employee_id) === -1
                        &&
                        idsOfReviewees.indexOf(reviewer.employee_id) === -1
                    ) {
                        idsOfReviewees.splice(
                            idsOfReviewees.length,
                            0,
                            reviewer.employee_id
                        );
                    }
                });
                allIds = [
                    ..._this.idsOfReporters,
                    ...idsOfReviewees
                ];
                return allIds;
            }
        },
        methods: {
            ...mapActions([
                "fetch_company_departments", 
                "fetch_company_KRAs", 
                "fetch_company_goals", 
                "fetch_company_employees_in_goals", 
                "fetch_company_appraisals", 
                "fetch_company_employees_in_appraisals", 
                "fetch_company_questionnaires",
                "fetch_company_employee_kras",
                "fetch_employee_assigned_goals",
                "fetch_company_goals_in_appraisals",
                "fetch_employee_appraisals",
                "fetch_company_reviewers",
                "fetch_company_questionnaire_responses",
                "fetch_employee_appraisal_reviews",
                "fetch_employee_goal_reviews",
                "fetch_employee_reporters",
                "fetch_company_feedbacks",
                "fetch_company_appraisal_feedbacks",
                "fetch_employee_skill_sets",
                "fetch_company_skill_sets"
            ]),
            hasHistory: function () {
                return window.history.length > 2;
            },
            refresh_page() {
                const _this = this;
                _this.fetch_company_KRAs();
                _this.fetch_company_goals();
                _this.fetch_company_employees_in_goals();
            },
            convertToSnakeCase(string) {
                let value = string.replaceAll(' ', '_').toLowerCase();
                return value;
            },
            employeeAppraisalIsEnded(employeeAppraisalId) {
                const _this = this;
                let result = [];
                result = _this.authEmployeeAppraisals.filter(EA => EA.id == employeeAppraisalId);
                if (result.length > 0) {
                    return result[0].status == 0
                }
                return false;
            },
            getGoalCompletionPercentage(goalId, employeeId) {
                const _this = this, KRAs = _this.getKRAsInGoal(goalId);
                const completedKRAs = KRAs.filter(kra => _this.getEmployeeKRAInfo(kra.id, employeeId) && _this.getEmployeeKRAInfo(kra.id, employeeId).is_completed == 1);
                const result = Math.round((completedKRAs.length/KRAs.length)*100) || 0;
                return result;
            },
            getFeedbacksByEmployeeId(employeeId) {
                let _this = this, result = [];
                result = _this.authCompanyFeedbacks.filter(feedback => (feedback.feedback_by == employeeId && feedback.feedback_to == _this.validEmployeeId) || (feedback.feedback_to == employeeId && feedback.feedback_by == _this.validEmployeeId));
                return result;
            },
            getFeedbacksByAppraisalId(employeeId, appraisalId) {
                let _this = this, result = [], resultIds = [], trueResultIds = [], finalVal = [];
                result = _this.authCompanyAppraisalFeedbacks.filter(feedback => feedback.employee_id == employeeId && feedback.appraisal_id == appraisalId);
                resultIds = result.map(feedback => feedback.feedbackcomment_id);

                resultIds.forEach(id => {
                    if (trueResultIds.indexOf(id) === -1) {
                        trueResultIds.splice(trueResultIds.length, 0, id);
                    }
                })

                trueResultIds.forEach(trueResultId => {
                    let val = _this.authCompanyAppraisalFeedbacks.filter(feedback => trueResultId == feedback.feedbackcomment_id)[0];
                    finalVal.splice(finalVal.length, 0, val);
                });

                return finalVal;
            },
            getFeedbackResponses(feedbackCommentId) {
                let _this = this, result = [];
                result = _this.authCompanyAppraisalFeedbacks.filter(feedback => feedback.feedbackcomment_id == feedbackCommentId && feedback.response);
                return result;
            },
            getReportingManagerName(managerId) {
                let _this = this; 
                if (managerId) {
                    let employeeObject = _this.singleEmployeeObject(managerId);
                    if (employeeObject) {
                        return {status: 'found', value: `${employeeObject.employee_fristname} ${employeeObject.employee_lastname}`};
                    } else {
                        return {status: 'not found', value: 'Reporting manager not found'}
                    }
                } else {
                    return {status: 'none added', value: 'No reporting manager added'};
                }
            },
            getTrueEmployeeKRAs(employeeId) {
                let _this = this, result = [];
                result = _this.authEmployeesKRAs.filter(kra => kra.employee_id == employeeId);
                return result;
            },
            getTrueEmployeeAssignedGoals(employeeId) {
                let _this = this, result = [];
                result = _this.authEmployeeAssignedGoals.filter(ag => ag.employee_id == employeeId);
                return result;
            },
            getTrueEmployeeAppraisals(employeeId) {
                let _this = this, result = [];
                result = _this.authEmployeeAppraisals.filter(appraisal => appraisal.employee_id == employeeId);
                return result;
            },
            getEmployeeReviewers(appraisalId, employeeId) {
                const _this = this;
                let result = [], finalVal = [];
                const employee = _this.singleEmployeeObject(employeeId);
                if (employee) {
                    const reportingManagerId = employee.reporting_to;
                    const reportingManager = _this.singleEmployeeObject(reportingManagerId);
                    if (reportingManager) {
                        const reportingManagerObject = {
                            appraisal_id: appraisalId,
                            company_id: _this.authCompanyData.id,
                            created_at: new Date().toDateString(),
                            employee_fristname: `Reporting Manager: ${reportingManager.employee_fristname}`,
                            employee_id: employeeId,
                            employee_lastname: reportingManager.employee_lastname,
                            id: null,
                            reviewer_id: reportingManager.id,
                            updated_at: new Date().toDateString()
                        }
                        finalVal = [reportingManagerObject];
                    }
                }
                result = _this.authCompanyReviewers.filter(reviewer => reviewer.employee_id == employeeId && reviewer.appraisal_id == appraisalId);
                finalVal = [
                    ...finalVal,
                    ...result
                ]
                return finalVal;
            },
            getEmployeeGoalsInfo(appraisalId, employeeId, reviewerId) {
                let _this = this, result = [];
                result = _this.getTrueEmployeeAssignedGoals(employeeId).filter(EAG => _this.goalIsInAppraisal(EAG.goal_id, appraisalId) === true);
                result = result.map(EAG => {
                    let employeeGoalReview = _this.getEmployeeGoalReview(EAG.goal_id, (reviewerId ? reviewerId : employeeId));
                    return {
                        ...EAG,
                        buttonText: 'Submit',
                        buttonLoading: false,
                        rating: employeeGoalReview ? (employeeGoalReview.rating ? parseInt(employeeGoalReview.rating) : 0) : 0,
                        comment: employeeGoalReview ? employeeGoalReview.comment : '',
                        KRAs: _this.getKRAsInGoal(EAG.goal_id)
                    }
                });
                return result;
            },
            getEmployeeGoalReview(goalId, reviewerId) {
                let _this = this, result = null;
                result = _this.authEmployeeGoalReviews.filter(review => {
                    return review.goal_id == goalId && review.reviewer_id == reviewerId
                });
                if (result.length >= 1) {
                    result = result[0];
                } else {
                    result = null;
                }
                return result;
            },
            getRatingInWords(rating) {
                if (rating >= 0 && rating <= 1) {
                    return 'Unsatisfactory';
                }
                if (rating > 1 && rating <= 2) {
                    return 'Needs Improvement';
                }
                if (rating > 2 && rating <= 3) {
                    return 'Satisfactory';
                }
                if (rating > 3 && rating <= 4) {
                    return 'Excellent';
                }
                if (rating > 4 && rating <= 5) {
                    return 'Outstanding';
                }
            },
            getEmployeeFinalScore(employee_id, appraisal_id) {
                let _this = this, allGoalReviews = [], allAppraisalReviews = [], totalGoalRatings = 0, totalAppraisalRatings = 0, value = 0;
                allGoalReviews = _this.authEmployeeGoalReviews.filter(gr => gr.employee_id == employee_id && !!gr.rating);
                allGoalReviews = allGoalReviews.filter(gr => _this.goalIsInAppraisal(gr.goal_id, appraisal_id));
                allAppraisalReviews = _this.authEmployeeAppraisalReviews.filter(ar => ar.employee_id == employee_id && ar.appraisal_id == appraisal_id && !!ar.rating);
                allGoalReviews.forEach(gr => totalGoalRatings += (gr.rating ? parseInt(gr.rating) : 0));
                allAppraisalReviews.forEach(ar => totalAppraisalRatings += (ar.rating ? parseInt(ar.rating) : 0));
                value = ((totalGoalRatings + totalAppraisalRatings)/(allGoalReviews.length + (allAppraisalReviews.length || 1))/5)*100;
                return value >= 0 ? `${value.toFixed(0)}%` : 'No ratings added';
            },
            getEmployeeFinalRating(employee_id, appraisal_id) {
                let _this = this, allGoalReviews = [], allAppraisalReviews = [], totalGoalRatings = 0, totalAppraisalRatings = 0, value = 0;
                allGoalReviews = _this.authEmployeeGoalReviews.filter(gr => gr.employee_id == employee_id && !!gr.rating);
                allGoalReviews = allGoalReviews.filter(gr => _this.goalIsInAppraisal(gr.goal_id, appraisal_id));
                allAppraisalReviews = _this.authEmployeeAppraisalReviews.filter(ar => ar.employee_id == employee_id && ar.appraisal_id == appraisal_id && !!ar.rating);
                allGoalReviews.forEach(gr => totalGoalRatings += (gr.rating ? parseInt(gr.rating) : 0));
                allAppraisalReviews.forEach(ar => totalAppraisalRatings += (ar.rating ? parseInt(ar.rating) : 0));
                value = (totalGoalRatings + totalAppraisalRatings)/(allGoalReviews.length + (allAppraisalReviews.length || 1));
                return value >= 0 ? _this.getRatingInWords(value) : 'No ratings added'
            },
            getEmployeeAppraisalReview(appraisalId, employeeId, reviewerId) {
                let _this = this, result = null;
                result = _this.authEmployeeAppraisalReviews.filter(review => {
                    return review.appraisal_id == appraisalId && review.employee_id == employeeId && review.reviewer_id == reviewerId
                });
                if (result.length >= 1) {
                    result = result[0];
                } else {
                    result = null;
                }
                return result;
            },
            getReviewerFinalScore(employee_id, reviewer_id, appraisal_id) {
                let _this = this, allGoalReviews = [], allAppraisalReviews = [], totalGoalRatings = 0, totalAppraisalRatings = 0, value = 0;
                allGoalReviews = _this.authEmployeeGoalReviews.filter(gr => gr.reviewer_id == reviewer_id && gr.employee_id == employee_id && !!gr.rating);
                allGoalReviews = allGoalReviews.filter(gr => _this.goalIsInAppraisal(gr.goal_id, appraisal_id));
                allAppraisalReviews = _this.authEmployeeAppraisalReviews.filter(ar => ar.reviewer_id == reviewer_id && ar.employee_id == employee_id && ar.appraisal_id == appraisal_id && !!ar.rating);
                allGoalReviews.forEach(gr => totalGoalRatings += (gr.rating ? parseInt(gr.rating) : 0));
                allAppraisalReviews.forEach(ar => totalAppraisalRatings += (ar.rating ? parseInt(ar.rating) : 0));
                value = ((totalGoalRatings + totalAppraisalRatings)/(allGoalReviews.length + (allAppraisalReviews.length || 1))/5)*100;
                return value >= 0 ? `${value.toFixed(0)}%` : 'No ratings added';
            },
            getReviewerFinalRating(employee_id, reviewer_id, appraisal_id) {
                let _this = this, allGoalReviews = [], allAppraisalReviews = [], totalGoalRatings = 0, totalAppraisalRatings = 0, value = 0;
                allGoalReviews = _this.authEmployeeGoalReviews.filter(gr => gr.reviewer_id == reviewer_id && gr.employee_id == employee_id && !!gr.rating);
                allGoalReviews = allGoalReviews.filter(gr => _this.goalIsInAppraisal(gr.goal_id, appraisal_id));
                allAppraisalReviews = _this.authEmployeeAppraisalReviews.filter(ar => ar.reviewer_id == reviewer_id && ar.employee_id == employee_id && ar.appraisal_id == appraisal_id && !!ar.rating);
                allGoalReviews.forEach(gr => totalGoalRatings += (gr.rating ? parseInt(gr.rating) : 0));
                allAppraisalReviews.forEach(ar => totalAppraisalRatings += (ar.rating ? parseInt(ar.rating) : 0));
                value = (totalGoalRatings + totalAppraisalRatings)/(allGoalReviews.length + (allAppraisalReviews.length || 1));
                return value >= 0 ? _this.getRatingInWords(value) : 'No ratings added'
            },
            getResponseByQuestionId(questionId, employeeId) {
                let _this = this, result = null;
                result = _this.authCompanyQuestionnaireResponses.filter(response => {
                    return response.question_id == questionId && response.employee_id == employeeId
                });
                if (result.length >= 1) {
                    result = result[0];
                } else {
                    result = null;
                }
                return result;
            },
            getEmployeeKRAInfo(kra_id, employeeId) {
                let _this = this, result = [];
                result = _this.getTrueEmployeeKRAs(employeeId).filter(ekra => ekra.kra_id == kra_id);
                if (result.length >= 1) {
                    result = result[0];
                } else {
                    result = null;
                }
                return result;
            },
            getKRAsInGoalWithUnapproved(goal_id) {
                let _this = this, result = [];
                result = _this.authCompanyKRAs.filter(kra => kra.goal_id == goal_id);
                return result;
            },
            getKRAsInGoal(goal_id) {
                let _this = this, result = [];
                result = _this.authCompanyKRAs.filter(kra => kra.goal_id == goal_id && kra.status == 1);
                return result;
            },
            get_dept_name(dept_id) {
                let _this = this;
                let dept = _this.authCompanyDepartments.filter(dept => {
                    return dept.id == dept_id;
                });
                if (dept.length > 0) {
                    return dept[0].department_name;
                }
                return null;
            },
            get_designation_name(designation_id) {
                let _this = this;
                let designation = _this.authDesignations.filter(des => {
                    return des.id == designation_id;
                });
                if (designation.length > 0) {
                    return designation[0].title;
                }
                return null;
            },
            getGoalName(goal_id) {
                let result = this.authCompanyGoals.filter((item) => {
                    return item.id == goal_id;
                });
                if (result.length >= 1) {
                    return result[0].goal_name;
                }
                return "";
            },
            getAppraisalById(appraisal_id) {
                let result = this.authCompanyAppraisals.filter((item) => {
                    return item.id == appraisal_id;
                });
                if (result.length >= 1) {
                    return result[0];
                }
                return null;
            },
            getAppraisalByEmployeeId(appraisal_id, employee_id) {
                let result = this.authEmployeeAppraisals.filter((item) => {
                    return (item.appraisal_id == appraisal_id && item.employee_id == employee_id);
                });
                if (result.length >= 1) {
                    return result[0];
                }
                return null;
                
            },
            designationObject(designation) {
                let _this = this,
                    result = null;
                if (_this.authDesignations) {
                    result = _this.authDesignations.filter((d) => {
                        return d.title === designation;
                    });
                    if (result.length) {
                        result = result[0];
                    }
                }
                return null;
            },
            employeeObject(id) {
                let _this = this,
                    result = null;
                result = _this.authCompanyEmployees.filter((e) => e.id == id);
                if (result.length >= 1) {
                    result = result[0];
                }
                return result;
            },
            get_employee_name: function (t) {
                var e = this;
                if (e.authCompanyEmployees) {
                    var a = e.authCompanyEmployees.filter(function (e) {
                        return e.id == t;
                    })[0];
                    return a ? a.employee_fristname + " " + a.employee_lastname : "Not found";
                }
                return "";
            },
            goalIsInAppraisal(goal_id, appraisal_id) {
                let _this = this,
                    truth = false;
                _this.authCompanyGoalsInAppraisals.forEach((item) => {
                    if (item.appraisal_id == appraisal_id && item.goal_id == goal_id) {
                        truth = true;
                    }
                });
                return truth;
            },
            get_employee_id(employee_name) {
                let _this = this,
                    result = [],
                    value = null;
                result = _this.authCompanyEmployees.filter((te) => te.employee_fristname + " " + te.employee_lastname == employee_name);
                if (result.length) {
                    value = result[0].id;
                }
                return value;
            },
            singleEmployeeObject(id) {
                let _this = this, result = null;
                if (!id) {
                    return result;
                }
                if (_this.authCompanyEmployees.length >= 1) {
                    result = _this.authCompanyEmployees.filter(te => te.id == id)[0];
                }
                return result;
            },
            getEmployeeByName(name) {
                let _this = this, result = [], value = null;
                result = _this.authCompanyEmployees.filter(employee => {
                    return `${employee.employee_fristname} ${employee.employee_lastname}` == name;
                });
                if (result.length > 0) {
                    value = result[0];
                }
                return value;
            },
            getEmployeeCommitteeNames(employeeId) {
                const _this = this;
                let employeeCommittees = [], employeeCommitteeNames = [];
                employeeCommittees = _this.authEmployeeCommittees.filter(EMPLOYEE_COMMITTEE => EMPLOYEE_COMMITTEE.employee_id == employeeId);
                employeeCommittees.forEach(EMPLOYEE_COMMITTEE => {
                    let committee = _this.getCommitteeById(EMPLOYEE_COMMITTEE.committee_id);
                    if (committee) {
                        employeeCommitteeNames.splice(
                            employeeCommitteeNames.length,
                            0,
                            committee.committee_name
                        );
                    }
                });
                return employeeCommitteeNames;
            },
            getCommitteeById(committeeId) {
                const _this = this;
                let result = [], value = null;
                result = _this.authCompanyCommittees.filter(COMMITTEE => COMMITTEE.ID == committeeId);
                if (result.length > 0) {
                    value = result[0];
                }
                return value;
            },
            employeeIsUnderReportingManager(employeeId) {
                return this.employeeIdsUnderReportingManager.indexOf(employeeId) !== -1;
            },
            isViewable(tabName) {
                tabName = this.convertToSnakeCase(tabName);
                if (tabName === 'goals') {
                    return this.userType == 1 || this.permissionIsAssigned('goal-read');
                } else if (tabName === 'appraisals') {
                    return this.userType == 1 || this.permissionIsAssigned('appraisal-read');
                } else if (tabName === 'appraisal_reviews') {
                    return this.userType == 1 || this.employeeIdsOfReviewees.length > 0;
                } else if (tabName === 'publishment') {
                    return this.userType == 1 || this.idsOfReporters.length > 0;
                } else {
                    return true;
                }
            }
        },
        mounted() {
            document.title = "Kylian ERP - Performance";
            /* Admin-related endpoints */
            this.fetch_company_KRAs();
            this.fetch_company_goals();
            this.fetch_company_employees_in_goals();
            this.fetch_company_appraisals();
            this.fetch_company_employees_in_appraisals();
            this.fetch_company_questionnaires();
            this.fetch_company_goals_in_appraisals();
            this.fetch_company_reviewers();
            this.fetch_employee_appraisal_reviews();
            this.fetch_employee_goal_reviews();
            this.fetch_employee_reporters();
            this.fetch_company_feedbacks();
            this.fetch_company_appraisal_feedbacks();
            this.fetch_company_skill_sets();
            /* Admin-related endpoints */
            
            /* Employee-related endpoints */
            this.fetch_company_employee_kras();
            this.fetch_employee_assigned_goals();
            this.fetch_employee_appraisals();
            this.fetch_employee_skill_sets();
            /* Employee-related endpoints */

            if (this.userType != 1) {
                if (this.permissionIsAssigned('goal-read') === false) {
                    this.view = 'your_goals';
                }
            }
        },
    };
</script>

<style>
    .remove-msg .v-messages {
        display: none;
    }

    .file-attachment div div div .v-file-input__text {
        cursor: pointer;
    }

    .v-time-picker-clock__item--active {
        color: #fff !important;
        font-size: -webkit-xxx-large !important;
    }

    [class="v-picker__title primary"] {
        background-color: #069 !important;
    }

    .accent {
        background: #069 !important;
    }
    .create-ip:hover {
        color: #069;
    }

    .ip-address div .v-text-field__details {
        display: none;
    }

    .ip-address div .v-input__slot {
        margin-bottom: 0px !important;
    }

    .ip-address .v-input__control {
        min-height: 35px !important;
    }
</style>
