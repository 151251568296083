<template>
    <div class="tab-pane fade" id="list-settings50" role="tabpanel" aria-labelledby="list-settings-list50">
        <div v-if="payCalendarView == 0">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Pay Calendar Management
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div v-if="permissionIsAssigned('paycalendar-create')" class="col-lg-3 offset-9 py-4">
                                <button @click="openCreatePayCalendar" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Create Pay Calendar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div class="row">
                    <div v-for="(pc, index) in authPayCalendars" :key="pc.id" class="col-lg-4 col-md-6 col-sm-6">
                        <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" :style="getStyleByPattern(index)">
                            <div class="card-header">
                                {{ pc.calendar_name }}
                            </div>
                            <div class="row card-body text-center py-2">
                                <p class="col-12 text-left py-1 mb-1"><strong>Calendar Name: </strong>{{ pc.calendar_name }}
                                </p>
                                <p class="col-12 text-left py-1 mb-1"><strong>Calendar Type: </strong>{{ pc.calendar_type }}
                                </p>
                                <p class="col-12 text-left py-1 mb-1"><strong>No. of Employees: </strong>{{
                                    employeesInPayCalendar(pc.id).length || 0 }}</p>
                            </div>
                            <div class="card-footer py-1 text-right row">
                                <div class="col-lg-5 col-4 row py-4">
                                    <div v-if="permissionIsAssigned('paycalendar-delete')"
                                        class="col-lg-4 col-6 py-4 px-0 text-left offset-lg-1">
                                        <button @click="deletePayCalendar(pc)" type="button"
                                            class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                            <i class="fal fa-trash"></i>
                                        </button>
                                    </div>
                                    <div v-if="permissionIsAssigned('paycalendar-update')"
                                        class="col-lg-4 col-6 py-4 px-0 text-left">
                                        <button @click="payCalendarEdit(pc)" type="button"
                                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                            <i class="fal fa-edit"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-8 offset-lg-1 py-4 px-0">
                                    <button v-if="permissionIsAssigned('add-employee-to-paycalendar')" type="button"
                                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                        @click="displayCalendarDetails(pc)">
                                        Add/Remove Employees
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="payCalendarView == 1">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Create Pay Calendar
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="payCalendarView = 0" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    View Pay Calendars
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0 pt-0">
                <div>
                    <v-form ref='form1' @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Calendar Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="payCalendarForm.calendar_name" :rules="[
                                        v => !!v || 'Calendar name is required'
                                    ]" placeholder="Enter calendar name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Calendar Type <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select class="mt-2" :items="['Daily', 'Weekly', 'Bi-Weekly', 'Monthly', 'Yearly']"
                                        placeholder="Select calendar type" solo dense :full-width="true"
                                        v-model="payCalendarForm.calendar_type" :rules="[
                                            v => !!v || 'Calendar type is required'
                                        ]"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Employees <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <button v-if="permissionIsAssigned('add-employee-to-paycalendar')"
                                        @click="addEmployeesToPayCalendar2()" type="button"
                                        class="btn btn-outline-secondary m-1 btn-raised ripple d-block mt-3">
                                        Add/Remove Employees
                                    </button>
                                </v-col>
                                <v-col cols="12">
                                    <span style="color: #111d5e;">Employees on this pay calendar</span>
                                    <div class="card mt-3" style="border-radius: 10px;">
                                        <div class="card-body px-1 py-1 pt-0 pr-0">
                                            <div class="table-responsive"
                                                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'height: 25vh;' : 'height: 25vh;'"
                                                style="border-radius: 10px; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                Employee
                                                            </th>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                Email
                                                            </th>
                                                            <th class="text-left" style="border-top: none;" scope="col">
                                                                Department
                                                            </th>
                                                            <th class="text-left" scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(e, index) in employeesToAddDetails" :key="e.id">
                                                            <td class="text-left">
                                                                {{ e.name }}
                                                            </td>
                                                            <td class="text-left">
                                                                {{ e.email }}
                                                            </td>
                                                            <td class="text-left">
                                                                {{ get_dept_name(e.department_id) }}
                                                            </td>
                                                            <td style="padding: 5px;" class="text-left employee-options">
                                                                <button @click="removeEmployeeFromList(index)" type="button"
                                                                    class="btn btn-outline-secondary m-1 btn-raised ripple d-block btn-sm">
                                                                    Remove
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                                    <button @click="paycalendar_create_submit ? null : paycalendar_create()"
                                        style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ paycalendar_create_text }}
                                        <v-icon
                                            :style="!paycalendar_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Edit Pay Calendar
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="payCalendarView = 0" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    View Pay Calendars
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form ref='form2' @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Calendar Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="editPayCalendarForm.calendar_name" :rules="[
                                        v => !!v || 'Calendar name is required'
                                    ]" placeholder="Enter calendar name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Calendar Type <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select class="mt-2" :items="['Daily', 'Weekly', 'Bi-Weekly', 'Monthly', 'Yearly']"
                                        placeholder="Select calendar type" solo dense :full-width="true"
                                        v-model="editPayCalendarForm.calendar_type" :rules="[
                                            v => !!v || 'Calendar type is required'
                                        ]"></v-select>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                                    <button @click="edit_paycalendar_create_submit ? null : paycalendar_update()"
                                        style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ edit_paycalendar_create_text }}
                                        <v-icon
                                            :style="!edit_paycalendar_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>

        <v-dialog v-model="pay_calendar_employees_dialog" max-width="1200px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Employees to add</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col cols="12">
                                                <h4>Departments</h4>
                                            </v-col>
                                            <v-col v-for="d in selectableDepartments" :key="d.id" cols="12" class="py-0">
                                                <h4 class="font-bold col-12 pb-0 mb-0">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        @change="check_all_department_employees(d.id)" v-model="d.checked">
                                                    </v-checkbox>
                                                    <span style="bottom: 5px; position: relative;">{{ d.department_name
                                                    }}</span>
                                                </h4>
                                                <v-col v-for="e in departmentalEmployees(d.id)" :key="e.id"
                                                    class="mb-0 pb-0 d-none" cols="2">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        :label="e.employee_fristname + ' ' + e.employee_lastname"
                                                        v-model="e.checked"
                                                        @change="checkedEmployeesForPayCalendar(d.id).length === departmentalEmployees(d.id).length ? (d.checked = true) : (d.checked = false)"></v-checkbox>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col cols="12">
                                                <h4>Designations</h4>
                                            </v-col>
                                            <v-col v-for="d in selectableDesignations" :key="d.id" cols="12" class="py-0">
                                                <h4 class="font-bold col-12 pb-0 mb-0">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        @change="check_all_designation_employees(d.title)"
                                                        v-model="d.checked"> </v-checkbox>
                                                    <span style="bottom: 5px; position: relative;">{{ d.title }}</span>
                                                </h4>
                                                <v-col v-for="e in designationEmployees(d.title)" :key="e.id"
                                                    class="mb-0 pb-0 d-none" cols="2">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        :label="e.employee_fristname + ' ' + e.employee_lastname"
                                                        v-model="e.checked"
                                                        @change="checkedEmployeesForPayCalendar2(d.title).length === designationEmployees(d.title).length ? (d.checked = true) : (d.checked = false)"></v-checkbox>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="table-responsive"
                                            :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'height: 25vh;' : 'height: 25vh;'"
                                            style="border-radius: 10px; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="border-top: none;" scope="col">
                                                            Employee
                                                        </th>
                                                        <th class="text-left" style="border-top: none;" scope="col">
                                                            Email
                                                        </th>
                                                        <th class="text-left" style="border-top: none;" scope="col">
                                                            Department
                                                        </th>
                                                        <th class="text-left" scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(e, index) in employeesToAddDetails" :key="e.id">
                                                        <td class="text-left">
                                                            {{ e.name }}
                                                        </td>
                                                        <td class="text-left">
                                                            {{ e.email }}
                                                        </td>
                                                        <td class="text-left">
                                                            {{ get_dept_name(e.department_id) }}
                                                        </td>
                                                        <td style="padding: 5px;" class="text-left employee-options">
                                                            <button @click="removeEmployeeFromList(index)" type="button"
                                                                class="btn btn-outline-secondary m-1 btn-raised ripple d-block btn-sm">
                                                                Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3">
                                        <button @click="add_employees_to_pc_submit ? null : add_to_pay_calendar()"
                                            style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ add_employees_to_pc_text }}
                                            <v-icon
                                                :style="!add_employees_to_pc_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="pay_calendar_employees_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="pay_calendar_employees_dialog2" max-width="1200px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Employees to add</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col cols="12">
                                                <h4>Departments</h4>
                                            </v-col>
                                            <v-col v-for="d in selectableDepartments" :key="d.id" cols="12" class="py-0">
                                                <h4 class="font-bold col-12 pb-0 mb-0">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        @change="check_all_department_employees(d.id)" v-model="d.checked">
                                                    </v-checkbox>
                                                    <span style="bottom: 5px; position: relative;">{{ d.department_name
                                                    }}</span>
                                                </h4>
                                                <v-col v-for="e in departmentalEmployees(d.id)" :key="e.id"
                                                    class="mb-0 pb-0 d-none" cols="2">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        :label="e.employee_fristname + ' ' + e.employee_lastname"
                                                        v-model="e.checked"
                                                        @change="checkedEmployeesForPayCalendar(d.id).length === departmentalEmployees(d.id).length ? (d.checked = true) : (d.checked = false)"></v-checkbox>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col cols="12">
                                                <h4>Designations</h4>
                                            </v-col>
                                            <v-col v-for="d in selectableDesignations" :key="d.id" cols="12" class="py-0">
                                                <h4 class="font-bold col-12 pb-0 mb-0">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        @change="check_all_designation_employees(d.title)"
                                                        v-model="d.checked"> </v-checkbox>
                                                    <span style="bottom: 5px; position: relative;">{{ d.title }}</span>
                                                </h4>
                                                <v-col v-for="e in designationEmployees(d.title)" :key="e.id"
                                                    class="mb-0 pb-0 d-none" cols="2">
                                                    <v-checkbox class="mt-0 d-inline-block mr-5"
                                                        :label="e.employee_fristname + ' ' + e.employee_lastname"
                                                        v-model="e.checked"
                                                        @change="checkedEmployeesForPayCalendar2(d.title).length === designationEmployees(d.title).length ? (d.checked = true) : (d.checked = false)"></v-checkbox>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="pay_calendar_employees_dialog2 = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        get_dept_name: Function
    },
    data() {
        return {
            paycalendar_create_text: "Submit",
            paycalendar_create_submit: false,
            edit_paycalendar_create_text: "Update",
            edit_paycalendar_create_submit: false,
            add_employees_to_pc_submit: false,
            add_employees_to_pc_text: "Submit",
            payCalendarView: 0,
            payCalendarForm: {
                calendar_name: "",
                calendar_type: "",
                payday_mode: ""
            },
            editPayCalendarForm: {
                id: "",
                calendar_name: "",
                calendar_type: "",
                payday_mode: ""
            },
            pay_calendar_employees_dialog: false,
            pay_calendar_employees_dialog2: false,
            employeesToAddDetails: [],
            selectableDepartments: [],
            selectableDesignations: [],
            companyEmployees: [],
            currentPayCalendar: null
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authUserData',
            'authPayCalendars',
            'authCompanyDepartments',
            'authDesignations',
            'authEmployeePayCalendars',
            'true_employees'
        ]),
        allEmployeesInPayCalendar() {
            let _this = this,
                result = [];
            result = _this.authEmployeePayCalendars.map((e) => {
                return {
                    ...e,
                    department_id: _this.employeeObject(e.employee_id) ? _this.employeeObject(e.employee_id).department_id : null,
                    email: _this.employeeObject(e.employee_id) ? _this.employeeObject(e.employee_id).employee_email : null,
                };
            });
            return result;
        },
        employeeIdsToAdd() {
            let _this = this,
                checked = [],
                ids = [];
            checked = _this.companyEmployees.filter((ce) => {
                return ce.checked;
            });
            ids = checked.map((c) => {
                return {
                    company_id: _this.authCompanyData.id,
                    employee_id: c.id,
                    paycalendar_id: _this.currentPayCalendar.id,
                    department_id: c.department_id,
                };
            });
            return ids;
        },
    },
    methods: {
        ...mapActions([
            'fetch_all_pay_calendar',
            'fetch_company_departments',
            'fetch_all_designations',
            'fetch_all_employees_pay_calendar',
            'fetch_active_company_employees'
        ]),
        employeeObject(id) {
            let _this = this,
                result = null;
            result = _this.true_employees.filter((e) => e.id == id);
            if (result.length >= 1) {
                result = result[0];
            }
            return result;
        },
        designationEmployees(designation) {
            let _this = this,
                result = [];
            if (_this.authDesignations && _this.companyEmployees) {
                result = _this.companyEmployees.filter((te) => {
                    return te.designation == designation;
                });
            }
            return result;
        },
        check_all_designation_employees(des) {
            let _this = this;
            _this.designationEmployees(des).forEach((e) => {
                e.checked = false;
                // designation.checked = true;
            });

            let designation = _this.selectableDesignations.filter((d) => d.title == des)[0];

            if (_this.checkedEmployeesForPayCalendar2(des).length !== _this.designationEmployees(des).length) {
                if (designation.checked) {
                    _this.designationEmployees(des).forEach((e) => {
                        e.checked = true;
                        // designation.checked = true;
                    });
                } else {
                    _this.departmentalEmployees(des).forEach((e) => {
                        e.checked = false;
                        // designation.checked = true;
                    });
                }
            } else {
                if (designation.checked) {
                    _this.designationEmployees(des).forEach((e) => {
                        e.checked = true;
                        // designationEmployees.checked = false;
                    });
                } else {
                    _this.designationEmployees(des).forEach((e) => {
                        e.checked = false;
                        // designationEmployees.checked = false;
                    });
                }
            }
            _this.updateEmployeesToAdd();
        },
        checkedEmployeesForPayCalendar(id) {
            let _this = this,
                result = [];
            result = _this.departmentalEmployees(id).filter((e) => e.checked);
            return result;
        },
        checkedEmployeesForPayCalendar2(designation) {
            let _this = this,
                result = [];
            result = _this.designationEmployees(designation).filter((e) => e.checked);
            return result;
        },
        departmentalEmployees(id) {
            let _this = this,
                result = [];
            if (_this.authCompanyDepartments && _this.companyEmployees) {
                result = _this.companyEmployees.filter((te) => {
                    return te.department_id == id;
                });
            }
            return result;
        },
        check_all_department_employees(id) {
            const _this = this;
            _this.departmentalEmployees(id).forEach((e) => {
                e.checked = false;
                // department.checked = true;
            });

            let department = _this.selectableDepartments.filter((d) => d.id == id)[0];

            if (_this.checkedEmployeesForPayCalendar(id).length !== _this.departmentalEmployees(id).length) {
                if (department.checked) {
                    _this.departmentalEmployees(id).forEach((e) => {
                        e.checked = true;
                        // department.checked = true;
                    });
                } else {
                    _this.departmentalEmployees(id).forEach((e) => {
                        e.checked = false;
                        // department.checked = true;
                    });
                }
            } else {
                if (department.checked) {
                    _this.departmentalEmployees(id).forEach((e) => {
                        e.checked = true;
                        // department.checked = false;
                    });
                } else {
                    _this.departmentalEmployees(id).forEach((e) => {
                        e.checked = false;
                        // department.checked = false;
                    });
                }
            }
            _this.updateEmployeesToAdd();
        },
        add_to_pay_calendar() {
            let _this = this;
            _this.add_employees_to_pc_text = "";
            _this.add_employees_to_pc_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}addemployeestopaycalendar`, {
                company_id: _this.authCompanyData.id,
                paycalendar_id: _this.currentPayCalendar.id,
                employeecollections: _this.employeesToAddDetails,
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: _this.employeeIdsToAdd.length == 1 ? "The employee has been added successfully" : "The employees have been added successfully",
                    });
                    // FETCH EMPLOYEE IN PAYCALENDAR
                    _this.fetch_all_employees_pay_calendar();
                    _this.companyEmployees.forEach((ce) => {
                        ce.checked = false;
                    });
                    _this.pay_calendar_employees_dialog = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.employeecollections
                                    ? errors.employeecollections.length
                                        ? errors.employeecollections[0]
                                        : null
                                    : null
                            : error.response.data.error || error.response.data.message || _this.employeeIdsToAdd.length == 1
                                ? "The employee couldn't be added"
                                : "The employees couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_employees_to_pc_submit = false;
                    _this.add_employees_to_pc_text = "Update";
                });
        },
        updateEmployeesToAdd() {
            let _this = this,
                checked = [],
                employees = [];
            checked = _this.companyEmployees.filter((ce) => {
                return ce.checked;
            });
            employees = checked.map((c) => {
                return {
                    employee_id: c.id,
                    company_id: _this.authCompanyData.id,
                    name: c.employee_fristname + " " + c.employee_lastname,
                    email: _this.employeeObject(c.id).employee_email,
                    department_id: _this.employeeObject(c.id).department_id,
                    designation: c.designation,
                    paycalendar_id: _this.currentPayCalendar ? _this.currentPayCalendar.id : null,
                };
            });
            _this.employeesToAddDetails = employees;
        },
        removeEmployeeFromList(index) {
            let _this = this,
                department = null,
                employee = null,
                designation = null;
            employee = _this.employeesToAddDetails[index];
            department = _this.selectableDepartments.filter((d) => d.id == employee.department_id);
            designation = _this.selectableDesignations.filter((d) => d.title == employee.designation);
            if (department.length) {
                department = department[0];
                department.checked = false;
                _this.employeesToAddDetails.splice(index, 1);
            } else if (designation.length) {
                designation = designation[0];
                designation.checked = false;
                _this.employeesToAddDetails.splice(index, 1);
            } else {
                _this.employeesToAddDetails.splice(index, 1);
            }
        },
        addEmployeesToPayCalendar2() {
            let _this = this;
            _this.pay_calendar_employees_dialog2 = true;
        },
        employeesInPayCalendar(id) {
            let _this = this,
                result = [];
            if (_this.allEmployeesInPayCalendar.length >= 1) {
                result = _this.allEmployeesInPayCalendar.filter((pc) => pc.paycalendar_id == id);
            }
            return result;
        },
        displayCalendarDetails(payCalendar) {
            let _this = this,
                employees = [],
                employeesToAdd = [];
            _this.selectableDepartments.forEach((d) => (d.checked = false));
            _this.selectableDesignations.forEach((d) => (d.checked = false));
            _this.currentPayCalendar = payCalendar;
            employeesToAdd = _this.employeesInPayCalendar(payCalendar.id);
            employees = employeesToAdd.map((e) => {
                return {
                    employee_id: e.id,
                    company_id: _this.authCompanyData.id,
                    name: e.employee_fristname + " " + e.employee_lastname,
                    email: e.email,
                    department_id: e.department_id,
                    designation: e.designation,
                    paycalendar_id: _this.currentPayCalendar ? _this.currentPayCalendar.id : null,
                };
            });
            _this.employeesToAddDetails = employees;
            _this.pay_calendar_employees_dialog = true;
        },
        openCreatePayCalendar() {
            let _this = this;
            _this.companyEmployees.forEach((ce) => {
                ce.checked = false;
            });
            _this.payCalendarView = 1;
        },
        payCalendarEdit(pc) {
            this.editPayCalendarForm.id = pc.id;
            this.editPayCalendarForm.calendar_name = pc.calendar_name;
            this.editPayCalendarForm.calendar_type = pc.calendar_type;
            this.editPayCalendarForm.payday_mode = pc.payday_mode;
            this.payCalendarView = 2;
        },
        async paycalendar_create() {
            const _this = this;
            const valid = await _this.$refs.form1.validate();
            if (!valid) {
                return;
            }
            _this.paycalendar_create_text = "";
            _this.paycalendar_create_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}createpaycalendar`, {
                company_id: _this.authCompanyData.id,
                calendar_name: _this.payCalendarForm.calendar_name,
                calendar_type: _this.payCalendarForm.calendar_type,
                payday_mode: _this.payCalendarForm.payday_mode,
                user_name: _this.authUserData.name,
                employeecollections: _this.employeesToAddDetails,
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Pay calendar added successfully",
                    });
                    _this.payCalendarForm.calendar_name = "";
                    _this.payCalendarForm.calendar_type = "";
                    _this.payCalendarForm.payday_mode = "";
                    _this.fetch_all_pay_calendar();
                    _this.fetch_all_employees_pay_calendar();
                    _this.companyEmployees.forEach((ce) => {
                        ce.checked = false;
                    });
                    _this.payCalendarView = 0;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors,
                    }) ||
                        Toast.fire({
                            icon: "error",
                            title: errors
                                ? typeof errors === "string"
                                    ? errors
                                    : null || errors.calendar_name
                                        ? errors.calendar_name[0]
                                        : null || errors.calendar_type
                                            ? errors.calendar_type[0]
                                            : null || errors.payday_mode
                                                ? errors.payday_mode[0]
                                                : null
                                : null || error.response.data.error || error.response.data.message || "The pay calendar details couldn't be added",
                        });
                })
                .then(function () {
                    _this.paycalendar_create_submit = false;
                    _this.paycalendar_create_text = "Submit";
                });
        },
        async paycalendar_update() {
            const _this = this;
            const valid = await _this.$refs.form2.validate();
            if (!valid) {
                return;
            }
            _this.edit_paycalendar_create_text = "";
            _this.edit_paycalendar_create_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updatepaycalendar`, {
                company_id: _this.authCompanyData.id,
                calendar_name: _this.editPayCalendarForm.calendar_name,
                calendar_type: _this.editPayCalendarForm.calendar_type,
                payday_mode: _this.editPayCalendarForm.payday_mode,
                user_name: _this.authUserData.name,
                paycalendar_id: _this.editPayCalendarForm.id,
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The pay calendar has been updated successfully",
                    });
                    _this.editPayCalendarForm.id = "";
                    _this.editPayCalendarForm.calendar_name = "";
                    _this.editPayCalendarForm.calendar_type = "";
                    _this.editPayCalendarForm.payday_mode = "";
                    _this.fetch_all_pay_calendar();
                    _this.payCalendarView = 0;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.calendar_name
                                    ? errors.calendar_name[0]
                                    : null || errors.calendar_type
                                        ? errors.calendar_type[0]
                                        : null || errors.payday_mode
                                            ? errors.payday_mode[0]
                                            : null
                            : null || error.response.data.error || error.response.data.message || "The vendor details couldn't be added",
                    });
                })
                .then(function () {
                    _this.edit_paycalendar_create_submit = false;
                    _this.edit_paycalendar_create_text = "Update";
                });
        },
        deletePayCalendar(pc) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this pay calendar?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}deletepaycalendar`, {
                            paycalendar_id: pc.id,
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "The pay calendar has been deleted successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "The pay calendar couldn't be deleted",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_all_pay_calendar();
                            });
                    }
                });
        },

    },
    mounted() {
        const _this = this;
        _this.fetch_all_pay_calendar();
        _this.fetch_all_employees_pay_calendar();
        _this.fetch_company_departments().then(() => {
            if (_this.authCompanyDepartments.length >= 1) {
                _this.selectableDepartments = _this.authCompanyDepartments.map(({ id, department_name }) => {
                    return {
                        id,
                        department_name,
                        checked: false,
                    };
                });
            }
        });
        _this.fetch_all_designations().then(() => {
            if (_this.authDesignations.length >= 1) {
                _this.selectableDesignations = _this.authDesignations.map(({ id, title }) => {
                    return {
                        id,
                        title,
                        checked: false,
                    };
                });
            }
        });
        _this.fetch_active_company_employees().then(() => {
            _this.true_employees.forEach((e) => {
                _this.companyEmployees.splice(_this.companyEmployees.length, 0, {
                    id: e.id,
                    employee_fristname: e.employee_fristname,
                    employee_lastname: e.employee_lastname,
                    department_id: e.department_id,
                    employee_email: e.employee_email,
                    designation: e.employee_designation,
                    paycalendar_id: _this.currentPayCalendar ? _this.currentPayCalendar.id : null,
                    checked: false,
                });
            });
        });
    }
}
</script>

<style></style>