<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">
          <div v-if="ids.length >= 1" class="dropdown animate__animated animate__fadeIn">
            <button @click="convert_applicants_submit ? null : converttocandidates()" style="float: right;"
              :style="convert_applicants_submit ? 'width: 12em;' : null" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              {{ convert_applicants_text }}
              <v-icon :style="
                !convert_applicants_submit
                  ? 'display: none;'
                  : 'color: #fff; padding: 0px 10px; font-size: 16px;'
              ">
                fas fa-circle-notch fa-spin
              </v-icon>
            </button>
          </div>
          <div v-else class="dropdown animate__animated animate__fadeIn">
            <button v-if="permissionIsAssigned('recruitment-read')" @click="$router.push({ name: 'Recruitment' })"
              style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              View All Openings
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb row"
          :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'flex-direction: row;' : null"
          style="margin-top: 1rem;">
          <div class="col-8">
            <h1>
              <span>Job Title: {{ current_job.job_title }}</span>
            </h1>
          </div>
          <div class="col-4">
            <button @click="filterDialog = true" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              Filter
            </button>
          </div>
        </div>
        <div class="main-content mt-0 row pr-0"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
          <div class="card w-100" style="border-radius: 10px;">
            <div class="card-body px-1 py-1 pt-0 pr-0">
              <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                <table class="table">
                  <thead>
                    <tr>
                      <th v-if="permissionIsAssigned('applicants-approve')" style="width: 80px; border-top: none;">
                        <label class="checkbox checkbox-secondary mb-3">
                          <input type="checkbox"
                            :checked="ids.length == applicantsFiltered.length && applicantsFiltered.length != 0"
                            @change="check_all()" />
                          <span class="checkmark"></span>
                        </label>
                      </th>
                      <th class="text-left" style="border-top: none;" scope="col">Name</th>
                      <th class="text-left" style="border-top: none;" scope="col">Email</th>
                      <th class="text-left" style="border-top: none;" scope="col">Phone Number</th>
                      <th class="text-left" style="border-top: none;" scope="col">Age</th>
                      <th class="text-left" style="border-top: none;" scope="col">Marital Status</th>
                      <th class="text-left" style="border-top: none;" scope="col">Address</th>
                      <th class="text-left" style="border-top: none;" scope="col">Highest Qualification</th>
                      <th class="text-left" style="border-top: none;" scope="col">Years of Experience</th>
                      <th class="text-left" style="border-top: none;" scope="col">Date Applied</th>
                      <th class="text-left" style="border-top: none;" scope="col">Full Details</th>
                      <th class="text-left" style="border-top: none;" scope="col">Resume</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="a in applicantsFiltered" :key="a.id">
                      <th v-if="permissionIsAssigned('applicants-approve')" scope="row">
                        <label class="checkbox checkbox-secondary">
                          <input type="checkbox" :checked="ids.indexOf(a.id) != -1"
                            @change="ids.indexOf(a.id) == -1 ? ids.splice(ids.length - 1, 0, a.id) : ids.splice(ids.indexOf(a.id), 1)" />
                          <span class="checkmark"></span>
                        </label>
                      </th>
                      <td class="text-left">{{ a.first_name }} {{ a.last_name }}</td>
                      <td class="text-left">{{ a.email }}</td>
                      <td class="text-left">{{ a.phone_number }}</td>
                      <td class="text-left">{{ getAge(a.date_of_birth) }} years</td>
                      <td class="text-left">{{ a.marrital_status }}</td>
                      <td class="text-left">{{ a.address }}</td>
                      <td class="text-left">{{ a.highest_qualification }}</td>
                      <td class="text-left">{{ a.years_of_experience }} years</td>
                      <td class="text-left">{{ new Date(a.date_applied).toUTCString().substring(0, 16) }}
                      </td>
                      <td class="text-left">
                        <span style="color: #069; cursor: pointer;" @click="viewFullDetails(a)">View</span>
                      </td>
                      <td class="text-left">
                        <a style="color: #069 !important; cursor: pointer;" :href="getDownloadLink(a.id)"
                          :download="'Resume - ' + a.first_name + ' ' + a.last_name">Download</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1"></div>
    </div>
    <v-row>
      <v-dialog v-model="filterDialog" max-width="900px">
        <v-card>
          <v-card-title class="text-left d-block">
            <span class="headline">Filter All Applicants</span>
          </v-card-title>
          <v-card-text>
            <v-container class="text-left">
              <div class="main-content filter-radio py-0 mt-2 px-0">
                <h5>Education</h5>
                <v-radio-group v-model="filters.education" row @change="filterEducation()">
                  <v-radio class="mb-2" label="All" value="All"></v-radio>
                  <v-radio class="mb-2" label="O-Level" value="O-Level"></v-radio>
                  <v-radio class="mb-2" label="Certificate/Diploma" value="Certificate/Diploma"></v-radio>
                  <v-radio class="mb-2" label="A-Level" value="A-Level"></v-radio>
                  <v-radio class="mb-2" label="OND" value="OND"></v-radio>
                  <v-radio class="mb-2" label="HND" value="HND"></v-radio>
                  <v-radio class="mb-2" label="BSc" value="BSc"></v-radio>

                  <v-radio class="mb-2" label="PGD" value="PGD"></v-radio>
                  <v-radio class="mb-2" label="MSc" value="MSc"></v-radio>
                  <v-radio class="mb-2" label="PhD" value="PhD"></v-radio>
                </v-radio-group>
                <h5>Age</h5>
                <v-radio-group v-model="filters.age" row>
                  <v-radio class="mb-2" label="All" value="All"></v-radio>
                  <v-radio class="mb-2" label="Under 18" value="under_18"></v-radio>
                  <v-radio class="mb-2" label="18 - 26" value="18_26"></v-radio>
                  <v-radio class="mb-2" label="27 - 35" value="27_35"></v-radio>
                  <v-radio class="mb-2" label="36 - 60" value="36_60"></v-radio>
                  <v-radio class="mb-2" label="Over 60" value="over_60"></v-radio>
                </v-radio-group>
                <h5>Marital Status</h5>
                <v-radio-group v-model="filters.maritalStatus" row>
                  <v-radio class="mb-2" label="All" value="All"></v-radio>
                  <v-radio class="mb-2" label="Single" value="Single"></v-radio>
                  <v-radio class="mb-2" label="Married" value="Married"></v-radio>
                </v-radio-group>
                <h5>Gender</h5>
                <v-radio-group v-model="filters.sex" row>
                  <v-radio class="mb-2" label="All" value="All"></v-radio>
                  <v-radio class="mb-2" label="Male" value="Male"></v-radio>
                  <v-radio class="mb-2" label="Female" value="Female"></v-radio>
                </v-radio-group>
                <h5>Years Experience</h5>
                <v-radio-group v-model="filters.yearsExperience" row>
                  <v-radio class="mb-2" label="All" value="All"></v-radio>
                  <v-radio class="mb-2" label="0" value="0"></v-radio>
                  <v-radio class="mb-2" label="1" value="1"></v-radio>
                  <v-radio class="mb-2" label="2" value="2"></v-radio>
                  <v-radio class="mb-2" label="3" value="3"></v-radio>
                  <v-radio class="mb-2" label="4" value="4"></v-radio>
                  <v-radio class="mb-2" label="5-7" value="5_7"></v-radio>
                  <v-radio class="mb-2" label="8-10" value="8_10"></v-radio>
                  <v-radio class="mb-2" label="11-15" value="11_15"></v-radio>
                  <v-radio class="mb-2" label="16-20" value="16_20"></v-radio>
                  <v-radio class="mb-2" label="21+" value="21+"></v-radio>
                </v-radio-group>
                <h5>Grade</h5>
                <v-radio-group v-model="filters.grade" row>
                  <v-radio class="mb-2" label="All" value="All"></v-radio>
                  <v-radio class="mb-2" label="First Class" value="First Class"></v-radio>
                  <v-radio class="mb-2" label="Second Class Upper" value="Second Class Upper"></v-radio>
                  <v-radio class="mb-2" label="Second Class Lower" value="Second Class Lower"></v-radio>
                  <v-radio class="mb-2" label="Third Class" value="Third Class"></v-radio>
                  <v-radio class="mb-2" label="Distinction" value="Distinction"></v-radio>
                  <v-radio class="mb-2" label="Credit" value="Credit"></v-radio>
                  <v-radio class="mb-2" label="Pass" value="Pass"></v-radio>
                </v-radio-group>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="filterDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="fullDetails" max-width="900px">
        <v-card>
          <v-card-title class="text-left d-block">
            <span class="headline">Full Profile for {{ currentApplicant.first_name }} {{ currentApplicant.last_name
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container class="text-left">
              <!-- <img :src="assets_url + currentApplicant.photo" alt=""> -->
              <v-row>
                <v-col lg="6" md="6" cols="12">
                  <strong>First Name: </strong>{{ currentApplicant.first_name }}
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <strong>Last Name: </strong>{{ currentApplicant.last_name }}
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <strong>Middle Name: </strong>{{ currentApplicant.middle_name }}
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <strong>Email: </strong>{{ currentApplicant.email }}
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <strong>Phone Number: </strong>{{ currentApplicant.phone_number }}
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <strong>Date of Birth: </strong>{{ currentApplicant.date_of_birth }}
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <strong>Gender: </strong>{{ currentApplicant.sex }}
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <strong>Address: </strong>{{ currentApplicant.address }}
                </v-col>
                <v-col lg="12" md="12" cols="12">
                  <strong>Photo: </strong>
                  <img v-if="currentApplicant.photo" :src="assets_url + currentApplicant.photo" alt="">
                  <i v-else>No image uploaded</i>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <h4>Educational Background</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="color: #111d5e;" class="text-left">Institution Name</th>
                      <th style="color: #111d5e;" class="text-left">Institution Department</th>
                      <th style="color: #111d5e;" class="text-left">Degree/Diploma</th>
                      <th style="color: #111d5e;" class="text-left">Grade</th>
                      <th style="color: #111d5e;" class="text-left">Start Date</th>
                      <th style="color: #111d5e;" class="text-left">End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="i in currentInstitutions" :key="i.id">
                      <td class="text-left">
                        {{ i.institution_name }}
                      </td>
                      <td class="text-left">
                        {{ i.institution_department }}
                      </td>
                      <td class="text-left">
                        {{ i.course_of_study }}
                      </td>
                      <td class="text-left">
                        {{ i.grade }}
                      </td>
                      <td class="text-left">
                        {{ i.institutions_start_date }}
                      </td>
                      <td class="text-left">
                        {{ i.institutions_end_date }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-row>
              <br />
              <v-row>
                <h4>Work Experience</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="color: #111d5e;" class="text-left">Company Name</th>
                      <th style="color: #111d5e;" class="text-left">Currently Work Here</th>
                      <th style="color: #111d5e;" class="text-left">Department</th>
                      <th style="color: #111d5e;" class="text-left">Role Description</th>
                      <th style="color: #111d5e;" class="text-left">Achievements</th>
                      <th style="color: #111d5e;" class="text-left">Start Date</th>
                      <th style="color: #111d5e;" class="text-left">End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="w in currentWorkExperiences" :key="w.id">
                      <td class="text-left">
                        {{ w.company_name }}
                      </td>
                      <td class="text-left">
                        {{ w.designation }}
                      </td>
                      <td class="text-left">
                        {{ w.department }}
                      </td>
                      <td class="text-left">
                        {{ w.role_description }}
                      </td>
                      <td class="text-left">
                        {{ w.achievements }}
                      </td>
                      <td class="text-left">
                        {{ w.workexperiences_start_date }}
                      </td>
                      <td class="text-left">
                        {{ w.workexperiences_end_date }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-row>
              <br />
              <v-row>
                <h4>Referees</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="color: #111d5e;" class="text-left">Referee Name</th>
                      <th style="color: #111d5e;" class="text-left">Referee Address</th>
                      <th style="color: #111d5e;" class="text-left">Referee Email</th>
                      <th style="color: #111d5e;" class="text-left">Referee Phone</th>
                      <th style="color: #111d5e;" class="text-left">Referee Designation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="r in currentReferees" :key="r.id">
                      <td class="text-left">
                        {{ r.referee_name }}
                      </td>
                      <td class="text-left">
                        {{ r.referee_address }}
                      </td>
                      <td class="text-left">
                        {{ r.referee_email }}
                      </td>
                      <td class="text-left">
                        {{ r.referee_phone }}
                      </td>
                      <td class="text-left">
                        {{ r.referee_designation }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fullDetails = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, ASSETS_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      filters: {
        education: 'All',
        age: 'All',
        maritalStatus: 'All',
        sex: 'All',
        yearsExperience: 'All',
        grade: 'All'
      },
      convert_applicants_submit: false,
      convert_applicants_text: 'Convert to Candidate(s)',
      fullDetails: false,
      filterDialog: false,
      currentApplicant: '',
      currentInstitutions: null,
      currentWorkExperiences: null,
      currentReferees: null,
      ids: [],
      job_link_dialog: false,
      job_link: '',
      menu: false,
      menu2: false,
      qualifications_list: [
        'Ph.D',
        'M.Sc',
        'B.Sc',
        'HND',
        'OND',
        'O-Level',
        'First School Leaving Certificate',
      ],
      vendor_names: [],
      dialog: false,
      create_jobs_text: "Submit",
      create_jobs_submit: false,
      update_job_text: "Update",
      update_job_submit: false,
      create_jobs: 2,
      formData: {
        job_title: "",
        description: "",
        benefits: "",
        qualifications: "",
        requirements: "",
        start_date: "",
        end_date: ""
      },
      editFormData: {
        job_id: "",
        job_title: "",
        description: "",
        benefits: "",
        qualifications: "",
        requirements: "",
        start_date: "",
        end_date: ""
      },
      allEmployees: [],
      filtered_applicants: []
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "authUserData",
      "authCompanyEmployees",
      "userType",
      "authCompanyAllVendors",
      "auth_created_jobs",
      "auth_applicants",
      "auth_works",
      "auth_institutions",
      "auth_referees",
      "auth_professionalbodies"
    ]),
    assets_url() {
      return ASSETS_URL
    },

    applicantsFiltered() {
      let _this = this, filtered_applicants = [], final_filtered_applicants = [];
      // FILTER FOR EDUCATION
      let institutions = null;
      institutions = _this.auth_institutions.filter(e => {
        if (_this.filters.education.toLowerCase() == 'all' || _this.filters.education.toLowerCase() == 'o-level') {
          return e == e
        } else if (_this.filters.education.toLowerCase() == 'certificate/diploma') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd' || e.course_of_study.toLowerCase() == 'ond' || e.course_of_study.toLowerCase() == 'a-level' || e.course_of_study.toLowerCase() == 'certificate/diploma'
        } else if (_this.filters.education.toLowerCase() == 'a-level') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd' || e.course_of_study.toLowerCase() == 'ond' || e.course_of_study.toLowerCase() == 'a-level'
        } else if (_this.filters.education.toLowerCase() == 'ond') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd' || e.course_of_study.toLowerCase() == 'ond'
        } else if (_this.filters.education.toLowerCase() == 'hnd') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd'
        } else if (_this.filters.education.toLowerCase() == 'bsc') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc'
        } else if (_this.filters.education.toLowerCase() == 'pgd') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd'
        } else if (_this.filters.education.toLowerCase() == 'msc') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc'
        } else {
          return e.course_of_study.toLowerCase() == 'phd'
        }
      })
      _this.applicants.forEach(a => {
        institutions.forEach(e => {
          if (e.jobbiodetail_id == a.id) {
            if (filtered_applicants.indexOf(a) == -1) {
              filtered_applicants.splice(filtered_applicants.length - 1, 0, a)
            }
          }
        })
      })
      // END OF FILTER FOR EDUCATION

      // FILTER FOR AGE
      switch (_this.filters.age.toLowerCase()) {
        case 'under_18':
          filtered_applicants = filtered_applicants.filter(a => {
            let age = Math.floor((new Date().getTime() - new Date(a.date_of_birth).getTime()) / 1000 / 60 / 60 / 24 / 365);
            return age < 18;
          })
          break;

        case '18_26':
          filtered_applicants = filtered_applicants.filter(a => {
            let age = Math.floor((new Date().getTime() - new Date(a.date_of_birth).getTime()) / 1000 / 60 / 60 / 24 / 365);
            return age >= 18 && age <= 26;
          })
          break;

        case '27_35':
          filtered_applicants = filtered_applicants.filter(a => {
            let age = Math.floor((new Date().getTime() - new Date(a.date_of_birth).getTime()) / 1000 / 60 / 60 / 24 / 365);
            return age >= 27 && age <= 35;
          })
          break;

        case '36_60':
          filtered_applicants = filtered_applicants.filter(a => {
            let age = Math.floor((new Date().getTime() - new Date(a.date_of_birth).getTime()) / 1000 / 60 / 60 / 24 / 365);
            return age >= 36 && age <= 60;
          })
          break;

        case 'over_60':
          filtered_applicants = filtered_applicants.filter(a => {
            let age = Math.floor((new Date().getTime() - new Date(a.date_of_birth).getTime()) / 1000 / 60 / 60 / 24 / 365);
            return age > 60;
          })
          break;

        default:
          //
          break;
      }
      // END OF FILTER FOR AGE

      // FILTER FOR MARITAL STATUS
      switch (_this.filters.maritalStatus.toLowerCase()) {
        case 'single':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.marrital_status.toLowerCase() == 'single'
          })
          break;

        case 'married':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.marrital_status.toLowerCase() == 'married'
          })
          break;

        default:
          //
          break;
      }
      // END OF FILTER FOR MARITAL STATUS

      // FILTER FOR GENDER
      switch (_this.filters.sex.toLowerCase()) {
        case 'male':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.sex.toLowerCase() == 'male'
          })
          break;

        case 'female':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.sex.toLowerCase() == 'female'
          })
          break;

        default:
          //
          break;
      }
      // END OF FILTER FOR GENDER

      // FILTER FOR YEARS OF EXPERIENCE
      switch (_this.filters.yearsExperience) {
        case '0':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 0
          })
          break;

        case '1':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 1
          })
          break;

        case '2':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 2
          })
          break;

        case '3':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 3
          })
          break;

        case '4':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 4
          })
          break;

        case '5_7':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 5
          })
          break;

        case '8_10':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 8
          })
          break;

        case '11_15':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 11
          })
          break;

        case '16_20':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 16
          })
          break;

        case '21+':
          filtered_applicants = filtered_applicants.filter(a => {
            return a.years_of_experience >= 21
          })
          break;

        default:
          //
          break;
      }
      // END OF FILTER FOR YEARS OF EXPERIENCE

      // FILTER FOR SCHOOL GRADES
      let grades = null;
      grades = _this.auth_institutions.filter(e => {
        if (_this.filters.grade.toLowerCase() == 'all' || _this.filters.grade.toLowerCase() == 'pass') {
          return e == e
        } else if (_this.filters.grade.toLowerCase() == 'credit') {
          return e.grade.toLowerCase() == 'first class' || e.grade.toLowerCase() == 'second class upper' || e.grade.toLowerCase() == 'second class lower' || e.grade.toLowerCase() == 'third class' || e.grade.toLowerCase() == 'distinction' || e.grade.toLowerCase() == 'credit'
        } else if (_this.filters.grade.toLowerCase() == 'distinction') {
          return e.grade.toLowerCase() == 'first class' || e.grade.toLowerCase() == 'second class upper' || e.grade.toLowerCase() == 'second class lower' || e.grade.toLowerCase() == 'third class' || e.grade.toLowerCase() == 'distinction'
        } else if (_this.filters.grade.toLowerCase() == 'third class') {
          return e.grade.toLowerCase() == 'first class' || e.grade.toLowerCase() == 'second class upper' || e.grade.toLowerCase() == 'second class lower' || e.grade.toLowerCase() == 'third class'
        } else if (_this.filters.grade.toLowerCase() == 'second class lower') {
          return e.grade.toLowerCase() == 'first class' || e.grade.toLowerCase() == 'second class upper' || e.grade.toLowerCase() == 'second class lower'
        } else if (_this.filters.grade.toLowerCase() == 'second class upper') {
          return e.grade.toLowerCase() == 'first class' || e.grade.toLowerCase() == 'second class upper'
        } else {
          return e.grade.toLowerCase() == 'first class'
        }
      })

      filtered_applicants.forEach(a => {
        grades.forEach(e => {
          if (e.jobbiodetail_id == a.id) {
            if (final_filtered_applicants.indexOf(a) == -1) {
              final_filtered_applicants.splice(final_filtered_applicants.length - 1, 0, a)
            }
          }
        })
      })
      // END OF FILTER FOR SCHOOL GRADES


      return final_filtered_applicants;
    },
    applicants() {
      let _this = this;
      if (_this.auth_applicants) {
        return _this.auth_applicants.filter(a => {
          return a.createjobs_id == _this.$route.params.id
        });
      }
      return null;
    },
    current_job() {
      let _this = this;
      if (_this.auth_created_jobs) {
        return _this.auth_created_jobs.filter(job => {
          return job.id == _this.$route.params.id
        })[0];
      }
      return null;
    },
    selected_applicants() {
      let _this = this, selected_applicants = [];
      _this.applicants.forEach(a => {
        _this.ids.forEach(id => {
          if (a.id == id) {
            selected_applicants.splice(selected_applicants.length - 1, 0, a);
          }
        })
      })
      // return selected_applicants;
      let final_applicants = selected_applicants.map(a => {
        return {
          id: a.id,
          company_id: _this.authCompanyData.id,
          company_unique_id: _this.authCompanyData.company_unique_id,
          employee_fristname: a.first_name,
          employee_lastname: a.last_name,
          employee_date_of_birth: a.date_of_birth,
          employee_email: a.email,
          employee_phone1: a.phone_number,
          city: a.city,
          employee_state: a.state,
          employee_nationality: a.country,
          employee_address: a.address,
          experience: a.years_of_experience,
          // current_salary: _this.formData.salary,
          // source_of_hire: _this.formData.sourceOfHire,
          // skill_set: _this.formData.skillSet,
          Highest_qualification: a.highest_qualification,
          employee_maritalstatus: a.marrital_status,
          employee_sex: a.sex,
          employee_education: _this.fetchApplicantEducation(a.id),
          employee_workexperience: _this.fetchApplicantWork(a.id)
        }
      })
      return final_applicants;
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_company_jobs",
      "fetch_all_applicants"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    filterEducation() {
      let _this = this, institutions = null;
      institutions = _this.auth_institutions.filter(e => {
        if (_this.filters.education.toLowerCase() == 'all' || _this.filters.education.toLowerCase() == 'o-level') {
          return e == e
        } else if (_this.filters.education.toLowerCase() == 'certificate/diploma') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd' || e.course_of_study.toLowerCase() == 'ond' || e.course_of_study.toLowerCase() == 'a-level' || e.course_of_study.toLowerCase() == 'certificate/diploma'
        } else if (_this.filters.education.toLowerCase() == 'a-level') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd' || e.course_of_study.toLowerCase() == 'ond' || e.course_of_study.toLowerCase() == 'a-level'
        } else if (_this.filters.education.toLowerCase() == 'ond') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd' || e.course_of_study.toLowerCase() == 'ond'
        } else if (_this.filters.education.toLowerCase() == 'hnd') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc' || e.course_of_study.toLowerCase() == 'hnd'
        } else if (_this.filters.education.toLowerCase() == 'bsc') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd' || e.course_of_study.toLowerCase() == 'bsc'
        } else if (_this.filters.education.toLowerCase() == 'pgd') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc' || e.course_of_study.toLowerCase() == 'pgd'
        } else if (_this.filters.education.toLowerCase() == 'msc') {
          return e.course_of_study.toLowerCase() == 'phd' || e.course_of_study.toLowerCase() == 'msc'
        } else {
          return e.course_of_study.toLowerCase() == 'phd'
        }
      })
      if (institutions.length == 0) {
        _this.filtered_applicants = []
      } else {
        _this.applicants.forEach(a => {
          institutions.forEach(e => {
            if (e.jobbiodetail_id == a.id) {
              if (_this.filtered_applicants.indexOf(a) == -1) {
                _this.filtered_applicants.splice(_this.filtered_applicants.length - 1, 0, a)
              }
            }
          })
        })
      }
    },
    getDownloadLink(id) {
      let _this = this, resume = null;
      if (_this.auth_professionalbodies) {
        resume = _this.auth_professionalbodies.filter(p => {
          return p.jobbiodetail_id == id
        })[0].attach
      }
      if (resume) {
        resume = _this.assets_url + resume;
      }
      return resume;
    },
    fetchApplicantEducation(id) {
      let _this = this, education = [];
      education = _this.auth_institutions.filter(e => {
        return e.jobbiodetail_id == id
      })
      education = education.map(e => {
        return {
          company_id: _this.authCompanyData.id,
          school_name: e.institution_name,
          degree_diploma: e.course_of_study,
          date_of_completion: e.institutions_end_date,
          field_of_study: e.institution_department
          // additional_notes: e.additional_notes
        }
      })
      return education;
    },
    fetchApplicantWork(id) {
      let _this = this, works = [];
      works = _this.auth_works.filter(w => {
        return w.jobbiodetail_id == id
      })
      works = works.map(w => {
        return {
          company_id: _this.authCompanyData.id,
          occupation: w.role_description,
          company: w.company_name,
          summary: w.achievements,
          duration: _this.getDateDiff(w.workexperiences_start_date, w.workexperiences_end_date),
          currently_work_here: w.designation
        }
      })
      return works;
    },
    getDateDiff(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const diffMonths = Math.ceil(diffDays / 30);
      const diffYears = Math.ceil(diffMonths / 12);

      if (diffDays < 29) {
        let string = diffDays + ' day(s)';
        return string;
      }

      if (diffMonths < 12) {
        let string = diffMonths + ' month(s)';
        return string;
      }

      let string = diffYears + ' year(s)';
      return string;
    },
    viewFullDetails(applicant) {
      this.currentApplicant = applicant;
      this.currentInstitutions = this.auth_institutions.filter(a => {
        return applicant.id == a.jobbiodetail_id
      });
      this.currentWorkExperiences = this.auth_works.filter(w => {
        return applicant.id == w.jobbiodetail_id
      });
      this.currentReferees = this.auth_referees.filter(r => {
        return applicant.id == r.jobbiodetail_id
      });
      this.fullDetails = true;
    },
    converttocandidates() {
      let _this = this;
      _this.convert_applicants_text = "";
      _this.convert_applicants_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}createbulkcandidate`, {
          applicants: _this.selected_applicants
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Candidate conversion successful"
          });
          _this.fetch_all_company_jobs();
          _this.fetch_all_applicants();
          _this.ids = [];
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "Candidate conversion failed"
          });
        })
        .then(function () {
          _this.convert_applicants_submit = false;
          _this.convert_applicants_text = "Convert to Candidate(s)";
        });
    },
    check_all() {
      let _this = this;

      if (_this.ids.length != _this.applicants.length) {
        _this.ids = []
        _this.applicants.forEach(a => {
          _this.ids.splice(_this.ids.length - 1, 0, a.id)
        })
      } else {
        _this.applicants.forEach(a => {
          _this.ids.splice(_this.ids.indexOf(a.id), 1)
        })
      }
    },
    getAge(dob) {
      let age = Math.floor((new Date().getTime() - new Date(dob).getTime()) / 1000 / 60 / 60 / 24 / 365);
      return age;
    },
    tweak_display() {
      if (this.create_jobs == 1) {
        this.create_jobs = 2;
      } else if (this.create_jobs == 2) {
        this.create_jobs = 1;
        setTimeout(() => {
          new window.Quill('#editor', {
            modules: {
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline'],
                ['image', 'code-block'],
                [{ list: 'ordered' }]
              ]
            },
            placeholder: 'Compose the job requirements...',
            theme: 'snow'  // or 'bubble'
          });
        }, 100);
      } else {
        ("");
      }
    },
    edit_job(job) {
      let _this = this;
      _this.create_jobs = 3;
      _this.editFormData.job_id = job.id;
      _this.editFormData.job_title = job.job_title;
      _this.editFormData.description = job.description;
      _this.editFormData.benefits = job.benefits;
      _this.editFormData.qualifications = job.qualifications.split(', ');
      _this.editFormData.start_date = job.start_date;
      _this.editFormData.end_date = job.end_date;
    },
    delete_job(job) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        company_id: _this.authCompanyData.id,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to delete this job opening?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}deletecreatejob`, {
                id: job.id
              })
              .then(function () {

                _this.fetch_all_company_jobs();
                Toast.fire({
                  icon: "success",
                  title: "Job opening deleted successfully"
                });
              })
              .catch(function () {
                Toast.fire({
                  icon: "error",
                  title: "The job opening couldn't be deleted"
                });
              })
              .then(function () {
                //
              });
          }
        });
    },
    update_job() {
      let _this = this;
      _this.update_job_text = "";
      _this.update_job_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}updatecreatejob`, {
          company_id: _this.authCompanyData.id,
          id: _this.editFormData.job_id,
          job_title: _this.editFormData.job_title,
          description: _this.editFormData.description,
          benefits: _this.editFormData.benefits,
          qualifications: _this.qualifications_commas_2,
          start_date: _this.editFormData.start_date,
          end_date: _this.editFormData.end_date
        })
        .then(function () {
          _this.editFormData.job_id = "";
          _this.editFormData.job_title = "";
          _this.editFormData.description = "";
          _this.editFormData.qualifications = "";
          _this.editFormData.acquisition_date = "";
          _this.editFormData.start_date = "";
          _this.editFormData.end_date = "";

          _this.fetch_all_company_jobs();

          Toast.fire({
            icon: "success",
            title: "Job Opening Updated"
          });
          _this.create_jobs = 2;
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "Job opening could not be updated"
          });
        })
        .then(function () {
          _this.update_job_submit = false;
          _this.update_job_text = "Update";
        });
    },
    add_job() {
      let _this = this;
      _this.create_jobs_text = "";
      _this.create_jobs_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}createjob`, {
          company_id: _this.authCompanyData.id,
          job_title: _this.formData.job_title,
          description: _this.formData.description,
          benefits: _this.formData.benefits,
          qualifications: _this.qualifications_commas,
          requirements: document.querySelector('.ql-editor').innerHTML,
          start_date: _this.formData.start_date,
          end_date: _this.formData.end_date
        })
        .then(function () {
          _this.formData.job_title = "";
          _this.formData.description = "";
          _this.formData.benefits = "";
          document.querySelector('.ql-editor').innerHTML = null;
          _this.formData.start_date = "";
          _this.formData.end_date = "";

          _this.fetch_all_company_jobs();

          Toast.fire({
            icon: "success",
            title: "Job Opening Created"
          });
          _this.create_jobs = 2;
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "Job opening could not be created"
          });

        })
        .then(function () {
          _this.create_jobs_submit = false;
          _this.create_jobs_text = "Submit";
        });
    }
  },
  mounted() {
    // let quill_js = document.createElement('script');
    // quill_js.setAttribute('src', 'https://cdn.quilljs.com/1.3.6/quill.js');
    // document.body.appendChild(quill_js);
    document.title = "Kylian ERP - Recruitment";
    let _this = this;
    _this.fetch_all_company_jobs();
    _this.fetch_all_applicants()
    if (_this.userType) {
      _this.authCompanyEmployees.forEach(person => {
        _this.allEmployees.push(
          person.employee_fristname + " " + person.employee_lastname
        );
      });
    } else {
      //
    }
    if (_this.auth_applicants) {
      _this.filtered_applicants = _this.auth_applicants.filter(a => {
        return a.createjobs_id == _this.$route.params.id
      });
    }
  }
};
</script>

<style>
strong {
  color: #111d5e;
}

.filter-radio .v-input {
  margin-top: 0px !important;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
}

.editor-opener {
  border-radius: 5px;
  box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}
</style>
