<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="paySlipView" class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4 noShowOnPrint"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'"
        style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div class="input-group-prepend">
            <!-- <button
              class="btn dropdown-toggle w-100"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">
                Your Payslips
              </span>
            </button> -->
            <!-- <div
              class="dropdown-menu"
              style="min-width: 13rem;"
            >
              <a
                @click="employeeView = !employeeView"
                class="dropdown-item"
                style="cursor: pointer;"
              >
                {{ employeeView ? "All Employees' Records" : "Your Travel Records" }}
              </a>
            </div> -->
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-0' : 'pr-5'">
          <div class="dropdown">
            <button class="btn ripple m-1" type="button" @click="refresh_page()">
              <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
            </button>
          </div>
          <!-- Grid menu Dropdown -->
          <!-- Notificaiton -->
          <div class="dropdown">
            <button type="button" class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
          </div>
          <div class="dropdown">
            <button type="button" class="btn btn-raised ripple btn-raised-secondary text-white"
              @click="paySlipView = false">
              All Pay Slips
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-3' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">


            <div class="container-fluid">
              <v-row>
                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                  <div id="printMe" class="card">
                    <div class="card-header py-0 px-0 mx-3 row">
                      <div class="col-md-9 px-0" style="align-self: self-end;">
                        <h4>Payslip Summary</h4>
                      </div>
                      <div class="col-md-3 px-0 text-right noShowOnPrint">
                        <button @click="print()" type="button" class="btn btn-outline-secondary m-1">
                          Print Payslip
                        </button>
                      </div>
                    </div>
                    <div class="card-body px-3 py-1">
                      <v-row class="add-mt">
                        <v-col cols="6">
                          <v-row>
                            <v-col cols="12">
                              <h3 class="text-left">{{ authCompanyData.company_name }}</h3>
                              <p><i>{{ authCompanyData.address }}</i></p>
                            </v-col>
                            <v-col cols="12">
                              <h5 style="font-weight: bold;">Employee Name</h5>
                              <p>{{ authEmployeeDataForCompany.employee_fristname + ' ' +
                                authEmployeeDataForCompany.employee_lastname
                              }}</p>
                              <h5 style="font-weight: bold;">Employee Address</h5>
                              <p><i>{{ authEmployeeDataForCompany.employee_address }}</i></p>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="text-center">
                          <img :src="authCompanyData.logo
                            ? host_url + authCompanyData.logo
                            : '/img/kylian-logo-2.png'
                            " alt="" class="w-25" style="height: inherit;" />
                        </v-col>
                      </v-row>
                      <v-row class="mx-1">
                        <v-col cols="12">
                          <v-row>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Department
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Designation
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Period
                              </h5>
                            </v-col>
                            <!-- <v-col cols="4"></v-col> -->
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.department }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.designation }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="6">
                              <h5 v-if="currentPayRun">
                                {{ specificEmployeePayDetails.period }}
                              </h5>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Payment Date
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Bank Name
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Bank Account Number
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Payment Method
                              </h5>
                            </v-col>
                            <!-- <v-col cols="4"></v-col> -->
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.payment_date }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.bank_name }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.account_number }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.payment_method }}
                              </h5>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-3" cols="6" style="background: #e2e2e2; border-right: 10px solid #fff;">
                              <h5 style="font-weight: bold;">
                                Payments
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="6" style="background: #e2e2e2; border-left: 10px solid #fff;">
                              <h5 style="font-weight: bold;">
                                Deductions
                              </h5>
                            </v-col>
                            <v-col cols="6" style="border-right: 10px solid #fff;">
                              <v-row style="border-top: 2px solid #fff;">
                                <v-col class="py-3" cols="6">
                                  <h5>
                                    Pay Basic
                                  </h5>
                                </v-col>
                                <v-col cols="6" class="text-right py-3">
                                  <h5>
                                    ₦{{ parseInt(employeePayBasic.amount) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                              <template v-for="ea in specificEmployeeAllowances">
                                <v-row style="border-top: 2px solid #fff;" :key="ea.id">
                                  <v-col class="py-3" cols="6">
                                    <h5>
                                      {{ ea.allowance }}
                                    </h5>
                                  </v-col>
                                  <v-col cols="6" class="text-right py-3">
                                    <h5>
                                      ₦{{ (parseInt(ea.amount)) | number_with_commas }}
                                    </h5>
                                  </v-col>
                                </v-row>
                              </template>
                              <!-- <template v-for="fa in employeesFlexibleAllowances(authEmployeeDataForCompany.id)">
                                    <v-row style="border-top: 2px solid #fff;" :key="fa.id">
                                      <v-col class="py-3" cols="6">
                                        <h5>
                                          {{ fa.allowance_name }}
                                        </h5>
                                      </v-col>
                                      <v-col cols="6" class="text-right py-3">
                                        <h5>
                                          ₦{{ (parseInt(fa.allowance_amount)) | number_with_commas }}
                                        </h5>
                                      </v-col>
                                    </v-row>
                                  </template> -->
                              <v-row style="border-top: 2px solid #8c8c8c;">
                                <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                                  <h5 style="font-weight: bold;">
                                    Gross Pay
                                  </h5>
                                </v-col>
                                <v-col cols="6" style="background: #e2e2e2;" class="text-right py-3">
                                  <h5 style="font-weight: bold;">
                                    ₦{{ parseInt(specificEmployeePayDetails.total_payment) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6" style="border-left: 10px solid #fff;">
                              <template v-for="ed in specificEmployeeDeductions">
                                <v-row style="border-top: 2px solid #fff;" :key="ed.id">
                                  <v-col class="py-3" cols="6">
                                    <h5>
                                      {{ ed.deduction || ed.deduction_name }}
                                    </h5>
                                  </v-col>
                                  <v-col cols="6" class="text-right py-3">
                                    <h5>
                                      ₦{{ (parseInt(ed.amount || ed.deduction_amount)) | number_with_commas }}
                                    </h5>
                                  </v-col>
                                </v-row>
                              </template>
                              <v-row style="border-top: 2px solid #8c8c8c;">
                                <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                                  <h5 style="font-weight: bold;">
                                    Total Deductions
                                  </h5>
                                </v-col>
                                <v-col cols="6" style="background: #e2e2e2;" class="text-right py-3">
                                  <h5 style="font-weight: bold;">
                                    ₦{{ parseInt(specificEmployeePayDetails.total_deduction) | number_with_commas
                                    }}
                                  </h5>
                                </v-col>
                              </v-row>
                              <v-row style="border-top: 2px solid #8c8c8c;">
                                <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                                  <h5 style="font-weight: bold;">
                                    Net Pay
                                  </h5>
                                </v-col>
                                <v-col cols="6" style="background: #e2e2e2;" class="text-right py-3">
                                  <h5 style="font-weight: bold;">
                                    ₦{{ parseInt(specificEmployeePayDetails.net_pay) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div class="input-group-prepend">
            <!-- <button
              class="btn dropdown-toggle w-100"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">
                Your Payslips
              </span>
            </button> -->
            <!-- <div
              class="dropdown-menu"
              style="min-width: 13rem;"
            >
              <a
                @click="employeeView = !employeeView"
                class="dropdown-item"
                style="cursor: pointer;"
              >
                {{ employeeView ? "All Employees' Records" : "Your Travel Records" }}
              </a>
            </div> -->
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-0' : 'pr-5'">
          <div class="dropdown">
            <button class="btn ripple m-1" type="button" @click="refresh_page()">
              <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
            </button>
          </div>
          <!-- Grid menu Dropdown -->
          <!-- Notificaiton -->
          <div class="dropdown">
            <button type="button" class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-3' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-0 row pb-0 pt-2">
              <span class="col-12 col-lg-4 col-md-4">Your Payslips</span>
              <v-row class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col offset-md="6" offset-lg="6" md="3" lg="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo messages=" *Filter by year"
                    :full-width="true" v-model="yearSelected"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"></v-select>
                </v-col>
                <!-- <v-col v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')" lg="2" md="2" cols="12" class="pt-0">
                  <button
                    type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                  >
                    Print Payslip
                  </button>
                </v-col> -->
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Payrun Name</th>
                          <th scope="col" class="text-left">From</th>
                          <th scope="col" class="text-left">To</th>
                          <th scope="col" class="text-left">Payment Date</th>
                          <th scope="col" class="text-left">Pay Basic</th>
                          <th scope="col" class="text-left">Allowances</th>
                          <th scope="col" class="text-left">Deductions</th>
                          <!-- <th scope="col" class="text-left">Taxes</th> -->
                          <th scope="col" class="text-left">Net Pay</th>
                          <th scope="col" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="pr in finalApprovedPayRuns" :key="pr.id">
                          <td class="text-left">{{ pr.payrun_name }}</td>
                          <td class="text-left">{{ (new Date(pr.from_date).toUTCString().substring(0, 16)) }}</td>
                          <td class="text-left">{{ (new Date(pr.to_date).toUTCString().substring(0, 16)) }}</td>
                          <td class="text-left">{{ (new Date(pr.payment_date).toUTCString().substring(0, 16)) }}</td>
                          <td class="text-left">₦{{ pr.pay_basic | number_with_commas }}</td>
                          <td class="text-left">₦{{ pr.total_pay | number_with_commas }}</td>
                          <td class="text-left">₦{{ pr.total_deductions | number_with_commas }}</td>
                          <!-- <td class="text-left">₦{{ pr.total_tax | number_with_commas }}</td> -->
                          <td class="text-left">₦{{ pr.net_pay | number_with_commas }}</td>
                          <td class="text-center py-2">
                            <button @click="(currentPayRunId = pr.id, paySlipView = true)" type="button"
                              class="btn btn-raised ripple btn-raised-secondary text-white btn-sm">
                              View
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import { ASSETS_URL } from "@/main";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      paySlipView: false,
      firstYear: "",
      monthSelected: "",
      yearSelected: "",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      currentPayRunId: null
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyEmployees",
      "authEmployeeDataForCompany",
      "true_employees",
      "authCompanyAllCheckin",
      "authPayCalendars",
      "authEmployeePayCalendars",
      "authFixedAllowances",
      "authFixedDeductions",
      "authFlexibleAllowances",
      "authFlexibleDeductions",
      "authEmployeeTaxes",
      "authCompanyPayRun",
      "authCompanyDepartments",
      "authDesignations",
      "authEmployeePayslipDetails"
    ]),
    host_url() {
      return ASSETS_URL;
    },
    finalApprovedPayRuns() {
      let _this = this, result = [];
      result = _this.approvedEmployeePayRuns.map(pr => {
        return {
          id: pr.id,
          payrun_name: pr.payrun_name,
          from_date: pr.from_date,
          to_date: pr.to_date,
          payment_date: pr.payment_date,
          pay_basic: _this.getEmployeePayBasic(pr.id),
          total_pay: _this.getTotalAllowances(pr.id),
          total_deductions: _this.getTotalDeductions(pr.id),
          total_tax: _this.getTotalTaxes(pr.id),
          net_pay: _this.getNetPay(pr.id),
        }
      })
      return result;
    },
    allEmployeeAllowances() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails.employeeallowances) {
        result = _this.authEmployeePayslipDetails.employeeallowances.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id)
      }
      return result;
    },
    employeePayBasic() {
      let _this = this, result = [];
      result = _this.allEmployeeAllowances.filter(a => a.payrun_id == _this.currentPayRunId && a.allowance.toLowerCase() == 'pay basic')[0]
      return result
    },
    specificEmployeeAllowances() {
      let _this = this, result = []//, flexibles = [];
      result = _this.allEmployeeAllowances.filter(a => a.payrun_id == _this.currentPayRunId && a.allowance.toLowerCase() != 'pay basic')
      // flexibles = _this.authFlexibleAllowances.filter(a => a.payrun_id == _this.currentPayRunId && a.employee_id == _this.authEmployeeDataForCompany.id);
      // result = [...result, ...flexibles];
      return result
    },
    allEmployeePayDetails() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails.employeepayslip) {
        result = _this.authEmployeePayslipDetails.employeepayslip.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id)
      }
      return result;
    },
    specificEmployeePayDetails() {
      let _this = this, result = [];
      result = _this.allEmployeePayDetails.filter(pd => pd.payrun_id == _this.currentPayRunId)[0]
      return result
    },
    allEmployeeDeductions() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails.employeedeductions) {
        result = _this.authEmployeePayslipDetails.employeedeductions.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id)
      }
      return result;
    },
    specificEmployeeDeductions() {
      let _this = this, result = []//, flexibles = [];
      result = _this.allEmployeeDeductions.filter(d => d.payrun_id == _this.currentPayRunId);
      // flexibles = _this.authFlexibleDeductions.filter(d => d.payrun_id == _this.currentPayRunId && d.employee_id == _this.authEmployeeDataForCompany.id);
      // result = [...result, ...flexibles];
      return result
    },
    allEmployeeTaxes() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails.employeetaxes) {
        result = _this.authEmployeePayslipDetails.employeetaxes.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id)
      }
      return result;
    },
    specificEmployeeTaxes() {
      let _this = this, result = [];
      result = _this.allEmployeeTaxes.filter(t => t.payrun_id == _this.currentPayRunId)
      return result
    },
    allYears: function () {
      var t = [],
        e = new Date().getFullYear();
      t.push(this.firstYear);
      for (var a = this.firstYear; a <= e; a++) if (t.indexOf(a) == -1) { t.push(a.toString()); }
      return t.reverse();
    },
    approvedPayRuns() {
      let result = [];
      if (this.authCompanyPayRun.length >= 1) {
        result = this.authCompanyPayRun.filter(pr => {
          return pr.approved == 1 && pr
        });
      }
      return result;
    },
    approvedEmployeePayRuns() {
      let _this = this, result = [];
      if (_this.approvedPayRuns.length >= 1) {
        result = _this.approvedPayRuns.filter(pr => {
          return _this.employeeIsInCalendar(_this.validEmployeeId, pr.paycalendar_id);//pr.paycalendar_id == _this.employeePayCalendarDetails.paycalendar_id;
        });

        if (_this.yearSelected && _this.monthSelected) {
          result = result.filter(r => {
            return r.year == _this.yearSelected && r.month == _this.monthSelected
          })
        } else {
          result = result.filter(r => {
            return r.year == _this.yearSelected
          })
        }
        return result;
      } else {
        return result;
      }
    },
    payRunCalendars() {
      let _this = this, result = [], payCalendar = null;
      _this.approvedPayRuns.forEach(ap => {
        payCalendar = _this.authPayCalendars.filter(pc => {
          return pc.id == ap.paycalendar_id
        })[0]
        if (payCalendar && result.indexOf(payCalendar) == -1) {
          result.splice(result.length, 0, payCalendar);
        }
      });
      return result;
    },
    employeePayCalendarDetails() {
      let _this = this, result = null;
      if (_this.authEmployeePayCalendars.length >= 1) {
        result = _this.authEmployeePayCalendars.filter(epc => {
          return epc.employee_id == _this.authEmployeeDataForCompany.id
        })[0]
      }
      return result;
    },
    employeePayslips() {
      let _this = this, result = [];
      console.log(_this);
      return result;
    },
    currentPayRun() {
      let _this = this, result = null;
      if (_this.authCompanyPayRun.length >= 1) {
        result = _this.authCompanyPayRun.filter(pr => {
          return pr.id == _this.currentPayRunId
        })[0];
      }
      return result;
    },
  },
  methods: {
    ...mapActions([
      "fetch_all_pay_calendar",
      "fetch_all_employees_pay_calendar",
      "fetch_all_company_payrun",
      "fetch_all_flexible_allowances",
      "fetch_all_flexible_deductions",
      "fetch_all_fixed_allowances",
      "fetch_all_fixed_deductions",
      "fetch_all_employee_payslips"
    ]),
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
    hasHistory: function () {
      return window.history.length > 2;
    },
    employeeIsInCalendar(employee_id, paycalendar_id) {
      let _this = this, truth = false;
      _this.authEmployeePayCalendars.forEach(cal => {
        if (cal.employee_id == employee_id && cal.paycalendar_id == paycalendar_id) {
          truth = true;
        }
      });
      return truth;
    },
    getEmployeePayBasic(id) {
      let _this = this, result = [], value = 0;
      result = _this.allEmployeeAllowances.filter(a => a.payrun_id == id && a.allowance.toLowerCase() == 'pay basic');
      if (result.length) {
        value = result[0].amount
      }
      return value
    },
    getTotalAllowances(id) {
      let _this = this, result = 0, allowances = [];
      allowances = _this.allEmployeeAllowances.filter(a => a.payrun_id == id && a.allowance.toLowerCase() != 'pay basic')
      allowances.forEach(a => {
        result += parseInt(a.amount)
      });
      return result
    },
    getTotalDeductions(id) {
      let _this = this, result = 0, deductions = [];
      deductions = _this.allEmployeeDeductions.filter(d => d.payrun_id == id)
      deductions.forEach(d => {
        result += parseInt(d.amount)
      });
      return result
    },
    getTotalTaxes(id) {
      let _this = this, result = 0, taxes = [];
      taxes = _this.allEmployeeTaxes.filter(t => t.payrun_id == id)
      taxes.forEach(t => {
        result += parseInt(t.amount)
      });
      return result
    },
    getNetPay(id) {
      let _this = this, result = [], value = 0;
      result = _this.allEmployeePayDetails.filter(pd => pd.payrun_id == id)
      if (result.length) {
        value = result[0].net_pay
      }
      return value;
    },
    currentEmployeeFixedDeductions(id) {
      let _this = this, result = [];
      if (_this.authFixedDeductions.length >= 1) {
        result = _this.authFixedDeductions.filter(fd => fd.employee_id == id);
      }
      return result;
    },
    currentEmployeeFixedTax(id) {
      let _this = this, result = [];
      if (_this.authEmployeeTaxes.length >= 1) {
        result = _this.authEmployeeTaxes.filter(t => t.employee_id == id);
      }
      return result;
    },
    get_dept_name(dept_id) {
      let _this = this, dept;
      if (_this.authCompanyDepartments) {
        dept = _this.authCompanyDepartments.filter(dept => {
          return dept.id == dept_id;
        });
      }
      if (dept.length >= 1) {
        return dept[0].department_name;
      }
      return null;
    },
    currentEmployeeFixedAllowances(id) {
      let _this = this, result = [];
      if (_this.authFixedAllowances.length >= 1) {
        result = _this.authFixedAllowances.filter(fa => fa.employee_id == id);
      }
      return result;
    },
    finalEmployeeNetPay(payments, deductions, tax) {
      let result = parseInt(payments) - parseInt(deductions) - parseInt(tax);
      return result;
    },
    employeeFixedDeductions(id) {
      let _this = this, result = [], value = 0;
      if (_this.authFixedDeductions.length >= 1) {
        result = _this.authFixedDeductions.filter(fd => fd.employee_id == id);
        result.forEach(fdr => {
          value += parseInt(fdr.deduction_amount);
        })
      }
      return value;
    },
    employeeFixedTax(id) {
      let _this = this, result = [], value = 0;
      if (_this.authEmployeeTaxes.length >= 1) {
        result = _this.authEmployeeTaxes.filter(t => t.employee_id == id);
        result.forEach(tr => {
          value += parseInt(tr.amount);
        })
      }
      return value;
    },
    employeesFlexibleAllowances(employee_id) {
      let _this = this, result = [];
      if (_this.authFlexibleAllowances.length >= 1) {
        result = _this.authFlexibleAllowances.filter(fa => {
          return fa.employee_id == employee_id && fa.payrun_id == _this.currentPayRunId && fa.paycalendar_id == _this.employeePayCalendarDetails.paycalendar_id
        });
      }
      return result;
    },
    employeesFlexibleDeductions(employee_id) {
      let _this = this, result = [];
      if (_this.authFlexibleDeductions.length >= 1) {
        result = _this.authFlexibleDeductions.filter(fd => {
          return fd.employee_id == employee_id && fd.payrun_id == _this.currentPayRunId && fd.paycalendar_id == _this.employeePayCalendarDetails.paycalendar_id
        });
      }
      return result;
    },
    totalAllowanceToPay(fixed, flexibles, salary) {
      let result = 0;
      if (flexibles.length >= 1) {
        flexibles.forEach(fa => {
          result += parseInt(fa.allowance_amount)
        });
      }
      result += parseInt(fixed);
      result += parseInt(salary);
      return result;
    },
    totalDeductionToTake(fixed, flexibles) {
      let result = 0;
      if (flexibles.length >= 1) {
        flexibles.forEach(fa => {
          result += parseInt(fa.deduction_amount)
        });
      }
      result += parseInt(fixed);
      return result;
    },
    employeeFixedAllowances(id) {
      let _this = this, result = [], value = 0;
      if (_this.authFixedAllowances.length >= 1) {
        result = _this.authFixedAllowances.filter(fa => fa.employee_id == id);
        result.forEach(far => {
          value += parseInt(far.allowance_amount);
        })
      }
      return value;
    },
  },
  mounted() {
    document.title = "Kylian ERP - Payslip";
    this.yearSelected = new Date().getFullYear().toString();

    this.firstYear = new Date(this.authCompanyData.created_at).getFullYear().toString();

    this.fetch_all_pay_calendar();
    this.fetch_all_employees_pay_calendar();
    this.fetch_all_company_payrun();
    this.fetch_all_flexible_allowances();
    this.fetch_all_flexible_deductions();
    this.fetch_all_fixed_allowances();
    this.fetch_all_fixed_deductions();
    this.fetch_all_employee_payslips();
  },
};
</script>

<style scoped>
@media print {
  .noShowOnPrint {
    display: none;
  }
}

/* .noShowOnPrint {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
} */
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.upload-medical-bill div div,
.upload-medical-bill-2 div div {
  cursor: pointer !important;
}

.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
