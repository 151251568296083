<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div v-if="userType == 1 || (userType != 1 && permissionIsAssigned('event-create'))" class="main-header pl-4 mt-2"
        style="z-index: 60;">
        <div v-if="userType == 1 || (userType != 1 && permissionIsAssigned('event-create'))"
          class="row animate__animated animate__fadeIn">
          <div class="logo col-md-3 py-0">
            <div class="input-group-prepend">
              <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <span>{{ calendar_view ? "Calendar View" : "List View" }}</span>
              </button>
              <div class="dropdown-menu" style="min-width: 13rem; margin-left: 45px;">
                <a @click="calendar_view = !calendar_view" class="dropdown-item" style="cursor: pointer;">
                  {{ calendar_view ? "List View" : "Calendar View" }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div v-if="userType == 1 && permissionIsAssigned('event-create')"
            class="dropdown animate__animated animate__fadeIn">
            <button @click="create_event_dialog = true" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              Create Event
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <div v-else class="main-header pl-4 mt-2" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'">
          <div class="dropdown">
            <button class="btn ripple m-1" type="button" @click="refresh_page()"
              :class="refreshing_page ? 'rotate-spinner' : ''">
              <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
            </button>
          </div>
          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content">
        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>
            <span>All Events</span>
          </h1>
        </div>
        <div class="main-content mt-0 row"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
          <vue-cal v-if="calendar_view" style="height: 500px;"
            :selected-date="new Date().toISOString().substring(0, 10)" :time="false" active-view="month" xsmall
            :disable-views="['years', 'year' /*, 'month'*/]" hide-weekends :events="events_to_show" />
          <div v-else class="col-12">
            <div class="card" style="border-radius: 10px;">
              <div class="card-body px-1 py-1 pt-0 pr-0">
                <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                                        overflow-y: scroll;
                                                        padding-top: 0;
                                                        padding-bottom: 0;">
                  <table class="table table-hover">
                    <thead>
                      <tr style="border: 2px solid #4440;">
                        <th class="text-left" scope="col">Title</th>
                        <th class="text-left" scope="col">Start Date</th>
                        <th class="text-left" scope="col">End Date</th>
                        <th v-if="permissionIsAssigned('event-update') || permissionIsAssigned('event-delete')"
                          scope="col" class="text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="event in authCompanyAllEvents" :key="event.id">
                        <td class="underline text-left" scope="row">{{ event.title }}</td>
                        <td class="text-left">{{ event.start_date.split(' ')[0] }}</td>
                        <td class="text-left">{{ event.end_date.split(' ')[0] }}</td>
                        <td v-if="permissionIsAssigned('event-update') || permissionIsAssigned('event-delete')"
                          style="padding-top: 13px;" class="text-left employee-options">
                          <div class="row mt-0">
                            <i v-if="permissionIsAssigned('event-update')" v-bind="attrs" v-on="on"
                              class="col-1 py-0 text-center fas fa-edit mr-2" @click="launch_edit(event)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            <i v-if="permissionIsAssigned('event-delete')" v-bind="attrs" v-on="on"
                              class="col-1 py-0 text-center fas fa-trash ml-2" @click="delete_event(event)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-row justify="center">
      <v-dialog v-model="create_event_dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="headline">Create an Event</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="remove-hint">
                      <v-text-field dense v-model="formData.title" label="Event Title" solo></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="formData.start_date" label="Start Date" solo readonly v-bind="attrs" v-on="on">
                          </v-text-field>
                        </template>
                        <v-date-picker color="#069" ref="picker1" v-model="formData.start_date" :min="
                          new Date(new Date().getTime() + 86400000)
                            .toISOString()
                            .substring(0, 10)
                        " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save1"></v-date-picker>
                      </v-menu>
                      <v-time-picker v-model="formData.start_time" ampm-in-title format="ampm"></v-time-picker>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="formData.end_date" label="End Date" solo readonly v-bind="attrs" v-on="on">
                          </v-text-field>
                        </template>
                        <v-date-picker color="#069" ref="picker2" v-model="formData.end_date" :min="
                          new Date(new Date().getTime() + 86400000)
                            .toISOString()
                            .substring(0, 10)
                        " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save2"></v-date-picker>
                      </v-menu>
                      <v-time-picker v-model="formData.end_time" ampm-in-title format="ampm"></v-time-picker>
                    </v-col>
                    <v-col cols="12" class="remove-hint">
                      <v-radio-group label="Recipients" v-model="formData.mode" inline>
                        <v-radio label="All Staff" value="3"></v-radio>
                        <v-radio label="Department" value="1"></v-radio>
                        <v-radio label="Branch" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col v-if="formData.mode == 1" cols="12" class="remove-hint">
                      <v-select v-model="formData.department" :items="allDepartmentNames" label="Selected Departments" dense solo></v-select>
                    </v-col>
                    <v-col v-if="formData.mode == 2" cols="12" class="remove-hint">
                      <v-select v-model="formData.branch" :items="allBranchNames" label="Selected Branches" dense solo></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                      <button @click="create_event_submit ? null : create_event()" style="width: 100%;" type="button"
                        class="btn btn-secondary m-1 ripple">
                        {{ create_event_text }}
                        <v-icon :style="
                          !create_event_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="create_event_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="edit_event_dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Event</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="remove-hint">
                      <v-text-field dense v-model="editFormData.title" label="Event Title" solo></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="editFormData.start_date" label="Start Date" solo readonly v-bind="attrs" v-on="on">
                          </v-text-field>
                        </template>
                        <v-date-picker color="#069" ref="picker3" v-model="editFormData.start_date" :min="
                          new Date(new Date().getTime() + 86400000)
                            .toISOString()
                            .substring(0, 10)
                        " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save3"></v-date-picker>
                      </v-menu>
                      <v-time-picker v-model="editFormData.start_time" ampm-in-title format="ampm"></v-time-picker>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="editFormData.end_date" label="End Date" solo readonly v-bind="attrs" v-on="on">
                          </v-text-field>
                        </template>
                        <v-date-picker color="#069" ref="picker4" v-model="editFormData.end_date" :min="
                          new Date(new Date().getTime() + 86400000)
                            .toISOString()
                            .substring(0, 10)
                        " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save4"></v-date-picker>
                      </v-menu>
                      <v-time-picker v-model="editFormData.end_time" ampm-in-title format="ampm"></v-time-picker>
                    </v-col>
                    <v-col cols="12" class="remove-hint">
                      <v-radio-group label="Recipients" v-model="editFormData.mode" inline>
                        <v-radio label="All Staff" value="3"></v-radio>
                        <v-radio label="Department" value="1"></v-radio>
                        <v-radio label="Branch" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col v-if="editFormData.mode == 1" cols="12" class="remove-hint">
                      <v-select v-model="editFormData.department" :items="allDepartmentNames" label="Selected Departments" dense solo></v-select>
                    </v-col>
                    <v-col v-if="editFormData.mode == 2" cols="12" class="remove-hint">
                      <v-select v-model="editFormData.branch" :items="allBranchNames" label="Selected Branches" dense solo></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                      <button @click="update_event_submit ? null : edit_event()" style="width: 100%;" type="button"
                        class="btn btn-secondary m-1 ripple">
                        {{ update_event_text }}
                        <v-icon :style="
                          !update_event_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="edit_event_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      calendar_view: true,
      create_event_dialog: false,
      edit_event_dialog: false,
      create_event_text: "Submit",
      create_event_submit: false,
      update_event_text: "Update",
      update_event_submit: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      // menu: false,
      formData: {
        title: "",
        start_date: "",
        start_time: "00:00",
        end_date: "",
        end_time: "00:00",
        branch: "",
        department: "",
        mode: "3"
      },
      editFormData: {
        id: null,
        title: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        branch: "",
        department: "",
        mode: "3"
      }
    };
  },
  components: { VueCal },
  watch: {
    menu1(val) {
      val && setTimeout(() => (this.$refs.picker1.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    },
    menu3(val) {
      val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
    },
    menu4(val) {
      val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "authUserData",
      "authCompanyEmployees",
      "userType",
      "authCompanyAllEvents",
      "authCompanyApprovedLeave",
      "authCompanyAllAnnouncements",
      "authCompanyDepartments",
      "authCompanyBranches"
    ]),
    events_to_show() {
      let events = [],
        _this = this,
        all_event_days;
      _this.authCompanyAllEvents.forEach(event => {
        if (event.start_date == event.end_date) {
          events.push({
            start: event.start_date,
            end: event.end_date,
            title: event.title,
            content: '<i class="fas fa-calendar"></i>',
            class: "event"
          });
        } else {
          all_event_days = _this.getDaysArray(
            new Date(event.start_date),
            new Date(event.end_date)
          );
          all_event_days.forEach(day => {
            events.push({
              start: day,
              end: day,
              title: event.title,
              content: '<i class="fas fa-calendar"></i>',
              class: "event"
            });
          });
        }
      });
      _this.authCompanyApprovedLeave.forEach(leave => {
        let daylist = _this.getDaysArray(
          new Date(leave.leave_start),
          new Date(leave.leave_end)
        );
        daylist.forEach(day => {
          events.push({
            start: day,
            end: day,
            title: leave.employee_name + " is on leave today",
            content: '<i class="fas fa-umbrella"></i>',
            class: "leave"
          });
        });
      });
      if (_this.authCompanyEmployees) {
        _this.authCompanyEmployees.forEach(employee => {
          if (employee.employee_date_of_birth) {
            // return parseInt(employee.employee_date_of_birth.substring(5, 7)) == (new Date().getMonth()+1);
            let day = (
              new Date().getFullYear() +
              employee.employee_date_of_birth.substring(4)
            ).toString();
            events.push({
              start: day,
              end: day,
              title:
                "Happy birthday " +
                employee.employee_fristname +
                " " +
                employee.employee_lastname,
              content: '<i class="fas fa-birthday-cake"></i>',
              class: "birthday"
            });
          }
        });
      }
      return events;
    },
    allDepartmentNames() {
      const _this = this;
      let names = [];
      names = _this.authCompanyDepartments.map(department => department.department_name);
      return names;
    },
    selectedDepartmentId() {
      const _this = this;
      let foundDepartment = [], id = null;
      foundDepartment = _this.authCompanyDepartments.filter(department => department.department_name == _this.formData.department);
      if (foundDepartment.length > 0) {
        id = foundDepartment[0].id;
      }
      return id;
    },
    selectedDepartmentIdForEdit() {
      const _this = this;
      let foundDepartment = [], id = null;
      foundDepartment = _this.authCompanyDepartments.filter(department => department.department_name == _this.editFormData.department);
      if (foundDepartment.length > 0) {
        id = foundDepartment[0].id;
      }
      return id;
    },
    allBranchNames() {
      const _this = this;
      let names = [];
      names = _this.authCompanyBranches.map(branch => branch.branch_name);
      return names;
    },
    selectedBranchId() {
      const _this = this;
      let foundBranch = [], id = null;
      foundBranch = _this.authCompanyBranches.filter(branch => branch.branch_name == _this.formData.branch);
      if (foundBranch.length > 0) {
        id = foundBranch[0].id;
      }
      return id;
    },
    selectedBranchIdForEdit() {
      const _this = this;
      let foundBranch = [], id = null;
      foundBranch = _this.authCompanyBranches.filter(branch => branch.branch_name == _this.editFormData.branch);
      if (foundBranch.length > 0) {
        id = foundBranch[0].id;
      }
      return id;
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_company_events",
      "fetch_company_departments",
      "fetch_company_branches"
    ]),
    hasHistory() {
      return window.history.length > 2;
    },
    getBranchNameById(branchId) {
      const _this = this;
      let name = '', foundBranch = [];
      foundBranch = _this.authCompanyBranches.filter(branch => branch.id == branchId);
      if (foundBranch > 0) {
        name = foundBranch[0].branch_name
      }
      return name;
    },
    getDepartmentNameById(departmentId) {
      const _this = this;
      let name = '', foundDepartment = [];
      foundDepartment = _this.authCompanyDepartments.filter(department => department.id == departmentId);
      if (foundDepartment > 0) {
        name = foundDepartment[0].department_name
      }
      return name;
    },
    launch_edit(event) {
      let _this = this;
      _this.editFormData.id = event.id;
      _this.editFormData.title = event.title;
      _this.editFormData.start_date = event.start_date.split(' ')[0];
      _this.editFormData.end_date = event.end_date.split(' ')[0];
      _this.editFormData.start_time = event.start_date.split(' ')[1];
      _this.editFormData.end_time = event.end_date.split(' ')[1];
      
      
      _this.editFormData.branch = _this.getBranchNameById(event.branch);
      _this.editFormData.department = _this.getDepartmentNameById(event.department);
      _this.editFormData.mode = event.mode;
      
      _this.edit_event_dialog = true;
    },
    delete_event(event) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this event?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deletesingleevent`, {
                event_id: event.id
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Event deleted successfully"
                });
                _this.fetch_all_company_events();
              })
              .catch(function () {
                Toast.fire({
                  icon: "error",
                  title: "Event delete failed"
                });
              })
              .then(function () {
                //
              });
          }
        });
    },
    edit_event() {
      let _this = this;
      _this.update_event_text = "";
      _this.update_event_submit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}editevent`, {
          event_id: _this.editFormData.id,
          title: _this.editFormData.title,
          start_date: _this.editFormData.start_date + ' ' + _this.editFormData.start_time + ':00',
          end_date: _this.editFormData.end_date + ' ' + _this.editFormData.end_time + ':00',
          branch_id: _this.selectedBranchIdForEdit,
          department_id: _this.selectedDepartmentIdForEdit,
          mode: _this.editFormData.mode
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Event update successful"
          });
          _this.fetch_all_company_events();
          _this.edit_event_dialog = false;
          _this.editFormData.id = null;
          _this.editFormData.title = "";
          _this.editFormData.start_date = "";
          _this.editFormData.end_date = "";
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "Event update failed"
          });
        })
        .then(function () {
          _this.update_event_submit = false;
          _this.update_event_text = "Update";
        });
    },
    getDaysArray(start, end) {
      for (
        var arr = [], dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    },
    save1(date) {
      this.$refs.menu1.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    save3(date) {
      this.$refs.menu3.save(date);
    },
    save4(date) {
      this.$refs.menu4.save(date);
    },
    create_event() {
      let _this = this;
      _this.create_event_text = "";
      _this.create_event_submit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}createevent`, {
          company_id: _this.authCompanyData.id,
          title: _this.formData.title,
          start_date: _this.formData.start_date + ' ' + _this.formData.start_time + ':00',
          end_date: _this.formData.end_date + ' ' + _this.formData.end_time + ':00',
          branch_id: _this.selectedBranchId,
          department_id: _this.selectedDepartmentId,
          mode: _this.formData.mode
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Event creation successful"
          });
          _this.fetch_all_company_events();
          _this.create_event_dialog = false;
          _this.formData.title = "";
          _this.formData.start_date = "";
          _this.formData.end_date = "";
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "Event creation failed"
          });
        })
        .then(function () {
          _this.create_event_submit = false;
          _this.create_event_text = "Submit";
        });
    }
  },
  mounted() {
    document.title = "Kylian ERP - Events";
    this.fetch_all_company_events();
    this.fetch_company_departments();
    this.fetch_company_branches();
  }
};
</script>

<style>
.vuecal__event.leave {
  background-color: rgba(253, 156, 66, 0.9);
  border: 1px solid rgb(233, 136, 46);
  color: #fff;
}

.vuecal__event.birthday {
  background-color: rgba(255, 102, 102, 0.9);
  border: 1px solid rgb(235, 82, 82);
  color: #fff;
}

.vuecal__event.event {
  background-color: rgba(0, 102, 153, 0.849);
  border: 1px solid #069;
  color: #fff;
}
</style>
<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}
</style>
