<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1))
      " class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
      ? 'pr-1'
      : 'pr-5'
      ">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1)"
            class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span :style="$vuetify.breakpoint.name == 'xs'
      ? 'margin-left: 1.5rem;'
      : $vuetify.breakpoint.name == 'sm'
        ? 'margin-left: 5rem;'
        : null
      ">{{
      employeeView
        ? "Your Request Records"
        : "All Employees' Records"
    }}</span>
            </button>
            <div v-if="(permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1)"
              class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{
      employeeView
        ? "All Employees' Records"
        : "Your Request Records"
    }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
        ? null
        : 'pr-5'
      ">
          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1))
      " class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
      ? 'px-3'
      : null
      ">
        <div class="breadcrumb d-block" style="margin-top: 1rem;">
          <v-row class="my-2 pb-0">
            <v-col cols="6">
              <h1>
                <span v-if="employee_name.toLowerCase() != 'all requisitions'"
                  class="col-lg-5 col-md-5 col-12">Requisitions for {{ employee_name }}</span>
                <span v-else class="col-lg-5 col-md-5 col-12">All Requisitions</span>
              </h1>
            </v-col>
            <!-- :offset-md="
                (employee_name.toLowerCase() != 'all requisitions' ||
                  date_range.length >= 1) &&
                userType == 1
                  ? null
                  : '1'
              " -->
            <v-col cols="12" class="py-5 text-left">
              <v-row>
                <v-col cols="6" sm="6" md="6" class="remove-hint pt-1">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                        v-model="filtering.from" placeholder="From" :disabled="false" dense solo readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="#069" ref="picker" v-model="filtering.from" :disabled="false" :max="new Date(new Date().getTime() + 1.577e10)
      .toISOString()
      .substring(0, 10)
      " @change="save"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="6" md="6" class="remove-hint pt-1">
                  <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="filtering.to"
                        placeholder="To" :disabled="false" dense solo readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="#069" ref="picker2" v-model="filtering.to" :disabled="false" :max="new Date(new Date().getTime() + 1.577e10)
      .toISOString()
      .substring(0, 10)
      " @change="save2"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="justify-content: space-between;">
            <v-col cols="12" md="4" class="pt-0 text-right">
              <v-select class="remove-mb" :items="allEmployees" label="All Requisitions" dense solo
                messages="*Select Employees" :full-width="true" v-model="employee_name">
              </v-select>
            </v-col>
            <v-row class="col-12 col-md-6" style="float:right; justify-content: flex-end; align-item:flex-end;">
              <v-col cols="6" md="4" class="pt-0 text-right">
                <button @click="print()" :class="$vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
      ? 'w-100'
      : null
      " class="btn btn-outline-secondary w-100 m-1">
                  Print
                </button>
              </v-col>
              <v-col v-if="userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1))
      " cols="6" md="4" class="pt-0" :class="$vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
      ? 'text-left'
      : 'text-right'
      ">
                <download-excel v-if="excel_filtered_requisitions.length >= 1" :data="json_data" :fields="json_fields"
                  :worksheet="employee_name.toLowerCase() != 'all requisitions'
      ? employee_name
      : 'All Employees'
      " :name="'Request History for ' +
      (employee_name.toLowerCase() != 'all requisitions'
        ? employee_name
        : 'All Employees') +
      '.xls'
      " class="btn btn-raised ripple btn-raised-secondary m-1 text-white" :class="$vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
      ? 'w-100'
      : 'w-100'
      ">
                  Export to Excel
                </download-excel>
                <button v-else style="cursor: disabled; opacity: 0.5;"
                  class="btn btn-raised ripple btn-raised-secondary m-1 text-white" :class="$vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
      ? 'w-100'
      : null
      ">
                  Export to Excel
                </button>
              </v-col>
              <v-col v-if="(employee_name.toLowerCase() != 'all requisitions' ||
      date_range.length >= 1) &&
      userType == 1 &&
      (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1)
      " cols="6" md="4" class="pt-0">
                <button @click="reset_filter()" :class="$vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
      ? 'w-100'
      : null
      " class="btn btn-raised ripple btn-raised-secondary m-1 text-white w-100">
                  Reset
                </button>
              </v-col></v-row>
          </v-row>
          <div class="row">
            <div v-if="employee_name.toLowerCase() != 'all requisitions'" class="col-md-12">
              <div id="printMe" class="card" style="border-radius: 10px;">
                <div class="card-header px-3 d-print-block d-none">
                  <h4 v-if="excel_filtered_requisitions.length >= 1">
                    {{
      "REQUISITIONS FROM " +
      excel_filtered_requisitions[
        excel_filtered_requisitions.length - 1
      ].created_at +
      " TO " +
      excel_filtered_requisitions[0].created_at +
      " (" +
      employee_name.toUpperCase() +
      ")"
    }}
                  </h4>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive table-scroller">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Date Applied</th>
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">
                            Request Type
                          </th>
                          <th scope="col" class="text-left">Title</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-left">Amount</th>
                          <!-- <th scope="col" class="text-left">Amount in Words</th> -->
                          <th scope="col" class="text-left">
                            Attached Document
                          </th>
                          <th scope="col" class="text-left">Status</th>
                          <th scope="col" class="text-left">Final Approver</th>
                          <th scope="col" class="text-left d-print-none">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="requisition in filtered_requisitions" :key="requisition.requisition.id">
                          <td class="text-left" scope="row">
                            {{
      new Date(requisition.requisition.created_at)
        .toUTCString()
        .substring(0, 16)
    }}
                          </td>
                          <td class="text-left" scope="row">
                            {{ get_employee_name(requisition.requisition.request_by) }}
                          </td>

                          <td class="text-left">
                            {{
      getRequisitionTypeName(requisition.requisition.request_type)
    }}
                          </td>
                          <td class="text-left">
                            {{ requisition.requisition.requisition.title }}
                          </td>
                          <td class="text-left">
                            {{ requisition.requisition.purpose }}
                          </td>
                          <td class="text-left" scope="row">
                            ₦{{
      (requisition.requisition.amount || 0) | number_with_commas
    }}
                          </td>
                          <!-- <td class="text-left">
                                                        {{ requisition.amountwords }}
                                                    </td> -->
                          <td v-if="requisition.requisition.file" class="text-left">
                            <a :href="assets_url + requisition.requisition.file" target="_blank"
                              rel="noopener noreferrer">
                              View Document
                            </a>
                          </td>
                          <td v-else class="text-left">
                            <i>No attached document</i>
                          </td>
                          <td v-if="requisition.status == 0" class="text-left">
                            <span v-if="requisition.requisition.approval_status == 1"
                              class="badge text-center badge-success">APPROVED</span>
                            <span v-else-if="requisition.requisition.approval_status == 2"
                              class="badge text-center badge-danger">REJECTED</span>
                            <span v-else class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else class="text-left">
                            <span v-if="requisition.requisition.status == 1"
                              class="badge text-center badge-success">APPROVED</span>
                            <span v-else-if="requisition.requisition.status == 2"
                              class="badge text-center badge-danger">REJECTED</span>
                            <span v-else class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-if="requisition.requisition.approve_by" class="text-left">
                            {{ requisition.requisition.approve_by }}
                          </td>
                          <td v-else class="text-left">
                            <i>Unapproved</i>
                          </td>
                          <td class="text-left pt-1 d-print-none">
                            <div class="ul-widget4__actions">
                              <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="_dot _inline-dot bg-primary"></span>
                                <span class="_dot _inline-dot bg-primary"></span>
                                <span class="_dot _inline-dot bg-primary"></span>
                              </button>
                              <div class="dropdown-menu" x-placement="top-start"
                                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="generate_summary(requisition.requisition, requisition.approvals)">
                                  <i class="fal fa-print" style="color: inherit !important;"></i> View Document
                                </a>
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="view_items(requisition.requisition, requisition.approvals)">
                                  <i class="fal fa-sitemap" style="color: inherit !important;"></i> Items
                                </a>
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="view_comment(requisition.requisition)">
                                  <i class="fal fa-message-dots" style="color: inherit !important;"></i> Comments
                                </a>
                                <a v-if="userType == 1 || (permissionIsAssigned('requisition-approve') || hasWorkflow('requisitions'))"
                                  class="dropdown-item" style="cursor: pointer;"
                                  @click="approve_requisition(requisition.requisition)">
                                  <i class="fal fa-check-circle" style="color: inherit !important;"></i> Approve
                                </a>
                                <a v-if="userType == 1 || (permissionIsAssigned('requisition-approve') || hasWorkflow('requisitions'))"
                                  class="dropdown-item" style="cursor: pointer;"
                                  @click="disapprove_requisition(requisition.requisition)">
                                  <i class="fal fa-times-circle"></i> Disapprove
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-md-12">
              <div id="printMe" class="card" style="border-radius: 10px;">
                <div class="card-header px-3 d-print-block d-none">
                  <h4 v-if="excel_filtered_requisitions.length >= 1">
                    {{
      "REQUISITIONS FROM " +
      excel_filtered_requisitions[
        excel_filtered_requisitions.length - 1
      ].created_at +
      " TO " +
      excel_filtered_requisitions[0].created_at
    }}
                  </h4>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive table-scroller">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Date Applied</th>
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">
                            Request Type
                          </th>
                          <th scope="col" class="text-left">Title</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-left">Amount</th>
                          <!-- <th scope="col" class="text-left">Amount in Words</th> -->
                          <th scope="col" class="text-left">
                            Attached Document
                          </th>
                          <th scope="col" class="text-left">Status</th>
                          <th scope="col" class="text-left">Final Approver</th>
                          <th scope="col" class="text-left d-print-none">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="requisition in filtered_requisitions" :key="requisition.requisition.id">
                          <td class="text-left" scope="row">
                            {{
      new Date(requisition.requisition.created_at)
        .toUTCString()
        .substring(0, 16)
    }}
                          </td>
                          <td class="text-left" scope="row">
                            {{ get_employee_name(requisition.requisition.request_by) }}
                          </td>

                          <td class="text-left">
                            {{
      getRequisitionTypeName(requisition.requisition.request_type)
    }}
                          </td>
                          <td class="text-left">
                            {{ requisition.requisition.title }}
                          </td>
                          <td class="text-left">
                            {{ requisition.requisition.purpose }}
                          </td>
                          <td class="text-left" scope="row">
                            ₦{{
      (requisition.requisition.amount || 0) | number_with_commas
    }}
                          </td>
                          <!-- <td class="text-left">
                                                        {{ requisition.amountwords }}
                                                    </td> -->
                          <td v-if="requisition.requisition.file" class="text-left">
                            <a :href="assets_url + requisition.requisition.file" target="_blank"
                              rel="noopener noreferrer">
                              View Document
                            </a>
                          </td>
                          <td v-else class="text-left">
                            <i>No attached document</i>
                          </td>

                          <td v-if="requisition.requisition.status == 0" class="text-left">
                            <span v-if="requisition.requisition.approval_status == 1"
                              class="badge text-center badge-success">APPROVED</span>
                            <span v-else-if="requisition.requisition.approval_status == 2"
                              class="badge text-center badge-danger">REJECTED</span>
                            <span v-else class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else class="text-left">
                            <span v-if="requisition.requisition.status == 1"
                              class="badge text-center badge-success">APPROVED</span>
                            <span v-else-if="requisition.requisition.status == 2"
                              class="badge text-center badge-danger">REJECTED</span>
                            <span v-else class="badge badge-warning text-center text-white">PENDING</span>
                          </td>

                          <td v-if="requisition.requisition.approve_by" class="text-left">
                            {{ requisition.requisition.approve_by }}
                          </td>
                          <td v-else class="text-left">
                            <i>Unapproved</i>
                          </td>
                          <td class="text-left pt-1 d-print-none">
                            <div class="ul-widget4__actions">
                              <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="_dot _inline-dot bg-primary"></span>
                                <span class="_dot _inline-dot bg-primary"></span>
                                <span class="_dot _inline-dot bg-primary"></span>
                              </button>
                              <div class="dropdown-menu" x-placement="top-start"
                                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="generate_summary(requisition.requisition, requisition.approvals)">
                                  <i class="fal fa-print" style="color: inherit !important;"></i> View Document
                                </a>
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="view_items(requisition.requisition, requisition.approvals)">
                                  <i class="fal fa-sitemap" style="color: inherit !important;"></i> Items
                                </a>
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="view_comment(requisition.requisition)">
                                  <i class="fal fa-message-dots" style="color: inherit !important;"></i> Comments
                                </a>
                                <a v-if="userType == 1 || (permissionIsAssigned('requisition-approve') || hasWorkflow('requisitions'))"
                                  class="dropdown-item" style="cursor: pointer;"
                                  @click="approve_requisition(requisition.requisition)">
                                  <i class="fal fa-check-circle" style="color: inherit !important;"></i> Approve
                                </a>
                                <a v-if="userType == 1 || (permissionIsAssigned('requisition-approve') || hasWorkflow('requisitions'))"
                                  class="dropdown-item" style="cursor: pointer;"
                                  @click="disapprove_requisition(requisition.requisition)">
                                  <i class="fal fa-times-circle"></i> Disapprove
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
      ? 'pr-1'
      : 'pr-5'
      ">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1)"
            class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span :style="$vuetify.breakpoint.name == 'xs'
      ? 'margin-left: 1.5rem;'
      : $vuetify.breakpoint.name == 'sm'
        ? 'margin-left: 5rem;'
        : null
      ">{{
      employeeView
        ? "Your Request Records"
        : "All Employees' Records"
    }}</span>
            </button>
            <div v-if="permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1"
              class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{
      employeeView
        ? "All Employees' Records"
        : "Your Request Records"
    }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-lg-5 pr-md-5">
          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != 1" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
      ? 'px-2'
      : null
      ">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
      ">
            <h1 class="my-2 row pb-0">
              <span class="col-lg-5 col-md-5 col-12">Your Request Records</span>
              <!-- <span class="col-lg-5 col-md-5 col-12" v-else>Your Request Records (No data to display)</span> -->
              <v-row class="col-md-7 col-lg-7 col-12">
                <v-col cols="5" lg="4" md="4" :class="$vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
      ? 'text-left'
      : 'text-right'
      " class="pt-0 pr-0">
                </v-col>
                <v-col cols="7" lg="4" md="4" :class="$vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
      ? 'text-left'
      : 'text-right'
      " class="pt-0 pr-0">
                </v-col>
                <v-col cols="6" lg="4" md="4" class="text-right pt-0">
                  <button @click="create_requisition = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white">
                    Create Requisition
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive table-scroller">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Date Applied</th>
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">
                            Request Type
                          </th>
                          <th scope="col" class="text-left">Title</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-left">Amount</th>
                          <!-- <th scope="col" class="text-left">Amount in Words</th> -->
                          <th scope="col" class="text-left">
                            Attached Document
                          </th>
                          <th scope="col" class="text-left">Status</th>
                          <th scope="col" class="text-left">Approval Status</th>
                          <th scope="col" class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="requisition in filtered_requisitions" :key="requisition.id">
                          <td class="text-left" scope="row">
                            {{
      new Date(requisition.requisition.created_at)
        .toUTCString()
        .substring(0, 16)
    }}
                          </td>
                          <td class="text-left" scope="row">
                            {{ get_employee_name(requisition.requisition.request_by) }}
                          </td>

                          <td class="text-left">
                            {{
      getRequisitionTypeName(requisition.requisition.request_type)
    }}
                          </td>
                          <td class="text-left">
                            {{ requisition.requisition.title }}
                          </td>
                          <td class="text-left">
                            {{ requisition.requisition.purpose }}
                          </td>
                          <td class="text-left" scope="row">
                            ₦{{
      (requisition.requisition.amount || 0) | number_with_commas
    }}
                          </td>
                          <!-- <td class="text-left">
                                                        {{ requisition.amountwords }}
                                                    </td> -->
                          <td v-if="requisition.requisition.file" class="text-left">
                            <a :href="assets_url + requisition.requisition.file" target="_blank"
                              rel="noopener noreferrer">
                              View Document
                            </a>
                          </td>
                          <td v-else class="text-left">
                            <i>No attached document</i>
                          </td>
                          <td class="text-left" v-if="requisition.requisition.approval_status == 1">
                            <span class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td class="text-left" v-else-if="requisition.requisition.approval_status == 2">
                            <span class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td class="text-left" v-else>
                            <span class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td class="text-left">
                            {{ requisition.requisition.level }}
                          </td>
                          <td class="text-left pt-1 d-print-none">
                            <div class="ul-widget4__actions">
                              <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="_dot _inline-dot bg-primary"></span>
                                <span class="_dot _inline-dot bg-primary"></span>
                                <span class="_dot _inline-dot bg-primary"></span>
                              </button>
                              <div class="dropdown-menu" x-placement="top-start"
                                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="generate_summary(requisition.requisition, requisition.approvals)">
                                  <i class="fal fa-print" style="color: inherit !important;"></i> View Document
                                </a>
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="view_items(requisition.requisition, requisition.approvals)">
                                  <i class="fal fa-sitemap" style="color: inherit !important;"></i> Items
                                </a>
                                <a class="dropdown-item" style="cursor: pointer;"
                                  @click="view_comment(requisition.requisition)">
                                  <i class="fal fa-message-dots" style="color: inherit !important;"></i> Comments
                                </a>
                                <a v-if="requisition.approval_status != 1" class="dropdown-item"
                                  style="cursor: pointer;" @click="make_current_requisition(requisition.requisition)">
                                  <i class="fal fa-edit" style="color: inherit !important;"></i> Edit
                                </a>
                                <a v-if="requisition.approval_status != 1" class="dropdown-item"
                                  style="cursor: pointer;" @click="delete_requisition(requisition.requisition)">
                                  <i class="fal fa-trash"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <v-dialog v-model="edit_requisition" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Requisition</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Request Type
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="editFormData.requisition_type" item-color="#069" :items="requisitionTypeNames"
                      placeholder="Select request type" solo dense class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Title
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.title" placeholder="Enter request title" solo class="mt-2"
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Purpose
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.purpose" placeholder="Enter request purpose" solo class="mt-2"
                      dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Cost
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.amount" placeholder="Enter request cost" type="number" solo
                      class="mt-2" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="
      update_requisition_submit ? null : update_requisition()
      " style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ update_requisition_text }}
                      <v-icon :style="!update_requisition_submit
      ? 'display: none;'
      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="edit_requisition = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="create_requisition" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Requisition</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Request Type
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="formData.requisition_type" item-color="#069" :items="requisitionTypeNames"
                      placeholder="Select request type" solo dense class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Title
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.title" placeholder="Enter request title" solo class="mt-2"
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Purpose
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.purpose" placeholder="Enter request purpose" solo class="mt-2"
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Cost
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.amount" placeholder="Enter request cost" type="number" solo
                      class="mt-2" dense>
                    </v-text-field>
                  </v-col>
                  <v-col style="cursor: pointer;" cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Document</span>
                    <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                      label="Upload Attaching Document" solo dense style="text-indent: 5px;" color="#069"
                      class="fileAttachment" messages="Attaching Document">
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="add_requisition_submit ? null : add_requisition()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_requisition_text }}
                      <v-icon :style="!add_requisition_submit
      ? 'display: none;'
      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="create_requisition = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="flex-grow-1"></div>
    <v-dialog v-model="comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ approval ? "Approve" : "Reject" }} request with
            comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Requisition Comment
                      <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="comment" :placeholder="userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-approve') || hasWorkflow('requisitions')))
      ? 'Requisition Comment'
      : 'No comment'
      " solo class="mt-2" :disabled="userType == 1 ||
      (userType != 1 &&
        (permissionIsAssigned('requisition-approve') || hasWorkflow('requisitions')))
      ? false
      : true
      "></v-textarea>
                  </v-col>
                  <v-col v-if="userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-approve') || hasWorkflow('requisitions')))
      " cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="add_comment_submit ? null : requisition_action()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment_text }}
                      <v-icon :style="!add_comment_submit
      ? 'display: none;'
      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="requisition_comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Requisition Comments</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-left">Commenter</th>
                  <th class="text-left">Date Sent</th>
                  <th class="text-left">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="comment in filteredRequisitionComments" :key="comment.id">
                  <td class="text-left">
                    {{ get_employee_name(comment.employee_id) }}
                  </td>
                  <td class="text-left">
                    {{
      new Date(comment.created_at)
        .toUTCString()
        .substring(0, 16)
    }}
                  </td>
                  <td class="text-left">
                    {{ comment.comments }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="requisition_comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileAttachmentDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Attaching Document</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint">
                    <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                      label="Upload Attaching Document" solo style="text-indent: 5px;" color="#069"
                      class="fileAttachment" messages="Attaching Document">
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                    <button @click="
      fileAttachmentBtnSubmit
        ? null
        : upload_file_attachment()
      " style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                      {{ fileAttachmentBtnText }}
                      <v-icon :style="!fileAttachmentBtnSubmit
      ? 'display: none;'
      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
      ">
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="fileAttachmentDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="requisition_items_dialog" max-width="900px">
      <v-card v-if="current_requisition">
        <v-card-title>
          <div class="row">
            <div class="col-sm-6">
              <span class="headline">Requisition Items</span>
            </div>
            <div v-if="!(userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1))
    ) && current_requisition.approval_status != 1" class="col-sm-6">
              <div class="text-right">
                <button v-if="itemView === 'index'" @click="launchAddItems"
                  class="btn btn-sm btn-raised ripple btn-raised-secondary m-1 mt-0 text-white">
                  Add Items
                </button>
                <button v-else @click="itemView = 'index'" class="btn btn-sm btn-danger">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-if="itemView === 'index'">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="text-left">Item</th>
                    <th class="text-left">Quantity</th>
                    <th class="text-left">Rate</th>
                    <th class="text-left">Amount</th>
                    <th v-if="!(userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1))
    ) && current_requisition.approval_status != 1" class="text-left">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, itemIndex) in authRequisitionItems" :key="`item-${itemIndex}`">
                    <td class="text-left">{{ item.item }}</td>
                    <td class="text-left">{{ item.quantity }}</td>
                    <td class="text-left">₦{{ (item.rate || 0) | number_with_commas }}</td>
                    <td class="text-left">₦{{ (item.amount || 0) | number_with_commas }}</td>
                    <td v-if="!(userType == 1 ||
      (userType != 1 &&
        !employeeView &&
        (permissionIsAssigned('requisition-read') || authCompanyRequisitions.length >= 1))
    ) && current_requisition.approval_status != 1" class="text-left pt-1 d-print-none">
                      <div class="ul-widget4__actions">
                        <button class="btn _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false">
                          <span class="_dot _inline-dot bg-primary"></span>
                          <span class="_dot _inline-dot bg-primary"></span>
                          <span class="_dot _inline-dot bg-primary"></span>
                        </button>
                        <div class="dropdown-menu" x-placement="top-start"
                          style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                          <a v-if="current_requisition ? current_requisition.approval_status != 1 : false"
                            class="dropdown-item" style="cursor: pointer;" @click="make_current_requisition_item(item)">
                            <i class="fal fa-edit" style="color: inherit !important;"></i> Edit
                          </a>
                          <a v-if="current_requisition ? current_requisition.approval_status != 1 : false"
                            class="dropdown-item" style="cursor: pointer;" @click="delete_requisition_item(item)">
                            <i class="fal fa-trash"></i> Delete
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else-if="itemView === 'create'">
              <v-row v-for="(item, itemIndex) in itemsFormData" :key="`item-${itemIndex}`">
                <v-col cols="12">
                  <div class="text-right">
                    <a href="#" class="mr-2" @click.prevent="addRequisitionItem">
                      <i class="fal fa-plus"></i>
                    </a>
                    <a v-if="itemsFormData.length === 1" href="#" @click.prevent>
                      <i class="fal fa-minus d-none"></i>
                    </a>
                    <a v-else href="#" @click.prevent="removeRequisitionItem(itemIndex)">
                      <i class="fal fa-minus"></i>
                    </a>
                  </div>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Item Name
                    <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="item.item" placeholder="Enter item name" solo class="mt-2"
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Rate (₦)
                    <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="item.rate" type="number" placeholder="Enter item rate" solo class="mt-2"
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Quantity
                    <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="item.quantity" type="number" placeholder="Enter item quantity" solo
                    class="mt-2" dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Amount (₦)</span>
                  <v-text-field :value="item.rate * item.quantity" disabled type="number"
                    placeholder="Enter item amount" solo class="mt-2" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                  <button @click="add_requisition_item_submit ? null : add_requisition_items()" style="width: 99%;"
                    type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                    {{ add_requisition_item_text }}
                    <v-icon :style="!add_requisition_item_submit
      ? 'display: none;'
      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
      ">
                      fal fa-circle-notch fa-spin
                    </v-icon>
                  </button>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Item Name
                    <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="editItemFormData.item" placeholder="Enter item name" solo class="mt-2"
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Rate (₦)
                    <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="editItemFormData.rate" type="number" placeholder="Enter item rate" solo
                    class="mt-2" dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Quantity
                    <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="editItemFormData.quantity" type="number" placeholder="Enter item quantity" solo
                    class="mt-2" dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="remove-hint">
                  <span style="color: #111d5e;">Amount (₦)</span>
                  <v-text-field :value="editItemFormData.rate * editItemFormData.quantity" disabled type="number"
                    placeholder="Enter item amount" solo class="mt-2" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                  <button @click="update_requisition_item_submit ? null : update_requisition_items()"
                    style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                    {{ update_requisition_item_text }}
                    <v-icon :style="!update_requisition_item_submit
      ? 'display: none;'
      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
      ">
                      fal fa-circle-notch fa-spin
                    </v-icon>
                  </button>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="requisition_items_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="requisitionSummary" class="card" style="border-radius: 10px;">
      <div v-if="current_requisition" class="card-header px-3">
        <div class="row">
          <div class="col-md-9">
            <h3 style="text-transform: uppercase;">
              {{ current_requisition.title + ' REQUISITION FORM' }}
            </h3>
          </div>
          <div class="col-md-3">
            <img :src="authCompanyData.logo
      ? assets_url + authCompanyData.logo
      : '/img/kylian-logo-2.png'
      " alt="" style="height: inherit; max-width: 70px;" />
          </div>
        </div>
      </div>
      <div v-if="current_requisition" class="card-body px-1 py-1 pt-0 pr-0">
        <div class="table-responsive table-scroller">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td colspan="5" style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">
                  {{ authCompanyData.company_name }}
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Purpose:</td>
                <td colspan="4" style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">
                  {{ current_requisition.purpose }}
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Date: </td>
                <td colspan="4" style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">
                  {{ new Date(current_requisition.created_at).toUTCString().substring(0, 16) }}
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Amount:
                </td>
                <td colspan="4" style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">
                  <span>₦{{ (current_requisition.amount || 0) | number_with_commas }}</span>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Status: </td>
                <td colspan="4" style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">
                  <span v-if="current_requisition.approval_status == 1">APPROVED</span>
                  <span v-else-if="current_requisition.approval_status == 2">REJECTED</span>
                  <span v-else>PENDING</span>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">S/N</td>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Items</td>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Quantity</td>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Rate (₦)</td>
                <td style="font-weight: bold; padding: 3px !important;" scope="col" class="text-left">Amount (₦)</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, itemIndex) in authRequisitionItems" :key="`item-${itemIndex}`">
                <td style="padding: 3px !important;" class="text-left">{{ itemIndex + 1 }}</td>
                <td style="padding: 3px !important;" class="text-left">{{ item.item }}</td>
                <td style="padding: 3px !important;" class="text-left">{{ item.quantity }}</td>
                <td style="padding: 3px !important;" class="text-left">{{ (item.rate || 0) | number_with_commas }}</td>
                <td style="padding: 3px !important;" class="text-left">{{ (item.amount || 0) | number_with_commas }}
                </td>
              </tr>
              <tr style="height: 40px;">
                <td style="padding: 3px !important;" colspan="5"></td>
              </tr>
              <tr>
                <td style="padding: 3px !important;"></td>
                <td style="padding: 3px !important;" class="text-left">TOTAL</td>
                <td style="padding: 3px !important;"></td>
                <td style="padding: 3px !important;"></td>
                <td style="padding: 3px !important;" class="text-left">
                  {{ totalRequisitionItemAmount | number_with_commas }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" style="font-weight: bold; padding: 3px !important;" class="text-left">Request made by:
                </td>
                <td style="font-weight: bold; padding: 3px !important;" class="text-left">
                  {{ get_employee_name(current_requisition.request_by) }}
                </td>
              </tr>
              <!-- <tr>
                <td colspan="4" style="font-weight: bold; padding: 3px !important;" class="text-left">Signature</td>
                <td style="font-weight: bold; padding: 3px !important;" v-if="current_requisition.approval_status == 1"
                  class="text-left">
                  <img v-if="authCompanyData.signature" :src="assets_url + authCompanyData.signature"
                    style="max-width: 200px;" />
                  <span v-else>APPROVED (NO COMPANY SIGNATURE)</span>
                </td>
                <td style="padding: 3px !important;" v-else></td>
              </tr> -->
              <tr style="height: 40px;">
                <td style="font-weight: bold; padding: 3px !important;" colspan="5">APPROVALS:</td>
              </tr>
              <tr v-for="(approval, approvalIndex) in requisitionApprovals" :key="`approval-${approvalIndex}`">
                <td colspan="4" style="font-weight: bold; padding: 3px !important;" class="text-left">
                  {{ approval.employee_name }}
                </td>
                <td style="font-weight: bold; padding: 3px !important;" class="text-left">
                  <img v-if="approval.signature" :src="assets_url + approval.signature" style="max-width: 150px;" />
                  <span v-else>APPROVED (NO UPLOADED SIGNATURE)</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import currencies from "@/plugins/currencies";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      overlay: false,
      attachment_requisition_id: null,
      fileAttachmentBtnSubmit: false,
      fileAttachmentBtnText: "Upload",
      fileAttachmentDialog: false,
      employeeView: true,
      date_range: [],
      menu: false,
      menu2: false,
      formData: {
        requisition_type: "",
        title: "",
        purpose: "",
        amount: ""
      },
      editFormData: {
        id: null,
        requisition_type: "",
        title: "",
        purpose: "",
        amount: ""
      },
      requisitionItems: [],
      requisitionApprovals: [],
      itemsFormData: [
        {
          requisition_id: null,
          company_id: null,
          item: '',
          quantity: 1,
          rate: 1,
        },
      ],
      add_requisition_item_text: "Submit",
      add_requisition_item_submit: false,
      editItemFormData: {
        requistionitem_id: null,
        item: '',
        quantity: 1,
        rate: 1,
      },
      update_requisition_item_text: "Update",
      update_requisition_item_submit: false,
      add_requisition_text: "Submit",
      add_requisition_submit: false,
      edit_requisition: false,
      create_requisition: false,
      update_requisition_text: "Update",
      update_requisition_submit: false,
      employee_name: "All Requisitions",
      employee: null,
      day_selected: "",
      view: "date",
      itemView: "index",
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      firstYear: "",
      allEmployees: [],
      allEmployees2: [],
      current_requisition: null,
      comment_dialog: false,
      approval: false,
      add_comment_text: "Submit",
      add_comment_submit: false,
      comment: "",
      requisition_comment_dialog: false,
      requisition_items_dialog: false,
      all_requisition_comments: [],
      filtering: {
        from: "",
        to: ""
      }
    };
  },
  watch: {
    // menu(val) {
    //   val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    // },
    // menu2(val) {
    //   val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    // }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyEmployees",
      "authEmployeeDataForCompany",
      "true_employees",
      "authCompanyRequisitions",
      "authEmployeeRequisitions",
      "authCompanyRequisitionTypes",
      "authCompanyRequisitionComments",
      "authUserData",
      "authRequisitionItems",
    ]),
    totalRequisitionItemAmount() {
      const _this = this;
      let amount = 0;
      _this.authRequisitionItems.forEach(item => amount += Number(item.amount));
      return amount;
    },
    requisitionItemsToAdd() {
      const _this = this;
      return _this.itemsFormData.map(item => {
        return {
          requisition_id: _this.current_requisition.id,
          company_id: _this.authCompanyData.id,
          item: item.item,
          amount: (item.rate * item.quantity),
          quantity: item.quantity,
          rate: item.rate,
        }
      });
    },
    filteredRequisitionComments() {
      let _this = this,
        result = [];
      if (_this.current_requisition) {
        result = _this.authCompanyRequisitionComments.filter(
          (comment) => comment.requisition_id == _this.current_requisition.id
        );
      }
      return result;
    },
    requisitionTypeNames() {
      let _this = this,
        result = [];
      result = _this.authCompanyRequisitionTypes.map((type) => type.name);
      return result;
    },
    requisitionTypeId() {
      let _this = this,
        result = null,
        id = null;
      result = _this.authCompanyRequisitionTypes.filter(
        (type) => type.name == _this.formData.requisition_type
      );
      if (result.length) {
        id = result[0].id;
      }
      return id;
    },
    editRequisitionTypeId() {
      let _this = this,
        result = null,
        id = null;
      result = _this.authCompanyRequisitionTypes.filter(
        (type) => type.name == _this.editFormData.requisition_type
      );
      if (result.length) {
        id = result[0].id;
      }
      return id;
    },
    dateRangeText() {
      if (this.date_range) {
        return this.date_range.join(" ~ ");
      }
      return null;
    },
    json_data: function () {
      return this.excel_filtered_requisitions;
    },
    json_fields: function () {
      let _this = this;
      if (_this.employee_name.toLowerCase() != "all requisitions") {
        return {
          "Date Applied": "created_at",
          Employee: "employee",
          "Requisition Type": "requisition_type",
          Title: "title",
          Purpose: "purpose",
          Amount: "amount",
          "Approval Status": "approval_status",
          "Final Approver": "approver"
        };
      } else {
        return {
          "Date Applied": "created_at",
          Employee: "employee",
          "Requisition Type": "requisition_type",
          Title: "title",
          Purpose: "purpose",
          Amount: "amount",
          "Approval Status": "approval_status",
          "Final Approver": "approver"
        };
      }
    },
    excel_filtered_requisitions() {
      let _this = this;
      return _this.filtered_requisitions.map((t) => {
        return {
          employee: _this.get_employee_name(t.requisition.request_by),
          requisition_type: _this.getRequisitionTypeName(t.requisition.request_type),
          title: t.requisition.title,
          purpose: t.requisition.purpose,
          amount: t.requisition.amount,
          approval_status:
            t.requisition.approval_status == 0
              ? "Pending"
              : t.requisition.approval_status == 1
                ? "Approved"
                : "Rejected",
          approver: t.requisition.approve_by ? t.requisition.approve_by : "Unapproved",
          created_at: t.requisition.created_at.substring(0, 10)
        };
      });
    },
    filtered_requisitions() {
      let _this = this;
      let requisitions = (_this.userType == 1 || (_this.userType != 1 && !_this.employeeView)) ? _this.authCompanyRequisitions : _this.authEmployeeRequisitions;

      if (_this.filtering.from && _this.filtering.to) {
        const startDate = new Date(_this.filtering.from);
        const endDate = new Date(_this.filtering.to);

        requisitions = requisitions.filter((item) => {
          const updatedAt = new Date(item.requisition.updated_at);
          return updatedAt >= startDate && updatedAt <= endDate;
        });
      }
      if (_this.employee_id) {
        requisitions = requisitions.filter((req) => {
          return req.requisition.request_by == _this.employee_id;
        });
      }

      // if (_this.userType == 1 || (_this.userType != 1 && !_this.employeeView)) {
      //   return requisitions;
      // } else {
      //   requisitions = requisitions.filter(
      //     (r) => r.request_by == _this.validEmployeeId
      //   );
      // }
      return requisitions;
    },
    all_filtered_requisition_ids: function () {
      const _this = this;
      return _this.filtered_requisitions.map((requisition) => {
        return {
          id: requisition.requisition.id
        };
      });
    },
    currencies() {
      let all_currencies = currencies.map((currency) => {
        return currency.code + " (" + currency.name + ")";
      });
      return all_currencies;
    },
    assets_url() {
      return ASSETS_URL;
    },
    employee_id: function () {
      var t = this;
      return t.true_employees &&
        t.employee_name.toLowerCase() != "all requisitions"
        ? t.true_employees.filter(function (e) {
          return (
            e.employee_fristname + " " + e.employee_lastname ==
            t.employee_name
          );
        })[0].id
        : null;
    }
  },
  methods: {
    ...mapActions([
      "fetch_company_requisition_types",
      "fetch_company_requisitions",
      "fetch_employee_requisitions",
      "fetch_company_requisition_comments",
      "fetch_requisition_items",
      "fetch_company_workflows",
    ]),
    launchAddItems() {
      const _this = this;
      _this.itemView = 'create';
      _this.resetItemsFormData();
    },
    resetItemsFormData() {
      this.itemsFormData = [
        {
          requisition_id: null,
          company_id: null,
          item: '',
          amount: 1,
          quantity: 1,
          rate: 1,
        },
      ];
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    async printRequisitionSummary() {
      // Pass the element id here
      await this.$htmlToPaper("requisitionSummary");
    },
    removeRequisitionItem(index) {
      const _this = this;
      _this.itemsFormData.splice(index, 1);
    },
    addRequisitionItem() {
      const _this = this;
      _this.itemsFormData.splice(
        _this.itemsFormData.length,
        0,
        {
          requisition_id: _this.current_requisition.id,
          company_id: _this.authCompanyData.id,
          item: '',
          amount: 1,
          quantity: 1,
          rate: 1,
        }
      );
    },
    getRequisitionTypeName(id) {
      let _this = this,
        result = [],
        value = null;
      result = _this.authCompanyRequisitionTypes.filter(
        (type) => type.id == id
      );
      if (result.length) {
        value = result[0].name;
      }
      return value;
    },
    upload_file_attachment() {
      let _this = this;
      _this.fileAttachmentBtnText = "";
      _this.fileAttachmentBtnSubmit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let formData = new FormData();
      formData.append("requisition_id", _this.attachment_requisition_id);
      formData.append(
        "file",
        document.querySelector(".fileAttachment div div div input").files[0]
      );

      axios
        .post(`${BASE_URL}uploadRequistiondoc`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Attaching document uploaded successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          const files = document.querySelector(".fileAttachment div div div input").files;
          if (files.length >= 1) {
            document.querySelector(".fileAttachment div div div input").files = [];
          }
          _this.fetch_company_requisitions();
          _this.fetch_employee_requisitions();
          _this.fetch_company_requisition_types();
          _this.fileAttachmentDialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.file
                  ? errors.file[0]
                  : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "Attaching document could not be uploaded"
          });
        })
        .then(function () {
          _this.fileAttachmentBtnSubmit = false;
          _this.fileAttachmentBtnText = "Upload";
        });
    },
    reset_filter() {
      this.employee_name = "All Requisitions";
      this.date_range = [];
    },
    requisition_action() {
      const _this = this,
        Toast = _this.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", _this.$swal.stopTimer),
              t.addEventListener("mouseleave", _this.$swal.resumeTimer);
          }
        });
      _this.add_comment_text = "";
      _this.add_comment_submit = true;
      axios
        .post(`${BASE_URL + (_this.approval ? 'requisitionworkflowapproval' : 'requisitionworkflowdisapproval')}`, {
          company_id: _this.authCompanyData.id,
          original_requisition_id: _this.current_requisition.id,
          request_id: _this.current_requisition.approver_table_id,
          comment: _this.comment,
          approve_by: (
            _this.authEmployeeDataForCompany
              ? (_this.authEmployeeDataForCompany.employee_fristname + ' ' + _this.authEmployeeDataForCompany.employee_lastname)
              : _this.authUserData.name
          ),
          employee_id: _this.validEmployeeId,
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: `Request ${_this.approval ? 'approved' : 'rejected'} successfully`
          });
          _this.comment = "";
          _this.comment_dialog = false;
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: `Request couldn't be ${_this.approval ? 'approved' : 'rejected'}`
          });
        })
        .then(function () {
          _this.fetch_company_requisitions(),
            _this.fetch_employee_requisitions(),
            _this.fetch_company_requisition_types(),
            _this.fetch_company_requisition_comments();
          _this.add_comment_submit = false;
          _this.add_comment_text = "Submit";
        });
    },
    approve_requisition(requisition) {
      const _this = this;
      _this.$swal
        .fire({
          title: "Are you sure you want to approve this request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve"
        })
        .then(function (result) {
          _this.approval = true;
          _this.current_requisition = requisition;
          result.isConfirmed ? (_this.comment_dialog = true) : "";
        });
    },
    disapprove_requisition(requisition) {
      const _this = this;
      _this.$swal
        .fire({
          title: "Are you sure you want to reject this request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject"
        })
        .then(function (result) {
          _this.approval = false;
          _this.current_requisition = requisition;
          result.isConfirmed ? (_this.comment_dialog = true) : "";
        });
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    make_current_requisition(requisition) {
      this.editFormData.id = requisition.id;
      this.editFormData.title = requisition.title;
      this.editFormData.amount = requisition.amount;
      this.editFormData.purpose = requisition.purpose;
      this.editFormData.requisition_type = this.getRequisitionTypeName(
        requisition.request_type
      );
      this.edit_requisition = true;
    },
    make_current_requisition_item(item) {
      const _this = this;
      _this.editItemFormData = { ...item, requistionitem_id: item.id };
      _this.itemView = 'edit';
    },
    async generate_summary(requisition, approvals) {
      const _this = this;
      _this.load_items(requisition, approvals).then(async () => {
        await _this.printRequisitionSummary();
      });
    },
    async view_items(requisition, approvals) {
      const _this = this;
      await _this.load_items(requisition, approvals);
      _this.requisition_items_dialog = true;
    },
    async load_items(requisition, approvals) {
      const _this = this;
      _this.requisitionItems = [];
      _this.requisitionApprovals = [...approvals];
      _this.current_requisition = requisition;
      await _this.fetch_requisition_items(requisition.id).then(() => {
        _this.requisitionItems = [..._this.authRequisitionItems];
      });
    },
    view_comment(requisition) {
      let _this = this;
      _this.current_requisition = requisition;
      _this.requisition_comment_dialog = true;
    },
    hasHistory: function () {
      return window.history.length > 2;
    },
    delete_requisition_item(t) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer),
              t.addEventListener("mouseleave", e.$swal.resumeTimer);
          }
        });
      e.$swal
        .fire({
          title: "Are you sure you want to delete this requisition item?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Delete"
        })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deleterequisitionitem`, {
                requistionitem_id: t.id
              })
              .then(function () {
                a.fire({
                  icon: "success",
                  title: "Request item deleted successfully"
                });
              })
              .catch(function () {
                a.fire({
                  icon: "error",
                  title: "Request item couldn't be deleted"
                });
              })
              .then(function () {
                e.fetch_requisition_items(e.current_requisition.id);
              });
        });
    },
    delete_requisition(t) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer),
              t.addEventListener("mouseleave", e.$swal.resumeTimer);
          }
        });
      e.$swal
        .fire({
          title: "Are you sure you want to delete this requisition?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Delete"
        })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deleterequistion`, {
                ids: [
                  {
                    id: t.id
                  }
                ]
              })
              .then(function () {
                a.fire({
                  icon: "success",
                  title: "Request deleted successfully"
                });
              })
              .catch(function () {
                a.fire({
                  icon: "error",
                  title: "Request couldn't be deleted"
                });
              })
              .then(function () {
                e.fetch_company_requisitions(),
                  e.fetch_employee_requisitions(),
                  e.fetch_company_requisition_types();
              });
        });
    },
    add_requisition_items: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer),
              e.addEventListener("mouseleave", t.$swal.resumeTimer);
          }
        });

      t.add_requisition_item_submit ||
        ((t.add_requisition_item_text = ""),
          (t.add_requisition_item_submit = !0),
          axios
            .post(`${BASE_URL}storerequisitionitem`, {
              requisitionitems: t.requisitionItemsToAdd,
            })
            .then(function () {
              e.fire({
                icon: "success",
                title: "Request items sent successfully"
              }),
                (t.resetItemsFormData());
              t.fetch_requisition_items(t.current_requisition.id);
              t.itemView = 'index';
            })
            .catch(function () {
              e.fire({
                icon: "error",
                title: "Requisition items couldn't be created"
              });
            })
            .then(function () {
              (t.add_requisition_item_submit = !1),
                (t.add_requisition_item_text = "Submit");
            }));
    },
    add_requisition: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer),
              e.addEventListener("mouseleave", t.$swal.resumeTimer);
          }
        });
      let form_data = new FormData();
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.validEmployeeId);
      form_data.append("request_type", t.requisitionTypeId);
      form_data.append("request_by", t.validEmployeeId);
      form_data.append("title", t.formData.title);
      form_data.append("amount", t.formData.amount);
      form_data.append("purpose", t.formData.purpose);
      const fileAdded =
        document.querySelector(".fileAttachment div div div input").files
          .length > 0;
      if (fileAdded) {
        form_data.append(
          "file",
          document.querySelector(".fileAttachment div div div input").files[0]
        );
      }

      t.add_requisition_submit ||
        ((t.add_requisition_text = ""),
          (t.add_requisition_submit = !0),
          axios
            .post(`${BASE_URL}createrequistion`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({
                icon: "success",
                title: "Request request sent successfully"
              }),
                (t.formData.requisition_type = ""),
                (t.formData.title = ""),
                (t.formData.amount = ""),
                (t.formData.purpose = "");
              t.fetch_company_requisition_types();
              t.fetch_company_requisitions();
              t.fetch_employee_requisitions();
              t.create_requisition = false;
              if (fileAdded) {
                document
                  .querySelector(
                    '[class="v-icon notranslate v-icon--link theme--light"]'
                  )
                  .click();
                const files = document.querySelector(".fileAttachment div div div input").files;
                if (files.length >= 1) {
                  document.querySelector(".fileAttachment div div div input").files = [];
                }
              }
            })
            .catch(function () {
              e.fire({
                icon: "error",
                title: "Request request couldn't be sent"
              });
            })
            .then(function () {
              (t.add_requisition_submit = !1),
                (t.add_requisition_text = "Submit");
            }));
    },
    update_requisition_items: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer),
              e.addEventListener("mouseleave", t.$swal.resumeTimer);
          }
        });

      t.update_requisition_item_submit ||
        ((t.update_requisition_item_text = ""),
          (t.update_requisition_item_submit = !0),
          axios
            .post(`${BASE_URL}updaterequisitionitem`, {
              ...t.editItemFormData,
              amount: (t.editItemFormData.rate * t.editItemFormData.quantity),
            })
            .then(function () {
              e.fire({
                icon: "success",
                title: "Request item updated successfully"
              }),
                (t.resetItemsFormData());
              t.fetch_requisition_items(t.current_requisition.id);
              t.itemView = 'index';
            })
            .catch(function () {
              e.fire({
                icon: "error",
                title: "Requisition item couldn't be updated"
              });
            })
            .then(function () {
              (t.update_requisition_item_submit = !1),
                (t.update_requisition_item_text = "Update");
            }));
    },
    update_requisition: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer),
              e.addEventListener("mouseleave", t.$swal.resumeTimer);
          }
        });
      let form_data = new FormData();
      form_data.append("id", t.editFormData.id);
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.validEmployeeId);
      form_data.append("request_type", t.editRequisitionTypeId);
      form_data.append("request_by", t.validEmployeeId);
      form_data.append("title", t.editFormData.title);
      form_data.append("amount", t.editFormData.amount);
      form_data.append("purpose", t.editFormData.purpose);
      // const fileAdded =
      //   document.querySelector(".requisition-file-update div div div input")
      //     .files.length > 0;
      // if (fileAdded) {
      //   form_data.append(
      //     "file",
      //     document.querySelector(".requisition-file-update div div div input")
      //       .files[0]
      //   );
      // }

      t.update_requisition_submit ||
        ((t.update_requisition_text = ""),
          (t.update_requisition_submit = !0),
          axios
            .post(`${BASE_URL}editrequistion`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({
                icon: "success",
                title: "Request has been updated successfully"
              }),
                (t.editFormData.id = ""),
                (t.editFormData.requisition_type = ""),
                (t.editFormData.title = ""),
                (t.editFormData.amount = ""),
                (t.editFormData.purpose = ""),
                (t.edit_requisition = !1);
              t.fetch_company_requisition_types();
              t.fetch_company_requisitions();
              t.fetch_employee_requisitions();
              // if (fileAdded) {
              //   document
              //     .querySelector(
              //       '[class="v-icon notranslate v-icon--link theme--light"]'
              //     )
              //     .click();
              // }
            })
            .catch(function () {
              e.fire({ icon: "error", title: "Request couldn't be updated" });
            })
            .then(function () {
              (t.update_requisition_submit = !1),
                (t.update_requisition_text = "Update");
            }));
    },
    get_employee_name: function (t) {
      var e = this;
      if (e.true_employees) {
        var a = e.true_employees.filter(function (e) {
          return e.id == t;
        })[0];
        return a
          ? a.employee_fristname + " " + a.employee_lastname
          : "Not found";
      }
      return "";
    }
  },
  async mounted() {
    const _this = this;
    if (_this.authCompanyRequisitionComments.length == 0 || _this.authEmployeeRequisitions.length == 0) {
      _this.overlay = true;
    }
    document.title = "Kylian ERP - Requisition Management";
    1 != this.userType && (document.title = "Kylian ERP - Requisitions");
    var t = this;
    t.allEmployees.push("All Requisitions");
    t.userType &&
      t.true_employees.forEach(function (e) {
        t.allEmployees.push(e.employee_fristname + " " + e.employee_lastname),
          t.allEmployees2.push(
            e.employee_fristname + " " + e.employee_lastname
          );
      });
    _this.fetch_company_requisition_types();
    _this.fetch_company_requisitions();
    _this.fetch_company_workflows();
    await _this.fetch_employee_requisitions();
    await _this.fetch_company_requisition_comments();
    _this.overlay = false;
  }
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
