<template>
    <div>
        <!-- <SideMenu /> -->
        <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
            <div class="main-header pl-4 mt-3" style="z-index: 60;">
                <div class="row">
                    <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
                        <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
                            <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"></i>
                            <span class="ml-2" style="top: 4%; position: relative;">Back</span>
                        </div>
                    </div>
                </div>
                <div style="margin: auto"></div>
                <div class="header-part-right"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">
                    <div v-if="userType == 1/* || permissionIsAssigned('training-create')*/"
                        class="dropdown animate__animated animate__fadeIn">
                        <template v-if="create_training != 2">
                            <button v-if="userType == 1/* || permissionIsAssigned('training-create')*/"
                                @click="create_training = 2" style="float: right;" type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                View Trainings
                            </button>
                        </template>
                    </div>
                    <!-- Notificaiton End -->
                    <!-- User avatar dropdown -->
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <div v-if="create_training == 1" class="main-content"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                <div class="breadcrumb mb-0"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
                    style="margin-top: 1rem;">
                    <h1 class="my-2 row pb-0">
                        <span class="col-lg-5 col-md-5 col-12">Create Training</span>
                    </h1>
                </div>
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Provider <i class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-select v-model="formData.provider_id" item-color="#069" item-text="provider_name"
                                    item-value="id" :items="authCompanyTrainingProviders"
                                    placeholder="Select the training provider" solo class="mt-2" dense
                                    color="#069"></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Title <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="formData.training_title"
                                    placeholder="Enter the training title" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <span style="color: #111d5e;">Training Description <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-textarea v-model="formData.training_description"
                                    placeholder="Enter the training description" solo class="mt-2">
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Option <i class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-select v-model="formData.training_option" item-color="#069"
                                    :items="['Internal', 'External']" placeholder="Select the training option" solo
                                    class="mt-2" dense color="#069"></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Cost (₦) <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="formData.training_cost" type="number"
                                    placeholder="Enter the training cost" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Start Date <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-menu ref="startDateMenuForCreation" v-model="startDateMenuForCreation"
                                    :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-1" color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline"
                                            v-model="formData.start_date" placeholder="Start Date" dense solo readonly
                                            v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="#069" ref="picker"
                                        :min="(new Date().getFullYear()).toString()" v-model="formData.start_date"
                                        @change="saveStartDateForCreation">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">End Date <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-menu ref="endDateMenuForCreation" v-model="endDateMenuForCreation"
                                    :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-1" color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline" v-model="formData.end_date"
                                            placeholder="End Date" dense solo readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="#069" ref="picker"
                                        :min="(new Date().getFullYear()).toString()" v-model="formData.end_date"
                                        @change="saveEndDateForCreation">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" class="remove-hint text-center">
                                <button @click="create_training_submit ? null : add_training()" type="button"
                                    class="btn btn-secondary m-1 ripple w-25">
                                    {{ create_training_text }}
                                    <v-icon :style="!create_training_submit
                                ? 'display: none;'
                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                ">
                                        fas fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                                <button @click="create_training = 2" type="button"
                                    class="btn btn-outline-secondary m-1">
                                    Cancel
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div v-else-if="create_training == 2" class="main-content"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                <div class="breadcrumb mb-0"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
                    style="margin-top: 1rem;">
                    <div class="row pb-0 my-2">
                        <div class="col-12 col-md-6">
                            <h1>All Trainings</h1>
                        </div>
                        <div class="col-12 col-md-6">
                            <v-row>
                                <v-col cols="6" md="3" offset-md="7" class="pt-0 text-center">
                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="dateRangeText" placeholder="Select dates" solo dense readonly
                                                v-bind="attrs" v-on="on"
                                                :messages="date_range.length == 0 ? '*Select date range' : date_range.length == 1 ? '*Please select an end date' : '*Showing results for ' + new Date(date_range[0]).toUTCString().substring(0, 16) + ' to ' + new Date(date_range[1]).toUTCString().substring(0, 16)">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker color="#069" range v-model="date_range" :min="new Date(new Date().getTime() - 3.156e+10)
                                .toISOString()
                                .substring(0, 10)
                                " :max="new Date(new Date().getTime() + 86400000000)
                                .toISOString()
                                .substring(0, 10)
                                "></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" md="2" class="pt-0 text-center">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button"
                                            data-toggle="dropdown" aria-expanded="false">
                                            Actions
                                        </button>
                                        <div class="dropdown-menu" x-placement="bottom-start"
                                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 34px, 0px);">
                                            <a v-if="(userType == 1/* || permissionIsAssigned('training-create')*/)"
                                                @click="tweak_display()" class="dropdown-item" href="#">
                                                {{
                                create_training == 1
                                    ? "View Trainings"
                                    : create_training == 2
                                        ? "Create Training"
                                        : "View Trainings"
                            }}
                                            </a>
                                            <a v-if="userType == 1" @click="print()" class="dropdown-item"
                                                href="#">Export to
                                                PDF</a>
                                            <a v-if="userType == 1" class="dropdown-item" href="#">
                                                <download-excel :data="json_data" :fields="json_fields"
                                                    worksheet="All Trainings" name="Trainings.xls">
                                                    Export to Excel
                                                </download-excel>
                                            </a>
                                            <a @click="reset_filter()" href="#" class="dropdown-item">Reset Filter</a>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                        </div>
                    </div>
                </div>
                <div class="main-content mt-0 row pr-0"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                    <div id="printMe" class="card w-100" style="border-radius: 10px;">
                        <div class="card-header px-3 d-print-block d-none">
                            <h4 v-if="excel_filtered_trainings.length >= 1">
                                Trainings
                            </h4>
                        </div>
                        <div class="card-body px-1 py-1 pt-0 pr-0">
                            <div class="table-responsive table-scroller">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-left" style="border-top: none;" scope="col">Provider</th>
                                            <th class="text-left" style="border-top: none;" scope="col">Training Title
                                            </th>
                                            <th class="text-left" style="border-top: none;" scope="col">Training
                                                Description</th>
                                            <th class="text-left" style="border-top: none;" scope="col">Training Option
                                            </th>
                                            <th class="text-left" style="border-top: none;" scope="col">Training Cost
                                            </th>
                                            <th class="text-left" style="border-top: none;" scope="col">Start Date</th>
                                            <th class="text-left" style="border-top: none;" scope="col">End Date</th>
                                            <th v-if="userType == 1/* || (permissionIsAssigned('training-update') || permissionIsAssigned('training-delete'))*/"
                                                class="text-center d-print-none" style="border-top: none;" scope="col">
                                                Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="training in filtered_trainings" :key="training.id">
                                            <td class="text-left">{{ training.provider_name }}</td>
                                            <td class="text-left">{{ training.training_title }}</td>
                                            <td class="text-left">{{ training.training_description }}</td>
                                            <td class="text-left">{{ training.training_option }}</td>
                                            <td class="text-left">₦{{ training.training_cost }}</td>
                                            <td class="text-left">
                                                {{ new Date(training.start_date).toUTCString().substring(0, 16) }}
                                            </td>
                                            <td class="text-left">
                                                {{ new Date(training.end_date).toUTCString().substring(0, 16) }}
                                            </td>
                                            <td v-if="userType == 1/* || (permissionIsAssigned('training-update') || permissionIsAssigned('training-delete'))*/"
                                                class="pt-1 d-print-none">
                                                <div class="ul-widget4__actions">
                                                    <button class="btn _r_btn border-0" type="button"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <span class="_dot _inline-dot bg-primary"></span>
                                                        <span class="_dot _inline-dot bg-primary"></span>
                                                        <span class="_dot _inline-dot bg-primary"></span>
                                                    </button>
                                                    <div class="dropdown-menu" x-placement="top-start"
                                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                                        <a class="dropdown-item" style="cursor: pointer;"
                                                            @click="(current_training = training, view_employees_dialog = true)">
                                                            <i class="fal fa-users"
                                                                style="color: inherit !important;"></i>
                                                            Employees
                                                        </a>
                                                        <a class="dropdown-item" style="cursor: pointer;"
                                                            @click="edit_training(training)">
                                                            <i class="fal fa-edit"
                                                                style="color: inherit !important;"></i> Edit
                                                        </a>
                                                        <a class="dropdown-item" style="cursor: pointer;"
                                                            @click="delete_training(training.id)">
                                                            <i class="fal fa-trash"></i> Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="main-content"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                <div class="breadcrumb mb-0" style="margin-top: 1rem;"
                    :style="$vuetify.breakpoint.name != 'xs' ? null : null">
                    <h1 class="my-2 row pb-0">
                        <span class="col-lg-5 col-md-5 col-12">Edit Training</span>
                    </h1>
                </div>
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Provider <i class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-select v-model="editFormData.provider_id" item-color="#069" item-text="provider_name"
                                    item-value="id" :items="authCompanyTrainingProviders"
                                    placeholder="Select the training provider" solo class="mt-2" dense
                                    color="#069"></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Title <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="editFormData.training_title"
                                    placeholder="Enter the training title" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <span style="color: #111d5e;">Training Description <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-textarea v-model="editFormData.training_description"
                                    placeholder="Enter the training description" solo class="mt-2">
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Option <i class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-select v-model="editFormData.training_option" item-color="#069"
                                    :items="['Internal', 'External']" placeholder="Select the training option" solo
                                    class="mt-2" dense color="#069"></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Training Cost (₦) <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="editFormData.training_cost" type="number"
                                    placeholder="Enter the training cost" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Start Date <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-menu ref="startDateMenuForEdit" v-model="startDateMenuForEdit"
                                    :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-1" color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline"
                                            v-model="editFormData.start_date" placeholder="Start Date" dense solo
                                            readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="#069" ref="picker"
                                        :min="(new Date().getFullYear()).toString()" v-model="editFormData.start_date"
                                        @change="saveStartDateForEdit">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">End Date <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-menu ref="endDateMenuForEdit" v-model="endDateMenuForEdit"
                                    :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-1" color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline"
                                            v-model="editFormData.end_date" placeholder="End Date" dense solo readonly
                                            v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="#069" ref="picker"
                                        :min="(new Date().getFullYear()).toString()" v-model="editFormData.end_date"
                                        @change="saveEndDateForEdit">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" class="remove-hint text-center">
                                <button @click="update_training_submit ? null : update_training()" type="button"
                                    class="btn btn-secondary m-1 ripple w-25">
                                    {{ update_training_text }}
                                    <v-icon :style="!update_training_submit
                                ? 'display: none;'
                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                ">
                                        fas fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                                <button @click="create_training = 2" type="button"
                                    class="btn btn-outline-secondary m-1">
                                    Cancel
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div class="flex-grow-1"></div>
        </div>
        <v-row justify="center">
            <v-dialog v-model="view_employees_dialog" max-width="600px">
                <v-card>
                    <v-card-title class="text-left d-block">
                        <span class="headline">Employees in training</span>
                        <button @click="(view_employees_dialog = false, add_employees_dialog = true)"
                            style="float: right;" type="button"
                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            Add Employee
                        </button>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="text-left">Employee</th>
                                        <th v-if="userType == 1/* || permissionIsAssigned('training-remove-employees')*/"
                                            class="text-left">Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(employee, index) in currentEmployeesInTraining"
                                        :key="'employee' + index">
                                        <td class="text-left">
                                            {{ employee.name }}
                                        </td>
                                        <td v-if="userType == 1/* || permissionIsAssigned('training-remove-employees')*/"
                                            class="text-left">
                                            <div class="row">
                                                <i @click="remove_employee(current_training.id, employee.id)"
                                                    class="col-sm-12 py-4 text-left fas fa-user-minus ml-2"
                                                    style="color: #069; !important; font-size: 125%; cursor: pointer;"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="view_employees_dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="add_employees_dialog" max-width="600px">
                <v-card>
                    <v-card-title class="text-left d-block">
                        <span class="headline">Add employees to training</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form @submit.prevent>
                                <div class="container-fluid">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" class="remove-hint">
                                            <v-select v-model="selectedEmployees" :items="allEmployees"
                                                :menu-props="{ maxHeight: '400' }" label="Select" multiple solo dense
                                                return-object item-text="name" hint="Select Employees"
                                                persistent-hint></v-select>
                                        </v-col>
                                        <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6" class="remove-hint">
                                            <button @click="add_employees_submit ? null : add_employees_to_training()"
                                                style="width: 99%" type="button" class="
                                                btn btn-secondary
                                                m-1
                                                text-white
                                                btn-raised
                                                ripple
                                                ">
                                                {{ add_employees_text }}
                                                <v-icon :style="!add_employees_submit
                                ? 'display: none;'
                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                ">
                                                    fal fa-circle-notch fa-spin
                                                </v-icon>
                                            </button>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="add_employees_dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import axios from "axios";
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    data() {
        return {
            overlay: false,
            add_employees_text: 'Submit',
            add_employees_submit: false,
            selectedEmployees: [],
            date_range: [],
            current_training: null,
            add_employees_dialog: false,
            view_employees_dialog: false,
            startDateMenuForCreation: false,
            endDateMenuForCreation: false,
            startDateMenuForEdit: false,
            endDateMenuForEdit: false,
            create_training_text: "Submit",
            create_training_submit: false,
            update_training_text: "Update",
            update_training_submit: false,
            create_training: 2,
            formData: {
                company_id: '',
                provider_id: '',
                training_title: '',
                training_description: '',
                training_option: '',
                training_cost: '',
                start_date: '',
                end_date: '',
            },
            editFormData: {
                id: '',
                company_id: '',
                provider_id: '',
                training_title: '',
                training_description: '',
                training_option: '',
                training_cost: '',
                start_date: '',
                end_date: '',
            },
        };
    },
    computed: {
        ...mapState([
            'weekDays',
            'userToken'
        ]),
        ...mapGetters([
            "validEmployeeId",
            "authCompanyData",
            "userType",
            "authCompanyEmployees",
            "authCompanyTrainingProviders",
            "authEmployeeTrainings",
            "authCompanyTrainings",
        ]),
        currentEmployeesInTraining() {
            const _this = this;
            let employees = [];
            if (_this.current_training) {
                employees = JSON.parse(_this.current_training.employees);
            }
            return employees;
        },
        allEmployees() {
            const _this = this;
            return _this.authCompanyEmployees.map(EMPLOYEE => {
                return {
                    name: `${EMPLOYEE.employee_fristname} ${EMPLOYEE.employee_lastname}`,
                    id: EMPLOYEE.id,
                    department_id: EMPLOYEE.department_id,
                }
            });
        },
        employeesToAdd() {
            let _this = this, result = [];
            result = _this.selectedEmployees.map(SE => {
                return {
                    company_id: _this.authCompanyData.id,
                    employee_id: SE.id,
                    training_id: _this.current_training.id,
                    department_id: SE.department_id
                }
            })
            return result;
        },
        assets_url() {
            return ASSETS_URL;
        },
        dateRangeText() {
            if (this.date_range) {
                return this.date_range.join(' ~ ')
            }
            return null
        },
        json_data: function () {
            return this.excel_filtered_trainings;
        },
        json_fields: function () {
            return {
                "TRAINING PROVIDER": "provider",
                "TRAINING TITLE": "title",
                "TRAINING DESCRIPTION": "description",
                "TRAINING OPTION": "option",
                "TRAINING COST (₦)": "cost",
                "START DATE": "start_date",
                "END DATE": "end_date",
            }
        },
        filtered_trainings() {
            let _this = this, trainings = [];
            if (_this.userType == 1) {
                trainings = _this.authCompanyTrainings;
            } else {
                trainings = _this.authEmployeeTrainings;
            }

            if (_this.date_range.length == 2) {
                trainings = trainings.filter(training => {
                    return (new Date(training.start_date).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(training.start_date).getTime() <= new Date(_this.date_range[1]).getTime())
                });
            }

            return trainings;
        },
        excel_filtered_trainings() {
            let _this = this;
            return _this.filtered_trainings.map(training => {
                return {
                    provider: training.provider_id,
                    title: training.training_title,
                    description: training.training_description,
                    option: training.training_option,
                    cost: training.training_cost,
                    start_date: training.start_date,
                    end_date: training.end_date,
                }
            })
        },
    },
    methods: {
        ...mapActions([
            "fetch_employee_trainings",
            "fetch_company_trainings",
            "fetch_company_training_providers"
        ]),
        async print() {
            // Pass the element id here
            await this.$htmlToPaper('printMe');
        },
        getTrainingAssignmentId(training_id, employee_id) {
            let _this = this, value = 0, object = _this.authEmployeeTrainings.filter(ed => {
                return ed.training_id == training_id && ed.employee_id == employee_id
            });
            if (object.length) {
                value = object[0].id
            }
            return value;
        },
        hasHistory: function () {
            return window.history.length > 2;
        },
        reset_filter() {
            this.date_range = [];
        },
        saveStartDateForCreation: function (t) {
            this.$refs.startDateMenuForCreation.save(t);
        },
        saveEndDateForCreation: function (t) {
            this.$refs.endDateMenuForCreation.save(t);
        },
        saveStartDateForEdit: function (t) {
            this.$refs.startDateMenuForEdit.save(t);
        },
        saveEndDateForEdit: function (t) {
            this.$refs.endDateMenuForEdit.save(t);
        },
        add_employees_to_training() {
            let _this = this;
            _this.add_employees_text = "";
            _this.add_employees_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}addemployeestotraining`, {
                    training_id: _this.current_training.id,
                    company_id: _this.authCompanyData.id,
                    employeecollections: _this.employeesToAdd,
                }, {
                    headers: {
                        Authorization: `Bearer ${_this.userToken}`
                    }
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Employees added successfully"
                    });
                    _this.fetch_company_trainings();
                    _this.fetch_employee_trainings();
                    _this.add_employees_dialog = false;
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: 'Employees couldn\'t be added'
                    });
                })
                .then(function () {
                    _this.add_employees_submit = false;
                    _this.add_employees_text = "Submit";
                });
        },
        remove_employee(training_id, employee_id) {
            let _this = this, idtoremove = _this.getTrainingAssignmentId(training_id, employee_id);

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            _this.$swal
                .fire({
                    title:
                        "Are you sure you want to remove this employee?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`
                })
                .then(result => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}removefromtraining `, {
                                idtoremove
                            }, {
                                headers: {
                                    Authorization: `Bearer ${_this.userToken}`
                                }
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Employee removed successfully"
                                });
                                _this.fetch_employee_trainings();
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Could not remove the employee"
                                });
                                _this.fetch_company_trainings();
                                _this.fetch_employee_trainings();
                                console.log(error);
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        tweak_display() {
            if (this.create_training == 1) {
                this.create_training = 2;
            } else if (this.create_training == 2) {
                this.create_training = 1;
            } else {
                ("");
            }
        },
        edit_training(training) {
            let _this = this;
            _this.current_training = training;
            _this.create_training = 3;
            _this.editFormData.id = training.id;
            _this.editFormData.company_id = training.company_id;
            _this.editFormData.provider_id = training.provider_id;
            _this.editFormData.training_title = training.training_title;
            _this.editFormData.training_description = training.training_description;
            _this.editFormData.training_option = training.training_option;
            _this.editFormData.training_cost = training.training_cost;
            _this.editFormData.start_date = training.start_date;
            _this.editFormData.end_date = training.end_date;
        },
        delete_training(training_id) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                company_id: _this.authCompanyData.id,
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            _this.$swal
                .fire({
                    title:
                        "Are you sure you want to delete this training?",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6 !important",
                    confirmButtonText: `Yes, Delete`
                })
                .then(result => {
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deletetraining`, {
                                idtoremove: training_id,
                                company_id: _this.authCompanyData.id
                            }, {
                                headers: {
                                    Authorization: `Bearer ${_this.userToken}`
                                }
                            })
                            .then(function () {
                                _this.fetch_company_trainings();
                                _this.fetch_employee_trainings();
                                Toast.fire({
                                    icon: "success",
                                    title: "Training deleted successfully"
                                });
                            })
                            .catch(function () {
                                Toast.fire({
                                    icon: "error",
                                    title: "The training couldn't be deleted"
                                });
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        update_training() {
            let _this = this;
            _this.update_training_text = "";
            _this.update_training_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}updatetraining`, {
                    training_id: _this.editFormData.id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    case: _this.editFormData.case,
                    date: _this.editFormData.date,
                    court: _this.editFormData.court,
                    position: _this.editFormData.position
                }, {
                    headers: {
                        Authorization: `Bearer ${_this.userToken}`
                    }
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Training updated successfully"
                    });
                    _this.create_training = 2;
                    _this.fetch_employee_trainings();
                    _this.fetch_company_trainings();
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === 'string' ? errors : null
                            || errors.provider_id ? errors.provider_id[0] : null
                                || errors.training_title ? errors.training_title[0] : null
                                    || errors.training_description ? errors.training_description[0] : null
                                        || errors.training_option ? errors.training_option[0] : null
                                            || errors.training_cost ? errors.training_cost[0] : null
                                                || errors.start_date ? errors.start_date[0] : null
                                                    || errors.end_date ? errors.end_date[0] : null) : null
                                                    || error.response.data.error || error.response.data.message || 'Training could not be updated'
                    });
                })
                .then(function () {
                    _this.update_training_text = "Update";
                    _this.update_training_submit = false;
                });
        },
        add_training() {
            let _this = this;
            _this.create_training_text = "";
            _this.create_training_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}storetraining`, {
                    company_id: _this.authCompanyData.id,
                    provider_id: _this.formData.provider_id,
                    training_title: _this.formData.training_title,
                    training_description: _this.formData.training_description,
                    training_option: _this.formData.training_option,
                    training_cost: _this.formData.training_cost,
                    start_date: _this.formData.start_date,
                    end_date: _this.formData.end_date,
                }, {
                    headers: {
                        Authorization: `Bearer ${_this.userToken}`
                    }
                })
                .then(function () {
                    _this.authCompanyData.id = '';
                    _this.formData.provider_id = '';
                    _this.formData.training_title = '';
                    _this.formData.training_description = '';
                    _this.formData.training_option = '';
                    _this.formData.training_cost = '';
                    _this.formData.start_date = '';
                    _this.formData.end_date = '';
                    Toast.fire({
                        icon: "success",
                        title: "Training added successfully"
                    });
                    _this.create_training = 2;
                    _this.fetch_employee_trainings();
                    _this.fetch_company_trainings();
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === 'string' ? errors : null
                            || errors.provider_id ? errors.provider_id[0] : null
                                || errors.training_title ? errors.training_title[0] : null
                                    || errors.training_description ? errors.training_description[0] : null
                                        || errors.training_option ? errors.training_option[0] : null
                                            || errors.training_cost ? errors.training_cost[0] : null
                                                || errors.start_date ? errors.start_date[0] : null
                                                    || errors.end_date ? errors.end_date[0] : null) : null
                                                    || error.response.data.error || error.response.data.message || 'Training could not be created'
                    });
                })
                .then(function () {
                    _this.create_training_text = "Submit";
                    _this.create_training_submit = false;
                });
        }
    },
    async mounted() {
        document.title = "Kylian ERP - Training";
        let _this = this;
        if (!_this.authCompanyTrainings.length || !_this.authEmployeeTrainings) {
            _this.overlay = true;
        }
        await _this.fetch_company_trainings();
        await _this.fetch_employee_trainings();
        _this.overlay = false;
        await _this.fetch_company_training_providers();
    }
};
</script>

<style>
.ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
}

.editor-opener {
    border-radius: 5px;
    box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.ql-container.ql-snow {
    border: none !important;
}

.remove-hint div div div.v-text-field__details {
    display: none !important;
}

.educationField .v-text-field__details {
    display: none !important;
}

.educationField .v-input__slot {
    margin: 0;
}

.supportingDocs div div div .v-file-input__text {
    cursor: pointer;
}
</style>