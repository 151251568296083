<template>
  <FlexiblePayroll v-if="authCompanyData.company_payroll == 1" />
  <TaxPayroll v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FlexiblePayroll from './FlexiblePayroll';
import TaxPayroll from './TaxPayroll';
export default {
  components: {
    FlexiblePayroll,
    TaxPayroll
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      'authUserData',
      'authCompanyData'
    ])
  },
  methods: {
    ...mapActions([
      'refresh_company_data'
    ])
  },
  mounted() {
    const _this = this;
    _this.refresh_company_data({
      user_id: _this.authUserData.id,
      company_id: _this.authCompanyData.id
    });
  }
}
</script>

<style>

</style>