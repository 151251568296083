<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4 mt-3" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">
          <div v-if="userType == 1 || (userType != 1 && permissionIsAssigned('recruitment-create'))"
            class="dropdown animate__animated animate__fadeIn">
            <button v-if="create_jobs == 1 || (create_jobs == 2 && permissionIsAssigned('recruitment-create'))"
              @click="tweak_display()" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              {{
                create_jobs == 1
                ? "View Job Openings"
                : create_jobs == 2
                  ? "Create Job Opening"
                  : "View Job Openings"
              }}
            </button>
            <template v-else>
              <button v-if="permissionIsAssigned('recruitment-create')" @click="create_jobs = 2" style="float: right;"
                type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                {{ "View Job Openings" }}
              </button>
            </template>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div v-if="create_jobs == 1" class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb"
          :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
          style="margin-top: 1rem;">
          <h1>
            <span class="col-4">Create a Job Opening</span>
          </h1>
        </div>
        <v-form ref='form1' @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Title <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field class="mt-1" :rules="[
                  v => !!v || 'Job title is required'
                ]" v-model="formData.job_title" placeholder="Job Title" solo dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Qualifications <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Job Qualifications" v-model="formData.qualifications"
                  :items="qualifications_list" :menu-props="{ maxHeight: '400' }" label="Select" multiple
                  item-color="#069" :rules="[
                    v => !!v || 'Job qualification is required'
                  ]" solo dense color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Location <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Eg. Lagos" v-model="formData.location" :items="local_states"
                  :menu-props="{ maxHeight: '400' }" :rules="[
                    v => !!v || 'Job location is required'
                  ]" label="Select" item-color="#069" solo dense color="#069">
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Employment Type <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Employment Type" v-model="formData.employment_type"
                  :items="['Full-Time', 'Part-Time', 'Contract', 'Consultancy', 'Internship']"
                  :menu-props="{ maxHeight: '400' }" :rules="[
                    v => !!v || 'Employment type is required'
                  ]" label="Select" item-color="#069" solo dense color="#069">
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Work Mode <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Work Mode" v-model="formData.work_mode"
                  :items="['On site', 'Remote', 'Hybrid']" :rules="[
                    v => !!v || 'Work mode is required'
                  ]" :menu-props="{ maxHeight: '400' }" label="Select" item-color="#069" solo dense
                  color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <span style="color: #111d5e;">Job Requirements <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <div class="editor-opener">
                  <div id="editor">
                    <ol>
                      <li>Requirement 1</li>
                      <li>...</li>
                    </ol>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <span style="color: #111d5e;">Job Description <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <div class="editor-opener">
                  <div id="editor-description">
                    <p>Description...</p>
                  </div>
                </div>
                <!-- <v-textarea class="mt-1" placeholder="Job Description" v-model="formData.description" solo dense>
                </v-textarea> -->
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <span style="color: #111d5e;">Job Benefits <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-textarea class="mt-1" :items="['On site', 'Remote', 'Hybrid']" :rules="[
                  v => !!v || 'Job benefits is required'
                ]" placeholder="Ex: N500,000/month" v-model="formData.benefits" solo dense>
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Opening Start Date <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="formData.start_date" :items="['On site', 'Remote', 'Hybrid']" :rules="[
                        v => !!v || 'Date is required'
                      ]" placeholder="Job Opening Start Date" dense solo readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                    :max="(new Date().getFullYear() + 1).toString()" v-model="formData.start_date" @change="save">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Opening End Date <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="formData.end_date" :items="['On site', 'Remote', 'Hybrid']" :rules="[
                        v => !!v || 'Date is required'
                      ]" placeholder="Job Opening End Date" dense solo readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                    :max="(new Date().getFullYear() + 1).toString()" v-model="formData.end_date" @change="save2">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="remove-hint text-center">
                <button @click="create_jobs_submit ? null : add_job()" type="button"
                  class="btn btn-secondary m-1 ripple w-25">
                  {{ create_jobs_text }}
                  <v-icon :style="!create_jobs_submit
                    ? 'display: none;'
                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                    ">
                    fas fa-circle-notch fa-spin
                  </v-icon>
                </button>
                <button @click="create_jobs = 2" type="button" class="btn btn-outline-secondary m-1">
                  Cancel
                </button>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div v-else-if="create_jobs == 2" class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb"
          :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
          style="margin-top: 1rem;">
          <h1>
            <span class="col-4">Company Job Openings</span>
          </h1>
        </div>
        <div class="main-content mt-0 row pr-0"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
          <div class="card w-100" style="border-radius: 10px;">
            <div class="card-body px-1 py-1 pt-0 pr-0">
              <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-left" style="border-top: none;" scope="col">Job Title</th>
                      <!-- <th class="text-left" style="border-top: none;" scope="col">Description</th> -->
                      <th class="text-left" style="border-top: none;" scope="col">Location</th>
                      <th class="text-left" style="border-top: none;" scope="col">Type</th>
                      <th class="text-left" style="border-top: none;" scope="col">Benefits</th>
                      <th class="text-left" style="border-top: none;" scope="col">Qualifications</th>
                      <th class="text-left" style="border-top: none;" scope="col">Job Opening Start Date</th>
                      <th class="text-left" style="border-top: none;" scope="col">Job Opening End Date</th>
                      <th class="text-left" style="border-top: none;" scope="col">Job Link</th>
                      <th class="text-left" style="border-top: none;" scope="col">Supporting Documents</th>
                      <th v-if="permissionIsAssigned('applicants-read')" class="text-center"
                        style="border-top: none; width: 12%;" scope="col">Applicants</th>
                      <th class="text-center" style="border-top: none;" scope="col">Status</th>
                      <th v-if="permissionIsAssigned('recruitment-update') || permissionIsAssigned('recruitment-delete')"
                        class="text-left" style="border-top: none;" scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="job in auth_created_jobs" :key="job.id">
                      <td class="text-left">{{ job.job_title }}</td>
                      <!-- <td class="text-left">{{ job.description }}</td> -->
                      <td class="text-left">{{ job.location }}</td>
                      <td class="text-left">{{ job.employment_type }}</td>
                      <td class="text-left">{{ job.benefits }}</td>
                      <td class="text-left">{{ job.qualifications }}</td>
                      <td class="text-left">{{ job.start_date }}</td>
                      <td class="text-left">{{ job.end_date }}</td>
                      <td class="text-left">
                        <button @click="getLink(job)" type="button" class="btn btn-secondary btn-sm ripple w-100">
                          Get Link
                        </button>
                      </td>
                      <td class="text-left">
                        <button @click="(current_job_id = job.id, supporting_docs_dialog = true)" type="button"
                          class="btn btn-secondary btn-sm ripple w-100">
                          Manage
                        </button>
                      </td>
                      <td v-if="permissionIsAssigned('applicants-read')" class="text-center">
                        <span @click="$router.push({ name: 'Applicants', params: { id: job.id } })"
                          style="cursor: pointer; color: #069;">
                          View Applicants
                        </span>
                      </td>
                      <td class="text-center" v-if="job.status == 1">
                        <span style="width: 60px;" class="badge badge-success text-white">ACTIVE</span>
                      </td>
                      <td v-else>
                        <span style="width: 60px;" class="badge badge-danger text-white">INACTIVE</span>
                      </td>
                      <td v-if="permissionIsAssigned('recruitment-update') || permissionIsAssigned('recruitment-delete')"
                        class="text-left">
                        <div class="row" style="padding-top: 13px;">
                          <i v-if="permissionIsAssigned('recruitment-update')" class="col-3 py-0 text-center fal fa-edit"
                            @click="edit_job(job)" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                          <i v-if="permissionIsAssigned('recruitment-delete')" class="col-3 py-0 text-center fal fa-trash"
                            @click="delete_job(job)"
                            style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;" :style="$vuetify.breakpoint.name != 'xs' ? null : null">
          <h1>
            <span>Edit Job Opening</span>
          </h1>
        </div>
        <v-form ref='form2' @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Title <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field class="mt-1" :rules="[
                  v => !!v || 'Job title is required'
                ]" v-model="editFormData.job_title" placeholder="Job Title" solo></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Qualifications <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Job Qualifications" v-model="editFormData.qualifications"
                  :items="qualifications_list" :menu-props="{ maxHeight: '400' }" label="Select" multiple
                  item-color="#069" :rules="[
                    v => !!v || 'Job qualification is required'
                  ]" solo dense color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Location <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Eg. Lagos" v-model="editFormData.location" :items="local_states"
                  :menu-props="{ maxHeight: '400' }" :rules="[
                    v => !!v || 'Job location is required'
                  ]" label="Select" item-color="#069" solo dense color="#069">
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Employment Type <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Employment Type" v-model="editFormData.employment_type"
                  :items="['Full-Time', 'Part-Time', 'Contract']" :menu-props="{ maxHeight: '400' }" label="Select"
                  item-color="#069" :rules="[
                    v => !!v || 'Employment type is required'
                  ]" solo dense color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Work Mode <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select class="mt-1" placeholder="Work Mode" v-model="editFormData.work_mode"
                  :items="['On site', 'Remote', 'Hybrid']" :rules="[
                    v => !!v || 'Work mode is required'
                  ]" :menu-props="{ maxHeight: '400' }" label="Select" item-color="#069" solo dense
                  color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <span style="color: #111d5e;">Job Requirements <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <div class="editor-opener">
                  <div :id="'editor' + editFormData.job_id" class="edit-requirement">
                    <ol>
                      <li>Requirement 1</li>
                      <li>...</li>
                    </ol>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <span style="color: #111d5e;">Job Description <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <div class="editor-opener">
                  <div :id="'editor-description' + editFormData.job_id">
                    <p>Description...</p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <span style="color: #111d5e;">Job Benefits <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-textarea class="mt-1" :items="['On site', 'Remote', 'Hybrid']" :rules="[
                  v => !!v || 'Job benefits is required'
                ]" placeholder="Ex: N500,000/month" v-model="editFormData.benefits" solo>
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Opening Start Date <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="editFormData.start_date" :items="['On site', 'Remote', 'Hybrid']" :rules="[
                        v => !!v || 'Date is required'
                      ]" placeholder="Job Opening Start Date" dense solo readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                    :max="(new Date().getFullYear() + 1).toString()" v-model="editFormData.start_date" @change="save">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <span style="color: #111d5e;">Job Opening End Date <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="editFormData.end_date" :items="['On site', 'Remote', 'Hybrid']" :rules="[
                        v => !!v || 'Date is required'
                      ]" placeholder="Job Opening End Date" dense solo readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                    :max="(new Date().getFullYear() + 1).toString()" v-model="editFormData.end_date" @change="save2">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="remove-hint text-center">
                <button @click="update_job_submit ? null : update_job()" type="button"
                  class="btn btn-secondary m-1 ripple w-25">
                  {{ update_job_text }}
                  <v-icon :style="!update_job_submit
                    ? 'display: none;'
                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                    ">
                    fas fa-circle-notch fa-spin
                  </v-icon>
                </button>
                <button @click="create_jobs = 2" type="button" class="btn btn-outline-secondary m-1">
                  Cancel
                </button>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="flex-grow-1"></div>
      <input v-for="job in auth_created_jobs" :id="'job-' + job.id" :key="job.id" type="text"
        :value="`https://jobs.kylianerp.com/${authCompanyData.subdomain}/${job.id}`"
        style="position: fixed; opacity: 0;" />
    </div>
    <v-row justify="center">
      <v-dialog v-model="job_link_dialog" max-width="600px">
        <v-card>
          <v-card-title class="text-center d-block">
            <span class="headline">Link has been copied to clipboard</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <a :href="job_link" target="_blank">
                <h3>{{ job_link }}</h3>
              </a>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="job_link_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="supporting_docs_dialog" max-width="600px">
        <v-card>
          <v-card-title class="text-left d-block">
            <span class="headline">Supporting Document</span>
            <button @click="add_supporting_docs_dialog = true" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              Add Document
            </button>
          </v-card-title>
          <v-card-text>
            <v-container>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="text-left">Document</th>
                    <th class="text-left">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="doc in currentJobDocuments" :key="doc.id">
                    <td class="text-left">
                      <a :href="assets_url + doc.document" target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td class="text-left">
                      <div class="row">
                        <i @click="delete_document(doc)" class="col-sm-12 py-4 text-left fal fa-trash ml-2"
                          style="color: #069; !important; font-size: 125%; cursor: pointer;"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="supporting_docs_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="add_supporting_docs_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Supporting Document</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref='form3' @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col style="cursor: pointer;" cols="12" sm="12" md="12">
                      <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                        label="Upload Supporting Document" solo style="text-indent: 5px;" color="#069"
                        class="supportingDocs" messages="Supporting Document" :items="['On site', 'Remote', 'Hybrid']"
                        :rules="[
                          v => !!v || 'Document is required'
                        ]">
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3">
                      <button @click="supportingDocsBtnSubmit ? null : upload_supporting_docs()" style="width: 100%;"
                        type="button" class="btn btn-secondary m-1 ripple">
                        {{ supportingDocsBtnText }}
                        <v-icon :style="!supportingDocsBtnSubmit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="add_supporting_docs_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      supportingDocsBtnSubmit: false,
      supportingDocsBtnText: 'Upload',
      current_job_id: null,
      supporting_docs_dialog: false,
      add_supporting_docs_dialog: false,
      job_link_dialog: false,
      job_link: '',
      menu: false,
      menu2: false,
      qualifications_list: [
        'Ph.D',
        'M.Sc',
        'B.Sc',
        'HND',
        'OND',
        'O-Level',
        'First School Leaving Certificate',
      ],
      vendor_names: [],
      dialog: false,
      create_jobs_text: "Submit",
      create_jobs_submit: false,
      update_job_text: "Update",
      update_job_submit: false,
      create_jobs: 2,
      formData: {
        job_title: "",
        location: "",
        employment_type: "",
        description: "",
        benefits: "",
        qualifications: "",
        requirements: "",
        work_mode: "",
        start_date: "",
        end_date: ""
      },
      editFormData: {
        job_id: "",
        job_title: "",
        location: "",
        employment_type: "",
        description: "",
        benefits: "",
        qualifications: "",
        requirements: "",
        work_mode: "",
        start_date: "",
        end_date: ""
      },
      allEmployees: []
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "authUserData",
      "authCompanyEmployees",
      "userType",
      "authCompanyAllVendors",
      "auth_created_jobs",
      "auth_states",
      "authCompanyJobDocuments"
    ]),
    assets_url() {
      return ASSETS_URL;
    },
    currentJobDocuments() {
      let _this = this, result = [];
      result = _this.authCompanyJobDocuments.filter(doc => {
        return doc.jobcategory_id == _this.current_job_id
      });
      return result;
    },
    local_states() {
      let _this = this, local_states = [];
      if (_this.auth_states) {
        local_states = _this.auth_states.filter(s => {
          return s.countryID == 'NGA'
        });
        local_states = local_states.map(s => {
          return s.stateName + ', Nigeria'
        });
      }
      return local_states;
    },
    qualifications_commas() {
      let _this = this;
      if (_this.formData.qualifications) {
        let string = _this.formData.qualifications.join(', ');
        return string;
      }
      return ''
    },
    qualifications_commas_2() {
      let _this = this;
      if (_this.editFormData.qualifications) {
        let string = _this.editFormData.qualifications.join(', ');
        return string;
      }
      return ''
    },
    edit_job_type_id() {
      let _this = this;
      if (_this.editFormData.type_name) {
        return _this.authCompanyAllAssetType.filter(type => {
          return _this.editFormData.type_name == type.assettype_name;
        })[0].id;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions([
      "fetch_all_company_jobs",
      "fetch_company_job_documents"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    delete_document(job) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to delete this document?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}deletecompanysupportdocs `, {
                ids: [
                  job.id
                ]
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Document deleted successfully"
                });
                _this.fetch_all_company_jobs();
                _this.fetch_company_job_documents();
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: "Could not delete the document"
                });
                _this.fetch_all_company_jobs();
                _this.fetch_company_job_documents();
                console.log(error);
              })
              .then(function () {
                //
              });
          }
        });
    },
    async upload_supporting_docs() {
      let _this = this;
      const valid = await _this.$refs.form3.validate();
      if (!valid) {
        return;
      }
      _this.supportingDocsBtnText = "";
      _this.supportingDocsBtnSubmit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let formData = new FormData();
      formData.append(
        "jobcategory_id",
        _this.current_job_id
      );
      formData.append(
        "company_id",
        _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id
      );

      // let documents = document.querySelector(".supportingDocs div div div input").files;

      formData.append(
        "document",
        document.querySelector(".supportingDocs div div div input").files[0]
      );

      // for (let index = 0; index < documents.length; index++) {
      //   formData.append("document", documents[index]);
      // }

      axios
        .post(`${BASE_URL}companysupportdocs`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Recruitment attachment uploaded successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_all_company_jobs();
          _this.fetch_company_job_documents();
          _this.add_supporting_docs_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.document ? errors.document[0] : null) : null
              || error.response.data.error || error.response.data.message || 'Recruitment attachment could not be uploaded'
          });
        })
        .then(function () {
          _this.supportingDocsBtnSubmit = false;
          _this.supportingDocsBtnText = "Upload";
        });
    },
    getLink(job) {
      /* Get the text field */
      let copyText = document.getElementById("job-" + job.id);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      /* Alert the copied text */
      this.job_link = copyText.value;
      this.job_link_dialog = true;
      // alert("Copied the text: " + copyText.value);
    },
    // viewApplicants(job) {
    //   this.create_jobs = 4;
    //   console.log(job);
    // },
    tweak_display() {
      if (this.create_jobs == 1) {
        this.create_jobs = 2;
      } else if (this.create_jobs == 2) {
        this.create_jobs = 1;
        setTimeout(() => {
          new window.Quill('#editor', {
            modules: {
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline'],
                ['image', 'code-block'],
                [{ list: 'ordered' }]
              ]
            },
            placeholder: 'Compose the job requirements...',
            theme: 'snow'  // or 'bubble'
          });
          new window.Quill('#editor-description', {
            modules: {
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline'],
                ['image', 'code-block'],
                [{ list: 'ordered' }]
              ]
            },
            placeholder: 'Compose the job description...',
            theme: 'snow'  // or 'bubble'
          });
        }, 100);
      } else {
        ("");
      }
    },
    edit_job(job) {
      let _this = this;
      _this.create_jobs = 3;
      _this.editFormData.job_id = job.id;
      _this.editFormData.job_title = job.job_title;
      // _this.editFormData.description = job.description;
      _this.editFormData.benefits = job.benefits;
      _this.editFormData.qualifications = job.qualifications.split(', ');
      _this.editFormData.start_date = job.start_date;
      _this.editFormData.end_date = job.end_date;
      _this.editFormData.location = job.location;
      _this.editFormData.employment_type = job.employment_type;
      _this.editFormData.work_mode = job.work_mode;
      setTimeout(() => {
        new window.Quill('#editor' + job.id, {
          modules: {
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline'],
              ['image', 'code-block'],
              [{ list: 'ordered' }]
            ]
          },
          placeholder: 'Compose the job requirements...',
          theme: 'snow'  // or 'bubble'
        });
        document.querySelector('#editor' + job.id + ' div').innerHTML = job.requirements;

        new window.Quill('#editor-description' + job.id, {
          modules: {
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline'],
              ['image', 'code-block'],
              [{ list: 'ordered' }]
            ]
          },
          placeholder: 'Compose the job description...',
          theme: 'snow'  // or 'bubble'
        });
        document.querySelector('#editor-description' + job.id + ' div').innerHTML = job.description;
      }, 100);
    },
    delete_job(job) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        company_id: _this.authCompanyData.id,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to delete this job opening?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}deletecreatejob`, {
                id: job.id
              })
              .then(function () {

                _this.fetch_all_company_jobs();
                Toast.fire({
                  icon: "success",
                  title: "Job opening deleted successfully"
                });
              })
              .catch(function () {
                Toast.fire({
                  icon: "error",
                  title: "The job opening couldn't be deleted"
                });
              })
              .then(function () {
                //
              });
          }
        });
    },
    async update_job() {
      let _this = this;
      const valid = await _this.$refs.form2.validate();
      if (!valid) {
        return;
      }
      _this.update_job_text = "";
      _this.update_job_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}updatecreatejob`, {
          company_id: _this.authCompanyData.id,
          id: _this.editFormData.job_id,
          job_title: _this.editFormData.job_title,
          description: document.querySelector('#editor-description' + _this.editFormData.job_id + ' div').innerHTML,
          benefits: _this.editFormData.benefits,
          qualifications: _this.qualifications_commas_2,
          requirements: document.querySelector('#editor' + _this.editFormData.job_id + ' div').innerHTML,
          start_date: _this.editFormData.start_date,
          end_date: _this.editFormData.end_date,
          location: _this.editFormData.location,
          work_mode: _this.editFormData.work_mode,
          employment_type: _this.editFormData.employment_type
        })
        .then(function () {
          _this.editFormData.job_id = "";
          _this.editFormData.job_title = "";
          _this.editFormData.description = "";
          _this.editFormData.qualifications = "";
          _this.editFormData.acquisition_date = "";
          _this.editFormData.start_date = "";
          _this.editFormData.end_date = "";
          _this.editFormData.location = "";
          _this.editFormData.work_mode = "";
          _this.editFormData.employment_type = "";

          _this.fetch_all_company_jobs();

          Toast.fire({
            icon: "success",
            title: "Job Opening Updated"
          });
          _this.create_jobs = 2;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.job_title ? errors.job_title[0] : null
                || errors.benefits ? errors.benefits[0] : null
                  || errors.description ? errors.description[0] : null
                    || errors.qualifications ? errors.qualifications[0] : null
                      || errors.requirements ? errors.requirements[0] : null
                        || errors.start_date ? errors.start_date[0] : null
                          || errors.end_date ? errors.end_date[0] : null
                            || errors.location ? errors.location[0] : null
                              || errors.work_mode ? errors.work_mode[0] : null
                                || errors.employment_type ? errors.employment_type[0] : null) : null
                                || error.response.data.error || error.response.data.message || 'Job opening could not be updated'
          });
        })
        .then(function () {
          _this.update_job_submit = false;
          _this.update_job_text = "Update";
        });
    },
    async add_job() {
      let _this = this;
      const valid = await _this.$refs.form1.validate();
      if (!valid) {
        return;
      }
      _this.create_jobs_text = "";
      _this.create_jobs_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}createjob`, {
          company_id: _this.authCompanyData.id,
          job_title: _this.formData.job_title,
          description: document.querySelectorAll('.ql-editor')[1].innerHTML,
          benefits: _this.formData.benefits,
          qualifications: _this.qualifications_commas,
          requirements: document.querySelectorAll('.ql-editor')[0].innerHTML,
          start_date: _this.formData.start_date,
          end_date: _this.formData.end_date,
          location: _this.formData.location,
          work_mode: _this.formData.work_mode,
          employment_type: _this.formData.employment_type
        })
        .then(function () {
          _this.formData.job_title = "";
          _this.formData.description = "";
          _this.formData.benefits = "";
          document.querySelectorAll('.ql-editor')[0].innerHTML = null;
          document.querySelectorAll('.ql-editor')[1].innerHTML = null;
          _this.formData.start_date = "";
          _this.formData.end_date = "";
          _this.formData.location = "";
          _this.formData.work_mode = "";
          _this.formData.employment_type = "";

          _this.fetch_all_company_jobs();

          Toast.fire({
            icon: "success",
            title: "Job Opening Created"
          });
          _this.create_jobs = 2;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.job_title ? errors.job_title[0] : null
                || errors.benefits ? errors.benefits[0] : null
                  || errors.description ? errors.description[0] : null
                    || errors.qualifications ? errors.qualifications[0] : null
                      || errors.requirements ? errors.requirements[0] : null
                        || errors.start_date ? errors.start_date[0] : null
                          || errors.end_date ? errors.end_date[0] : null
                            || errors.location ? errors.location[0] : null
                              || errors.work_mode ? errors.work_mode[0] : null
                                || errors.employment_type ? errors.employment_type[0] : null) : null
                                || error.response.data.error || error.response.data.message || 'Job opening could not be created'
          });
        })
        .then(function () {
          _this.create_jobs_submit = false;
          _this.create_jobs_text = "Submit";
        });
    }
  },
  mounted() {
    // let quill_js = document.createElement('script');
    // quill_js.setAttribute('src', 'https://cdn.quilljs.com/1.3.6/quill.js');
    // document.body.appendChild(quill_js);
    document.title = "Kylian ERP - Recruitment";
    let _this = this;
    _this.fetch_all_company_jobs();
    _this.fetch_company_job_documents();
    if (_this.authCompanyAllVendors) {
      _this.authCompanyAllVendors.forEach(vendor => {
        _this.vendor_names.push(
          vendor.vendor_name + " (" + vendor.vendor_company + ")"
        );
      });
    }
    if (_this.userType) {
      _this.authCompanyEmployees.forEach(person => {
        _this.allEmployees.push(
          person.employee_fristname + " " + person.employee_lastname
        );
      });
    } else {
      //
    }
  }
};
</script>

<style>
.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
}

.editor-opener {
  border-radius: 5px;
  box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}

.supportingDocs div div div .v-file-input__text {
  cursor: pointer;
}
</style>
