<template>
    <div class="card-body row py-4">
        <div class="col-sm-4" style="align-self: center;">
            <h4>Skill Sets</h4>
        </div>
        <div class="col-sm-8">
            <v-row>
                <v-col md="3"></v-col>
                <v-col md="3">
                    <v-select
                        v-model="filters.departmentName"
                        :items="availableDepartmentsNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select department"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-select
                        v-model="filters.designationName"
                        :items="availableDesignationNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select designation"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-text-field
                    v-model="filters.searchText" 
                    placeholder="Search"
                    messages="Search by employee name" 
                    class="search-employees"
                    solo 
                    dense
                    ></v-text-field>
                </v-col>
            </v-row>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Employee
                                    </th>
                                    <th class="text-left" scope="col">
                                        Department
                                    </th>
                                    <th class="text-left" scope="col">
                                        Designation
                                    </th>
                                    <th class="text-left" scope="col">
                                        Skill Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Skill Description
                                    </th>
                                    <th class="text-left" scope="col">
                                        Skill Level (%)
                                    </th>
                                    <th class="text-left" scope="col">
                                        Supporting Document
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-left" scope="col">
                                        Approval
                                    </th>
                                    <th class='text-enter' scope="col">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr 
                                v-for='(reportee, index) in filteredReporteesSkillsets'
                                :key='index'
                                >
                                    <td class="text-left">{{ reportee.employeeName }}</td>
                                    <td class="text-left">{{ reportee.department }}</td>
                                    <td class="text-left">{{ reportee.designation }}</td>
                                    <td class="text-left">{{ reportee.skill_name }}</td>
                                    <td v-if="reportee.description" class="text-left">{{ reportee.description }}</td>
                                    <td v-else class="text-left"><i>No description</i></td>
                                    <td class="text-left">{{ reportee.skill_level + '%' }}</td>
                                    <td v-if="reportee.support_document" class="text-left">
                                        <a :href="assets_url + reportee.support_document" target="_blank" rel="noopener noreferrer">
                                            View Support Document
                                        </a> 
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No Support Document </i> 
                                    </td>
                                    <td class="text-center">
                                        <span v-if="reportee.approval_status == 1" class="badge badge-success text-white">APPROVED</span>
                                        <span v-else-if="reportee.approval_status == 2" class="badge badge-danger text-white">DISAPPROVED</span>
                                        <span v-else class="badge badge-warning text-white">PENDING</span>
                                    </td>
                                    <td v-if="reportee.approve_by" class="text-left">
                                        {{
                                            (
                                                reportee.approval_status == 1
                                                ?
                                                'Approved by '
                                                :
                                                'Disapproved by '
                                            )
                                            +
                                            reportee.approve_by
                                        }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No approval/disapproval</i>
                                    </td>
                                    <td class="text-center">
                                        <i
                                        @click="launchApprovalDialog(reportee.id, reportee.employee_id, true)"
                                        class="col-3 py-0 text-center fas fa-check mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                        ></i>
                                        <i
                                        @click="launchApprovalDialog(reportee.id, reportee.employee_id, false)"
                                        class="col-3 py-0 text-center fas fa-times ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                        ></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <!-- Dialog for approving a skill set -->
        <v-dialog v-model="skillSetFormData.approveSkillSetDialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Approve Skill Set</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Comment</span>
                                        <v-textarea v-model="skillSetFormData.comment" placeholder="Enter approval comment" solo></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="skillSetFormData.approvingSkillSet ? null : approveSkillSet()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            <span v-if="skillSetFormData.approvingSkillSet === false">
                                                Approve
                                            </span>
                                            <v-icon
                                                v-if="skillSetFormData.approvingSkillSet"
                                                style="color: #fff; padding: 0px 10px; font-size: 16px;"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="skillSetFormData.approveSkillSetDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for approving a skill set -->

        <!-- Dialog for disapproving a skill set -->
        <v-dialog v-model="skillSetFormData.disapproveSkillSetDialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Disapprove Skill Set</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Comment</span>
                                        <v-textarea v-model="skillSetFormData.comment" placeholder="Enter disapproval comment" solo></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="skillSetFormData.disapprovingSkillSet ? null : disapproveSkillSet()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            <span v-if="skillSetFormData.disapprovingSkillSet === false">
                                                Disapprove
                                            </span>
                                            <v-icon
                                                v-if="skillSetFormData.disapprovingSkillSet"
                                                style="color: #fff; padding: 0px 10px; font-size: 16px;"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="skillSetFormData.disapproveSkillSetDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for disapproving a skill set -->
    </div>
</template>

<script>
import axios from 'axios';
import { 
    mapActions, 
    mapGetters 
} from 'vuex';
import { 
    BASE_URL,
    ASSETS_URL
} from '@/main';

export default {
    props: {
        employeeIdsUnderReportingManager: Array,
        singleEmployeeObject: Function,
        get_dept_name: Function,
        availableDepartmentsNames: Array,
        availableDesignationNames: Array
    },
    data() {
        return {
            skillSetFormData: {
                id: null,
                employeeId: null,
                comment: '',
                approveSkillSetDialog: false,
                approvingSkillSet: false,
                disapproveSkillSetDialog: false,
                disapprovingSkillSet: false
            },

            filters: {
                departmentName: 'All',
                designationName: 'All',
                searchText: ''
            }
        }
    },
    computed: {
        ...mapGetters([
            'authCompanySkillSets',
            'authCompanyData',
            'authEmployeeDataForCompany',
            'authCompanyDepartments',
            'authDesignations',
            'authCompanyDepartmentEmployees',
            'validEmployeeId'
        ]),
        assets_url() {
            return ASSETS_URL;
        },
        reporteesSkillsets() {
            const _this = this;
            let availableSkillSets = [], result = [];
            _this.employeeIdsUnderReportingManager.forEach(employeeId => {
                availableSkillSets = [
                    ...availableSkillSets,
                    ..._this.getEmployeeSkillSets(employeeId)
                ];
            });
            result = availableSkillSets.map(skillSet => {
                const employeeObject = _this.singleEmployeeObject(skillSet.employee_id)
                const employeeName = employeeObject
                                ?
                                (
                                    `${employeeObject.employee_fristname} ${employeeObject.employee_lastname}`
                                ) 
                                :
                                'Employee not found';
                const department = employeeObject ? _this.get_dept_name(employeeObject.department_id) : 'Department not found';
                const designation = employeeObject ? employeeObject.employee_designation : 'Designation not found';
                const skillSetObject = {
                    ...skillSet,
                    employeeName,
                    department,
                    designation
                }
                return skillSetObject;
            });
            return result;
        },
        filteredReporteesSkillsets() {
            const _this = this;

            // Search and filter by department name
            let result = _this.reporteesSkillsets.filter(REPORTEE => {
                if (_this.filters.departmentName === 'All') {
                    // If all department should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.singleEmployeeObject(REPORTEE.employee_id);
                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == REPORTEE.employee_id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(REPORTEE => {
                if (_this.filters.designationName === 'All') {
                    // If all designation should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employee = _this.singleEmployeeObject(REPORTEE.employee_id);
                    let employeeDesignation = employee.employee_designation;
                    return employeeDesignation === _this.filters.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by employee name
            result = result.filter(REPORTEE => {
                let employee = _this.singleEmployeeObject(REPORTEE.employee_id);
                let employeeName = employee ? `${employee.employee_fristname} ${employee.employee_lastname}` : null;
                return employeeName.toLowerCase().indexOf(_this.filters.searchText.toLowerCase()) !== -1;
            });
            // Search and filter by employee name

            return result;
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_skill_sets'
        ]),
        launchApprovalDialog(id, employeeId, approvingSkillSet) {
            const _this = this;
            _this.skillSetFormData.id = id;
            _this.skillSetFormData.employeeId = employeeId;
            _this.skillSetFormData.comment = '';
            if (approvingSkillSet) {
                _this.skillSetFormData.approveSkillSetDialog = true;
            } else {
                _this.skillSetFormData.disapproveSkillSetDialog = true;
            }
        },
        approveSkillSet() {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            const approve_by = _this.singleEmployeeObject(_this.validEmployeeId) 
                                ?
                                (
                                    `${_this.singleEmployeeObject(_this.validEmployeeId).employee_fristname} ${_this.singleEmployeeObject(_this.validEmployeeId).employee_lastname}`
                                ) 
                                :
                                'Employee not found';
            _this.$swal.fire({
                title: `Are you sure you want to approve this skill set?`,
                showCancelButton: true,
                confirmButtonText: `Yes, Approve`,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    _this.skillSetFormData.approvingSkillSet = true;
                    axios.post(`${BASE_URL}approveSkillset`, {
                        company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id,
                        approve_by,
                        employee_id: _this.skillSetFormData.employeeId,
                        ids: [
                            {
                                id: _this.skillSetFormData.id,
                                comment: _this.skillSetFormData.comment
                            }
                        ]
                    }).then(function () {
                        _this.skillSetFormData.comment = '';
                        _this.skillSetFormData.approvingSkillSet = false;
                        _this.skillSetFormData.approveSkillSetDialog = false;
                        Toast.fire({
                            icon: "success",
                            title: "The skill set was approved successfully",
                        });
                    }).catch(function (error) {
                        let errors = error.response.data.error || null;
                        Toast.fire({
                            icon: "error",
                            title: errors
                                ? typeof errors === "string"
                                    ? errors
                                    : null
                                : null || error.response.data.error || error.response.data.message || "The skill set couldn't be approved",
                        });
                    }).then(function () {
                        _this.fetch_company_skill_sets();
                    });
                }
            });
        },
        disapproveSkillSet() {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            const approve_by = _this.singleEmployeeObject(_this.validEmployeeId) 
                                ?
                                (
                                    `${_this.singleEmployeeObject(_this.validEmployeeId).employee_fristname} ${_this.singleEmployeeObject(_this.validEmployeeId).employee_lastname}`
                                ) 
                                :
                                'Employee not found';
            _this.$swal.fire({
                title: `Are you sure you want to disapprove this skill set?`,
                showCancelButton: true,
                confirmButtonText: `Yes, Disapprove`,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    _this.skillSetFormData.disapprovingSkillSet = true;
                    axios.post(`${BASE_URL}disapproveSkillset`, {
                        company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id,
                        approve_by,
                        employee_id: _this.skillSetFormData.employeeId,
                        ids: [
                            {
                                id: _this.skillSetFormData.id,
                                comment: _this.skillSetFormData.comment
                            }
                        ]
                    }).then(function () {
                        _this.skillSetFormData.comment = '';
                        _this.skillSetFormData.disapprovingSkillSet = false;
                        _this.skillSetFormData.disapproveSkillSetDialog = false;
                        Toast.fire({
                            icon: "success",
                            title: "The skill set was disapproved successfully",
                        });
                    }).catch(function (error) {
                        let errors = error.response.data.error || null;
                        Toast.fire({
                            icon: "error",
                            title: errors
                                ? typeof errors === "string"
                                    ? errors
                                    : null
                                : null || error.response.data.error || error.response.data.message || "The skill set couldn't be disapproved",
                        });
                    }).then(function () {
                        _this.fetch_company_skill_sets();
                    });
                }
            });
        },
        getEmployeeSkillSets(employeeId) {
            const _this = this;
            let result = _this.authCompanySkillSets.filter(SK => SK.employee_id == employeeId);
            return result;
        }
    }
}
</script>

<style>

</style>