<template>
    <div class="tab-pane fade" id="list-trainings-settings" role="tabpanel"
        aria-labelledby="list-trainings-settings-list">
        <div v-if="currentView == 'create'">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Create Training Provider
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="goToView('list')" style="float: right;" type="button"
                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Training Provider Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="newTrainingProviderForm.provider_name"
                                        placeholder="Enter the training provider name" solo class="mt-2"
                                        dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Trainer Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="newTrainingProviderForm.traininer"
                                        placeholder="Enter the trainer name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Contact Number <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="newTrainingProviderForm.contact_number"
                                        placeholder="Enter the contact number" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Address <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="newTrainingProviderForm.address"
                                        placeholder="Enter the address" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button @click="
            creatingTrainingProvider
                ? null
                : createTrainingProvider()
            " style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ trainingProviderCreationText }}
                                        <v-icon :style="!creatingTrainingProvider
            ? 'display: none;'
            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
            ">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Training Providers
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="goToView('create')" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Create Training Provider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Name
                            </th>
                            <th scope="col" class="text-left">
                                Trainer
                            </th>
                            <th scope="col" class="text-left">
                                Contact Number
                            </th>
                            <th scope="col" class="text-left">
                                Address
                            </th>
                            <th scope="col" style="width: 10px;">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="trainingProvider in authCompanyTrainingProviders" :key="trainingProvider.id">
                            <td class="text-left">
                                {{ trainingProvider.provider_name }}
                            </td>
                            <td class="text-left">
                                {{ trainingProvider.traininer }}
                            </td>
                            <td class="text-left">
                                {{ trainingProvider.contact_number }}
                            </td>
                            <td class="text-left">
                                {{ trainingProvider.address }}
                            </td>
                            <td class="pt-1">
                                <div class="ul-widget4__actions">
                                    <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                    </button>
                                    <div class="dropdown-menu" x-placement="top-start"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="intiateTrainingProviderEdit(trainingProvider)">
                                            <i class="fal fa-edit" style="color: inherit !important;"></i> Edit
                                        </a>
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="deleteTrainingProvider(trainingProvider)">
                                            <i class="fal fa-trash"></i> Delete
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <v-dialog v-model="editTrainingProviderDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Training Provider</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Training Provider Name <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="trainingProviderEditForm.provider_name"
                                            placeholder="Enter the training provider name" solo class="mt-2"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Trainer Name <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="trainingProviderEditForm.traininer"
                                            placeholder="Enter the trainer name" solo class="mt-2" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Contact Number <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="trainingProviderEditForm.contact_number"
                                            placeholder="Enter the contact number" solo class="mt-2"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Address <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="trainingProviderEditForm.address"
                                            placeholder="Enter the address" solo class="mt-2" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="updatingTrainingProvider ? null : updateTrainingProvider()"
                                            style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ trainingProviderUpdateText }}
                                            <v-icon :style="!updatingTrainingProvider
            ? 'display: none;'
            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
            ">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editTrainingProviderDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
    props: {},
    data() {
        return {
            currentView: 'list',
            newTrainingProviderForm: {
                provider_name: "",
                traininer: "",
                company_id: "",
                contact_number: "",
                address: "",
            },
            trainingProviderEditForm: {
                id: null,
                provider_name: "",
                traininer: "",
                company_id: "",
                contact_number: "",
                address: "",
            },
            trainingProviderCreationText: "Submit",
            creatingTrainingProvider: false,
            trainingProviderUpdateText: "Update",
            updatingTrainingProvider: false,
            editTrainingProviderDialog: false,
        }
    },
    computed: {
        ...mapState([
            'userToken'
        ]),
        ...mapGetters([
            'authCompanyData',
            'authCompanyEmployees',
            'authCompanyTrainingProviders',
        ]),
        allEmployees() {
            const _this = this;
            return _this.authCompanyEmployees.map(EMPLOYEE => {
                return {
                    name: `${EMPLOYEE.employee_fristname} ${EMPLOYEE.employee_lastname}`,
                    id: EMPLOYEE.id
                }
            });
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_training_providers',
        ]),
        goToView(view) {
            this.currentView = view;
        },
        intiateTrainingProviderEdit(trainingProvider) {
            this.trainingProviderEditForm.id = trainingProvider.id;
            this.trainingProviderEditForm.provider_name = trainingProvider.provider_name;
            this.trainingProviderEditForm.traininer = trainingProvider.traininer;
            this.trainingProviderEditForm.company_id = trainingProvider.company_id;
            this.trainingProviderEditForm.contact_number = trainingProvider.contact_number;
            this.trainingProviderEditForm.address = trainingProvider.address;
            // CONTINUE
            this.editTrainingProviderDialog = true;
            // CONTINUE
        },
        deleteTrainingProvider(trainingProvider) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this training provider [" + trainingProvider.provider_name + "]?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}deletetrainingprovider`, {
                            provider_id: trainingProvider.id,
                            company_id: _this.authCompanyData.id,
                        }, {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`
                            }
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Training Provider [" + trainingProvider.provider_name + "] deleted successfully",
                                });
                                _this.fetch_company_training_providers();
                            })
                            .catch(function (error) {
                                console.log(error)
                                Toast.fire({
                                    icon: "error",
                                    title: "Training Provider [" + trainingProvider.provider_name + "] couldn't be deleted",
                                });
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        createTrainingProvider() {
            const _this = this;
            _this.trainingProviderCreationText = "";
            _this.creatingTrainingProvider = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios.post(`${BASE_URL}storetrainingprovider`, {
                provider_name: _this.newTrainingProviderForm.provider_name,
                traininer: _this.newTrainingProviderForm.traininer,
                company_id: _this.authCompanyData.id,
                contact_number: _this.newTrainingProviderForm.contact_number,
                address: _this.newTrainingProviderForm.address,
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: _this.newTrainingProviderForm.provider_name + " was added successfully",
                    });
                    _this.newTrainingProviderForm.provider_name = "";
                    _this.newTrainingProviderForm.traininer = "";
                    _this.newTrainingProviderForm.contact_number = "";
                    _this.newTrainingProviderForm.address = "";
                    _this.fetch_company_training_providers();
                    _this.goToView('list');
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.provider_name
                                    ? errors.provider_name[0]
                                    : null || errors.traininer
                                        ? errors.traininer[0]
                                        : null || errors.contact_number
                                            ? errors.contact_number[0]
                                            : null || errors.address
                                                ? errors.address[0]
                                                : null
                            : null || error.response.data.error || error.response.data.message || "The training provider could not be added",
                    });
                    _this.fetch_company_training_providers();
                })
                .then(function () {
                    _this.creatingTrainingProvider = false;
                    _this.trainingProviderCreationText = "Submit";
                });
        },
        updateTrainingProvider() {
            const _this = this;
            _this.trainingProviderUpdateText = "";
            _this.updatingTrainingProvider = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updatetrainingprovider`, {
                provider_id: _this.trainingProviderEditForm.id,
                provider_name: _this.trainingProviderEditForm.provider_name,
                traininer: _this.trainingProviderEditForm.traininer,
                company_id: _this.authCompanyData.id,
                contact_number: _this.trainingProviderEditForm.contact_number,
                address: _this.trainingProviderEditForm.address,
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The training provider has been updated successfully",
                    });
                    _this.newTrainingProviderForm.id = null;
                    _this.newTrainingProviderForm.provider_name = "";
                    _this.newTrainingProviderForm.traininer = "";
                    _this.newTrainingProviderForm.contact_number = "";
                    _this.newTrainingProviderForm.address = "";
                    _this.fetch_company_training_providers();
                    _this.editTrainingProviderDialog = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.provider_name
                                    ? errors.provider_name[0]
                                    : null || errors.traininer
                                        ? errors.traininer[0]
                                        : null || errors.contact_number
                                            ? errors.contact_number[0]
                                            : null || errors.address
                                                ? errors.address[0]
                                                : null
                            : null || error.response.data.error || error.response.data.message || "The training provider could not be updated",
                    });
                })
                .then(function () {
                    _this.updatingTrainingProvider = false;
                    _this.trainingProviderUpdateText = "Update";
                });
        },
    },
    mounted() {
        const _this = this;
        _this.fetch_company_training_providers();
    }
}
</script>

<style></style>