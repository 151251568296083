<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('timesheets-read'))"
      class="main-content-wrap sidenav-open d-flex flex-column"
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
      <div class="main-header pl-4"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null"
        style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('timesheets-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                  employeeView ? "Your Timesheets" : "All Employees' Timesheets"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('timesheets-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Timesheets" : "Your Timesheets" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button" class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 105%;' : 'width: 80%;'">
            <h1 class="my-2 row pb-0">
              <span class="col-12 col-lg-4 col-md-4">Employee Timesheets</span>
              <v-row class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col
                  :offset="employee_name && filtered_timesheets.length >= 1 ? ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : '1') : ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : '3')"
                  cols="6" lg="2" md="2" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo messages=" *Filter by year"
                    :full-width="true" v-model="yearSelected"
                    @change="fetch_all_timesheets({ month: monthSelected, year: yearSelected })"></v-select>
                </v-col>
                <v-col cols="6" lg="3" md="3" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"
                    @change="fetch_all_timesheets({ month: monthSelected, year: yearSelected })"></v-select>
                </v-col>
                <v-col cols="6" lg="3" md="3" class="pt-0">
                  <v-select class="remove-mb" :items="allEmployees" placeholder="Employee" dense solo
                    messages="*Select Employee" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
                <v-col cols="6" lg="1" md="1" class="pt-0">
                  <button @click="reset()" type="button" class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null">
                    Reset
                  </button>
                </v-col>
                <v-col v-if="employee_name && filtered_timesheets.length >= 1" cols="2" class="pt-0">
                  <download-excel :data="json_data" :fields="json_fields"
                    :worksheet="employee_name + ' - ' + monthSelected + ' ' + yearSelected"
                    :name="'Timesheet for ' + employee_name + ' (' + monthSelected + ' ' + yearSelected + ').xls'"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Export to Excel
                  </download-excel>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" :style="employee_name && monthSelected && yearSelected && monthly_status == 'approved'
                ? 'border-radius: 10px; border: 4px solid #4caf50d4;'
                : employee_name && monthSelected && yearSelected && monthly_status == 'disapproved'
                  ? 'border-radius: 10px; border: 4px solid #f44336e0;'
                  : employee_name && monthSelected && yearSelected && monthly_status == 'pending'
                    ? 'border-radius: 10px; border: 4px solid #ffc107db;'
                    : ''">

                <div v-if="employee_name && monthSelected && yearSelected" class="card-header bg-white">
                  <span class="">
                    Status: <span :class="monthly_status == 'approved'
                      ? 'badge badge-square badge-square-opacity-success m-1 sm'
                      : monthly_status == 'disapproved'
                        ? 'badge badge-square badge-square-opacity-danger m-1 sm'
                        : 'badge badge-square badge-square-opacity-warning m-1 sm'">
                      <i v-if="monthly_status == 'approved'" class="fal fa-check"></i>
                      <i v-else-if="monthly_status == 'disapproved'" class="fal fa-times"></i>
                      <i v-else class="fal fa-hourglass"></i>
                    </span>
                  </span>
                  <span class="" style="top: 0px; position: relative; margin-left: 10px;">
                    Days Worked: {{ filtered_timesheets.length }}
                  </span>
                  <div
                    v-if="(employee_name && monthSelected && yearSelected && monthly_status == 'pending') || (employee_name && monthSelected && yearSelected && monthly_status == 'disapproved')"
                    class="float-right">
                    <button
                      v-if="permissionIsAssigned('timesheets-approve') && (monthly_status == 'disapproved' || (monthly_status == 'pending' && filtered_timesheets.length >= 1))"
                      type="button" @click="approve_timesheet_submit ? null : approve_timesheets()"
                      class="btn btn-raised ripple btn-raised-secondary m-1 text-white float-right">
                      {{ approve_timesheet_text }}
                      <v-icon :style="!approve_timesheet_submit
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                    <button
                      v-if="permissionIsAssigned('timesheets-approve') && (monthly_status == 'approved' || (monthly_status == 'pending' && filtered_timesheets.length >= 1))"
                      type="button" @click="disapprove_timesheet_submit ? null : disapprove_timesheets()"
                      class="btn btn-raised ripple btn-raised-secondary m-1 text-white float-right">
                      {{ disapprove_timesheet_text }}
                      <v-icon :style="!disapprove_timesheet_submit
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                    <!-- <button class="btn btn-outline-secondary m-1 float-right" type="button">
                      Comment
                    </button> -->
                  </div>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th class="text-left" scope="col">Date</th>
                          <th class="text-left" scope="col">Employee Name</th>
                          <th class="text-left" scope="col">Task</th>
                          <!-- <th scope="col">Task Details</th> -->
                          <th class="text-left" scope="col">Work Done</th>
                          <th class="text-left" scope="col">Place of Performance</th>
                          <th class="text-left" scope="col">Claimed Expenses</th>
                          <th class="text-left" scope="col">Advanced Payment</th>
                          <th class="text-left" scope="col">Reports Submitted</th>
                          <th class="text-left" scope="col">File Attachment</th>
                          <th class="text-left" scope="col">Check-in</th>
                          <th class="text-left" scope="col">Check-out</th>
                          <th class="text-left" scope="col">Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="timesheet in filtered_timesheets" :key="timesheet.id">
                          <th @click="set_date(timesheet.date)" class="underline text-left" scope="row">
                            {{
                              new Date(timesheet.date)
                                .toUTCString()
                                .substring(0, 16)
                            }}
                          </th>
                          <th @click="
                          employee_name =
                            timesheet.employee_fristname +
                            ' ' +
                            timesheet.employee_lastname
                            " class="underline text-left" scope="row">
                            {{
                              timesheet.employee_fristname +
                              " " +
                              timesheet.employee_lastname
                            }}
                          </th>

                          <td class="text-left">{{ timesheet.project_title }}</td>

                          <!-- <td>{{ timesheet.details }}</td> -->

                          <td class="text-left">
                            {{ timesheet.work_done }}
                          </td>

                          <td class="text-left">{{ timesheet.place_performance }}</td>

                          <td class="text-left" v-if="timesheet.claimed_expenses != 'No claimed expenses'">
                            ₦{{ (timesheet.claimed_expenses || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" v-else><i>No claimed expenses</i></td>

                          <td class="text-left" v-if="timesheet.payment_advanced != 'No advance payment'">
                            ₦{{ (timesheet.payment_advanced || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" v-else><i>No advance payment</i></td>

                          <td class="text-left" v-if="timesheet.report_submitted != 'No reports'">
                            {{ timesheet.report_submitted }}
                          </td>
                          <td class="text-left" v-else><i>No reports</i></td>

                          <td class="text-left" v-if="timesheet.fileurl">
                            <a :href="assets_url + timesheet.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td>

                          <td class="text-left" v-if="timesheet.check_in && timesheet.check_in != 'No checkin'">
                            {{ timesheet.check_in.split(' ').length == 1 ? timesheet.check_in :
                              timesheet.check_in.split(' ')[1] }}
                          </td>
                          <td class="text-left" v-else><i>No checkin</i></td>
                          <td class="text-left" v-if="timesheet.check_out && timesheet.check_out != 'No checkout'">
                            {{ timesheet.check_out.split(' ').length == 1 ? timesheet.check_out :
                              timesheet.check_out.split(' ')[1] }}
                          </td>
                          <td class="text-left" v-else><i>No checkout</i></td>
                          <td class="text-left" v-if="timesheet.check_duration != 'No checkout'">
                            {{ timesheet.check_duration }}
                          </td>
                          <td class="text-left" v-else><i>No checkout</i></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-dialog v-model="timesheet_comment" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ approval ? 'Approve' : 'Disapprove' }} Timesheet with Comment</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref='form3' @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12">
                        <span style="color: #111d5e;">Comment for {{ employee_name }} ({{ monthSelected }})</span>
                        <v-textarea :rules="[
                          v => !!v || 'Comment is required',
                          v => v.length >= 5 || 'Comment must be at least 5 characters long',
                        ]" v-model="comment_to_add" placeholder="Comment" solo class="mt-2"
                          :disabled="userType != 1 && !permissionIsAssigned('timesheets-approve') ? true : false">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                        <button @click="add_timesheet_comment_submit ? null : add_comment()" style="width: 99%;"
                          type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ add_timesheet_comment_text }}
                          <v-icon :style="!add_timesheet_comment_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                            ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="timesheet_comment = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column"
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('timesheets-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                  employeeView ? "Your Timesheets" : "All Employees' Timesheets"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('timesheets-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Timesheets" : "Your Timesheets" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button" class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 105%;' : 'width: 80%;'">
            <h1 class="my-0 row pb-0">
              <span class="col-12 col-lg-4 col-md-4">Your Timesheets</span>
              <v-row v-if="userType == 1" class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col offset-md="1" offset-lg="1" md="3" lg="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo messages=" *Filter by year"
                    :full-width="true" v-model="yearSelected"
                    @change="fetch_all_timesheets({ month: monthSelected, year: yearSelected })"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"
                    @change="fetch_all_timesheets({ month: monthSelected, year: yearSelected })"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allEmployees" placeholder="Employee" dense solo
                    messages="*Select Employee" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
                <v-col lg="2" md="2" cols="12" class="pt-0">
                  <button
                    v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')"
                    @click="add_timesheet_dialog = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Add Timesheet
                  </button>
                </v-col>
              </v-row>
              <v-row v-else class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col
                  :offset-md="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved') ? '4' : '6'"
                  :offset-lg="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved') ? '4' : '6'"
                  md="3" lg="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo messages=" *Filter by year"
                    :full-width="true" v-model="yearSelected"
                    @change="fetch_employee_timesheets({ month: monthSelected, year: yearSelected })"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"
                    @change="fetch_employee_timesheets({ month: monthSelected, year: yearSelected })"></v-select>
                </v-col>
                <v-col
                  v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')"
                  lg="2" md="2" cols="12" class="pt-0">
                  <button @click="add_timesheet_dialog = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Add Timesheet
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" :style="authEmployeeDataForCompany.id && monthSelected && yearSelected && monthly_status == 'approved'
                ? 'border-radius: 10px; border: 4px solid #4caf50d4;'
                : authEmployeeDataForCompany.id && monthSelected && yearSelected && monthly_status == 'disapproved'
                  ? 'border-radius: 10px; border: 4px solid #f44336e0;'
                  : authEmployeeDataForCompany.id && monthSelected && yearSelected && monthly_status == 'pending'
                    ? 'border-radius: 10px; border: 4px solid #ffc107db;'
                    : ''">

                <div class="card-header bg-white">
                  <span class="">
                    Status: <span :class="monthly_status == 'approved'
                      ? 'badge badge-square badge-square-opacity-success m-1 sm'
                      : monthly_status == 'disapproved'
                        ? 'badge badge-square badge-square-opacity-danger m-1 sm'
                        : 'badge badge-square badge-square-opacity-warning m-1 sm'">
                      <i v-if="monthly_status == 'approved'" class="fal fa-check"></i>
                      <i v-else-if="monthly_status == 'disapproved'" class="fal fa-times"></i>
                      <i v-else class="fal fa-hourglass"></i>
                    </span>
                  </span>
                  <span class="" style="top: 0px; position: relative; margin-left: 10px;">
                    Days Worked: {{ filtered_timesheets.length }}
                  </span>
                  <div class="float-right" v-if="filtered_timesheets.length >= 1 && submitted == false">
                    <button
                      v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')"
                      class="btn btn-secondary m-1 float-right" type="button"
                      @click="submit_timesheet_submit ? null : submit_timesheet()">
                      {{ submit_timesheet_text }}
                      <v-icon :style="!submit_timesheet_submit
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </div>
                  <div class="float-right" v-if="filtered_timesheets.length >= 1 && submitted == true">
                    <button style="cursor: not-allowed;" class="btn btn-secondary m-1 float-right disabled" type="button">
                      Submitted
                    </button>
                  </div>
                  <div class="float-right" v-if="comment_for_month.length >= 1">
                    <button class="btn btn-outline-secondary m-1 float-right" type="button"
                      @click="timesheet_comment = true">
                      View Comment
                    </button>
                  </div>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th class="text-left" scope="col">Date</th>
                          <th class="text-left" v-if="userType == 1" scope="col">
                            Employee Name
                          </th>
                          <th class="text-left" scope="col">Task</th>
                          <!-- <th scope="col">Task Details</th> -->
                          <th class="text-left" scope="col">Work Done</th>
                          <th class="text-left" scope="col">Place of Performance</th>
                          <th class="text-left" scope="col">Claimed Expenses</th>
                          <th class="text-left" scope="col">Advanced Payment</th>
                          <th class="text-left" scope="col">Reports Submitted</th>
                          <th class="text-left" scope="col">File Attachment</th>
                          <th
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            scope="col">Add Attachment</th>
                          <th
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            scope="col">Edit</th>
                          <th
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="timesheet in filtered_timesheets" :key="timesheet.id">
                          <td class="text-left" @click="set_date(timesheet.date)" scope="row">
                            {{
                              new Date(timesheet.date)
                                .toUTCString()
                                .substring(0, 16)
                            }}
                          </td>
                          <th v-if="userType == 1" @click="
                            employee_name =
                            timesheet.employee_fristname +
                            ' ' +
                            timesheet.employee_lastname
                            " class="underline text-left" scope="row">
                            {{
                              timesheet.employee_fristname +
                              " " +
                              timesheet.employee_lastname
                            }}
                          </th>

                          <td class="text-left">{{ timesheet.project_title }}</td>

                          <!-- <td>{{ timesheet.details }}</td> -->

                          <td class="text-left">
                            {{ timesheet.work_done }}
                          </td>

                          <td class="text-left">{{ timesheet.place_performance }}</td>

                          <td class="text-left" v-if="timesheet.claimed_expenses">
                            ₦{{ (timesheet.claimed_expenses || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" v-else><i>No claimed expenses</i></td>

                          <td class="text-left" v-if="timesheet.payment_advanced">
                            ₦{{ (timesheet.payment_advanced || 0) | number_with_commas }}
                          </td>
                          <td class="text-left" v-else><i>No advance payment</i></td>

                          <td class="text-left" v-if="timesheet.report_submitted">
                            {{ timesheet.report_submitted }}
                          </td>
                          <td class="text-left" v-else><i>No reports</i></td>

                          <td class="text-left" v-if="timesheet.fileurl">
                            <a :href="assets_url + timesheet.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td>

                          <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="pl-0 text-left">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on"
                                  @click="(attachment_timesheet_id = timesheet.id, fileAttachmentDialog = true)"
                                  class="col-3 offset-3 py-0 text-center fal fa-file-plus"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              </template>
                              <span>Add Attachment</span>
                            </v-tooltip>
                          </td>

                          <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="pl-0 text-left">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on" @click="launch_edit_timesheet(timesheet)"
                                  class="col-3 offset-3 py-0 text-center fal fa-edit"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              </template>
                              <span>Edit Timesheet</span>
                            </v-tooltip>
                          </td>

                          <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="pl-0 text-left">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on" @click="delete_timesheet(timesheet)"
                                  class="col-3 offset-3 py-0 text-center fal fa-trash"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              </template>
                              <span>Delete Timesheet</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-dialog v-model="add_timesheet_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Add Timesheet</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref='form1' @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Date <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                              v-model="formData.date" placeholder="Select a date" solo class="mt-2" dense readonly
                              v-bind="attrs" v-on="on" :rules="[
                                v => !!v || 'Date is required'
                              ]"></v-text-field>
                          </template>
                          <v-date-picker color="#069" ref="picker" v-model="formData.date" :min="new Date(firstDayOfLastMonth.getTime())
                            .toISOString()
                            .substring(0, 10)
                            " :max="new Date(new Date().getTime())
    .toISOString()
    .substring(0, 10)
    " @change="save"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Project Title <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="formData.project_title" :rules="[
                          v => !!v || 'Project title is required'
                        ]" placeholder="Enter project title" solo class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Place of Performance <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="formData.place_performance" :rules="[
                          v => !!v || 'Place of performance is required'
                        ]" placeholder="Enter place of performance" solo class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Report Submitted</span>
                        <v-text-field v-model="formData.report_submitted" placeholder="Enter report submitted" solo
                          class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Claimed Expenses</span>
                        <v-text-field v-model="formData.claimed_expenses" placeholder="Enter claimed expenses" solo
                          class="mt-2" dense type="number"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Payment Advanced</span>
                        <v-text-field v-model="formData.payment_advanced" placeholder="Enter payment advanced" solo
                          class="mt-2" dense type="number"></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="6" >
                          <v-textarea
                            v-model="formData.details"
                            placeholder="Task Details"
                            solo
                              class="mt-2"
                          ></v-textarea>
                        </v-col> -->
                      <v-col cols="12" sm="12" md="12">
                        <span style="color: #111d5e;">Work Done <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-textarea v-model="formData.work_done" :rules="[
                          v => !!v || 'Work done is required'
                        ]" placeholder="Enter work done" solo class="mt-2">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                        <button @click="add_timesheet_submit ? null : add_timesheet()" style="width: 99%;" type="button"
                          class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ add_timesheet_text }}
                          <v-icon :style="!add_timesheet_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                            ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="add_timesheet_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="edit_timesheet_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Timesheet</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref='form2' @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Date <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                              v-model="editFormData.date" placeholder="Date" :disabled="false" dense solo class="mt-2"
                              readonly v-bind="attrs" v-on="on" :rules="[
                                v => !!v || 'Date is required'
                              ]"></v-text-field>
                          </template>
                          <v-date-picker color="#069" ref="picker2" v-model="editFormData.date" :disabled="false" :min="new Date(firstDayOfLastMonth.getTime())
                            .toISOString()
                            .substring(0, 10)
                            " :max="new Date(new Date().getTime())
    .toISOString()
    .substring(0, 10)
    " @change="save2"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Project Title <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="editFormData.project_title" :rules="[
                          v => !!v || 'Project title is required'
                        ]" placeholder="Project Title" solo class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Place of Performance <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="editFormData.place_performance" :rules="[
                          v => !!v || 'Place of performance is required'
                        ]" placeholder="Place of Performance" solo class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Report Submitted</span>
                        <v-text-field v-model="editFormData.report_submitted" placeholder="Report Submitted" solo
                          class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Claimed Expenses</span>
                        <v-text-field v-model="editFormData.claimed_expenses" placeholder="Claimed Expenses" solo
                          class="mt-2" dense type="number"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <span style="color: #111d5e;">Payment Advanced</span>
                        <v-text-field v-model="editFormData.payment_advanced" placeholder="Payment Advanced" solo
                          class="mt-2" dense type="number"></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="6" >
                          <v-textarea
                            v-model="editFormData.details"
                            placeholder="Task Details"
                            solo
                              class="mt-2"
                          ></v-textarea>
                        </v-col> -->
                      <v-col cols="12" sm="12" md="12">
                        <span style="color: #111d5e;">Work Done <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-textarea v-model="editFormData.work_done" :rules="[
                          v => !!v || 'Work done is required'
                        ]" placeholder="Work Done" solo class="mt-2">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                        <button @click="update_timesheet_submit ? null : update_timesheet()" style="width: 99%;"
                          type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ update_timesheet_text }}
                          <v-icon :style="!update_timesheet_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                            ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="edit_timesheet_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="timesheet_comment" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ approval ? 'Approve' : 'Disapprove' }} Timesheet with Comment</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref='form4' @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12">
                        <v-textarea :rules="[
                          v => !!v || 'Comment is required',
                          v => v.length >= 5 || 'Comment must be at least 5 characters long',
                        ]" v-model="comment_to_add"
                          :placeholder="userType == 1 || permissionIsAssigned('timesheets-approve') ? 'Comment for ' + employee_name + ' (' + monthSelected + ')' : ''"
                          solo class="mt-2"
                          :disabled="userType != 1 && !permissionIsAssigned('timesheets-approve') ? true : false">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                        <button v-if="userType == 1 || permissionIsAssigned('timesheets-approve')"
                          @click="add_timesheet_comment_submit ? null : add_comment()" style="width: 99%;" type="button"
                          class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ add_timesheet_comment_text }}
                          <v-icon :style="!add_timesheet_comment_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                            ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="timesheet_comment = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="fileAttachmentDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">File Attachment</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref='form5' @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col style="cursor: pointer;" cols="12" sm="12" md="12">
                        <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                          label="Upload File Attachment" solo style="text-indent: 5px;" color="#069"
                          class="fileAttachment" messages="File Attachment" :rules="[
                            v => !!v || 'Document is required'
                          ]">
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3">
                        <button @click="fileAttachmentBtnSubmit ? null : upload_file_attachment()" style="width: 100%;"
                          type="button" class="btn btn-secondary m-1 ripple">
                          {{ fileAttachmentBtnText }}
                          <v-icon :style="!fileAttachmentBtnSubmit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                            ">
                            fas fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="fileAttachmentDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      attachment_timesheet_id: null,
      fileAttachmentBtnSubmit: false,
      fileAttachmentBtnText: 'Upload',
      fileAttachmentDialog: false,
      employeeView: true,
      approval: true,
      submit_timesheet_text: "Submit",
      submit_timesheet_submit: false,
      comment_to_add: "",
      formData: {
        date: "",
        project_title: "",
        place_performance: "",
        report_submitted: "",
        claimed_expenses: "",
        payment_advanced: "",
        details: "",
        work_done: ""
      },
      editFormData: {
        timesheet_id: "",
        date: "",
        project_title: "",
        place_performance: "",
        report_submitted: "",
        claimed_expenses: "",
        payment_advanced: "",
        details: "",
        work_done: ""
      },
      timesheet_comment: false,
      add_timesheet_comment_text: "Submit",
      add_timesheet_comment_submit: false,
      approve_timesheet_text: "Approve",
      approve_timesheet_submit: false,
      disapprove_timesheet_text: "Disapprove",
      disapprove_timesheet_submit: false,
      add_timesheet_text: "Submit",
      add_timesheet_submit: false,
      update_timesheet_text: "Update",
      update_timesheet_submit: false,
      add_timesheet_dialog: false,
      edit_timesheet_dialog: false,
      employee_name: "",
      employee: null,
      menu: false,
      menu2: false,
      day_selected: "",
      view: "date", // also day and employee
      day_attendance: [],
      checkingSingleDetails: [],
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      firstYear: "",
      allEmployees: [],
      comments: [],
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  watch: {
    timesheet_comment: function (t) {
      0 == t && (this.comment_to_add = this.comment_for_month[0].comments);
    },
    comment_for_month: function (t) {
      t && t.length >= 1 ? (this.comment_to_add = t[0].comments) : (this.comment_to_add = "");
    },
    employee_name: function (t) {
      t && this.fetch_timesheet_comment();
    },
    monthSelected: function (t) {
      1 == this.userType ? t && this.employee_name && this.yearSelected && this.fetch_timesheet_comment() : t && this.authEmployeeDataForCompany.id && this.yearSelected && this.fetch_timesheet_comment();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyAllCheckin",
      "authCompanyEmployees",
      "authCompanyAllTimesheets",
      "authEmployeeTimesheets",
      "authEmployeeDataForCompany",
      "true_employees",
      // "authCompanyAllCheckin",
      "authCheckinAllTime"
    ]),
    assets_url() {
      return ASSETS_URL;
    },
    json_fields: function () {
      return {
        Date: "date",
        Task: "project_title",
        "Place of Performance": "place_performance",
        "Brief Summary of Work Done": "work_done",
        "Claimed Expenses (NGN)": "claimed_expenses",
        "Advanced Payment (NGN)": "payment_advanced",
        "Reports Submitted": "report_submitted",
        "Check-in": "check_in",
        "Check-out": "check_out",
        "Duration": "check_duration"
      };
    },
    firstDayOfLastMonth() {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - 1);
      return date;
    },
    attendance() {
      if (!this.checkingDetails) {
        return this.authCompanyAllCheckin.attendance;
      } else {
        return this.checkingDetails;
      }
    },
    json_data: function () {
      return this.filtered_timesheets;
    },
    comment_for_month: function () {
      var t = this;
      return t.comments.length >= 1
        ? t.comments.filter(function (e) {
          return e.year == t.yearSelected && e.month == t.monthSelected && e.employee_id == (t.employee_id || t.authEmployeeDataForCompany.id);
        })
        : [];
    },
    monthly_status: function () {
      var t,
        e,
        a = this;
      return 0 == a.filtered_timesheets.length
        ? "pending"
        : a.filtered_timesheets &&
          ((t = a.filtered_timesheets.filter(function (t) {
            return 1 == t.status;
          })),
            t.length == a.filtered_timesheets.length)
          ? "approved"
          : a.filtered_timesheets &&
            ((e = a.filtered_timesheets.filter(function (t) {
              return 2 == t.status;
            })),
              e.length == a.filtered_timesheets.length)
            ? "disapproved"
            : "pending";
    },
    filtered_timesheets: function () {
      var t = this;
      if (t.authCompanyAllTimesheets.length || t.authEmployeeTimesheets.length) {
        if (t.authCompanyAllTimesheets) {
          if (t.userType != 1 && t.employeeView) {
            if (t.authEmployeeTimesheets.length) {
              return t.authEmployeeTimesheets.filter(function (ts) {
                return ts.month_name == t.monthSelected && ts.year == t.yearSelected && ts.employee_id == t.authEmployeeDataForCompany.id
              })
            }
            return [];
          }
          if (t.day_selected && t.employee_name) {
            t.day_selected = "";
            var e = t.authCompanyAllTimesheets.filter(function (e) {
              return e.month_name == t.monthSelected && e.year == t.yearSelected && t.employee_name == e.employee_fristname + " " + e.employee_lastname;
            });
            e = e.map(ts => {
              return {
                claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
                comment: ts.comment,
                company_id: ts.company_id,
                created_at: ts.created_at,
                date: ts.date,
                day: ts.day,
                details: ts.details,
                employee_fristname: ts.employee_fristname,
                employee_id: ts.employee_id,
                employee_lastname: ts.employee_lastname,
                fileurl: ts.fileurl,
                id: ts.id,
                is_submitted: ts.is_submitted,
                month: ts.month,
                month_name: ts.month_name,
                payment_advanced: ts.payment_advanced || 'No advance payment',
                place_performance: ts.place_performance,
                project_title: ts.project_title,
                report_submitted: ts.report_submitted || 'No reports',
                status: ts.status,
                updated_at: ts.updated_at,
                work_done: ts.work_done,
                year: ts.year,
                // data[0].check_in.split(' ').length == 1 ? data[0].check_in : data[0].check_in.split(' ')[1]
                check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
                check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
                check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
              }
            })
            return e;
          }
          if (t.day_selected) {
            var a = t.authCompanyAllTimesheets.filter(function (e) {
              return e.date == t.day_selected;
            });
            a = a.map(ts => {
              return {
                claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
                comment: ts.comment,
                company_id: ts.company_id,
                created_at: ts.created_at,
                date: ts.date,
                day: ts.day,
                details: ts.details,
                employee_fristname: ts.employee_fristname,
                employee_id: ts.employee_id,
                employee_lastname: ts.employee_lastname,
                fileurl: ts.fileurl,
                id: ts.id,
                is_submitted: ts.is_submitted,
                month: ts.month,
                month_name: ts.month_name,
                payment_advanced: ts.payment_advanced || 'No advance payment',
                place_performance: ts.place_performance,
                project_title: ts.project_title,
                report_submitted: ts.report_submitted || 'No reports',
                status: ts.status,
                updated_at: ts.updated_at,
                work_done: ts.work_done,
                year: ts.year,
                check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
                check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
                check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
              }
            })
            return a;
          }
          if (t.employee_name) {
            var s = t.authCompanyAllTimesheets.filter(function (e) {
              return e.month_name == t.monthSelected && e.year == t.yearSelected && t.employee_name == e.employee_fristname + " " + e.employee_lastname;
            });
            s = s.map(ts => {
              return {
                claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
                comment: ts.comment,
                company_id: ts.company_id,
                created_at: ts.created_at,
                date: ts.date,
                day: ts.day,
                details: ts.details,
                employee_fristname: ts.employee_fristname,
                employee_id: ts.employee_id,
                employee_lastname: ts.employee_lastname,
                fileurl: ts.fileurl,
                id: ts.id,
                is_submitted: ts.is_submitted,
                month: ts.month,
                month_name: ts.month_name,
                payment_advanced: ts.payment_advanced || 'No advance payment',
                place_performance: ts.place_performance,
                project_title: ts.project_title,
                report_submitted: ts.report_submitted || 'No reports',
                status: ts.status,
                updated_at: ts.updated_at,
                work_done: ts.work_done,
                year: ts.year,
                check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
                check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
                check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
              }
            })
            return s;
          }
          var i = t.authCompanyAllTimesheets.filter(function (e) {
            return e.month_name == t.monthSelected && e.year == t.yearSelected;
          });
          console.log('THIS IS IT');
          i = i.map(ts => {
            return {
              claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
              comment: ts.comment,
              company_id: ts.company_id,
              created_at: ts.created_at,
              date: ts.date,
              day: ts.day,
              details: ts.details,
              employee_fristname: ts.employee_fristname,
              employee_id: ts.employee_id,
              employee_lastname: ts.employee_lastname,
              fileurl: ts.fileurl,
              id: ts.id,
              is_submitted: ts.is_submitted,
              month: ts.month,
              month_name: ts.month_name,
              payment_advanced: ts.payment_advanced || 'No advance payment',
              place_performance: ts.place_performance,
              project_title: ts.project_title,
              report_submitted: ts.report_submitted || 'No reports',
              status: ts.status,
              updated_at: ts.updated_at,
              work_done: ts.work_done,
              year: ts.year,
              check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
              check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
              check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
            }
          })
          return i;
        }
        var o = this;
        if (o.day_selected) {
          if (o.authEmployeeTimesheets.length) {
            var n = o.authEmployeeTimesheets.filter(function (t) {
              return t.date == o.day_selected;
            });
            return n;
          }
          return [];
        }
        if (o.employee_name) {
          if (o.authEmployeeTimesheets.length) {
            var l = o.authEmployeeTimesheets.filter(function (t) {
              return t.month_name == o.monthSelected && t.year == o.yearSelected && o.employee_name == t.employee_fristname + " " + t.employee_lastname;
            });
            return l;
          }
          return [];
        }
        if (o.authEmployeeTimesheets.length) {
          var r = o.authEmployeeTimesheets.filter(function (t) {
            return t.month_name == o.monthSelected && t.year == o.yearSelected;
          });
          return r;
        }
        return [];
      } else {
        return [];
      }
    },
    month: function () {
      var t = new Date();
      return this.monthNames[t.getMonth()];
    },
    allYears: function () {
      var t = [],
        e = new Date().getFullYear();
      t.push(this.firstYear);
      for (var a = this.firstYear; a <= e; a++) if (t.indexOf(a) == -1) { t.push(a.toString()); }
      return t.reverse();
    },
    all_filtered_ts_ids: function () {
      var t = this;
      return t.filtered_timesheets.map(function (t) {
        return { id: t.id };
      });
    },
    employee_id: function () {
      var t = this;
      return t.true_employees && t.employee_name
        ? t.true_employees.filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
        })[0].id
        : null;
    },
    submitted() {
      let _this = this, all_submitted = [];
      if (_this.monthly_status == 'disapproved') {
        return false;
      }
      if (_this.filtered_timesheets) {
        all_submitted = _this.filtered_timesheets.filter(ft => {
          return ft.is_submitted == 1;
        });
        if (all_submitted.length == _this.filtered_timesheets.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return true
      }
    },
  },
  methods: {
    ...mapActions(["fetch_employee_timesheets", "fetch_all_timesheets", "fetch_all_check_in_all_time"]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    async upload_file_attachment() {
      let _this = this;
      const valid = await _this.$refs.form5.validate();
      if (!valid) {
        return;
      }
      _this.fileAttachmentBtnText = "";
      _this.fileAttachmentBtnSubmit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let formData = new FormData();
      formData.append(
        "timesheet_id",
        _this.attachment_timesheet_id
      );
      formData.append(
        "file",
        document.querySelector(".fileAttachment div div div input").files[0]
      );

      axios
        .post(`${BASE_URL}timesheetattachment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Timesheet attachment uploaded successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_all_timesheets();

          const year = _this.yearSelected;
          const month = _this.monthSelected;

          _this.fetch_employee_timesheets({ month, year });
          _this.fileAttachmentDialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.file ? errors.file[0] : null) : null
              || error.response.data.error || error.response.data.message || 'File attachment could not be uploaded'
          });
        })
        .then(function () {
          _this.fileAttachmentBtnSubmit = false;
          _this.fileAttachmentBtnText = "Upload";
        });
    },
    get_check_in(employee_id, day) {
      let _this = this, data;
      if (_this.authCheckinAllTime) {
        data = _this.authCheckinAllTime.filter(checkin => {
          return checkin.employee_id == employee_id && checkin.day == day
        });
        if (data.length >= 1) {
          let result;
          result = data[0].check_in;
          return result;
        }
      }
      return null;
    },
    get_check_out(employee_id, day) {
      let _this = this, data;
      if (_this.authCheckinAllTime) {
        data = _this.authCheckinAllTime.filter(checkin => {
          return checkin.employee_id == employee_id && checkin.day == day
        });
        if (data.length >= 1) {
          let result;
          result = data[0].check_out;
          return result;
        }
      }
      return null;
    },
    get_check_duration(employee_id, day) {
      let _this = this, data;
      if (_this.authCheckinAllTime) {
        data = _this.authCheckinAllTime.filter(checkin => {
          return checkin.employee_id == employee_id && checkin.day == day
        });
        if (data.length >= 1) {
          return data[0].duration;
        }
      }
      return null;
    },
    submit_timesheet() {
      var t = this;
      t.submit_timesheet_text = "";
      t.submit_timesheet_submit = true;
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      t.$swal.fire({ title: "Are you sure you want to submit this timesheet?", showCancelButton: true, confirmButtonText: "Yes, Submit" }).then(function (s) {
        if (s.isConfirmed) {
          axios
            .post(`${BASE_URL}submittimesheet`, {
              company_id: t.authEmployeeDataForCompany.company_id,
              employee_id: t.authEmployeeDataForCompany.id,
              month: t.monthSelected,
              year: t.yearSelected
            })
            .then(function () {
              const year = t.yearSelected;
              const month = t.monthSelected;

              t.fetch_employee_timesheets({ month, year });
              t.fetch_all_timesheets();
              e.fire({
                icon: "success",
                title: "Timesheets for " + t.monthSelected + " submitted successfully."
              });
            })
            .catch(function (error) {
              e.fire({
                icon: "error",
                title: error.response.data.error || error.response.data.message || 'Timesheet couldn\'t be submitted'
              });
            })
            .then(function () {
              t.submit_timesheet_submit = false;
              t.submit_timesheet_text = "Submit";
            });
        } else {
          t.submit_timesheet_submit = false;
          t.submit_timesheet_text = "Submit";
        }
      })
    },
    add_comment: async function () {
      var t = this;
      const valid = await t.$refs.form3.validate();
      if (!valid) {
        return;
      }
      (t.add_timesheet_comment_text = ""), (t.add_timesheet_comment_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      if (t.approval) {
        // (t.approve_timesheet_text = ""), (t.approve_timesheet_submit = true);
        axios
          .post(`${BASE_URL}approvetimesheet`, {
            company_id: t.authCompanyData.id,
            employee_id: t.employee_id,
            month: t.monthSelected,
            ids: t.all_filtered_ts_ids,
            comment: t.comment_to_add
          })
          .then(function () {
            t.fetch_timesheet_comment(), (t.comment_to_add = ""), (t.timesheet_comment = false);
            e.fire({ icon: "success", title: "The Timesheet for " + t.employee_name + " for the month of " + t.monthSelected + " has been approved and the comment has been added." }), t.fetch_all_timesheets();
          })
          .catch(function (error) {
            let errors = error.response.data.error || null;
            e.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.comment ? errors.comment[0] : null
                  || errors.monthSelected ? errors.monthSelected[0] : null) : null
                  || error.response.data.error || error.response.data.message || 'Timesheet couldn\'t be approved'
            });
          })
          .then(function () {
            // (t.approve_timesheet_submit = false), (t.approve_timesheet_text = "Approve");
            (t.add_timesheet_comment_submit = false), (t.add_timesheet_comment_text = "Submit");
          });
      } else {
        // (t.disapprove_timesheet_text = ""), (t.disapprove_timesheet_submit = true);
        axios
          .post(`${BASE_URL}disapprovetimesheet`, {
            company_id: t.authCompanyData.id,
            employee_id: t.employee_id,
            month: t.monthSelected,
            ids: t.all_filtered_ts_ids,
            comment: t.comment_to_add
          })
          .then(function () {
            t.fetch_timesheet_comment(), (t.comment_to_add = ""), (t.timesheet_comment = false);
            e.fire({ icon: "success", title: "The Timesheet for " + t.employee_name + " for the month of " + t.monthSelected + " has been disapproved and the comment has been added." }), t.fetch_all_timesheets();
          })
          .catch(function (error) {
            let errors = error.response.data.error || null;
            e.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.comment ? errors.comment[0] : null
                  || errors.monthSelected ? errors.monthSelected[0] : null) : null
                  || error.response.data.error || error.response.data.message || 'Timesheet couldn\'t be disapproved'
            });
          })
          .then(function () {
            // (t.disapprove_timesheet_submit = false), (t.disapprove_timesheet_text = "Disapprove");
            (t.add_timesheet_comment_submit = false), (t.add_timesheet_comment_text = "Submit");
          });
      }
    },
    fetch_timesheet_comment: function () {
      var t = this;
      axios
        .post(`${BASE_URL}fetchtimesheetcomment`, { employee_id: t.employee_id || t.authEmployeeDataForCompany.id })
        .then(function (e) {
          t.comments = e.data.comments;
        })
        .catch(function () { })
        .then(function () { });
    },
    disapprove_timesheets: function () {
      var t = this;
      t.approval = false;
      t.timesheet_comment = true;
    },
    approve_timesheets: function () {
      var t = this;
      t.approval = true;
      t.timesheet_comment = true;
    },
    delete_timesheet: function (t) {
      var e = this,
        a = e.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2e3,
          timerProgressBar: true,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal.fire({ title: "Are you sure you want to delete this timesheet?", showCancelButton: true, confirmButtonText: "Yes, Delete" }).then(function (s) {
        s.isConfirmed &&
          axios
            .post(`${BASE_URL}deletetimesheet`, { ids: [{ id: t.id }] })
            .then(function () {
              a.fire({ icon: "success", title: "Timesheet deleted successfully" });
            })
            .catch(function (t) {
              a.fire({ icon: "error", title: "Timesheet couldn't be deleted" }), console.log(t);
            })
            .then(function () {
              const year = e.yearSelected;
              const month = e.monthSelected;

              e.fetch_employee_timesheets({ month, year });
            });
      });
    },
    launch_edit_timesheet: function (t) {
      var e = this;
      (e.editFormData.timesheet_id = t.id),
        (e.editFormData.date = t.date),
        (e.editFormData.project_title = t.project_title),
        (e.editFormData.place_performance = t.place_performance),
        (e.editFormData.work_done = t.work_done),
        (e.editFormData.report_submitted = t.report_submitted),
        (e.editFormData.claimed_expenses = t.claimed_expenses),
        (e.editFormData.payment_advanced = t.payment_advanced),
        (e.edit_timesheet_dialog = true);
    },
    set_date: function (t) {
      (this.day_selected = t), (this.yearSelected = t.substring(0, 4)), (this.monthSelected = this.monthNames[parseInt(t.substring(5, 7)) - 1]), (this.employee_name = "");
    },
    viewAll: function () {
      (this.monthSelected = this.monthNames[new Date().getMonth()]), (this.yearSelected = new Date().getFullYear().toString()), (this.checkingDetails = null), (this.view = "date");
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    load_day: function (t) {
      var e = this;
      axios
        .post(`${BASE_URL}fetchcheckingbyday`, { company_id: e.authCompanyData.id, day: t })
        .then(function (a) {
          (e.day_attendance = a.data.attendance), (e.view = "day"), (e.day_selected = t);
        })
        .catch(function (t) {
          console.log(t.response);
        })
        .then(function () { });
    },
    update_timesheet: async function () {
      var t = this;
      const valid = await t.$refs.form2.validate();
      if (!valid) {
        return;
      }
      (t.update_timesheet_text = ""), (t.update_timesheet_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      axios
        .post(`${BASE_URL}edittimesheet`, {
          timesheet_id: t.editFormData.timesheet_id,
          date: t.editFormData.date,
          project_title: t.editFormData.project_title,
          place_performance: t.editFormData.place_performance,
          work_done: t.editFormData.work_done,
          report_submitted: t.editFormData.report_submitted,
          claimed_expenses: t.editFormData.claimed_expenses,
          payment_advanced: t.editFormData.payment_advanced,
          details: null,
        })
        .then(function () {
          const year = t.yearSelected;
          const month = t.monthSelected;
          e.fire({ icon: "success", title: "Timesheet for " + new Date(t.editFormData.date).toUTCString().substring(0, 16) + " has been updated" }),
            (t.editFormData.timesheet_id = ""),
            (t.editFormData.date = ""),
            (t.editFormData.project_title = ""),
            (t.editFormData.place_performance = ""),
            (t.editFormData.work_done = ""),
            (t.editFormData.report_submitted = ""),
            (t.editFormData.claimed_expenses = ""),
            (t.editFormData.payment_advanced = ""),
            t.fetch_employee_timesheets({ month, year }),
            (t.edit_timesheet_dialog = false);
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          e.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.date ? errors.date[0] : null
                || errors.project_title ? errors.project_title[0] : null
                  || errors.place_performance ? errors.place_performance[0] : null
                    || errors.work_done ? errors.work_done[0] : null) : null
                    || error.response.data.error || error.response.data.message || 'Timesheet couldn\'t be added'
          });
        })
        .then(function () {
          (t.update_timesheet_submit = false), (t.update_timesheet_text = "Update");
        });
    },
    add_timesheet: async function () {
      var t = this;
      const valid = await t.$refs.form1.validate();
      if (!valid) {
        return;
      }
      (t.add_timesheet_text = ""), (t.add_timesheet_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      t.formData.date
        ? t.formData.project_title
          ? t.formData.place_performance
            ? t.formData.work_done
              ? axios
                .post(`${BASE_URL}addtimesheet`, {
                  timesheets: [
                    {
                      company_id: t.authEmployeeDataForCompany.company_id,
                      employee_id: t.authEmployeeDataForCompany.id,
                      date: t.formData.date,
                      project_title: t.formData.project_title,
                      place_performance: t.formData.place_performance,
                      work_done: t.formData.work_done,
                      report_submitted: t.formData.report_submitted,
                      claimed_expenses: t.formData.claimed_expenses,
                      payment_advanced: t.formData.payment_advanced,
                      details: null,
                    },
                  ],
                })
                .then(function () {
                  const year = t.yearSelected;
                  const month = t.monthSelected;
                  e.fire({ icon: "success", title: "Timesheet for " + new Date(t.formData.date).toUTCString().substring(0, 16) + " has been added" }),
                    (t.formData.date = ""),
                    (t.formData.project_title = ""),
                    (t.formData.place_performance = ""),
                    (t.formData.work_done = ""),
                    (t.formData.report_submitted = ""),
                    (t.formData.claimed_expenses = ""),
                    (t.formData.payment_advanced = ""),
                    t.fetch_employee_timesheets({ month, year }),
                    (t.add_timesheet_dialog = false);
                })
                .catch(function (error) {
                  let errors = error.response.data.error || null;
                  e.fire({
                    icon: "error",
                    title: errors ? (typeof errors === 'string' ? errors : null
                      || errors.date ? errors.date[0] : null
                        || errors.project_title ? errors.project_title[0] : null
                          || errors.place_performance ? errors.place_performance[0] : null
                            || errors.work_done ? errors.work_done[0] : null) : null
                            || error.response.data.error || error.response.data.message || 'Timesheet couldn\'t be added'
                  });
                })
                .then(function () {
                  (t.add_timesheet_submit = false), (t.add_timesheet_text = "Submit");
                })
              : (e.fire({ icon: "error", title: "Please enter your work done" }), (t.add_timesheet_submit = false), (t.add_timesheet_text = "Submit"))
            : (e.fire({ icon: "error", title: "Please enter your place of performance" }), (t.add_timesheet_submit = false), (t.add_timesheet_text = "Submit"))
          : (e.fire({ icon: "error", title: "Please enter a project title" }), (t.add_timesheet_submit = false), (t.add_timesheet_text = "Submit"))
        : (e.fire({ icon: "error", title: "Please enter a valid date" }), (t.add_timesheet_submit = false), (t.add_timesheet_text = "Submit"));
    },
    reset: function () {
      (this.day_selected = ""), (this.monthSelected = this.monthNames[new Date().getMonth()]), (this.yearSelected = new Date().getFullYear().toString()), (this.employee_name = "");
    },
  },
  mounted() {
    if (this.userType != 1) {
      let fname = this.authEmployeeDataForCompany.employee_fristname;
      let lname = this.authEmployeeDataForCompany.employee_lastname;
      this.employee_name = `${fname} ${lname}`;
    }

    const date = new Date();
    const year = date.getFullYear();
    const month = this.monthNames[date.getMonth()];

    this.fetch_employee_timesheets({ month, year });
    this.fetch_all_timesheets({ month, year });

    this.fetch_all_check_in_all_time();
    document.title = "Kylian ERP - Timesheets";
    1 != this.userType && this.fetch_timesheet_comment(), (document.title = "Kylian ERP - Timesheets"), (this.yearSelected = new Date().getFullYear().toString());
    var t = this;
    t.true_employees.forEach(function (e) {
      e.user_id != t.authCompanyData.id && t.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
    }),
      this.firstYear = new Date(this.authCompanyData.created_at).getFullYear().toString(),
      this.reset();

    this.yearSelected = new Date().getFullYear().toString();
  },
  created() {
    var t = this;
    if (this.userType == 1 || this.permissionIsAssigned('timesheets-read')) {
      t.$route.query.name &&
        (console.log("Route name found"),
          t.$watch("allEmployees", function () {
            if (t.allEmployees.indexOf(unescape(t.$route.query.name)) >= 0) {
              console.log("Route name found in array");
              var a = setInterval(function () {
                console.log("Interval"), (t.employee_name = unescape(t.$route.query.name)), t.employee_name == unescape(t.$route.query.name) && clearInterval(a);
              }, 500);
            }
          }));
    }
  }
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.fileAttachment div div div .v-file-input__text {
  cursor: pointer;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
