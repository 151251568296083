<template>
  <div>
    <div
      id="default-overlay-view-single-goal"
      style="
            width: 100%;
            height: 100%;
            background: #000;
            position: fixed;
            z-index: 1;
            opacity: 0;
            display: none;
            top: 0;
            left: 0;"
      @click="closeNavSingleGoal()"
    ></div>
    <div id="mySidenavViewSingleGoal" class="sidenav-applicants">
      <v-row>
        <v-col cols="12" md="12">
          <h3 class="h3-header pt-0" style="font-weight: 400;">View Goal</h3>
          <h3 class="h3-header pt-3 text-left">
            Securing 5 business clients for the Vitolo product and turning them
            to paying customers
          </h3>
          <v-row>
            <v-col cols="6" md="6">
              <span style="font-size: 11px; color: #111d5e8a;"
                >Challenged By </span
              ><span style="font-size: 12px;">Daniel Enamudu</span>
            </v-col>
            <v-col cols="6" md="6">
              <span style="font-size: 11px; color: #111d5e8a;">Owner </span
              ><span style="font-size: 12px;">Daniel Enamudu</span>
            </v-col>
            <v-col class="pt-3" cols="6" md="6">
              <span style="font-size: 11px; color: #111d5e8a;">Deadline </span
              ><span style="font-size: 12px;">31st July 2018</span>
            </v-col>
            <v-col class="pt-0" cols="12" md="12">
              <span style="font-size: 11px; color: #111d5e8a;"
                >Defined Target</span
              >
              <p>
                Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis
                ullamco cillum dolor. Voluptate exercitation incididunt aliquip
                deserunt reprehenderit elit laborum.
                <br />
                Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis
                ullamco cillum dolor. Voluptate exercitation incididunt aliquip
                deserunt reprehenderit elit laborum.
              </p>
            </v-col>
            <v-col class="pt-3" cols="12" md="12">
              <span style="font-size: 12px; color: #111d5e8a;"
                >Progress Report</span
              >
              <br />
              <span
                class="pt-2 pb-1 text-center"
                style="font-size: 25px; display: block;"
                >{{ progress }}%</span
              >
              <v-progress-linear
                rounded
                :value="progress"
                color="#111D5E"
                height="25"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <br />
    </div>
    <a @click.stop="openNavSingleGoal()">Open</a>
    <v-row justify="center">
      <v-date-picker v-model="appraisal_date"></v-date-picker>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      progress: 25,

      meeting_agenda: "",
      menu1: false,
      menu2: false,
      start_time: "00:00",
      end_time: "00:00",
      appraisal_date: new Date().toISOString().substr(0, 10),
      formData: {
        email: "",
        phone: "",
        address: "",
        bank: "",
        account_number: "",
        document: "",
        additional_message: ""
      },
      banks: [
        "Keystone Bank",
        "Firstbank",
        "Access Bank",
        "GT Bank",
        "FCMB",
        "Zenith Bank"
      ],
      show: false,
      btnTxt2: "Submit",
      isLoading: false
    };
  },
  mounted() {
    this.openNavSingleGoal();
  },
  methods: {
    allowedDates: val => parseInt(val.split("-")[2], 10) % 2 === 0,
    openNavSingleGoal() {
      if (screen.width > 450) {
        document.getElementById("mySidenavViewSingleGoal").style.width = "40%";
        document.getElementById("mySidenavViewSingleGoal").style.paddingLeft =
          "30px";
        document.getElementById("mySidenavViewSingleGoal").style.paddingRight =
          "30px";
        document.getElementById(
          "default-overlay-view-single-goal"
        ).style.opacity = "0.1";
        document.getElementById(
          "default-overlay-view-single-goal"
        ).style.display = "block";
      } else {
        document.getElementById("mySidenavViewSingleGoal").style.width = "80%";
        document.getElementById(
          "default-overlay-view-single-goal"
        ).style.opacity = "0.1";
        document.getElementById(
          "default-overlay-view-single-goal"
        ).style.display = "block";
      }
    },
    closeNavSingleGoal() {
      console.log("close");
      document.getElementById("mySidenavViewSingleGoal").style.width = "0";
      document.getElementById("mySidenavViewSingleGoal").style.paddingLeft =
        "0px";
      document.getElementById("mySidenavViewSingleGoal").style.paddingRight =
        "0px";
      document.getElementById(
        "default-overlay-view-single-goal"
      ).style.opacity = "0";
      document.getElementById(
        "default-overlay-view-single-goal"
      ).style.display = "none";
    }
  }
};
</script>

<style lang="scss" scoped>
span,
p {
  color: #111d5e;
}
.closebtnz {
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
}
</style>
<style lang="scss">
$primary-color: #069;
.headButton {
  background-color: #fff !important;
  color: $primary-color;
  border: 1px solid $primary-color;
  width: 90%;
  height: 50px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: none !important;
  span {
    color: $primary-color;
    text-transform: none !important;
  }
}
.create-submit-notifications {
  font-size: 14px !important;
  font-weight: 600;
  width: 90%;
  margin-right: 5px;
  height: 50px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  span {
    text-transform: none;
  }
}
.textarea-for-package-type div div {
  padding-right: 0px !important;
}
.create-submit-package {
  width: 100%;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  span {
    text-transform: initial !important;
  }
}
.h3-header {
  font-size: 20px !important;
}
.sidenav-applicants {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 35px;
  padding-right: 0;
  padding-left: 0;
}

.sidenav-applicants a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #111d5e;
  display: block;
  transition: 0.3s;
}

.sidenav-applicants a:hover {
  color: #111d5e;
}

.sidenav-applicants .closebtn {
  position: absolute;
  bottom: 3px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav-applicants {
    padding-top: 15px;
  }
  .sidenav-applicants a {
    font-size: 18px;
  }
}
</style>
