<template>
    <div class="card-body row py-4">
        <div class="col-sm-4" style="align-self: center;">
            <h4>360-Degree Feedback</h4>
        </div>
        <div class="col-sm-8">
            <v-row>
                <v-col md="3"></v-col>
                <v-col md="3">
                    <v-select v-model="filters.departmentName" :items="availableDepartmentsNames"
                        :menu-props="{ maxHeight: '400' }" label="Select" solo dense hint="Select department"
                        persistent-hint></v-select>
                </v-col>
                <v-col md="3">
                    <v-select v-model="filters.designationName" :items="availableDesignationNames"
                        :menu-props="{ maxHeight: '400' }" label="Select" solo dense hint="Select designation"
                        persistent-hint></v-select>
                </v-col>
                <v-col md="3">
                    <v-text-field v-model="filters.searchText" placeholder="Search" messages="Search by employee name"
                        class="search-employees" solo dense></v-text-field>
                </v-col>
            </v-row>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Employee Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Department
                                    </th>
                                    <th class="text-left" scope="col">
                                        Designation
                                    </th>
                                    <th class="text-left" scope="col">
                                        Latest Feedback
                                    </th>
                                    <th class="text-center" scope="col">
                                        Manage Feedback
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(employee, index) in allFiltered360FeedbackEmployees"
                                    :key="'employee-' + index">
                                    <td class="text-left">
                                        {{ employee.employee_fristname + ' ' + employee.employee_lastname }}
                                    </td>
                                    <td v-if="singleEmployeeObject(employee.id)" class="text-left">
                                        {{
                        get_dept_name(singleEmployeeObject(employee.id).department_id)
                        ||
                        'Department not found'
                    }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No department</i>
                                    </td>
                                    <td v-if="employee.employee_designation" class="text-left">
                                        {{ employee.employee_designation }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No designation</i>
                                    </td>
                                    <td v-if="getFeedbacksByEmployeeId(employee.id).length >= 1" class="text-left">
                                        {{
                        getFeedbacksByEmployeeId(employee.id)[0].feedback
                        +
                        (
                            singleEmployeeObject(getFeedbacksByEmployeeId(employee.id)[0].feedback_by)
                                ?
                                (
                                    ' [' +
                                    (employee.id == validEmployeeId
                                        ?
                                        'You'
                                        :
                                        (
                                            singleEmployeeObject(getFeedbacksByEmployeeId(employee.id)[0].feedback_by).employee_fristname
                                            + ' ' +
                                            singleEmployeeObject(getFeedbacksByEmployeeId(employee.id)[0].feedback_by).employee_lastname
                                        ))
                                    + ']'
                                )
                                :
                                ''
                        )
                    }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No feedback</i>
                                    </td>
                                    <td class="text-center">
                                        <i class='cursor-pointer far fa-messages fa-2x' style='color: #069;' @click="
                    current_reviewer_id = employee.id,
                        view_feedbacks_dialog = true
                        "></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dialog for viewing 360-feedbacks -->
        <v-dialog v-model="view_feedbacks_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span v-if="singleEmployeeObject(current_reviewer_id)" class="headline">
                        Feedbacks with
                        {{
                        ' '
                        +
                        singleEmployeeObject(current_reviewer_id).employee_fristname
                        +
                        ' '
                        +
                        singleEmployeeObject(current_reviewer_id).employee_lastname
                    }}
                    </span>
                    <span v-else class="headline">All Feedbacks</span>
                    <span class="headline float-right">
                        <i @click="add_feedback_dialog = true" class='cursor-pointer far fa-message-plus'
                            style='float: right; color: #069; font-size: 1.25em;'></i>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="card-body row py-4">
                            <div class="col-sm-12 py-0">
                                <div class="main-content mt-0 px-0 py-0">
                                    <v-row>
                                        <v-col v-for="feedback in reversedFeedbacksByEmployeeId(current_reviewer_id)"
                                            :key="feedback.id" cols="12">
                                            <div v-if="currentUserIsSender(feedback.feedback_by)">
                                                <div class="p-2" style="
                                                    float: right;
                                                    max-width: 75%;
                                                    background: #069;
                                                    color: #fff;
                                                    border-radius: 10px;
                                                ">
                                                    <div class="text-right">
                                                        <p class="mb-0 text-left">
                                                            {{ feedback.feedback }}
                                                        </p>
                                                        <small>
                                                            {{
                        new Date(feedback.created_at).toDateString()
                        + ' ' +
                        new Date(feedback.created_at).toLocaleTimeString()
                    }}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="p-2" style="
                                                    float: left;
                                                    max-width: 75%;
                                                    background: #eee;
                                                    color: #000;
                                                    border-radius: 10px;
                                                ">
                                                    <div class="text-right">
                                                        <p class="mb-0 text-left">
                                                            {{ feedback.feedback }}
                                                        </p>
                                                        <small>
                                                            {{
                        new Date(feedback.created_at).toDateString()
                        + ' ' +
                        new Date(feedback.created_at).toLocaleTimeString()
                    }}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="view_feedbacks_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for viewing 360-feedbacks -->

        <!-- Dialog for adding 360-feedback -->
        <v-dialog v-model="add_feedback_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span v-if="singleEmployeeObject(current_reviewer_id)" class="headline">Add Feedback for {{
                        singleEmployeeObject(current_reviewer_id).employee_fristname + ' ' +
                        singleEmployeeObject(current_reviewer_id).employee_lastname }}</span>
                    <span v-else class="headline">Add Feedback</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-form ref='form'>
                                <v-row>
                                    <v-col cols="12">
                                        <span style="color: #111d5e;">Feedback</span>
                                        <v-textarea v-model="feedback" :rules="[
                        v => !!v || 'Feedback is required',
                        v => v.length >= 5 || 'Feedback must be at least 5 characters long',
                    ]" placeholder="Enter comment" solo class="mt-2"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <button @click="submitFeedbackLoading ? null : submit_feedback()" type="button"
                                            class="btn btn-secondary m-1 ripple w-25 px-0">
                                            {{ submitFeedbackText }}
                                            <v-icon
                                                :style="!submitFeedbackLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_feedback_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding 360-feedback -->
    </div>
</template>

<script>
import axios from 'axios';
import {
    mapActions,
    mapGetters
} from 'vuex';
import { BASE_URL } from '@/main';

export default {
    props: {
        singleEmployeeObject: Function,
        getFeedbacksByEmployeeId: Function,
        get_dept_name: Function,
        availableDepartmentsNames: Array,
        availableDesignationNames: Array
    },
    data() {
        return {
            current_reviewer_id: null,
            view_feedbacks_dialog: false,
            add_feedback_dialog: false,

            feedback: '',
            submitFeedbackText: 'Submit Feedback',
            submitFeedbackLoading: false,

            filters: {
                departmentName: 'All',
                designationName: 'All',
                searchText: ''
            }
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyEmployees',
            'authCompanyData',
            'authEmployeeDataForCompany',
            'authCompanyDepartments',
            'authDesignations',
            'authCompanyDepartmentEmployees',
            'validEmployeeId',
        ]),
        employeesExcludingReportingManager() {
            const _this = this;
            let result = [], employeesWithFeedback = [], employeesWithoutFeedback = [];
            result = _this.authCompanyEmployees.filter(e => e.id != _this.authEmployeeDataForCompany.reporting_to && e.id != _this.validEmployeeId);
            employeesWithFeedback = result.filter(e => _this.getFeedbacksByEmployeeId(e.id).length >= 1);
            employeesWithoutFeedback = result.filter(e => _this.getFeedbacksByEmployeeId(e.id).length == 0);
            result = [
                ...employeesWithFeedback,
                ...employeesWithoutFeedback
            ]
            return result;
        },
        all360FeedbackEmployees() {
            const _this = this;
            let result = [], reportingManager = _this.singleEmployeeObject(_this.authEmployeeDataForCompany.reporting_to);
            if (reportingManager) {
                let reportingManagerObject = {
                    id: reportingManager.id,
                    employee_fristname: reportingManager.employee_fristname,
                    employee_lastname: reportingManager.employee_lastname,
                    employee_designation: reportingManager.employee_designation,
                }
                result = [
                    reportingManagerObject,
                    ..._this.employeesExcludingReportingManager
                ];
            } else {
                result = [
                    ..._this.employeesExcludingReportingManager
                ];
            }
            return result;
        },
        allFiltered360FeedbackEmployees() {
            const _this = this;

            // Search and filter by department name
            let result = _this.all360FeedbackEmployees.filter(FEEDBACK_EMPLOYEE => {
                if (_this.filters.departmentName === 'All') {
                    // If all department should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.singleEmployeeObject(FEEDBACK_EMPLOYEE.id);
                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == FEEDBACK_EMPLOYEE.id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(FEEDBACK_EMPLOYEE => {
                if (_this.filters.designationName === 'All') {
                    // If all designation should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employee = _this.singleEmployeeObject(FEEDBACK_EMPLOYEE.id);
                    let employeeDesignation = employee.employee_designation;
                    return employeeDesignation === _this.filters.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by employee name
            result = result.filter(FEEDBACK_EMPLOYEE => {
                let employee = _this.singleEmployeeObject(FEEDBACK_EMPLOYEE.id);
                let employeeName = employee ? `${employee.employee_fristname} ${employee.employee_lastname}` : null;
                return employeeName.toLowerCase().indexOf(_this.filters.searchText.toLowerCase()) !== -1;
            });
            // Search and filter by employee name

            return result;
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_feedbacks'
        ]),
        async submit_feedback() {
            let _this = this;
            const valid = await _this.$refs.form.validate();
            if (!valid) {
                return;
            }

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.submitFeedbackText = "";
            _this.submitFeedbackLoading = true;

            axios
                .post(`${BASE_URL}createfeedback`, {
                    company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id,
                    feedback_by: _this.validEmployeeId,
                    feedback_to: _this.current_reviewer_id,
                    feedback: _this.feedback
                })
                .then(function () {
                    _this.add_feedback_dialog = false;
                    _this.feedback = '';
                    Toast.fire({
                        icon: "success",
                        title: `Feedback submitted successfully`,
                    });
                })
                .catch(function (error) {
                    Toast.fire({
                        icon: "error",
                        title: `Feedback couldn't be submitted`,
                    });
                    console.log(error);
                })
                .then(function () {
                    _this.fetch_company_feedbacks();
                    _this.submitFeedbackLoading = false;
                    _this.submitFeedbackText = "Submit Feedback";
                });
        },
        currentUserIsSender(senderEmployeeId) {
            return senderEmployeeId == this.validEmployeeId
        },
        reversedFeedbacksByEmployeeId(current_reviewer_id) {
            const _this = this, result = _this.getFeedbacksByEmployeeId(current_reviewer_id).reverse();
            return result;
        }
    }
}
</script>

<style></style>