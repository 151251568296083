<template>
  <div>
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div class="dropdown">
            <button class="btn ripple m-1" type="button" @click="refresh_page()"
              :class="refreshing_page ? 'rotate-spinner' : ''">
              <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
            </button>
          </div>
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row">
            <h1 class="my-0 col-md-12 pb-0">
              <span>Reports</span>
            </h1>
          </div>
        </div>
        <div class="row">
          <div class="text-left" style="width: 100%;">
            <div class="card-body py-5">
              <div class="row">
                <div class="col-12 col-lg-2 col-xl-2 col-md-2">
                  <div class="accordion" id="accordionRightIcon">
                    <div v-if="(userType == 1 || userType == 2) && check_all_services('Onboarding')" class="card">
                      <div class="collapse show" id="accordion-item-icon-right-1" data-parent="#accordionRightIcon"
                        style="">
                        <div class="list-group" id="list-tab" role="tablist">
                          <a v-if="check_all_services('Onboarding')" style="border-radius: 0;"
                            class="list-group-item list-group-item-action active" id="onboarding-reports-info"
                            data-toggle="list" href="#onboarding-reports" role="tab" aria-controls="onboarding-reports"
                            aria-selected="false" @click="check_class('Onboarding')">
                            Onboarding
                          </a>
                          <a v-if="check_all_services('Payroll')" style="border-radius: 0;"
                            class="list-group-item list-group-item-action" id="payroll-reports-info" data-toggle="list"
                            href="#payroll-reports" role="tab" aria-controls="payroll-reports" aria-selected="false"
                            @click="launchPayroll()">
                            Payroll
                          </a>
                          <a v-if="check_all_services('Attendance')" style="border-radius: 0;"
                            class="list-group-item list-group-item-action" id="attendance-reports-info"
                            data-toggle="list" href="#attendance-reports" role="tab" aria-controls="attendance-reports"
                            aria-selected="false" @click="launchAttendance()">
                            Attendance
                          </a>
                          <a v-if="check_all_services('Timesheets')" style="border-radius: 0;"
                            class="list-group-item list-group-item-action" id="timesheets-reports-info"
                            data-toggle="list" href="#timesheets-reports" role="tab" aria-controls="timesheets-reports"
                            aria-selected="false" @click="launchTimesheets()">
                            Timesheets
                          </a>
                          <a v-if="check_all_services('Absence or Leave')" style="border-radius: 0;"
                            class="list-group-item list-group-item-action" id="leave-reports-info" data-toggle="list"
                            href="#leave-reports" role="tab" aria-controls="leave-reports" aria-selected="false"
                            @click="launchLeave()">
                            Absence or Leave
                          </a>
                          <a v-if="check_all_services('Projects')" style="border-radius: 0;"
                            class="list-group-item list-group-item-action" id="projects-reports-info" data-toggle="list"
                            href="#projects-reports" role="tab" aria-controls="projects-reports" aria-selected="false"
                            @click="launchProjects()">
                            Projects
                          </a>
                          <a v-if="check_all_services('Assets')" style="border-radius: 0;"
                            class="list-group-item list-group-item-action" id="assets-reports-info" data-toggle="list"
                            href="#assets-reports" role="tab" aria-controls="assets-reports" aria-selected="false"
                            @click="launchAssets()">
                            Assets
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-10 col-xl-10 col-md-10">
                  <div class="tab-content py-0 px-0" id="nav-tabContent">
                    <div class="tab-pane fade active show" id="onboarding-reports" role="tabpanel"
                      aria-labelledby="onboarding-reports-info">
                      <h4>
                        <div class="row">
                          <div class="col-lg-6" style="align-self: center;">
                            Onboarding Reports
                          </div>
                        </div>
                      </h4>
                      <div class="row">
                        <!-- ICON BG-->
                        <div class="col-md-6 col-sm-6"
                          :class="check_all_services('Recruitment') ? 'col-lg-3' : 'col-lg-4'">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ true_employees.length }}</h3>
                              <h4>Employees</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Onboarding', query: { view: 'employee' } }"
                                type="button" class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6"
                          :class="check_all_services('Recruitment') ? 'col-lg-3' : 'col-lg-4'">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ authCompanyCandidates.length }}</h3>
                              <h4>Candidates</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Onboarding' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div v-if="check_all_services('Recruitment')" class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ auth_applicants.length }}</h3>
                              <h4>Applicants</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Recruitment' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6"
                          :class="check_all_services('Recruitment') ? 'col-lg-3' : 'col-lg-4'">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ authCompanyAllBilling.companyservices.length }}</h3>
                              <h4>Services</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Services' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">Employees by Department</div>
                              <div id="departmentChartArea" style="height: 300px;"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card mb-2">
                            <div class="card-body py-5">
                              <div class="card-title">Employees by Gender (Chart)</div>
                              <div id="genderChartArea" style="height: 300px;"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card mb-2">
                            <div class="card-body py-5">
                              <div class="card-title">Employees by Gender (Table)</div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Gender</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Count</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Percentage</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th class="text-left underline">Male</th>
                                        <td class="text-left">{{ maleEmployees.length }}</td>
                                        <td class="text-left">{{ (((maleEmployees.length / true_employees.length) *
                                            100) || 0).toString().substring(0, 5)
                                        }}%</td>
                                      </tr>
                                      <tr>
                                        <th class="text-left underline">Female</th>
                                        <td class="text-left">{{ femaleEmployees.length }}</td>
                                        <td class="text-left">{{ (((femaleEmployees.length / true_employees.length) *
                                            100 || 0)).toString().substring(0, 5)
                                        }}%</td>
                                      </tr>
                                      <tr>
                                        <th class="text-left underline">Unspecified</th>
                                        <td class="text-left">{{ genderlessEmployees.length }}</td>
                                        <td class="text-left">{{ (((genderlessEmployees.length / true_employees.length) *
                                            100) || 0).toString().substring(0, 5)
                                        }}%</td>
                                      </tr>
                                      <tr>
                                        <th class="text-left underline">Total</th>
                                        <td class="text-left">{{ true_employees.length }}</td>
                                        <td class="text-left">100%</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>
                                  All Employees
                                </span>
                                <button @click="$router.push({ name: 'Onboarding', query: { view: 'employee' } })"
                                  style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive"
                                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'height: 75vh;' : 'height: 75vh;'"
                                style="overflow-y: scroll;
                                              padding-top: 0;
                                              padding-bottom: 0;">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th class="text-left" style="border-top: none;" scope="col">Name</th>
                                      <th class="text-left" style="border-top: none;" scope="col">Hire Date</th>
                                      <th class="text-left" style="border-top: none;" scope="col">Job Title</th>
                                      <th class="text-left" style="border-top: none;" scope="col">Department</th>
                                      <th class="text-left" style="border-top: none;" scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="text-left" v-for="person in true_employees" :key="person.id">
                                      <td class="text-left">{{ person.employee_fristname + ' ' +
                                          person.employee_lastname
                                      }}</td>
                                      <td class="text-left">{{ new Date(person.created_at).toUTCString().substring(0,
                                          16)
                                      }}</td>
                                      <td class="text-left" v-if="person.employee_designation">{{
                                          person.employee_designation
                                      }}</td>
                                      <td class="text-left" v-else>
                                        <i>
                                          No assigned designation
                                        </i>
                                      </td>
                                      <td class="text-left" v-if="person.department_id">{{
                                          get_dept_name(person.department_id)
                                      }}</td>
                                      <td class="text-left" v-else>
                                        <i>
                                          No assigned department
                                        </i>
                                      </td>
                                      <td class="text-left">
                                        <span v-if="person.is_active == 0" class="badge badge-pill badge-primary"
                                          style="padding: 0.5rem 0.8rem;">
                                          {{ " " }}Active{{ " " }}
                                        </span>
                                        <span v-else class="badge badge-pill badge-light p-2">Inactive</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="payroll-reports" role="tabpanel"
                      aria-labelledby="payroll-reports-info">
                      <h4>
                        <div class="row">
                          <div class="col-lg-6" style="align-self: center;">
                            Payroll Reports
                          </div>
                        </div>
                      </h4>
                      <div class="row">
                        <!-- ICON BG-->
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>₦{{ totalExpenses | number_with_commas }}</h3>
                              <h4>Total Expenses</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Payroll', query: { view: 'list' } }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ authPayCalendars.length }}</h3>
                              <h4>Total Pay Calendar</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Payroll' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ approvedPayRuns.length }}</h3>
                              <h4>Total Pay Run</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Payroll', query: { view: 'list' } }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>₦{{ lastMonthExpenses | number_with_commas }}</h3>
                              <h4>Spent on last month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Payroll', query: { view: 'list' } }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Last 10 Pay Run Records</span>
                                <button @click="$router.push({ name: 'Payroll', query: { view: 'list' } })"
                                  style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Period</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Pay Run</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Payment Date</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Employees</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Payment</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="p in authCompanyPayRun" :key="p.id">
                                        <td class="text-left">{{ (new Date(p.from_date).toUTCString().substring(0, 16))
                                            + ' to ' + (new Date(p.to_date).toUTCString().substring(0, 16))
                                        }}</td>
                                        <td class="text-left">{{ p.payrun_name }}</td>
                                        <td class="text-left">{{ new Date(p.payment_date).toUTCString().substring(0, 16)
                                        }}</td>
                                        <td class="text-left">{{ employeesInPayCalendar(p.paycalendar_id).length }}</td>
                                        <td class="text-left">₦{{ parseInt(p.total_net_pay) | number_with_commas
                                        }}</td>
                                        <td class="text-left" v-if="p.approved == 0">
                                          Unapproved
                                        </td>
                                        <td class="text-left" v-else>
                                          Approved
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">Total payment for the year {{ new Date().getFullYear() }}</div>
                              <div id="paymentChartArea" style="height: 300px;"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="attendance-reports" role="tabpanel"
                      aria-labelledby="attendance-reports-info">
                      <h4>
                        <div class="row">
                          <div class="col-lg-6" style="align-self: center;">
                            Attendance Reports
                          </div>
                        </div>
                      </h4>
                      <div class="row">
                        <!-- ICON BG-->
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ monthlyEarlyCheckins.length + monthlyLateCheckins.length }}</h3>
                              <h4>Check-ins this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Attendance' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ monthlyAbsentCheckins.length }}</h3>
                              <h4>Absents</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Attendance' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ monthlyEarlyCheckins.length }}</h3>
                              <h4>Early</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Attendance' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ monthlyLateCheckins.length }}</h3>
                              <h4>Late</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Attendance' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Check-in statistics by employees this month</span>
                                <button @click="$router.push({ name: 'Attendance' })" style="float: right;"
                                  type="button" class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Date</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Employee Name</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Department</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Status</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Check-in</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Check-out</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Work time</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Check-in note</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Check-out note</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="c in allMonthlyCheckinInfo"
                                        :key="c.employee_id + '-' + c.status + '-' + Math.random()">
                                        <td class="underline text-left" scope="row">
                                          {{
                                              new Date(c.day)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              getEmployeeName(c.employee_id)
                                          }}
                                        </td>
                                        <td class="text-left">{{ c.department_id ? get_dept_name(c.department_id) : 'No assigned department' }}</td>
                                        <td class="text-center">
                                          <span v-if="c.status == 'early'" class="badge badge-round-success sm"
                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;">
                                            <span></span>
                                          </span>
                                          <span v-if="c.status == 'grace'" class="badge badge-round-warning sm"
                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;">
                                            <span></span>
                                          </span>
                                          <span v-if="c.status == 'late'" class="badge badge-round-warning sm"
                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #ffc107;">
                                            <span></span>
                                          </span>
                                          <span v-if="c.status == 'on leave'" class="badge badge-round-info sm"
                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #003473;">
                                            <span></span>
                                          </span>
                                          <span v-if="c.status == 'absent'" class="badge badge-round-danger sm"
                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #f44336;">
                                            <span></span>
                                          </span>
                                        </td>
                                        <td class="text-left" v-if="c.check_in">
                                          {{ c.check_in.split(' ').length == 1 ? c.check_in : c.check_in.split(' ')[1]
                                          }}
                                        </td>
                                        <td class="text-left" v-else><i>No check in</i></td>
                                        <td class="text-left" v-if="c.check_out">
                                          {{ c.check_out.split(' ').length == 1 ? c.check_out : c.check_out.split(' ')[1] }}
                                        </td>
                                        <td class="text-left" v-else><i>No checkout</i></td>
                                        <td class="text-left" v-if="c.duration">{{ c.duration }}</td>
                                        <td class="text-left" v-else><i>No checkout</i></td>
                                        <td class="text-left" v-if="c.punch_in_note">{{ c.punch_in_note }}</td>
                                        <td class="text-left" v-else><i>No check in note</i></td>
                                        <td class="text-left" v-if="c.punch_out_note">{{ c.punch_out_note }}</td>
                                        <td class="text-left" v-else><i>No checkout note</i></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title mb-0">
                                <v-row>
                                  <v-col cols="12" sm="6">
                                    <span>Top punctual employees</span>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-row>
                                      <v-col cols="6" class="pt-0">
                                        <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo
                                          messages=" *Filter by year" :full-width="true" v-model="yearSelected">
                                        </v-select>
                                      </v-col>
                                      <v-col cols="6" class="pt-0">
                                        <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                                          messages="*Filter by month" :full-width="true" v-model="monthSelected">
                                        </v-select>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Employee Name</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Department</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Average Checkin Time
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="e in topEarliestEmployees"
                                        :key="e.id + '-' + e.status + '-' + Math.random()">
                                        <td class="text-left">
                                          {{
                                              e.employee_fristname + ' ' + e.employee_lastname
                                          }}
                                          {{
                                              !e.avgIndexTrackerValue ? ' (No checkin within period)' : null
                                          }}
                                        </td>
                                        <td class="text-left">{{ e.department_id ? get_dept_name(e.department_id) : 'No assigned department' }}</td>
                                        <td class="text-left">{{ e.avgTime }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title mb-0">
                                <v-row>
                                  <v-col cols="12" sm="6">
                                    <span>Top tardy employees</span>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-row>
                                      <v-col cols="6" class="pt-0">
                                        <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo
                                          messages=" *Filter by year" :full-width="true" v-model="yearSelected2">
                                        </v-select>
                                      </v-col>
                                      <v-col cols="6" class="pt-0">
                                        <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                                          messages="*Filter by month" :full-width="true" v-model="monthSelected2">
                                        </v-select>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Employee Name</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Department</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Average Checkin Time
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="e in topTardyEmployees"
                                        :key="e.id + '-' + e.status + '-' + Math.random()">
                                        <td class="text-left">
                                          {{
                                              e.employee_fristname + ' ' + e.employee_lastname
                                          }}
                                          {{
                                              !e.avgIndexTrackerValue ? ' (No checkin within period)' : null
                                          }}
                                        </td>
                                        <td class="text-left">{{ e.department_id ? get_dept_name(e.department_id) : 'No assigned department' }}</td>
                                        <td class="text-left">{{ e.avgTime }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="col-md-12">
                            <div class="card mb-4">
                                <div class="card-body py-5">
                                    <div class="card-title">Payment Overtime {{ new Date().getFullYear() }}</div>
                                    <div id="paymentChartArea" style="height: 300px;"></div>
                                </div>
                            </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="tab-pane fade" id="timesheets-reports" role="tabpanel"
                      aria-labelledby="timesheets-reports-info">
                      <h4>
                        <div class="row">
                          <div class="col-lg-6" style="align-self: center;">
                            Timesheets Reports
                          </div>
                        </div>
                      </h4>
                      <div class="row">
                        <!-- ICON BG-->
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ submittedTimesheets.length }}</h3>
                              <h4>Submitted timesheets this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Timesheets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ approvedTimesheets.length }}</h3>
                              <h4>Approved timesheets this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Timesheets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ disapprovedTimesheets.length }}</h3>
                              <h4>Disapproved timesheets this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Timesheets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ pendingTimesheets.length }}</h3>
                              <h4>Pending timesheets this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Timesheets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card mb-2">
                            <div class="card-body py-5">
                              <div class="card-title">Timesheets Status (Pie Chart)</div>
                              <div id="timesheetsPieChartArea" style="height: 300px;"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card mb-2">
                            <div class="card-body py-5">
                              <div class="card-title">Timesheets Status (Bar Chart)</div>
                              <div id="timesheetsBarChartArea" style="height: 300px;"></div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="tab-pane fade" id="leave-reports" role="tabpanel" aria-labelledby="leave-reports-info">
                      <h4>
                        <div class="row">
                          <div class="col-lg-6" style="align-self: center;">
                            Leave Reports
                          </div>
                        </div>
                      </h4>
                      <div class="row" style="overflow-x: scroll;
                                          padding-top: 0;
                                          padding-bottom: 0;">
                        <!-- <div
                              class="col-md-6 col-sm-6 col-lg-3"
                          >
                            <div
                                class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                            >
                                <div v-if="topTwoLeaveTypes[0]" class="card-body  d-block text-center py-7">
                                    <h3>{{ topTwoLeaveTypes[0].approvedCount }}</h3>
                                    <h4>{{ topTwoLeaveTypes[0].leave_name }}</h4>
                                </div>
                                <div v-else class="card-body  d-block text-center py-7">
                                    <h3><i>Unspecified</i></h3>
                                    <h4><i>Unspecified</i></h4>
                                </div>
                                <div class="card-footer p-0">
                                    <router-link
                                        tag="button"
                                        :to="{ name: 'Absence or Leave' }"
                                        type="button"
                                        class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100"
                                    >
                                        <h6 class="mb-0 text-white"> View details </h6>
                                    </router-link>
                                </div>
                            </div>
                        </div> -->
                        <div v-for="lv in topTwoLeaveTypes" :key="lv.id" class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ lv.approvedCount }}</h3>
                              <h4>{{ lv.leave_name }}</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Absence or Leave' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ on_leave_today.length }}</h3>
                              <h4>On leave</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Absence or Leave' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ pendingLeaveRequests.length }}</h3>
                              <h4>Pending requests</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Absence or Leave' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>On leave</span>
                                <button @click="$router.push({ name: 'Absence or Leave' })" style="float: right;"
                                  type="button" class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Employee</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Start</th>
                                        <th style="border-top: none;" class="text-left" scope="col">End</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Leave type</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="lv in on_leave_today" :key="lv.id">
                                        <td class="underline text-left" scope="row">
                                          {{
                                              getEmployeeName(lv.employee_id) ||
                                              lv.employee_name
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              new Date(lv.leave_start)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              new Date(lv.leave_end)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{ get_type_name(lv.leave_type) }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Pending requests</span>
                                <button @click="$router.push({ name: 'Absence or Leave' })" style="float: right;"
                                  type="button" class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th class="text-left" scope="col">Employee Name</th>
                                        <th class="text-left" scope="col">Start Date</th>
                                        <th class="text-left" scope="col">End Date</th>
                                        <th class="text-left" scope="col">Days Left</th>
                                        <th class="text-left" scope="col">Leave Type</th>
                                        <th class="text-center" scope="col">Status</th>
                                        <th class="text-left" scope="col">Comments</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="leave in pendingLeaveRequests" :key="leave.id">
                                        <td class="text-left">{{ leave.employee_name }}</td>
                                        <td class="text-left">{{ leave.leave_start }}</td>
                                        <td class="text-left">{{ leave.leave_end }}</td>
                                        <td class="text-left">{{ fetch_days_left(leave) || '...Loading' }}</td>
                                        <td class="text-left">{{ get_type_name(leave.leave_type) }}</td>
                                        <td class="text-center" v-if="leave.status == 0">
                                          <span style="width: 60px;" class="badge badge-danger text-white">DENIED</span>
                                        </td>
                                        <td class="text-center" v-else-if="leave.status == 1">
                                          <span style="width: 60px;" class="badge badge-success">APPROVED</span>
                                        </td>
                                        <td class="text-center" v-else>
                                          <span style="width: 60px;"
                                            class="badge badge-warning text-white">PENDING</span>
                                        </td>
                                        <td class="text-left" v-if="leave.additional_comments">
                                          {{ leave.additional_comments }}
                                        </td>
                                        <td class="text-left" v-else><i>No comment</i></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="projects-reports" role="tabpanel"
                      aria-labelledby="projects-reports-info">
                      <h4>
                        <div class="row">
                          <div class="col-lg-6" style="align-self: center;">
                            Projects Reports
                          </div>
                        </div>
                      </h4>
                      <div class="row">
                        <!-- ICON BG-->
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ monthlyProjects.length }}</h3>
                              <h4>Total projects this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Projects' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ completedProjects.length }}</h3>
                              <h4>Completed projects this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Projects' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ inCompletedProjects.length }}</h3>
                              <h4>Incomplete projects this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Projects' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ tasksDue.length }}</h3>
                              <h4>Tasks due this month</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Projects' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Projects completed</span>
                                <button @click="$router.push({ name: 'Projects' })" style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Name</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Description</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Completed date</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Budget</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="p in completedProjects" :key="p.id">
                                        <td class="underline text-left" scope="row">
                                          {{
                                              p.title
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              p.description
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              new Date(p.est_completion_date)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                        <td class="text-left">
                                          ₦{{ parseInt(p.budget) || 0 | number_with_commas }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Incomplete projects</span>
                                <button @click="$router.push({ name: 'Projects' })" style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Name</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Description</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Completed date</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Budget</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="p in inCompletedProjects" :key="p.id">
                                        <td class="underline text-left" scope="row">
                                          {{
                                              p.title
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              p.description
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              new Date(p.est_completion_date)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                        <td class="text-left">
                                          ₦{{ parseInt(p.budget) || 0 | number_with_commas }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Tasks in progress</span>
                                <button @click="$router.push({ name: 'Projects' })" style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Project name</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Task title</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Task description
                                        </th>
                                        <th style="border-top: none;" scope="col" class="text-left">Due date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="t in tasksInProgress" :key="t.id">
                                        <td class="underline text-left" scope="row">
                                          {{
                                            getProjectV2(t.project_id) 
                                            ? 
                                            getProjectV2(t.project_id).title 
                                            : 
                                            'Project not found'
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              t.title
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              t.task_description
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              new Date(t.task_duedate)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="assets-reports" role="tabpanel"
                      aria-labelledby="assets-reports-info">
                      <h4>
                        <div class="row">
                          <div class="col-lg-6" style="align-self: center;">
                            Assets Reports
                          </div>
                        </div>
                      </h4>
                      <div class="row">
                        <!-- ICON BG-->
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ authCompanyAssets.length }}</h3>
                              <h4>Total assets</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Assets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body  d-block text-center py-7">
                              <h3>{{ completedProjects.length }}</h3>
                              <h4>Assigned assets</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Assets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ inCompletedProjects.length }}</h3>
                              <h4>Unassigned assets</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Assets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-3">
                          <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body d-block text-center py-7">
                              <h3>{{ tasksDue.length }}</h3>
                              <h4>Vendors</h4>
                            </div>
                            <div class="card-footer p-0">
                              <router-link tag="button" :to="{ name: 'Assets' }" type="button"
                                class="btn btn-sm btn-secondary py-2 text-white btn-raised ripple w-100">
                                <h6 class="mb-0 text-white"> View details </h6>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Projects completed</span>
                                <button @click="$router.push({ name: 'Projects' })" style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Name</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Description</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Completed date</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Budget</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="p in completedProjects" :key="p.id">
                                        <td class="underline text-left" scope="row">
                                          {{
                                              p.title
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              p.description
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              new Date(p.est_completion_date)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                        <td class="text-left">
                                          ₦{{ parseInt(p.budget) || 0 | number_with_commas }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Incomplete projects</span>
                                <button @click="$router.push({ name: 'Projects' })" style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Name</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Description</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Completed date</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Budget</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="p in inCompletedProjects" :key="p.id">
                                        <td class="underline text-left" scope="row">
                                          {{
                                              p.title
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              p.description
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              new Date(p.est_completion_date)
                                                .toUTCString()
                                                .substring(0, 16)
                                          }}
                                        </td>
                                        <td class="text-left">
                                          ₦{{ parseInt(p.budget) || 0 | number_with_commas }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-4">
                            <div class="card-body py-5">
                              <div class="card-title">
                                <span>Tasks in progress</span>
                                <button @click="$router.push({ name: 'Projects' })" style="float: right;" type="button"
                                  class="btn btn-sm btn-secondary text-white btn-raised ripple">
                                  View All
                                </button>
                              </div>
                              <div class="table-responsive" style="height: 300px;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="border-top: none;" scope="col" class="text-left">Project name</th>
                                        <th style="border-top: none;" scope="col" class="text-left">Task title</th>
                                        <th style="border-top: none;" class="text-left" scope="col">Task description
                                        </th>
                                        <th style="border-top: none;" scope="col" class="text-left">Due date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="t in tasksInProgress" :key="t.id">
                                        <td class="underline text-left" scope="row">
                                          {{
                                            getProjectV2(t.project_id) 
                                            ? 
                                            getProjectV2(t.project_id).title 
                                            : 
                                            'Project not found'
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              t.title
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                              t.task_description
                                          }}
                                        </td>
                                        <td class="text-left">
                                          {{
                                            new Date(t.task_duedate)
                                              .toUTCString()
                                              .substring(0, 16)
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      all_leave_rmaining: [],
      allEmployee: [],
      companyEmployees: [],
      daysOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      create_shift_text: 'Submit',
      create_shift_submit: false,
      edit_shift_text: 'Update',
      edit_shift_submit: false,
      create_shift_form: 0,
      create_holiday_form: false,
      grace_period: "None",
      shift_grace_period: "None",
      grace_period_list: [
        "None",
        "5 Minutes",
        "10 Minutes",
        "15 Minutes",
        "20 Minutes",
        "30 Minutes",
        "45 Minutes",
        "1 Hour"
      ],
      resumption_time: null,
      close_time: null,
      update_checkin_text: "Update",
      update_checkin_submit: false,
      update_checkin_text2: "Update Configuration",
      update_checkin_submit2: false,
      edit_holiday_dialog: false,
      holiday_creator: {
        id: '',
        title: '',
        start: '',
        end: '',
        description: '',
      },
      liday_updater: {
        id: '',
        title: '',
        start: '',
        end: '',
        description: '',
      },
      holiday_update_text: "Update",
      holiday_update_submit: false,
      holiday_create_text: "Submit",
      holiday_create_submit: false,
      name: "Danikoko",
      ips: [],
      create_ip_form: false,
      ip_to_create: "",
      shiftEnabled: false,
      refreshing_page: false,
      monthNames: [
        "All year",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      firstYear: "",
      yearSelected: "",
      monthSelected: "",
      yearSelected2: "",
      monthSelected2: ""
    };
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authUserData",
      "authCompanyAttendanceConfig",
      "authCompanyData",
      "authCompanyCandidates",
      "authCompanyDepartments",
      "authCompanyEmployees",
      "userType",
      "true_employees",
      "auth_applicants",
      "authCompanyAllBilling",
      "authCompanyPayRun",
      "authPayCalendars",
      "authEmployeePayCalendars",
      "authCheckinAllTime",
      "authCompanyHolidays",
      "authCompanyAllLeave",
      "authCompanyAllTimesheets",
      "authCompanyAllLeave",
      "authCompanyAllLeaveType",
      "authCompanyApprovedLeave",
      "authCompanyProjects",
      "authCompanyTasks",
      "authCompanyAssets",
      "authCompanyAllAssetType",
      "authCompanyAllVendors",
      "authCompanyAllCheckin"
    ]),
    topEarliestEmployees() {
      // let _this = this, legitEmployees = [], noCheckInEmployees = [];
      // legitEmployees = _this.employeesWithAvgTime.filter(e => e.avgTime);
      // noCheckInEmployees = _this.employeesWithAvgTime.filter(e => !e.avgTime);
      // legitEmployees.sort((a, b) => {
      //   return a.timeInMilliseconds - b.timeInMilliseconds
      // });
      // legitEmployees.splice(legitEmployees.length, 0, ...noCheckInEmployees);
      // return legitEmployees;

      let _this = this, result = [], dateToday = new Date().toDateString();
      _this.true_employees.forEach(e => {
        let employeeCheckins = _this.getEmployeeCheckinsEarly(e.id);
        let allIndexTrackerValue = 0;
        let newEmployeeObj
        employeeCheckins.forEach(c => {
          allIndexTrackerValue += c.indexTrackerValue
        });
        newEmployeeObj = {
          ...e,
          avgTime: _this.getAverageTime(e.id, _this.yearSelected, _this.monthSelected),
          timeInMilliseconds: new Date(dateToday + ' ' + _this.getAverageTime(e.id, _this.yearSelected, _this.monthSelected)).getTime(),
          avgIndexTrackerValue: (allIndexTrackerValue/employeeCheckins.length) || 0
        }
        result.splice(result.length, 0, newEmployeeObj);
      });
      result.sort((a, b) => {
        return b.avgIndexTrackerValue - a.avgIndexTrackerValue
      });
      return result;
    },
    topLatestEmployees() {
      // let _this = this, legitEmployees = [], noCheckInEmployees = [];
      // legitEmployees = _this.employeesWithAvgTime2.filter(e => e.avgTime);
      // noCheckInEmployees = _this.employeesWithAvgTime2.filter(e => !e.avgTime);
      // legitEmployees.sort((a, b) => {
      //   return b.timeInMilliseconds - a.timeInMilliseconds
      // });
      // legitEmployees.splice(legitEmployees.length, 0, ...noCheckInEmployees);
      // return legitEmployees;
      let _this = this, result = [], dateToday = new Date().toDateString();
      _this.true_employees.forEach(e => {
        let employeeCheckins = _this.getEmployeeCheckinsLate(e.id);
        let allIndexTrackerValue = 0;
        let newEmployeeObj
        employeeCheckins.forEach(c => {
          allIndexTrackerValue += c.indexTrackerValue
        });
        newEmployeeObj = {
          ...e,
          avgTime: _this.getAverageTime(e.id, _this.yearSelected2, _this.monthSelected2),
          timeInMilliseconds: new Date(dateToday + ' ' + _this.getAverageTime(e.id, _this.yearSelected2, _this.monthSelected2)).getTime(),
          avgIndexTrackerValue: (allIndexTrackerValue/employeeCheckins.length) || 0
        }
        result.splice(result.length, 0, newEmployeeObj);
      });
      result.sort((a, b) => {
        return a.avgIndexTrackerValue - b.avgIndexTrackerValue
      });
      return result;
    },
    topTardyEmployees() {
      let _this = this, result = [];
      result = _this.topLatestEmployees.filter(e => {
        return _this.displayOnTardyList(e.timeInMilliseconds);
      });
      return result;
    },
    employeesWithAvgTime() {
      let _this = this, result = [], dateToday = new Date().toDateString();
      result = _this.true_employees.map(te => {
        return {
          ...te,
          avgTime: _this.getAverageTime(te.id, _this.yearSelected, _this.monthSelected),
          timeInMilliseconds: new Date(dateToday + ' ' + _this.getAverageTime(te.id, _this.yearSelected, _this.monthSelected)).getTime()
        }
      });
      return result;
    },
    employeesWithAvgTime2() {
      let _this = this, result = [], dateToday = new Date().toDateString();
      result = _this.true_employees.map(te => {
        return {
          ...te,
          avgTime: _this.getAverageTime(te.id, _this.yearSelected2, _this.monthSelected2),
          timeInMilliseconds: new Date(dateToday + ' ' + _this.getAverageTime(te.id, _this.yearSelected2, _this.monthSelected2)).getTime()
        }
      });
      return result;
    },
    allYears: function () {
        let t = [],
            e = new Date().getFullYear();
        t.push(this.firstYear);
        for (let a = this.firstYear; a <= e; a++) if (t.indexOf(a) == -1) {t.push(a.toString());}
        return t.reverse();
    },
    month: function () {
        let t = new Date();
        return this.monthNames[t.getMonth()+1];
    },
    tasksInProgress() {
      let _this = this, result = [];
      result = _this.authCompanyTasks.filter(t => t.task_status.toLowerCase() == 'in progress');
      return result;
    },
    tasksDue() {
      let _this = this, result = [], dueProjects = [];
      dueProjects = _this.monthlyProjects.filter(p => {
        if (p.est_completion_date && p.status != 'Completed' && !p.completed_at) {
          return new Date().getTime() >= new Date(p.est_completion_date).getTime();
        }
      });
      dueProjects.forEach(p => {
        _this.getTasks(p.id).forEach(tp => {
          result.splice(result.length, 0, tp)
        });
      });
      return result;
    },
    monthlyProjects() {
      let _this = this, result = [], d = new Date();
      result = _this.authCompanyProjects.filter(p => new Date(p.created_at).getMonth() == d.getMonth() && new Date(p.created_at).getFullYear() == d.getFullYear());
      return result;
    },
    completedProjects() {
      let _this = this, result = [];
      result = _this.monthlyProjects.filter(p => {
        if (p.task_status) {
          return p.task_status == 'Completed'
        }
      });
      return result;
    },
    inCompletedProjects() {
      let _this = this, result = [];
      result = _this.monthlyProjects.filter(p => {
        if (p.task_status) {
          return p.task_status != 'Completed'
        }
      });
      return result;
    },
    pendingLeaveRequests() {
      let _this = this, result = [];
      result = _this.authCompanyAllLeave.filter(lv => lv.status == null);
      return result;
    },
    on_leave_today() {
      let _this = this;
      let leave_today = [];
      if (_this.authCompanyApprovedLeave) {
        _this.authCompanyApprovedLeave.forEach(leave => {
          let daylist = _this.getDaysArray(
            leave.leave_start,
            leave.leave_end
          );
          daylist.forEach(day => {
            if (
              new Date().toString().substr(0, 15) ==
              day.toString().substr(0, 15)
            ) {
              leave_today.push(leave);
            }
          });
        });
      }
      return leave_today;
    },
    topTwoLeaveTypes() {
      let _this = this, leaveTypes = [], result = [];
      _this.authCompanyAllLeaveType.forEach(lt => {
        let leaveTypesApproved = _this.authCompanyApprovedLeave.filter(al => al.leave_type == lt.id);
        leaveTypes.splice(leaveTypes.length, 0, {
          id: lt.id,
          leave_name: lt.leave_name,
          approvedCount: leaveTypesApproved.length
        });
      });
      result = leaveTypes.sort((a, b) => {
        return b.approvedCount - a.approvedCount
      });

      // result = [
      //   result[0],
      //   result[1]
      // ]
      return result;
    },
    transformedTimesheets() {
      let _this = this, result = [], d = new Date();
      result = _this.authCompanyAllTimesheets.filter(t => t.month == d.getMonth()-1 && t.year == d.getFullYear())
      return result;
    },
    submittedTimesheets() {
      let _this = this, result = [];
      _this.true_employees.forEach(te => {
        let timesheets = _this.employeeMonthlyTimesheets(te.id), submitted = [];
        if (timesheets.length >= 1) {
          submitted = timesheets.filter(t => t.is_submitted == 1);
          if (timesheets.length === submitted.length) {
            result.splice(result.length, 0, timesheets);
          }
        }
      });
      return result;
    },
    approvedTimesheets() {
      let _this = this, result = [];
      _this.true_employees.forEach(te => {
        let timesheets = _this.employeeMonthlyTimesheets(te.id), submitted = [];
        if (timesheets.length >= 1) {
          submitted = timesheets.filter(t => t.status == 1);
          if (timesheets.length === submitted.length) {
            result.splice(result.length, 0, timesheets);
          }
        }
      });
      return result;
    },
    disapprovedTimesheets() {
      let _this = this, result = [];
      _this.true_employees.forEach(te => {
        let timesheets = _this.employeeMonthlyTimesheets(te.id), submitted = [];
        if (timesheets.length >= 1) {
          submitted = timesheets.filter(t => t.status == 2);
          if (timesheets.length === submitted.length) {
            result.splice(result.length, 0, timesheets);
          }
        }
      });
      return result;
    },
    pendingTimesheets() {
      let _this = this, result = [];
      _this.true_employees.forEach(te => {
        let timesheets = _this.employeeMonthlyTimesheets(te.id), submitted = [];
        submitted = timesheets.filter(t => t.status == 1);
        if (timesheets.length !== submitted.length || timesheets.length === 0) {
          result.splice(result.length, 0, timesheets);
        }
      });
      return result;
    },
    allMonthlyCheckinInfo() {
      let d = new Date(), _this = this, result = [];
      let dateToCheck = new Date(Date.UTC(parseInt(d.getFullYear()), _this.monthNames.indexOf(_this.mixinCurrentMonth)));
      let daysToGet = [];
      while (dateToCheck.getUTCMonth() === _this.monthNames.indexOf(_this.mixinCurrentMonth)) {
          if (dateToCheck.getTime() > d.getTime()) {
              break;
          }
          daysToGet.push(new Date(dateToCheck).toString().substring(0,15));
          dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
      }
      daysToGet = daysToGet.filter(d => {
        return _this.companyWorkDays.indexOf(_this.getDayOfWeek(new Date(d).getDay())) != -1;
      });
      
      // _this.true_employees.forEach(tr => {
      //   let leaveDays = [], holidays = [];

      //   // BELOW IS TO GET DAYS ON LEAVE

      //   if (_this.employeeApprovedLeave(tr.id).length >= 1) {
      //     _this.employeeApprovedLeave(tr.id).forEach(lv => {
      //       _this.getDatesBetweenDates(new Date(lv.leave_start), new Date(lv.leave_end + ' 01:00:01')).forEach(d => {
      //         leaveDays.splice(leaveDays.length, 0, new Date(d).toString().substring(0,15))
      //       });
      //     });
      //   }

      //   // END DAYS ON LEAVE CALCULATION

      //   let checkInDays = _this.monthlyCheckinsByEmployee(tr.id).map(c =>  c.ms_time.toString().substring(0,15));

      //   _this.authCompanyHolidays.forEach(h => {
      //     _this.getDatesBetweenDates(new Date(h.start), new Date(h.end.replace('00:00:00', '01:00:00'))).forEach(dt => {
      //       holidays.splice(holidays.length, 0, new Date(dt).toString().substring(0,15))
      //     });
      //   });

      //   daysToGet.forEach(day => {
      //     if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) != -1 && _this.companyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
      //       if (checkInDays.indexOf(day) == -1) {
      //         result.splice(result.length, 0, {
      //           employee_id: tr.id,
      //           day: day,
      //           status: 'on leave'
      //         });
      //       } else {
      //         result.splice(result.length, 0, _this.monthlyCheckinsByEmployee(tr.id)[checkInDays.indexOf(day)]);
      //       }
      //     } else if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) == -1 && _this.companyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) { // THIS IS IF THE DAY IS NOT A HOLIDAY
      //       if (checkInDays.indexOf(day) == -1) {
      //         result.splice(result.length, 0, {
      //           employee_id: tr.id,
      //           day: day,
      //           status: 'absent'
      //         });
      //       } else {
      //         console.log('NOT ABSENT')
      //         result.splice(result.length, 0, _this.monthlyCheckinsByEmployee(tr.id)[checkInDays.indexOf(day)]);
      //       }
      //     } else {
      //       //
      //     }
      //   });  

      // });


      daysToGet.forEach(day => {
        _this.true_employees.forEach(tr => {
          let leaveDays = [], holidays = [];

          // BELOW IS TO GET DAYS ON LEAVE

          if (_this.employeeApprovedLeave(tr.id).length >= 1) {
            _this.employeeApprovedLeave(tr.id).forEach(lv => {
              _this.getDatesBetweenDates(new Date(lv.leave_start), new Date(lv.leave_end + ' 01:00:01')).forEach(d => {
                leaveDays.splice(leaveDays.length, 0, new Date(d).toString().substring(0,15))
              });
            });
          }

          // END DAYS ON LEAVE CALCULATION

          let checkInDays = _this.monthlyCheckinsByEmployee(tr.id).map(c =>  c.ms_time.toString().substring(0,15));

          _this.authCompanyHolidays.forEach(h => {
            _this.getDatesBetweenDates(new Date(h.start), new Date(h.end.replace('00:00:00', '01:00:00'))).forEach(dt => {
              holidays.splice(holidays.length, 0, new Date(dt).toString().substring(0,15))
            });
          });

          if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) != -1 && _this.companyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
            if (checkInDays.indexOf(day) == -1) {
              result.splice(result.length, 0, {
                employee_id: tr.id,
                employee_fristname: tr.employee_fristname,
                employee_lastname: tr.employee_lastname,
                department_id: tr.department_id,
                day: day,
                status: 'on leave'
              });
            } else {
              result.splice(result.length, 0, _this.monthlyCheckinsByEmployee(tr.id)[checkInDays.indexOf(day)]);
            }
          } else if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) == -1 && _this.companyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) { // THIS IS IF THE DAY IS NOT A HOLIDAY
            if (checkInDays.indexOf(day) == -1) {
              result.splice(result.length, 0, {
                employee_id: tr.id,
                employee_fristname: tr.employee_fristname,
                employee_lastname: tr.employee_lastname,
                department_id: tr.department_id,
                day: day,
                status: 'absent'
              });
            } else {
              result.splice(result.length, 0, _this.monthlyCheckinsByEmployee(tr.id)[checkInDays.indexOf(day)]);
            }
          } else {
            //
          }


        })

      }); 

      return result;
    },
    companyWorkDays() {
      return this.authCompanyAttendanceConfig.working_days.split(',');
    },
    monthlyCheckins() {
      let _this = this, result = [];
      result = _this.transformedCheckinAllTime.filter(ct => {
        return ct.month_name == _this.mixinCurrentMonth && ct.year == new Date().getFullYear();
      })
      return result;
    },
    finalMonthlyCheckins() {
      let _this = this; 
      let result = [], holidays = [], d = new Date();

      _this.true_employees.forEach(tr => {

        let leaveDays = [];

        // BELOW IS TO GET DAYS ON LEAVE

        if (_this.employeeApprovedLeave(tr.id).length >= 1) {
          _this.employeeApprovedLeave(tr.id).forEach(lv => {
            _this.getDatesBetweenDates(new Date(lv.leave_start), new Date(lv.leave_end + ' 01:00:01')).forEach(d => {
              leaveDays.splice(leaveDays.length, 0, new Date(d).toString().substring(0,15))
            });
          });
        }

        // END DAYS ON LEAVE CALCULATION
        
        let checkInDays = _this.monthlyCheckinsByEmployee(tr.id).map(c =>  c.ms_time.toString().substring(0,15));

        let dateToCheck = new Date(Date.UTC(parseInt(d.getFullYear()), _this.monthNames.indexOf(_this.mixinCurrentMonth)));
        let daysToGet = [];
        while (dateToCheck.getUTCMonth() === _this.monthNames.indexOf(_this.mixinCurrentMonth)) {
            if (dateToCheck.getTime() > d.getTime()) {
                break;
            }
            daysToGet.push(new Date(dateToCheck).toString().substring(0,15));
            dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
        }

        _this.authCompanyHolidays.forEach(h => {
          _this.getDatesBetweenDates(new Date(h.start), new Date(h.end.replace('00:00:00', '01:00:00'))).forEach(dt => {
            holidays.splice(holidays.length, 0, new Date(dt).toString().substring(0,15))
          });
        });

        daysToGet.forEach(day => {
          if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) != -1 && _this.companyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
            if (checkInDays.indexOf(day) == -1) {
              result.splice(result.length, 0, {
                employee_id: tr.id,
                day: day,
                status: 'on leave'
              });
            } else {
              result.splice(result.length, 0, _this.monthlyCheckinsByEmployee(tr.id)[checkInDays.indexOf(day)]);
            }
          } else if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) == -1 && _this.companyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) { // THIS IS IF THE DAY IS NOT A HOLIDAY
            if (checkInDays.indexOf(day) == -1) {
              result.splice(result.length, 0, {
                employee_id: tr.id,
                day: day,
                status: 'absent'
              });
            } else {
              result.splice(result.length, 0, _this.monthlyCheckinsByEmployee(tr.id)[checkInDays.indexOf(day)]);
            }
          } else {
            //
          }
        });  
      })

      

      return result;
    },
    monthlyEarlyCheckins() {
      let _this = this, result = [];
      result = _this.finalMonthlyCheckins.filter(mc => {
        return mc.status == 'early';
      })
      return result;
    },
    monthlyLateCheckins() {
      let _this = this, result = [];
      result = _this.finalMonthlyCheckins.filter(mc => {
        return mc.status == 'late';
      })
      return result;
    },
    monthlyAbsentCheckins() {
      let _this = this, result = [];
      result = _this.finalMonthlyCheckins.filter(mc => {
        return mc.status == 'absent';
      })
      return result;
    },
    highestMonthlyExpense() {
      let _this = this, result = 0, new_val = 0;
      _this.allMonthlyExpenses.forEach(val => {
        new_val = val;
        if (result < new_val) {
          result = new_val
        }
      })
      return result;
    },
    lastMonthExpenses() {
      let _this = this, lastMonthPR = [], result = 0;
      lastMonthPR = _this.approvedPayRuns.filter(p => {
        return p.month == _this.lastMonth.month && p.year == _this.lastMonth.year
      });
      lastMonthPR.forEach(lm => {
        result += parseInt(lm.total_net_pay)
      });
      return result;
    },
    totalExpenses() {
      let _this = this, result = 0;
      _this.approvedPayRuns.forEach(p => {
        result += parseInt(p.total_net_pay)
      })
      return result;
    },
    approvedPayRuns() {
      let _this = this, result = [];
      result = _this.authCompanyPayRun.filter(p => p.approved == 1)
      return result;
    },
    employee_id: function () {
      let t = this;
      if (t.employee_name.toLowerCase() != 'all') {
        return t.true_employees && t.employee_name
          ? t.true_employees.filter(function (e) {
                return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
            })[0].id
          : null;
      } else {
        return null;
      }
    },
    departmentNames() {
        let _this = this, result = [];
        result = _this.authCompanyDepartments.map(d => {
            return d.department_name
        });
        return result;
    },
    departmentEmployees() {
        let _this = this, result = [], employees = [];
        _this.authCompanyDepartments.forEach(d => {
            employees = _this.true_employees.filter(e => {
                return e.department_id == d.id
            });
            result.splice(result.length + 1, 0, employees.length);
        });
        return result;
    },
    maleEmployees() {
        let _this = this, result = [];
        result = _this.true_employees.filter(e => {
            if (e.employee_sex) {
                return e.employee_sex.toLowerCase() == 'male'
            }
        });
        return result;
    },
    femaleEmployees() {
        let _this = this, result = [];
        result = _this.true_employees.filter(e => {
            if (e.employee_sex) {
                return e.employee_sex.toLowerCase() == 'female'
            }
        });
        return result;
    },
    genderlessEmployees() {
        let _this = this, result = [];
        result = _this.true_employees.filter(e => {
            return !e.employee_sex;
        });
        return result;
    },
    allMonthlyExpenses() {
      let _this = this, result = [];
      result = [
        _this.monthlyExpenses('January'),
        _this.monthlyExpenses('February'),
        _this.monthlyExpenses('March'),
        _this.monthlyExpenses('April'),
        _this.monthlyExpenses('May'),
        _this.monthlyExpenses('June'),
        _this.monthlyExpenses('July'),
        _this.monthlyExpenses('August'),
        _this.monthlyExpenses('September'),
        _this.monthlyExpenses('October'),
        _this.monthlyExpenses('November'),
        _this.monthlyExpenses('December')
      ];
      return result;
    },
    transformedCheckinAllTime() {
      let _this = this, result = [], daysInYear = [];
      _this.monthNames.forEach(month => {
        if (month.toLowerCase() !== 'all year') {
          daysInYear.splice(daysInYear.length, 0, ...(_this.getDaysInMonth(_this.monthNames.indexOf(month) - 1, _this.yearSelected)))
        }
      });
      daysInYear.forEach(day => {
        let checkins = _this.getCheckInByDay(day);
        let mappedCheckIns = checkins.map((c, index) => {
          return {
            ...c,
            indexTrackerValue: (_this.true_employees.length - index)
          }
        });
        result.splice(result.length, 0, ...mappedCheckIns)
      });
      return result;
    },
    filteredTransformedCheckinAllTimeEarly() {
      let _this = this, result = [];
      if (_this.monthSelected.toLowerCase() === 'all year') {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected
        });
      } else {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected && t.month_name == _this.monthSelected
        });
      }
      return result;
    },
    filteredTransformedCheckinAllTimeLate() {
      let _this = this, result = [];
      if (_this.monthSelected2.toLowerCase() === 'all year') {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected2
        });
      } else {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected2 && t.month_name == _this.monthSelected2
        });
      }
      return result;
    }
  },
  methods: {
    ...mapActions([
      "fetch_company_departments",
      "fetch_active_company_employees",
      "fetch_company_attendance_configuration",
      "fetch_all_shifts",
      "fetch_all_holidays"
    ]),
    hasHistory: function () {
        return window.history.length > 2;
    },
    refresh_page() {
        let _this = this;
        _this.refreshing_page = true;
        _this.loadDepartmentsChart();
        _this.loadGenderChart();
        _this.loadPaymentChart();
        _this.loadTimesheetsPieChart();
        _this.loadTimesheetsBarChart();
        _this.refreshing_page = false;
    },
    displayOnTardyList(timeInMilliseconds) {
      let _this = this, dateToday = new Date().toDateString(), graceInMilli = (_this.authCompanyAttendanceConfig.grace_period || 0)*60*1000;
      let fullResumptionTimeInMilli = new Date(dateToday + ' ' + _this.authCompanyAttendanceConfig.resumption_time).getTime() + graceInMilli;
      return timeInMilliseconds > fullResumptionTimeInMilli;
    },
    getEmployeeCheckinsEarly(employee_id) {
      let _this = this, result = [];
      result = _this.filteredTransformedCheckinAllTimeEarly.filter(t => {
        return t.employee_id == employee_id
      })
      return result;
    },
    getEmployeeCheckinsLate(employee_id) {
      let _this = this, result = [];
      result = _this.filteredTransformedCheckinAllTimeLate.filter(t => {
        return t.employee_id == employee_id
      })
      return result;
    },
    getDaysInMonth(month, year) {
      let date = new Date(year, month, 1);
      let days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    getCheckInByDay(day) {
      let _this = this, result = [];
      result = _this.authCheckinAllTime.filter(checkin => {
        return checkin.day === new Date(day).toISOString().substring(0, 10);
      });
      return result;
    },
    
    getCheckInByDay2(day) {
      let _this = this, result = [];
      result = _this.transformedCheckinAllTime.filter(checkin => {
        return checkin.day === new Date(day).toISOString().substring(0, 10);
      });
      return result;
    },
    convertToAMPM(time) {
      let initSplit = time.split(' '), trueTime = time;
      if (initSplit.length === 2) {
        trueTime = initSplit[1];
      }
      let split = trueTime.split(':'), firstNumber = split[0], newFirstNumber = '', result = '';
      if (parseInt(firstNumber) >= 13) {
        newFirstNumber = firstNumber - 12;
      }
      result = firstNumber >= 13 ? (newFirstNumber < 10 ? '0' + newFirstNumber : newFirstNumber) + ':' + split[1] + ':' + split[2] + ' PM' : (firstNumber < 10 ? '0' + firstNumber : firstNumber) + ':' + split[1] + ':' + split[2] + ' AM';
      return result;
    },
    getAverageTime(employeeId, year, month) {
      let _this = this, filteredCheckIns = [];
      if (month.toLowerCase() === 'all year') {
        filteredCheckIns = _this.transformedCheckinAllTime.filter(c => {
          return c.employee_id == employeeId && c.year == year
        });
      } else {
        filteredCheckIns = _this.transformedCheckinAllTime.filter(c => {
          return c.employee_id == employeeId && c.year == year && c.month_name == month
        });
      }
      
      const times = filteredCheckIns.map(fc => _this.convertToAMPM(fc.check_in));

      let count = times.length
      if (!count) {
        return null;
      }
      let timesInSeconds = [];
      // loop through times
      for (let i = 0; i < count; i++) {
          // parse
          let pieces = times[i].split(':');
          let ampm = pieces[2].split(' ');
          let hrs = Number(pieces[0]);
          let mins = Number(pieces[1]);
          let secs = Number(ampm[0]);
          ampm = ampm[1];
          // convert to 24 hr format (military time)
          if (ampm == 'PM') hrs = hrs + 12;   
          // find value in seconds of time
          let totalSecs = hrs * 60 * 60;
          totalSecs += mins * 60;
          totalSecs += secs;
          // add to array
          timesInSeconds[i] = totalSecs;
      }
      // find average timesInSeconds
      let total = 0;
      for (let j =0; j < count; j++) {
          total = total + Number(timesInSeconds[j]);
      }
      let avg = Math.round(total / count);
      // turn seconds back into a time
      let avgMins = Math.floor(avg/60);
      let avgSecs = avg - (60 * avgMins);
      let avgHrs = Math.floor(avgMins/60);
      avgMins = avgMins - (60 * avgHrs);
      // convert back to 12 hr. format
      let avgAmpm = 'AM';
      if (avgHrs > 12) {
          avgAmpm = 'PM';
          avgHrs = avgHrs - 12;
      }
      // add leading zeros for seconds, minutes
      avgSecs = ('0' + avgSecs).slice(-2);
      avgMins = ('0' + avgMins).slice(-2);
      // your answer
      return avgHrs+':'+avgMins+':'+avgSecs+' '+avgAmpm;
    },
    // getAvgTime(employeeId, year, month) {
    //   let _this = this, filteredCheckIns = [], allTimeToAdd = 0, averageMilliseconds = 0
    //   if (month.toLowerCase() === 'all year') {
    //     filteredCheckIns = _this.transformedCheckinAllTime.filter(c => {
    //       return c.employee_id == employeeId && c.year == year
    //     });
    //     filteredCheckIns.forEach(c => {
    //       allTimeToAdd += new Date(c.ms_time).getTime();
    //     });
    //     averageMilliseconds = allTimeToAdd/filteredCheckIns.length;
    //   } else {
    //     filteredCheckIns = _this.transformedCheckinAllTime.filter(c => {
    //       return c.employee_id == employeeId && c.year == year && c.month_name == month
    //     });
    //     filteredCheckIns.forEach(c => {
    //       allTimeToAdd += new Date(c.ms_time).getTime();
    //     });
    //     averageMilliseconds = filteredCheckIns.length ? allTimeToAdd/filteredCheckIns.length : null;
    //   }
    //   return averageMilliseconds
    // },
    fetch_days_left(leave) {
      let _this = this, result = [], finalResult = 'Error in evaluation';
      if (_this.all_leave_remaining) {
        // return _this.all_leave_remaining.filter(lr => {
        //   return lr.id == leave.id
        // })[0].message
        result = _this.all_leave_remaining.filter(lr => {
          return lr.id == leave.id
        });
        if (result.length >= 1) {
          finalResult = result[0].message
        }
        return finalResult;
      } else {
        return 'Error in evaluation';
      }
    },
    get_type_name(type_id) {
      let _this = this;
      let type = _this.authCompanyAllLeaveType.filter(
        type => type.id == type_id
      );
      if (type.length) {
        return type[0].leave_name;
      } else {
        return null;
      }
    },
    getDaysArray(s, e) {
      let start = new Date(s), end = new Date(e), arr = [];
      for (
        let dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    },
    employeeMonthlyTimesheets(id) {
      let _this = this, result = [];
      if (_this.true_employees && _this.transformedTimesheets) {
        result = _this.transformedTimesheets.filter(t => t.employee_id == id);
      }
      return result;
    },
    employeeApprovedLeave(id) {
      let _this = this, result = [];
      if (_this.authCompanyAllLeave.length >= 1) {
        result = _this.authCompanyAllLeave.filter(lv => {
          return lv.employee_id == id && lv.status == 1;
        })
      }
      return result;
    },
    monthlyCheckinsByEmployee(id) {
      let _this = this, result = [];
      result = _this.monthlyCheckins.filter(mc => mc.employee_id == id);
      return result;
    },
    getDatesBetweenDates(startDate, endDate) {
      let dates = []
      //to avoid modifying the original date
      const theDate = new Date(startDate)
      while (theDate < endDate) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
      }
      return dates
    },
    getDayOfWeek(day) {
      return this.daysOfWeek[day];
    },
    monthlyExpenses(month) {
      let _this = this, lastMonthPR = [], result = 0;
      lastMonthPR = _this.approvedPayRuns.filter(p => {
        return p.month == month && p.year == new Date().getFullYear()
      });
      lastMonthPR.forEach(lm => {
        result += parseInt(lm.total_net_pay)
      });
      return result;
    },
    employeesInPayCalendar(id) {
      let _this = this, result = 0;
      if (_this.authEmployeePayCalendars.length >= 1) {
        result = _this.authEmployeePayCalendars.filter(pc => pc.paycalendar_id == id);
      }
      result.filter(r => result.indexOf(r) <= 11)
      return result;
    },
    get_dept_name(dept_id) {
      let _this = this, dept;
      if (_this.authCompanyDepartments) {
        dept = _this.authCompanyDepartments.filter(dept => {
          return dept.id == dept_id;
        });
      }
      if (dept.length >= 1) {
        return dept[0].department_name;
      }
      return null;
    },
    loadDepartmentsChart() {
        let _this = this;

        let departmentChartAreaElem = document.getElementById('departmentChartArea');

        if (departmentChartAreaElem) {
            let departmentChartArea = window.echarts.init(departmentChartAreaElem);
            departmentChartArea.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        shadowStyle: {
                            opacity: 0
                        }
                    }
                },
                grid: {
                    top: '8%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    data: _this.departmentNames,
                    axisLabel: {
                        inside: true,
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    z: 10
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#999'
                        }
                    },
                    splitLine: {
                        show: false
                    }
                },
                dataZoom: [{
                    type: 'inside'
                }],
                series: [
                    // {
                    //     // For shadow
                    //     name: 'Interested',
                    //     type: 'bar',
                    //     itemStyle: {
                    //         normal: {
                    //             color: 'rgba(0,0,0,0.05)'
                    //         }
                    //     },
                    //     barGap: '-100%',
                    //     barCategoryGap: '40%',
                    //     data: [500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500],
                    //     animation: false
                    // }, 
                    {
                        name: 'Employees',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#83bff6'
                                    }, 
                                    {
                                        offset: 0.5,
                                        color: '#069'
                                    }, 
                                    {
                                        offset: 1,
                                        color: '#069'
                                    }
                                ])
                            },
                            emphasis: {
                                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#069'
                                    }, 
                                    {
                                        offset: 0.7,
                                        color: '#069'
                                    }, 
                                    {
                                        offset: 1,
                                        color: '#83bff6'
                                    }
                                ])
                            }
                        },
                        data: _this.departmentEmployees
                    }
                ]
            });
            window.$(window).on('resize', function () {
                setTimeout(function () {
                    departmentChartArea.resize();
                }, 500);
            });
        }
    },
    loadGenderChart() {
      let _this = this;
      const genderChartAreaElem = document.getElementById("genderChartArea");
      if (genderChartAreaElem) {
            let genderChartArea = window.echarts.init(genderChartAreaElem);
            genderChartArea.setOption({
              color: ["#4caf50", "#ebcb37", "#c13018"],
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              yAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              series: [
                {
                  name: "Time Logs",
                  type: "pie",
                  radius: "70%",
                  center: ["50%", "50%"],
                  data: [
                    {
                      value: _this.maleEmployees.length,
                      name: "Male Employees (" + _this.maleEmployees.length + ")"
                    },
                    {
                      value: _this.femaleEmployees.length,
                      name: "Female Employees (" + _this.femaleEmployees.length + ")"
                    },
                    {
                      value: _this.genderlessEmployees.length,
                      name: "Unspecified (" + _this.genderlessEmployees.length + ")"
                    }
                  ],
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                  }
                }
              ]
            });
            window.$(window).on("resize", function() {
              setTimeout(function() {
                genderChartArea.resize();
              }, 500);
            });
          }
    },
    loadPaymentChart() {
      let _this = this;

      let paymentChartAreaElem = document.getElementById('paymentChartArea');

      if (paymentChartAreaElem) {
        let paymentChartArea = window.echarts.init(paymentChartAreaElem);
        paymentChartArea.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: true
            }
          },
          grid: {
            left: '4%',
            top: '4%',
            right: '3%',
            bottom: '10%'
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            axisLabel: {
              formatter: '{value}',
              color: '#666',
              fontSize: 12,
              fontStyle: 'normal',
              fontWeight: 400
            },
            axisLine: {
              lineStyle: {
                color: '#ccc',
                width: 1
              }
            },
            axisTick: {
              lineStyle: {
                color: '#ccc',
                width: 1
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#ccc',
                width: 1
              }
            }
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: _this.highestMonthlyExpense * 1.25,
            interval: _this.highestMonthlyExpense / 10,
            axisLabel: {
              formatter: '{value}',
              color: '#666',
              fontSize: 8,
              fontStyle: 'normal',
              fontWeight: 400
            },
            axisLine: {
              lineStyle: {
                color: '#ccc',
                width: 1
              }
            },
            axisTick: {
              lineStyle: {
                color: '#ccc',
                width: 1
              }
            },
            splitLine: {
              lineStyle: {
                color: '#ddd',
                width: 1,
                opacity: 0.5
              }
            }
          },
          series: [{
            name: 'Amount',
            type: 'line',
            smooth: true,
            data: _this.allMonthlyExpenses,
            symbolSize: 8,
            showSymbol: false,
            lineStyle: {
              color: 'rgb(255, 87, 33)',
              opacity: 1,
              width: 1.5
            },
            itemStyle: {
              show: false,
              color: '#ff5721',
              borderColor: '#ff5721',
              borderWidth: 1.5
            },
            areaStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: 'rgba(255, 87, 33, 1)'
                  }, {
                    offset: 0.3,
                    color: 'rgba(255, 87, 33, 0.7)'
                  }, {
                    offset: 1,
                    color: 'rgba(255, 87, 33, 0)'
                  }]
                }
              }
            }
          }]
        });
        window.$(window).on('resize', function () {
          setTimeout(function () {
            paymentChartArea.resize();
          }, 500);
        });
      }
    },
    loadTimesheetsPieChart() {
      let _this = this;
      const timesheetsPieChartAreaElem = document.getElementById("timesheetsPieChartArea");
      if (timesheetsPieChartAreaElem) {
            let timesheetsPieChartArea = window.echarts.init(timesheetsPieChartAreaElem);
            timesheetsPieChartArea.setOption({
              color: ["#4caf50", "#ebcb37", "#c13018"],
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              yAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              series: [
                {
                  name: "Time Logs",
                  type: "pie",
                  radius: "70%",
                  center: ["50%", "50%"],
                  data: [
                    {
                      value: _this.approvedTimesheets.length,
                      name: "Approved timesheets (" + _this.approvedTimesheets.length + ")"
                    },
                    {
                      value: _this.pendingTimesheets.length,
                      name: "Pending timesheets (" + _this.pendingTimesheets.length + ")"
                    },
                    {
                      value: _this.disapprovedTimesheets.length,
                      name: "Disapproved timesheets (" + _this.disapprovedTimesheets.length + ")"
                    }
                  ],
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                  }
                }
              ]
            });
            window.$(window).on("resize", function() {
              setTimeout(function() {
                timesheetsPieChartArea.resize();
              }, 500);
            });
          }
    },
    loadTimesheetsBarChart() {
        let _this = this;

        let timesheetsBarChartAreaElem = document.getElementById('timesheetsBarChartArea');

        if (timesheetsBarChartAreaElem) {
            let timesheetsBarChartArea = window.echarts.init(timesheetsBarChartAreaElem);
            timesheetsBarChartArea.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        shadowStyle: {
                            opacity: 0
                        }
                    }
                },
                grid: {
                    top: '8%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    data: ['Submitted', 'Approved', 'Disapproved', 'Pending'],
                    axisLabel: {
                        inside: true,
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    z: 10
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#999'
                        }
                    },
                    splitLine: {
                        show: false
                    }
                },
                dataZoom: [{
                    type: 'inside'
                }],
                series: [
                    // {
                    //     // For shadow
                    //     name: 'Interested',
                    //     type: 'bar',
                    //     itemStyle: {
                    //         normal: {
                    //             color: 'rgba(0,0,0,0.05)'
                    //         }
                    //     },
                    //     barGap: '-100%',
                    //     barCategoryGap: '40%',
                    //     data: [500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500],
                    //     animation: false
                    // }, 
                    {
                        name: 'Timesheets',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#83bff6'
                                    }, 
                                    {
                                        offset: 0.5,
                                        color: '#069'
                                    }, 
                                    {
                                        offset: 1,
                                        color: '#069'
                                    }
                                ])
                            },
                            emphasis: {
                                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#069'
                                    }, 
                                    {
                                        offset: 0.7,
                                        color: '#069'
                                    }, 
                                    {
                                        offset: 1,
                                        color: '#83bff6'
                                    }
                                ])
                            }
                        },
                        data: [
                          _this.submittedTimesheets.length,
                          _this.approvedTimesheets.length,
                          _this.disapprovedTimesheets.length,
                          _this.pendingTimesheets.length
                        ]
                    }
                ]
            });
            window.$(window).on('resize', function () {
                setTimeout(function () {
                    timesheetsBarChartArea.resize();
                }, 500);
            });
        }
    },
    launchPayroll() {
      let _this = this;
      _this.check_class('Payroll');
      let int2 = setInterval(() => {
        if (document.getElementById('paymentChartArea').innerHTML) {
          clearInterval(int2);
        } else {
          _this.loadPaymentChart();
        }
      }, 1000);
    },
    launchAttendance() {
      let _this = this;
      _this.check_class('Attendance');
      let int2 = setInterval(() => {
        if (document.getElementById('paymentChartArea').innerHTML) {
          clearInterval(int2);
        } else {
          _this.loadPaymentChart();
        }
      }, 1000);
    },
    launchTimesheets() {
      let _this = this;
      _this.check_class('Timesheets');
      let int2 = setInterval(() => {
        if (document.getElementById('timesheetsPieChartArea').innerHTML) {
          clearInterval(int2);
        } else {
          _this.loadTimesheetsPieChart();
          _this.loadTimesheetsBarChart();
        }
      }, 1000);
    },
    launchLeave() {
      let _this = this;
      _this.check_class('Absence or Leave');
    },
    launchProjects() {
      let _this = this;
      _this.check_class('Projects');
    },
    launchAssets() {
      let _this = this;
      _this.check_class('Assets');
    },
    check_all(id) {
      let _this = this, department = _this.selectableDepartments.filter(d => d.id == id)[0];
      
      if (_this.checkedEmployeesForPayCalendar(id).length !== _this.departmentalEmployees(id).length) {
        if (department.checked) {
          _this.departmentalEmployees(id).forEach(e => {
            e.checked = true;
            // department.checked = true;
          });
        } else {
          _this.departmentalEmployees(id).forEach(e => {
            e.checked = false;
            // department.checked = true;
          });
        }
        
      } else {
        if (department.checked) {
          _this.departmentalEmployees(id).forEach(e => {
            e.checked = true;
            // department.checked = false;
          });
        } else {
          _this.departmentalEmployees(id).forEach(e => {
            e.checked = false;
            // department.checked = false;
          });
        }
        
      }
    },
    checkedEmployeesForPayCalendar(id) {
      let _this = this, result = [];
      result = _this.departmentalEmployees(id).filter(e => e.checked);
      return result;
    },
    check_class(innerHTML) {
      document.querySelectorAll('.list-group-item-action').forEach((el) => {
          if (el.innerHTML.indexOf(innerHTML) == -1) {
              el.classList.remove('active');   
          }
      })
    },
    getEmployeeName(id) {
      let _this = this, employee = null;
      if (_this.true_employees.length >= 1) {
        employee = _this.true_employees.filter(te => {
          return te.id == id;
        })[0];
        return employee.employee_fristname + ' ' + employee.employee_lastname;
      } else {
        return 'Error in evaluation'
      }
    },
    getProjectV2(id) {
      let _this = this, result = [];
      if (_this.authCompanyProjects.length >= 1) {
        result = _this.authCompanyProjects.filter(p => {
          return p.id == id;
        })[0];
        return result;
      } else {
        return { title: 'Project not found' }
      }
    },
    getProject(id) {
      let _this = this, result = [];
      if (_this.monthlyProjects.length >= 1) {
        result = _this.monthlyProjects.filter(p => {
          return p.id == id;
        })[0];
        return result;
      } else {
        return { title: 'Project not found' }
      }
    },
    getTasks(id) {
      let _this = this, result = [];
      if (_this.authCompanyTasks.length >= 1) {
        result = _this.authCompanyTasks.filter(t => {
          return t.project_id == id;
        });
        return result;
      } else {
        //
      }
    }
  },
  mounted() {
    let _this = this;
    document.title = "Kylian ERP - Reports";
    _this.firstYear = new Date(_this.authCompanyData.created_at).getFullYear().toString();
    this.yearSelected = new Date().getFullYear().toString();
    this.monthSelected = this.monthNames[new Date().getMonth()+1];
    this.yearSelected2 = new Date().getFullYear().toString();
    this.monthSelected2 = this.monthNames[new Date().getMonth()+1];
    _this.true_employees.forEach(function (e) {
        e.user_id != _this.authCompanyData.id && _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
    });

    _this.companyEmployees = _this.true_employees.map(e => {
      return {
        id: e.id,
        employee_fristname: e.employee_fristname,
        employee_lastname: e.employee_lastname,
        department_id: e.department_id,
        employee_email: e.employee_email,
        checked: false
      }
    });

    let int = setInterval(() => {
      if (document.getElementById('departmentChartArea').innerHTML) {
        clearInterval(int)
      } else {
        _this.loadDepartmentsChart();
        _this.loadGenderChart();
      }
    }, 1000);
    // let int2 = setInterval(() => {
    //   if (document.getElementById('paymentChartArea').innerHTML) {
    //     clearInterval(int2)
    //     console.log('PAYMENT NOT LOADED')
    //   } else {
    //     _this.loadPaymentChart();
    //     console.log('PAYMENT LOADED')
    //   }
    // }, 1000);

    if (_this.authCompanyAllLeave.length >= 1) {
      _this.authCompanyAllLeave.forEach(lv => {
        axios
          .post(`${BASE_URL}leaveremain`, {
            company_id: _this.authCompanyData.id,
            employee_id: lv.employee_id,
            // leave_type: lv.leave_type,
            leavetypes: [
              {
                id: lv.leave_type,
                year: new Date().getFullYear()
              }
            ]
          })
          .then(function(response) {
            _this.all_leave_remaining.push({id: lv.id, message: response.data.message});
          })
          .catch(function() {
            _this.all_leave_remaining.push({id: lv.id, message: 'Error in evaluation'});
          })
          .then(function() {
            //
          });
      });
    } else {
      //
    }
  }
};
</script>

<style scoped>
/* @media (max-width: 991px) {
  #genderChartArea div canvas {
    top: -100px !important;
  }
} */
@media (min-width: 992px) {
  #genderChartArea div canvas {
    top: 0px !important;
  }
}

.collapse:not(.show) {
  display: none !important;
}
</style>
<style>
.v-time-picker-clock__item--active {
  color: #fff !important;
  font-size: -webkit-xxx-large !important;
}

[class="v-picker__title primary"] {
  background-color: #069 !important;
}

.accent {
  background: #069 !important;
}

/* .theme--light.v-card {
  background-color: #069 !important;
} */
.create-ip:hover {
  color: #069;
}

.ip-address div .v-text-field__details {
  display: none;
}

.ip-address div .v-input__slot {
  margin-bottom: 0px !important;
}

.ip-address .v-input__control {
  min-height: 35px !important;
}
</style>
