<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div
        :class="checked_people.length >= 1 && ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? 'px-2' : 'pl-4'"
        class="main-header" style="z-index: 60; align-content: space-around;">
        <template v-if="checked_people.length >= 1">
          <div v-if="candidateView" class="dropdown animate__animated animate__fadeIn">
            <button type="button" class="btn btn-danger m-1 text-white btn-raised ripple" @click="delete_employee()">
              Delete
            </button>
            <button type="button" class="btn btn-secondary m-1 btn-raised ripple"
              @click="convertLoading ? null : convert_to_employee()">
              {{ convertTxt }}
              <v-icon :style="
                !convertLoading
                  ? 'display: none'
                  : 'font-size: 16px; color: #fff; padding: 0px 58.5px;'
              ">
                fal fa-circle-notch fa-spin
              </v-icon>
            </button>
          </div>
          <div v-else class="dropdown animate__animated animate__fadeIn">
            <button type="button" class="btn btn-danger m-1 text-white btn-raised ripple" @click="delete_employee()">
              Delete
            </button>
            <button v-if="showDisable" type="button" class="btn btn-secondary m-1 btn-raised ripple"
              @click="disableLoading ? null : disable_employee()">
              {{ disableTxt }}
              <v-icon :style="
                !disableLoading
                  ? 'display: none'
                  : 'font-size: 16px; color: #fff; padding: 0px 44px;'
              ">
                fal fa-circle-notch fa-spin
              </v-icon>
            </button>
            <button v-if="showEnable" type="button" class="btn btn-secondary m-1 btn-raised ripple"
              @click="enableLoading ? null : enable_employee()">
              {{ enableTxt }}
              <v-icon :style="
                !enableLoading
                  ? 'display: none'
                  : 'font-size: 16px; color: #fff; padding: 0px 43px;'
              ">
                fal fa-circle-notch fa-spin
              </v-icon>
            </button>
          </div>
          <div style="margin: auto"></div>
          <div :class="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'pr-5' : null"
            class="header-part-right">
            <!-- <div class="dropdown">
                                <button class="btn ripple btn-raised-secondary m-1" type="button"><span class="ul-btn__icon"><i class="i-Refresh"></i></span></button>
                            </div> -->
            <!-- Grid menu Dropdown -->
            <!-- <div class="dropdown animate__animated animate__fadeIn">
              <i
                @click="checked = !checked"
                style="cursor: pointer"
                class="i-Close mr-1"
              ></i>
            </div> -->
            <!-- User avatar dropdown -->
          </div>
        </template>

        <template v-else>
          <div class="row animate__animated animate__fadeIn">
            <div class="logo col-md-3 py-0">
              <div class="input-group-prepend">
                <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <span
                    :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                        candidateView ? "Candidate View" : "Employee View"
                    }}</span>
                </button>
                <div v-if="permissionIsAssigned('candidate-read') && permissionIsAssigned('employee-read')"
                  class="dropdown-menu" style="min-width: 13rem; margin-left: 45px;">
                  <a @click="candidateView = !candidateView" class="dropdown-item" style="cursor: pointer;">
                    {{ candidateView ? "Employee View" : "Candidate View" }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style="margin: auto"></div>
          <div :class="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'pr-5' : null"
            class="header-part-right">
            <!-- <div class="dropdown">
                            <button class="btn ripple btn-raised-secondary m-1" type="button"><span class="ul-btn__icon"><i class="i-Refresh"></i></span></button>
                        </div> -->
            <!-- Grid menu Dropdown -->
            <div class="dropdown animate__animated animate__fadeIn">
              <button v-if="candidateView && permissionIsAssigned('candidate-create')" type="button"
                class="btn btn-outline-secondary m-1 ripple" @click="$router.push({ name: 'AddCandidate' })">
                Add Candidate
              </button>
              <button
                v-if="candidateView && permissionIsAssigned('candidate-create') && ($vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm')"
                type="button" class="btn btn-outline-secondary m-1 ripple" @click="dialog = true">
                Bulk Upload
              </button>
              <v-text-field
                v-if="!candidateView && ($vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm')"
                v-model="filters.string" solo dense placeholder="Search through employees"
                messages="Search by name, department, designation, etc" class="search-employees"></v-text-field>
            </div>
            <!-- User avatar dropdown -->
          </div>
        </template>
      </div>

      <!-- ======== Body content start ========= -->
      <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null"
        class="main-content">
        <div class="card" style="border-radius: 10px;">
          <div class="card-body px-1 py-1 pt-0 pr-0">
            <div class="table-responsive"
              :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'height: 75vh;' : 'height: 75vh;'"
              style="border-radius: 10px;
                                  overflow-y: scroll;
                                  padding-top: 0;
                                  padding-bottom: 0;">
              <table class="table">
                <thead>
                  <tr>
                    <th v-if="permissionIsAssigned('candidate-update')" scope="col" style="width: 5%; border-top: none;"
                      :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'padding: 0px 10px 20px 15px;' : 'padding: 0px 10px 20px 30px;'">
                      <label data-v-ea551cb4="" class="checkbox checkbox-secondary">
                        <input data-v-ea551cb4="" type="checkbox" :checked="all_checked"
                          @change="allCheckboxesFunc()" />
                        <span data-v-ea551cb4="" class="checkmark"></span>
                      </label>
                    </th>
                    <th class="text-left" style="border-top: none;" scope="col">First name</th>
                    <th class="text-left" style="border-top: none;" scope="col">Last name</th>
                    <th class="text-left" style="border-top: none;" scope="col">Email ID</th>
                    <th class="text-left" style="border-top: none;" scope="col">Official Email</th>
                    <th class="text-left" style="border-top: none;" scope="col">Source of hire</th>
                    <th class="text-center" style="border-top: none;" v-if="!candidateView" scope="col">Status</th>
                    <th class="text-left" scope="col" style="border-top: none;">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="candidateView">
                  <tr v-for="(person) in company_candidates" :key="person.id">
                    <th v-if="permissionIsAssigned('candidate-update')" scope="row" style="padding-left: 2.5%;">
                      <label class="checkbox checkbox-secondary">
                        <input type="checkbox" :checked="person.selected" v-model="person.selected" />
                        <span class="checkmark"></span>
                      </label>
                    </th>
                    <td class="text-left">{{ person.firstname }}</td>
                    <td class="text-left">{{ person.lastname }}</td>
                    <td class="text-left">
                      <a :href="'mailto:' + person.email">{{ person.email }}</a>
                    </td>
                    <td class="text-left">
                      <a :href="'mailto:' + person.email">{{ person.email }}</a>
                    </td>
                    <td class="text-left" v-if="person.source_of_hire">{{ person.source_of_hire }}</td>
                    <td class="text-left" v-else>
                      <i>
                        None specified
                      </i>
                    </td>
                    <td style="padding-top: 13px;" class="text-left employee-options">
                      <div class="row">
                        <i class="col-3 py-0 text-left fal fa-eye mt-3"
                          style="color: #069 !important; font-size: 125%; cursor: pointer;" @click="
                            $router.push({
                              name: 'ViewCandidate',
                              params: { id: person.id }
                            })
                          "></i>
                        <i v-if="permissionIsAssigned('candidate-update')" class="col-3 py-0 text-left fal fa-edit mt-3"
                          style="color: #069 !important; font-size: 125%; cursor: pointer;" @click="
                            $router.push({
                              name: 'EditCandidate',
                              params: { id: person.id }
                            })
                          "></i>
                        <i v-if="permissionIsAssigned('candidate-delete')"
                          class="col-3 py-0 text-left fal fa-trash-alt mt-3"
                          style="color: #069 !important; font-size: 125%; cursor: pointer;"
                          @click="(employee_id = person.id, delete_inline())"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <template class="text-left" v-for="person in filteredEmployees">
                    <tr :key="person.id" v-if="person.user_type != 1">
                      <th v-if="permissionIsAssigned('employee-update')" scope="row" class="checkbox-shift"
                        style="padding-left: 2.5%;">
                        <label class="checkbox checkbox-secondary">
                          <input type="checkbox" :checked="person.selected" v-model="person.selected" />
                          <span class="checkmark"></span>
                        </label>
                      </th>
                      <td class="text-left">{{ person.firstname }}</td>
                      <td class="text-left">{{ person.lastname }}</td>
                      <td class="text-left">
                        <a :href="'mailto:' + person.email">{{ person.email }}</a>
                      </td>
                      <td class="text-left">
                        <a :href="'mailto:' + person.email">{{ person.email }}</a>
                      </td>
                      <td class="text-left" v-if="person.source_of_hire">{{ person.source_of_hire }}</td>
                      <td class="text-left" v-else>
                        <i>
                          None specified
                        </i>
                      </td>
                      <td class="text-center">
                        <span v-if="person.is_active == 0" class="badge badge-pill badge-primary"
                          style="padding: 0.5rem 0.8rem;">
                          {{ " " }}Active{{ " " }}
                        </span>
                        <span v-else class="badge badge-pill badge-light p-2">Inactive</span>
                      </td>
                      <td style="padding-top: 17px;" class="text-left employee-options">
                        <div class="row">
                          <i class="col-3 py-0 text-left fal fa-eye mt-3"
                            style="color: #069 !important; font-size: 125%; cursor: pointer;" @click="
                              $router.push({
                                name: 'ViewEmployee',
                                params: { id: person.id }
                              })
                            "></i>
                          <i v-if="permissionIsAssigned('employee-update')"
                            class="col-3 py-0 text-left fal fa-edit mt-3"
                            style="color: #069 !important; font-size: 125%; cursor: pointer;" @click="
                              $router.push({
                                name: 'EditEmployee',
                                params: { id: person.id }
                              })
                            "></i>
                          <i v-if="permissionIsAssigned('employee-delete')"
                            class="col-3 py-0 text-left fal fa-trash-alt mt-3"
                            style="color: #069 !important; font-size: 125%; cursor: pointer;"
                            @click="(employee_id = person.id, delete_inline())"></i>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <v-row justify="center">
        <v-dialog v-model="confirmation_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Confirmation box</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint pb-0">
                        <span>Deleting an employee will remove all data tied to the employee. Check the box below to
                          confirm your decision</span>
                      </v-col>
                      <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint pb-0">
                        <v-checkbox label="I agree with the terms and conditions" style="text-indent: 5px;" color="#069"
                          class="d-inline-block" v-model="confirmation_checkbox">
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="confirmation_checkbox && true_delete_inline(employee_id)" style="width: 100%;"
                          type="button" class="btn btn-secondary m-1 ripple">
                          Submit
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="confirmation_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmation_dialog_2" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Confirmation box</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint pb-0">
                        <span>Deleting these employees will remove all data tied to the employees. Check the box below
                          to confirm your decision</span>
                      </v-col>
                      <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint pb-0">
                        <v-checkbox label="I agree with the terms and conditions" style="text-indent: 5px;" color="#069"
                          class="d-inline-block" v-model="confirmation_checkbox_2">
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="confirmation_checkbox_2 && true_delete_employee()" style="width: 100%;"
                          type="button" class="btn btn-secondary m-1 ripple">
                          Submit
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="confirmation_dialog_2 = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Upload Candidates in Bulk</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint pb-0">
                        <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                          label="Upload Candidates List" solo style="text-indent: 5px;" color="#069" class="bulkUpload"
                          messages="Upload Document">
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="remove-hint text-right pt-0">
                        <a href="/assets/excel_sheets/sample_candidates.csv" download>Download template document</a>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="bulk_upload_submit ? null : candidates_bulk_upload()" style="width: 100%;"
                          type="button" class="btn btn-secondary m-1 ripple">
                          {{ bulk_upload_text }}
                          <v-icon :style="
                            !bulk_upload_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fas fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="payment_summary_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Cost breakdown</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>

                      <v-col cols="6" md="3">Calculation</v-col>
                      <v-col cols="6" md="9">
                        For all {{ companyServices.length }} modules [Price * No. months left in subscription ({{
                            (months_left == 0 ? 1 : months_left)
                        }})] * No. candidates to convert ({{ number_to_pay_for }})
                      </v-col>

                      <v-col cols="6" md="3">Subtotal</v-col>
                      <v-col cols="6" md="9">₦{{ (monthly_services_amount * number_to_pay_for) |
                          number_with_commas
                      }}</v-col>

                      <v-col cols="6" md="3">VAT</v-col>
                      <v-col cols="6" md="9">₦{{ VAT_FEE | number_with_commas }}</v-col>

                      <v-col cols="6" md="3">Total</v-col>
                      <v-col cols="6" md="9">₦{{ (VAT_FEE + (monthly_services_amount * number_to_pay_for)) |
                          number_with_commas
                      }}</v-col>

                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="make_payment()" style="width: 100%;" type="button"
                          class="btn btn-secondary m-1 ripple">
                          Pay securely
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="payment_summary_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      employee_id: null,
      confirmation_checkbox: false,
      confirmation_dialog: false,
      confirmation_checkbox_2: false,
      confirmation_dialog_2: false,
      payment_summary_dialog: false,
      filters: {
        string: ''
      },
      bulk_upload_text: 'Upload',
      bulk_upload_submit: false,
      dialog: false,
      convertLoading: false,
      convertTxt: "Convert to Employee(s)",
      enableLoading: false,
      enableTxt: "Enable Account(s)",
      disableLoading: false,
      disableTxt: "Disable Account(s)",
      candidateView: true,
      company_employees: [],
      allCheckboxes: false,
      company_candidates: []
    };
  },
  watch: {
    authCompanyCandidates() {
      this.company_candidates = this.authCompanyCandidates.map(candidate => {
        return {
          id: candidate.id,
          firstname: candidate.employee_fristname,
          lastname: candidate.employee_lastname,
          email: candidate.employee_email,
          source_of_hire: candidate.source_of_hire,
          selected: false,
          viewOptions: true
        };
      });
    },
    authCompanyEmployees() {
      this.company_employees = this.true_employees.map(employee => {
        return {
          id: employee.id,
          user_id: employee.user_id,
          firstname: employee.employee_fristname,
          lastname: employee.employee_lastname,
          email: employee.employee_email,
          selected: false,
          viewOptions: true,
          is_active: employee.is_active
        };
      });
    }
  },
  computed: {
    ...mapState([
      "paystackKey",
      "userToken",
      "VAT"
    ]),
    ...mapGetters([
      "validEmployeeId",
      "authCompanyEmployees",
      "authCompanyCandidates",
      "authCompanyData",
      "authCompanyDepartments",
      "true_employees",
      "number_of_employees",
      "months_left",
      "authCompanyServices",
      "authUserData",
      "trial_version",
      "authAllAvailableServices"
    ]),
    VAT_FEE() {
      let _this = this, result = 0
      result = (_this.VAT / 100) * (_this.monthly_services_amount * _this.number_to_pay_for);
      return result;
    },
    number_to_pay_for() {
      let _this = this, result = 0;
      result = _this.ids_to_work_on.length - (_this.number_of_employees - _this.true_employees.length);
      if (result < 1) {
        result = 0;
      }
      return result;
    },
    monthly_services_amount() {
      let _this = this, result = 0;
      _this.authCompanyServices.forEach(service => {
        result += (parseFloat(service.price) * (_this.months_left == 0 ? 1 : _this.months_left));
      });
      return result;
    },
    companyServices() {
      return this.authAllAvailableServices.human_resources_services;
    },
    filteredEmployees() {
      // I NEED TO ADD FILTER FOR EMPLOYEE DESIGNATION
      let _this = this, employees = [], isTrue = null;
      if (!_this.filters.string) {
        return _this.company_employees;
      }

      if (_this.true_employees) {
        employees = _this.true_employees.filter(e => {
          isTrue = (
            e.employee_fristname.toLowerCase().indexOf(_this.filters.string.toLowerCase()) != -1
            ||
            e.employee_lastname.toLowerCase().indexOf(_this.filters.string.toLowerCase()) != -1
            ||
            _this.get_dept_name(e.department_id).toLowerCase().indexOf(_this.filters.string.toLowerCase()) != -1
            ||
            e.employee_email.toLowerCase().indexOf(_this.filters.string.toLowerCase()) != -1
          )
          return isTrue;
        });
      }

      employees = employees.map(employee => {
        return {
          id: employee.id,
          user_id: employee.user_id,
          firstname: employee.employee_fristname,
          lastname: employee.employee_lastname,
          email: employee.employee_email,
          selected: false,
          viewOptions: true,
          is_active: employee.is_active
        };
      });

      return employees
    },
    showEnable() {
      let _this = this, all_enabled = [];
      if (_this.ids_to_work_on.length == _this.company_employees.length) {
        all_enabled = _this.company_employees.filter(e => {
          return e.is_active == 0
        });
        if (all_enabled.length == _this.company_employees.length) {
          return false
        }
        return true
      }

      all_enabled = _this.checked_people.filter(e => {
        return e.is_active == 0
      });
      if (all_enabled.length == _this.checked_people.length) {
        return false
      }
      return true
    },
    showDisable() {
      let _this = this, all_disabled = [];
      if (_this.ids_to_work_on.length == _this.company_employees.length) {
        all_disabled = _this.company_employees.filter(e => {
          return e.is_active != 0
        });
        if (all_disabled.length == _this.company_employees.length) {
          return false
        }
        return true
      }

      all_disabled = _this.checked_people.filter(e => {
        return e.is_active != 0
      });
      if (all_disabled.length == _this.checked_people.length) {
        return false
      }
      return true
    },
    ids_to_work_on() {
      return this.checked_people.map(checked => {
        return {
          id: checked.id
        };
      });
    },
    checked_people() {
      if (this.candidateView) {
        return this.company_candidates.filter(candidate => {
          return candidate.selected == true;
        });
      } else {
        return this.company_employees.filter(employee => {
          return employee.selected == true;
        });
      }
    },
    all_checked() {
      if (this.candidateView) {
        if (this.company_candidates.length) {
          return this.company_candidates.length == this.checked_people.length;
        } else {
          return false;
        }
      } else {
        if (this.company_employees.length) {
          return this.company_employees.length == this.checked_people.length;
        } else {
          return false;
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "fetch_candidates",
      "fetch_employees",
      "fetch_company_employees",
      "refresh_company_data",
      "fetch_all_company_billing"
    ]),
    get_dept_name(dept_id) {
      let _this = this, dept;
      if (!dept_id) {
        return 'No assigned department';
      }
      if (_this.authCompanyDepartments) {
        dept = _this.authCompanyDepartments.filter(dept => {
          return dept.id == dept_id;
        });
      }
      if (dept.length >= 1) {
        return dept[0].department_name;
      }
      return 'No assigned department';
    },
    candidates_bulk_upload() {
      // THIS HANDLES THE BULK UPLOAD OF CANDIDATES
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let bulkUploadData = new FormData();
      bulkUploadData.append(
        "company_id",
        _this.authCompanyData.id
      );
      bulkUploadData.append(
        "company_unique_id",
        _this.authCompanyData.company_unique_id
      );
      bulkUploadData.append(
        "file",
        document.querySelector(".bulkUpload div div div input").files[0]
      );

      _this.bulk_upload_text = '';
      _this.bulk_upload_submit = true;

      axios
        .post(`${BASE_URL}importcandidate`, bulkUploadData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Candidates added successfully"
          });
          _this.fetch_candidates();
          _this.dialog = false;
        })
        .catch(function (error) {
          if (window.navigator.onLine) {
            let trackedError = 'There was an error in adding the candidates';
            Toast.fire({
              icon: "error",
              title: trackedError
            });
            console.log(error.response);
          } else {
            Toast.fire({
              icon: "error",
              title: "Please check your internet connectivity"
            });
          }
        })
        .then(function () {
          _this.bulk_upload_submit = false;
          _this.bulk_upload_text = 'Submit';
          // context.commit('AUTH_FETCHED_EMPLOYEES', );
        });
    },
    delete_inline() {
      let _this = this;

      _this.$swal
        .fire({
          title: _this.candidateView
            ? "Are you sure you want to delete the candidate?"
            : "Are you sure you want to delete the employee?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            _this.confirmation_dialog = true;
          }
        });
    },
    true_delete_inline(id) {
      let _this = this;
      _this.confirmation_dialog = false;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}deleteemployee`, {
          ids: [
            {
              id
            }
          ]
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: _this.candidateView
              ? "Candidate deleted successfully"
              : "Employee deleted successfully"
          });
          _this.fetch_candidates();
          _this.fetch_company_employees();
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: _this.candidateView
              ? "Could not delete the candidate"
              : "Could not delete the employee"
          });
        })
        .then(function () {
          //
        });
    },
    delete_employee() {
      let _this = this;
      let number_of_employees = _this.ids_to_work_on.length;
      _this.confirmation_dialog_2 = false;

      _this.$swal
        .fire({
          title:
            number_of_employees >= 2
              ? _this.candidateView
                ? "Are you sure you want to delete the candidates?"
                : "Are you sure you want to delete the employees?"
              : _this.candidateView
                ? "Are you sure you want to delete the candidate?"
                : "Are you sure you want to delete the employee?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            _this.confirmation_dialog_2 = true;

          }
        });
    },
    true_delete_employee() {
      let _this = this;
      let number_of_employees = _this.ids_to_work_on.length;
      _this.confirmation_dialog_2 = false;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}deleteemployee`, {
          ids: _this.ids_to_work_on
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title:
              number_of_employees >= 2
                ? _this.candidateView
                  ? "Deleted the candidates successfully"
                  : "Deleted the employees successfully"
                : _this.candidateView
                  ? "Deleted the candidate successfully"
                  : "Deleted the employee successfully"
          });
          _this.fetch_candidates();
          _this.fetch_company_employees();
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "Could not delete employees"
          });
        })
        .then(function () {
          //
        });
    },
    convert_to_employee() {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: _this.ids_to_work_on.length == 1 ? "Are you sure you want to convert the candidate to an employee?" : "Are you sure you want to convert the candidates to employees?",
          showCancelButton: true,
          confirmButtonText: `Yes, Convert`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            if (_this.number_to_pay_for) {
              if (_this.trial_version) {
                // const Toast = _this.$swal.mixin({
                //   toast: true,
                //   position: "top-end",
                //   showConfirmButton: false,
                //   timer: 2000,
                //   timerProgressBar: true,
                //   didOpen: toast => {
                //     toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                //     toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                //   }
                // });

                _this.convertTxt = "";
                _this.convertLoading = true;

                axios
                  .post(`${BASE_URL}converttoemployee`, {
                    ids: _this.ids_to_work_on,
                    employeecount: _this.number_to_pay_for,
                    company_id: _this.authCompanyData.id
                  })
                  .then(function () {
                    Toast.fire({
                      icon: "success",
                      title: "Candidate conversion successful"
                    });
                    _this.fetch_candidates();
                    _this.fetch_company_employees();
                    _this.refresh_company_data({ token: _this.userToken, user_id: _this.authUserData.id, company_id: _this.authCompanyData.id });
                  })
                  .catch(function () {
                    Toast.fire({
                      icon: "error",
                      title: "Candidate conversion failed"
                    });
                  })
                  .then(function () {
                    _this.convertLoading = false;
                    if (_this.ids_to_work_on.length == 1) {
                      _this.convertTxt = "Convert to employee";
                    } else {
                      _this.convertTxt = "Convert to employees";
                    }
                  });
              } else {
                _this.$swal
                  .fire({
                    title: "You have used up your employee slots. Do you want to pay for " + (_this.number_to_pay_for == 1 ? 'an ' : '') + "additional" + (_this.number_to_pay_for != 1 ? ' ' + _this.number_to_pay_for.toString() : '') + (_this.number_to_pay_for == 1 ? ' slot?' : ' slots?'),
                    showCancelButton: true,
                    confirmButtonText: `Proceed to Pay`
                  })
                  .then(result => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      _this.payment_summary_dialog = true;
                    }
                  });
              }
            } else {
              const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
              });

              _this.convertTxt = "";
              _this.convertLoading = true;

              axios
                .post(`${BASE_URL}converttoemployee`, {
                  ids: _this.ids_to_work_on,
                  employeecount: _this.number_to_pay_for,
                  company_id: _this.authCompanyData.id
                })
                .then(function () {
                  Toast.fire({
                    icon: "success",
                    title: "Candidate conversion successful"
                  });
                  _this.fetch_candidates();
                  _this.fetch_company_employees();
                  _this.refresh_company_data({ token: _this.userToken, user_id: _this.authUserData.id, company_id: _this.authCompanyData.id });
                })
                .catch(function () {
                  Toast.fire({
                    icon: "error",
                    title: "Candidate conversion failed"
                  });
                })
                .then(function () {
                  _this.convertLoading = false;
                  if (_this.ids_to_work_on.length == 1) {
                    _this.convertTxt = "Convert to employee";
                  } else {
                    _this.convertTxt = "Convert to employees";
                  }
                });
            }
          }
        })
    },
    make_payment() {
      let _this = this;
      let letters = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ];
      let ref = "";
      for (let i = 0; i <= 10; i++) {
        if (i === 5) {
          ref += new Date().getTime();
          continue;
        }
        // ref += Math.floor(letters[Math.random() * letters.length])
        ref += letters[
          Math.floor(Math.random() * letters.length)
        ].toUpperCase();
      }
      let handler = window.PaystackPop.setup({
        key: _this.paystackKey, // Replace with your public key
        email: _this.authUserData.email,
        amount: (_this.VAT_FEE + (_this.monthly_services_amount * _this.number_to_pay_for)) * 100, //document.getElementById("amount").value * 100,
        ref, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        // label: "Optional string that replaces customer email"
        callback: function () {
          const Toast = _this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", _this.$swal.stopTimer);
              toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
            }
          });

          _this.convertTxt = "";
          _this.convertLoading = true;

          axios
            .post(`${BASE_URL}converttoemployee`, {
              ids: _this.ids_to_work_on,
              ispayment: 1,
              payment_ref: ref,
              amount: (_this.VAT_FEE + (_this.monthly_services_amount * _this.number_to_pay_for)),
              employeecount: _this.number_to_pay_for,
              company_id: _this.authCompanyData.id
            })
            .then(function () {
              Toast.fire({
                icon: "success",
                title: "Candidate conversion successful"
              });
              _this.payment_summary_dialog = false;
              _this.fetch_candidates();
              _this.fetch_company_employees();
              _this.fetch_all_company_billing();
              _this.refresh_company_data({ token: _this.userToken, user_id: _this.authUserData.id, company_id: _this.authCompanyData.id });
            })
            .catch(function () {
              Toast.fire({
                icon: "error",
                title: "Candidate conversion failed"
              });
            })
            .then(function () {
              _this.convertLoading = false;
              _this.convertTxt = "Convert to Employee(s)";
            });
        }
      });
      handler.openIframe();
    },
    enable_employee() {
      let _this = this;
      let number_of_employees = _this.ids_to_work_on.length;
      // _this.enableLoading = !_this.enableLoading;
      // if(_this.enableLoading) {
      //     _this.enableTxt = ''
      // } else {
      //     _this.enableTxt = 'Enable Account'
      // }
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.enableTxt = "";
      _this.enableLoading = true;

      axios
        .post(`${BASE_URL}enableemployee`, {
          ids: _this.ids_to_work_on
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title:
              number_of_employees == 1
                ? "The employee has been enabled"
                : "The employees have been enabled"
          });
          _this.fetch_candidates();
          _this.fetch_company_employees();
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "The employee couldn't be enabled"
          });
        })
        .then(function () {
          _this.enableLoading = false;
          _this.enableTxt = "Enable Account(s)";
        });
    },
    disable_employee() {
      let _this = this;
      let number_of_employees = _this.ids_to_work_on.length;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.disableTxt = "";
      _this.disableLoading = true;

      axios
        .post(`${BASE_URL}disableemployee`, {
          ids: _this.ids_to_work_on
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title:
              number_of_employees == 1
                ? "The employee has been disabled"
                : "The employees have been disabled"
          });
          _this.fetch_candidates();
          _this.fetch_company_employees();
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "The employee couldn't be disabled"
          });
        })
        .then(function () {
          _this.disableLoading = false;
          _this.disableTxt = "Disable Account(s)";
        });
    },
    // showOptions(index, truth) {
    //   if (this.candidateView) {
    //     if (truth) {
    //       this.company_candidates.splice(index, 1, {
    //         id: this.company_candidates[index].id,
    //         firstname: this.company_candidates[index].firstname,
    //         lastname: this.company_candidates[index].lastname,
    //         email: this.company_candidates[index].email,
    //         source_of_hire: this.company_candidates[index].source_of_hire,
    //         selected: this.company_candidates[index].selected,
    //         viewOptions: false
    //       });
    //     } else {
    //       this.company_candidates.splice(index, 1, {
    //         id: this.company_candidates[index].id,
    //         firstname: this.company_candidates[index].firstname,
    //         lastname: this.company_candidates[index].lastname,
    //         email: this.company_candidates[index].email,
    //         source_of_hire: this.company_candidates[index].source_of_hire,
    //         selected: this.company_candidates[index].selected,
    //         viewOptions: true
    //       });
    //     }
    //   } else {
    //     if (truth) {
    //       this.company_employees.splice(index, 1, {
    //         id: this.company_employees[index].id,
    //         firstname: this.company_employees[index].firstname,
    //         lastname: this.company_employees[index].lastname,
    //         email: this.company_employees[index].email,
    //         selected: this.company_employees[index].selected,
    //         viewOptions: false,
    //         is_active: this.employees[index].is_active
    //       });
    //     } else {
    //       this.company_employees.splice(index, 1, {
    //         id: this.company_employees[index].id,
    //         firstname: this.company_employees[index].firstname,
    //         lastname: this.company_employees[index].lastname,
    //         email: this.company_employees[index].email,
    //         selected: this.company_employees[index].selected,
    //         viewOptions: true,
    //         is_active: this.employees[index].is_active
    //       });
    //     }
    //   }
    // },
    allCheckboxesFunc() {
      if (this.candidateView) {
        if (this.company_candidates.length >= 1) {
          if (this.all_checked) {
            this.company_candidates.forEach(
              candidate => (candidate.selected = false)
            );
          } else {
            this.company_candidates.forEach(
              candidate => (candidate.selected = true)
            );
          }
        }
      } else {
        if (this.company_employees.length >= 1) {
          if (this.all_checked) {
            this.company_employees.forEach(
              employee => (employee.selected = false)
            );
          } else {
            this.company_employees.forEach(
              employee => (employee.selected = true)
            );
          }
        }
      }
    },
    checkForAll() {
      if (this.candidateView) {
        let allSelected = this.company_candidates.filter(item => {
          return item.selected == true;
        });
        if (allSelected.length == this.company_candidates.length) {
          this.allCheckboxes = true;
        } else {
          this.allCheckboxes = false;
        }
      } else {
        let allSelected = this.company_employees.filter(item => {
          return item.selected == true;
        });
        if (allSelected.length == this.company_employees.length) {
          this.allCheckboxes = true;
        } else {
          this.allCheckboxes = false;
        }
      }
    }
  },
  mounted() {
    document.title = "Kylian ERP - Onboarding";
    let _this = this;
    if (_this.$route.query.view == "employee") {
      _this.candidateView = false;
      if (!_this.permissionIsAssigned('candidate-read')) {
        _this.candidateView = false
      }
      if (!_this.permissionIsAssigned('employee-read')) {
        _this.candidateView = true
      }
    } else {
      if (!_this.permissionIsAssigned('candidate-read')) {
        _this.candidateView = false
      }
      if (!_this.permissionIsAssigned('employee-read')) {
        _this.candidateView = true
      }
    }

    this.fetch_candidates();
    this.fetch_company_employees();
    this.refresh_company_data({ token: this.userToken, user_id: this.authUserData.id, company_id: this.authCompanyData.id });
    this.company_candidates = this.authCompanyCandidates.map(candidate => {
      return {
        id: candidate.id,
        firstname: candidate.employee_fristname,
        lastname: candidate.employee_lastname,
        email: candidate.employee_email,
        source_of_hire: candidate.source_of_hire,
        selected: false,
        viewOptions: true
      };
    });
    this.company_employees = this.true_employees.map(function (employee) {
      return {
        id: employee.id,
        user_id: employee.user_id,
        firstname: employee.employee_fristname,
        lastname: employee.employee_lastname,
        email: employee.employee_email,
        selected: false,
        viewOptions: true,
        is_active: employee.is_active
      };
    });
  },
  activated() {
    console.log("ACTIVATED");
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .checkbox-shift {
    padding-left: 1.5% !important;
  }
}

.lead {
  font-size: 130%;
}

.employee-options {
  opacity: 1;
}
</style>

<style>
.bulkUpload div div div .v-file-input__text {
  cursor: pointer;
}

.search-employees div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
