<template>
    <div class="card-body row py-4">
        <div class="col-sm-6" style="align-self: center;">
            <h4>Manage Goals</h4>
        </div>
        <div v-if="userType == 1 || permissionIsAssigned('goal-create')" class="col-sm-6">
            <div class="row">
                <div class="col-lg-3 offset-9 py-4">
                    <button
                        @click="add_goal_dialog = true"
                        style="float: right;"
                        type="button"
                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                        :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                    >
                        Add Goal
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Goal Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Goal Description
                                    </th>
                                    <th class="text-center" scope="col">
                                        KRAs
                                    </th>
                                    <th class="text-center" scope="col">
                                        Assignees
                                    </th>
                                    <th class="text-left" scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="goal in authCompanyGoals" :key="'goal-'+goal.id">
                                    <td class="text-left">{{ goal.goal_name }}</td>
                                    <td v-if="goal.description" class="text-left">{{ goal.description }}</td>
                                    <td v-else class="text-left"><i>No description</i></td>
                                    <td v-if="userType == 1 || permissionIsAssigned('kra-read')" class="text-center">
                                        <button @click="(current_goal_id = goal.id, manage_kras_dialog = true)" type="button" class="btn btn-secondary btn-sm ripple">
                                            Manage
                                        </button>
                                    </td>
                                    <td v-else class="text-center">
                                        <i>Permission denied</i>
                                    </td>
                                    <td class="text-center">
                                        <button @click="(current_goal_id = goal.id, view_goal_assignees_dialog = true)" type="button" class="btn btn-secondary btn-sm ripple">
                                            Manage
                                        </button>
                                    </td>
                                    <td style="padding-top: 13px;" class="employee-options text-left">
                                        <div v-if="
                                            userType == 1 
                                            ||
                                            permissionIsAssigned('goal-update')
                                            ||
                                            permissionIsAssigned('goal-delete')
                                        " class="row ml-0">
                                            <i v-if="userType == 1 || permissionIsAssigned('goal-update')" @click="launch_edit_goal(goal)" class="py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            <i v-if="userType == 1 || permissionIsAssigned('goal-delete')" @click="delete_goal(goal)" class="py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        </div>
                                        <i v-else>
                                            Permission denied
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dialog for adding goals -->
        <v-dialog v-model="add_goal_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add Goal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Goal Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="add_goal_form.goal_name" placeholder="Enter the goal name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Goal Description </span>
                                        <v-text-field dense v-model="add_goal_form.description" placeholder="Enter the goal description" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="goal_create_submit ? null : add_goal()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ goal_create_text }}
                                            <v-icon
                                                :style="
                                                !goal_create_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_goal_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding goals -->

        <!-- Dialog for editing goals -->
        <v-dialog v-model="edit_goal_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Edit Goal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Goal Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="edit_goal_form.goal_name" placeholder="Enter the goal name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Goal Description </span>
                                        <v-text-field dense v-model="edit_goal_form.description" placeholder="Enter the goal description" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="goal_update_submit ? null : update_goal()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ goal_update_text }}
                                            <v-icon
                                                :style="
                                                !goal_update_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_goal_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for editing goals -->

        <!-- Dialog for viewing goal assignees -->
        <v-dialog v-model="view_goal_assignees_dialog" max-width="600px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Goal Assignees</span>
                    <button v-if="userType == 1 || permissionIsAssigned('add-employee-to-goal')" @click="add_goal_assignees_dialog = true" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                        Add Assignee
                    </button>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-left">Assignee</th>
                                    <th class="text-left">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(employee, index) in employeesInGoal(current_goal_id)" :key="'employee' + index">
                                    <td class="text-left">
                                        {{ get_employee_name(employee.id) }}
                                    </td>
                                    <td v-if="userType == 1 || permissionIsAssigned('remove-from-goal')" class="text-left">
                                        <div class="row">
                                            <i @click="remove_goal_assignee(current_goal_id, employee.id)" class="col-sm-12 py-4 text-left fas fa-user-minus ml-2" style="color: #069; !important; font-size: 125%; cursor: pointer;"></i>
                                        </div>
                                    </td>
                                    <td v-else class="text-left">
                                        <i>Permission denied</i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="view_goal_assignees_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for viewing goal assignees -->

        <!-- Dialog for adding goal assignees -->
        <v-dialog v-model="add_goal_assignees_dialog" max-width="600px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Assign employees to goal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingFilter.departmentName"
                                            :items="availableDepartmentsNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select department"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingFilter.designationName"
                                            :items="availableDesignationNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select designation"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingFilter.committeeName"
                                            :items="availableCommitteeNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select committee"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="selectedEmployeesForGoal"
                                            :items="filteredAvailableEmployeeNamesForGoal"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            multiple
                                            solo
                                            dense
                                            hint="Select Employees"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6" >
                                        <button @click="add_employees_to_goal_submit ? null : add_employees_to_goal()" style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                            {{ add_employees_to_goal_text }}
                                            <v-icon
                                                :style="
                                            !add_employees_to_goal_submit
                                                ? 'display: none;'
                                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                        "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_goal_assignees_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding goal assignees -->

        <!-- Dialog for managing KRAs -->
        <v-dialog v-model="manage_kras_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Manage KRAs</span>
                    <span v-if="userType == 1 || permissionIsAssigned('kra-create')" class="headline float-right">
                        <button @click="add_kra_dialog = true" style="float: right;" type="button"
                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            Add KRA
                        </button>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="card-body row py-4">
                            <div class="col-sm-12 py-0">
                                <div class="main-content mt-0 px-0 py-0">
                                    <div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" scope="col">
                                                            KRA
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Added By
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Weightage (%)
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Goal
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Start Date
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            End Date
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Approval Status
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="kra in filteredKRAs" :key="kra.id">
                                                        <td class="text-left">{{ kra.kra }}</td>
                                                        <td v-if="employeeObject(kra.added_by)" class="text-left">
                                                            {{
                                                                employeeObject(kra.added_by).employee_fristname 
                                                                + 
                                                                ' '
                                                                +
                                                                employeeObject(kra.added_by).employee_lastname
                                                            }}
                                                        </td>
                                                        <td v-else class="text-left">
                                                            <i>Creator not found</i>
                                                        </td>
                                                        <td class="text-left">{{ `${kra.weightage}%` }}</td>
                                                        <td class="text-left">{{ getGoalName(kra.goal_id) }}</td>
                                                        <td v-if="kra.from_date" class="text-left">{{ kra.from_date }}</td>
                                                        <td v-else class="text-left">
                                                            <i>No date added</i>
                                                        </td>
                                                        <td v-if="kra.to_date" class="text-left">{{ kra.to_date }}</td>
                                                        <td v-else class="text-left">
                                                            <i>No date added</i>
                                                        </td>
                                                        <td class="text-left">
                                                            <span v-if="kra.status == 1" class="badge badge-success text-white">APPROVED</span>
                                                            <span v-else class="badge badge-warning text-white">PENDING</span>
                                                        </td>
                                                        
                                                        <td v-if="kra.status == 1" style="padding-top: 13px;" class="employee-options text-left">
                                                            <div v-if="
                                                                userType == 1
                                                                ||
                                                                permissionIsAssigned('kra-update')
                                                                ||
                                                                permissionIsAssigned('kra-delete')
                                                            " class="row ml-0">
                                                                <i v-if="userType == 1 || permissionIsAssigned('kra-update')" @click="launch_edit_kra(kra)" class="py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                                <i v-if="userType == 1 || permissionIsAssigned('kra-delete')" @click="delete_kra(kra)" class="py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            </div>
                                                            <i v-else>
                                                                Permission denied
                                                            </i>
                                                        </td>
                                                        <td v-else style="padding-top: 13px;" class="employee-options text-left">
                                                            <div v-if="
                                                                userType == 1
                                                                ||
                                                                permissionIsAssigned('kra-create')
                                                                ||
                                                                permissionIsAssigned('kra-update')
                                                            " class="row ml-0">
                                                                <i v-if="userType == 1 || permissionIsAssigned('kra-create') || permissionIsAssigned('kra-update')" @click="approve_kra(kra)" class="py-4 text-center fal fa-check mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                                <i v-if="userType == 1 || permissionIsAssigned('kra-delete')" @click="delete_kra(kra)" class="py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="manage_kras_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for managing KRAs -->

        <!-- Dialog for adding KRAs -->
        <v-dialog v-model="add_kra_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add KRA</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">KRA <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="add_kra_form.kra" placeholder="Enter the KRA name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Weightage (%) <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="add_kra_form.weightage" placeholder="Enter the KRA weightage" type="number" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Start Date </span>
                                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="add_kra_form.from_date"
                                                    placeholder="Enter KRA start date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker1" v-model="add_kra_form.from_date" @change="save1"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">End Date </span>
                                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="add_kra_form.to_date"
                                                    placeholder="Enter KRA end date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker2" v-model="add_kra_form.to_date" @change="save2"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="add_kra_button_loading ? null : add_kra()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ add_kra_button_text }}
                                            <v-icon
                                                :style="
                                                !add_kra_button_loading
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_kra_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding KRAs -->

        <!-- Dialog for editing KRAs -->
        <v-dialog v-model="edit_kra_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Edit KRA</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <!-- <v-col cols="6" >
                                        <span style="color: #111d5e;">Goal <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-select class="mt-2" :items="goalNames" placeholder="Select goal" solo dense :full-width="true" v-model="edit_kra_form.goal"></v-select>
                                    </v-col> -->
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">KRA <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="edit_kra_form.kra" placeholder="Enter the KRA name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Weightage (%) <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="edit_kra_form.weightage" placeholder="Enter the KRA weightage" type="number" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">Start Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="edit_kra_form.from_date"
                                                    placeholder="Enter KRA start date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker3" v-model="edit_kra_form.from_date" @change="save3"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">End Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="edit_kra_form.to_date"
                                                    placeholder="Enter KRA end date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker4" v-model="edit_kra_form.to_date" @change="save4"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="edit_kra_button_loading ? null : update_kra()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ edit_kra_button_text }}
                                            <v-icon
                                                :style="
            !edit_kra_button_loading
                ? 'display: none;'
                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
            "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_kra_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for editing KRAs -->

    </div>
</template>

<script>
import axios from 'axios';
import { 
    mapGetters,
    mapActions
} from 'vuex';
import { BASE_URL } from "@/main";
export default {
    props: {
        getTrueEmployeeAssignedGoals: Function,
        getGoalName: Function,
        get_employee_name: Function,
        get_employee_id: Function,
        employeeObject: Function,
        designationObject: Function,
        getEmployeeByName: Function,
        get_dept_name: Function,
        getEmployeeCommitteeNames: Function,
        availableDepartmentsNames: Array,
        availableDesignationNames: Array,
        availableCommitteeNames: Array
    },
    data() {
        return {
            current_goal_id: null,
            add_goal_dialog: false,
            add_goal_form: {
                goal_name: "",
                description: "",
            },
            goal_create_text: "Submit",
            goal_create_submit: false,
            edit_goal_dialog: false,
            edit_goal_form: {
                id: "",
                goal_name: "",
                description: "",
            },
            goal_update_text: "Update",
            goal_update_submit: false,


            manage_kras_dialog: false,
            add_kra_dialog: false,
            add_kra_form: {
                goal: "",
                kra: "",
                weightage: "",
                from_date: "",
                to_date: "",
            },
            add_kra_button_text: "Submit",
            add_kra_button_loading: false,
            edit_kra_dialog: false,
            edit_kra_form: {
                id: "",
                goal: "",
                kra: "",
                weightage: "",
                from_date: "",
                to_date: "",
            },
            edit_kra_button_text: "Update",
            edit_kra_button_loading: false,

            
            add_goal_assignees_dialog: false,
            view_goal_assignees_dialog: false,
            
            selectedEmployeesForGoal: [],
            add_employees_to_goal_text: "Submit",
            add_employees_to_goal_submit: false,

            addingFilter: {
                designationName: 'All',
                departmentName: 'All',
                committeeName: 'All',
            },
            
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyGoals',
            'authCompanyEmployees',
            'validEmployeeId',
            'authCompanyKRAs',
            'authCompanyEmployeesInGoals',
            'authCompanyDepartmentEmployees',
            'userType'
        ]),
        departmentNameForWatch() {
            return this.addingFilter.departmentName;
        },
        currentAssignedGoal() {
            let _this = this, object = null;
            object = _this.getTrueEmployeeAssignedGoals(_this.validEmployeeId).filter(EAG => EAG.goal_id == _this.current_goal_id);
            if (object.length >= 1) {
                object = object[0];
            }
            return object;
        },
        filteredKRAs() {
            let _this = this, result = [];
            result = _this.authCompanyKRAs.filter(kra => kra.goal_id == _this.current_goal_id);
            return result;
        },
        filteredAvailableEmployeeNamesForGoal() {
            const _this = this;

            // Search and filter by department name
            let result = _this.availableEmployeeNamesForGoal.filter(EMPLOYEE_NAME => {
                if (_this.addingFilter.departmentName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employee) {
                        return false;
                    }

                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == employee.id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingFilter.designationName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let designationName = employeeObject.employee_designation;
                    return designationName === _this.addingFilter.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by committee name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingFilter.committeeName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by committee name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let committeeNames = _this.getEmployeeCommitteeNames(employeeObject.id);
                    return committeeNames.indexOf(_this.addingFilter.committeeName) !== -1;
                }
            });
            // Search and filter by committee name

            return result;
        },
        availableEmployeeNamesForGoal() {
            let _this = this,
                result = [];
            result = _this.availableEmployeesInGoal.map((employee) => `${employee.employee_fristname} ${employee.employee_lastname}`);
            return result;
        },
        availableEmployeesInGoal() {
            let _this = this,
                result = [];
            result = _this.authCompanyEmployees.filter((employee) => {
                return _this.employeeIsInGoal(employee.id, _this.current_goal_id) === false;
            });
            return result;
        },
        employeesToAddToGoals() {
            let _this = this,
                result = [],
                employee_id;
            result = _this.selectedEmployeesForGoal.map((se) => {
                employee_id = _this.get_employee_id(se);
                return {
                    company_id: _this.authCompanyData.id,
                    employee_id: employee_id,
                    goal_id: _this.current_goal_id,
                    department_id: _this.employeeObject(employee_id).department_id,
                    designation_id: _this.designationObject(_this.employeeObject(employee_id).employee_designation) ? _this.designationObject(_this.employeeObject(employee_id).employee_designation).id : null,
                };
            });
            return result;
        },
    },
    watch: {
        menu1(val) {
            val && setTimeout(() => (this.$refs.picker1.activePicker = "YEAR"));
        },
        menu2(val) {
            val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
        },
        menu3(val) { 
            val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
        },
        menu4(val) {
            val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
        },
        departmentNameForWatch() {
            // const _this = this;
            // Select all employees on the selected department
            // _this.filteredAvailableEmployeeNamesForGoal.forEach(employee => {
            //     _this.selectedEmployeesForGoal.splice(
            //         _this.selectedEmployeesForGoal.length,
            //         0,
            //         employee
            //     );
            // });
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_goals',
            'fetch_company_KRAs',
            'fetch_company_KRAs',
            'fetch_company_employees_in_goals'
        ]),
        launch_edit_goal(goal) {
            this.edit_goal_form.id = goal.id;
            this.edit_goal_form.goal_name = goal.goal_name;
            this.edit_goal_form.description = goal.description;
            this.edit_goal_dialog = true;
        },
        add_goal() {
            let _this = this;
            _this.goal_create_text = "";
            _this.goal_create_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}creategoal`, {
                    company_id: _this.authCompanyData.id,
                    goal_name: _this.add_goal_form.goal_name,
                    description: _this.add_goal_form.description,
                    employee_id: _this.validEmployeeId,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The goal was added successfully",
                    });
                    _this.add_goal_dialog = false;
                    _this.add_goal_form.goal_name = "";
                    _this.add_goal_form.description = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.goal_name
                                ? errors.goal_name[0]
                                : null || errors.description
                                ? errors.description[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The goal couldn't be added",
                    });
                })
                .then(function () {
                    _this.goal_create_submit = false;
                    _this.goal_create_text = "Submit";
                    _this.fetch_company_goals();
                });
        },
        update_goal() {
            let _this = this;
            _this.goal_update_text = "";
            _this.goal_update_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}updategoal`, {
                    goal_id: _this.edit_goal_form.id,
                    company_id: _this.authCompanyData.id,
                    goal_name: _this.edit_goal_form.goal_name,
                    description: _this.edit_goal_form.description,
                    employee_id: _this.validEmployeeId,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The goal was updated successfully",
                    });
                    _this.edit_goal_dialog = false;
                    _this.edit_goal_form.goal_name = "";
                    _this.edit_goal_form.description = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.goal_name
                                ? errors.goal_name[0]
                                : null || errors.description
                                ? errors.description[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The goal couldn't be updated",
                    });
                })
                .then(function () {
                    _this.goal_update_submit = false;
                    _this.goal_update_text = "Update";
                    _this.fetch_company_goals();
                });
        },
        delete_goal(goal) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this goal?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deletegoalbyid`, {
                                company_id: _this.authCompanyData.id, // for audit trail,
                                employee_id: _this.validEmployeeId, // for audit trail,
                                goal_id: goal.id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Goal removed successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Goal couldn't be removed",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_goals();
                            });
                    }
                });
        },


        launch_edit_kra(kra) {
            this.edit_kra_form.id = kra.id;
            this.edit_kra_form.goal = this.getGoalName(kra.goal_id);
            this.edit_kra_form.kra = kra.kra;
            this.edit_kra_form.weightage = kra.weightage;
            this.edit_kra_form.from_date = kra.from_date;
            this.edit_kra_form.to_date = kra.to_date;
            this.edit_kra_dialog = true;
        },
        add_kra() {
            let _this = this;
            if (_this.add_kra_form.weightage < 0) {
                Toast.fire({
                    icon: "error",
                    title: `KRA weightage must be 0 or higher`,
                });
                return;
            }
            if (_this.add_kra_form.weightage > 100) {
                Toast.fire({
                    icon: "error",
                    title: `KRA weightage must be 100 or less`,
                });
                return;
            }
            _this.add_kra_button_text = "";
            _this.add_kra_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}createkra`, {
                    company_id: _this.authCompanyData.id,
                    goal_id: _this.current_goal_id || _this.currentAssignedGoal.goal_id,
                    kra: _this.add_kra_form.kra,
                    weightage: _this.add_kra_form.weightage,
                    employee_id: _this.validEmployeeId,
                    added_by: _this.validEmployeeId,
                    from_date: _this.add_kra_form.from_date,
                    to_date: _this.add_kra_form.to_date,
                    status: 1
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The KRA was added successfully",
                    });
                    _this.add_kra_dialog = false;
                    _this.add_kra_form.goal = "";
                    _this.add_kra_form.kra = "";
                    _this.add_kra_form.weightage = "";
                    _this.add_kra_form.from_date = "";
                    _this.add_kra_form.to_date = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.goal_id
                                ? errors.goal_id[0]
                                : errors.kra
                                ? errors.kra[0]
                                : errors.weightage
                                ? errors.weightage[0]
                                : errors.from_date
                                ? errors.from_date[0]
                                : errors.to_date
                                ? errors.to_date[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The KRA couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_kra_button_loading = false;
                    _this.add_kra_button_text = "Submit";
                    _this.fetch_company_KRAs();
                });
        },
        update_kra() {
            let _this = this;
            if (_this.edit_kra_form.weightage < 0) {
                Toast.fire({
                    icon: "error",
                    title: `KRA weightage must be 0 or higher`,
                });
                return;
            }
            if (_this.edit_kra_form.weightage > 100) {
                Toast.fire({
                    icon: "error",
                    title: `KRA weightage must be 100 or less`,
                });
                return;
            }
            _this.edit_kra_button_text = "";
            _this.edit_kra_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}updatekra`, {
                    kra_id: _this.edit_kra_form.id,
                    company_id: _this.authCompanyData.id,
                    goal_id: _this.current_goal_id,
                    kra: _this.edit_kra_form.kra,
                    weightage: _this.edit_kra_form.weightage,
                    employee_id: _this.validEmployeeId,
                    added_by: _this.validEmployeeId,
                    from_date: _this.edit_kra_form.from_date,
                    to_date: _this.edit_kra_form.to_date,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The KRA was updated successfully",
                    });
                    _this.edit_kra_dialog = false;
                    _this.edit_kra_form.id = "";
                    _this.edit_kra_form.goal = "";
                    _this.edit_kra_form.kra = "";
                    _this.edit_kra_form.weightage = "";
                    _this.edit_kra_form.from_date = "";
                    _this.edit_kra_form.to_date = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.goal_id
                                ? errors.goal_id[0]
                                : errors.kra
                                ? errors.kra[0]
                                : errors.weightage
                                ? errors.weightage[0]
                                : errors.from_date
                                ? errors.from_date[0]
                                : errors.to_date
                                ? errors.to_date[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The KRA couldn't be updated",
                    });
                })
                .then(function () {
                    _this.edit_kra_button_loading = false;
                    _this.edit_kra_button_text = "Update";
                    _this.fetch_company_KRAs();
                });
        },
        approve_kra(kra) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to approve this KRA?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Approve`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}approvekra`, {
                                kra_id: kra.id
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "KRA approved successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "KRA couldn't be approved",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_KRAs();
                            });
                    }
                });
        },
        delete_kra(kra) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this KRA?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deletekra`, {
                                company_id: _this.authCompanyData.id,
                                kra_id: kra.id,
                                employee_id: _this.validEmployeeId,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "KRA removed successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "KRA couldn't be removed",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_KRAs();
                            });
                    }
                });
        },

        
        employeeIsInGoal(employee_id, goal_id) {
            let _this = this,
                truth = false;
            _this.authCompanyEmployeesInGoals.forEach((item) => {
                if (item.goal_id == goal_id && item.employee_id == employee_id) {
                    truth = true;
                }
            });
            return truth;
        },
        employeesInGoal(goal_id) {
            let _this = this,
                result = [];
            result = _this.authCompanyEmployees.filter((employee) => {
                return _this.employeeIsInGoal(employee.id, goal_id) === true;
            });
            return result;
        },
        
        getGoalAssigneeId(goal_id, employee_id) {
            let _this = this,
                value = 0,
                object = _this.authCompanyEmployeesInGoals.filter((eg) => {
                    return eg.goal_id == goal_id && eg.employee_id == employee_id;
                });
            if (object.length) {
                value = object[0].employeegoal_id;
            }
            return value;
        },
        
        add_employees_to_goal() {
            let _this = this;
            _this.add_employees_to_goal_text = "";
            _this.add_employees_to_goal_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addemployeestogoal`, {
                    goal_id: _this.current_goal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    employeecollections: _this.employeesToAddToGoals,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Employees added successfully",
                    });
                    _this.fetch_company_employees_in_goals();
                    _this.add_goal_assignees_dialog = false;
                    _this.selectedEmployeesForGoal = [];
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Employees couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_employees_to_goal_submit = false;
                    _this.add_employees_to_goal_text = "Submit";
                });
        },
        remove_goal_assignee(goal_id, employee_id) {
            let _this = this, assignee_id = _this.getGoalAssigneeId(goal_id, employee_id);

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this assignee?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}removefromgoal `, {
                                employee_id: _this.validEmployeeId,
                                company_id: _this.authCompanyData.id,
                                idtoremove: assignee_id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Assignee removed successfully",
                                });
                                _this.fetch_company_employees_in_goals();
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Could not remove the assignee",
                                });
                                _this.fetch_company_employees_in_goals();
                                console.log(error);
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },

        
        save1(date) {
            this.$refs.menu1.save(date);
        },
        save2(date) {
            this.$refs.menu2.save(date);
        },
        save3(date) {
            this.$refs.menu3.save(date);
        },
        save4(date) {
            this.$refs.menu4.save(date);
        },
    }
}
</script>

<style>

</style>