<template>
  <div class="breadcrumb" style="margin-top: 1rem; padding-top:1rem;">
    <div
      class="row"
      :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'"
    >
      <div class="col-12">
        <div class="row justify-content-between align-items-center">
          <div>
            <h1 class="my-0 row pb-0">
              <span class="col-lg-122 col-md-12 col-12"
                >Company Transactions</span
              >
            </h1>
          </div>

          <download-excel
            style="position: fixed; top: 0px;"
            id="export-company-transaction-button"
            :data="json_companytransactions_data"
            :fields="json_companytransactions_fields"
            :worksheet="companyTransactionExcelSheetName"
            :name="companyTransactionExcelSheetName + '.xls'"
            :class="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
                ? 'w-100'
                : 'none'
            "
          >
          </download-excel>
          <div
            class="d-flex gap-3 flex-row justify-content-center align-items-center"
          >
            <div>
              <button
                @click="exportCompanyPaymentsToExcel"
                type="button"
                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                :style="
                  $vuetify.breakpoint.name == 'xs' ||
                  $vuetify.breakpoint.name == 'sm'
                    ? ''
                    : 'margin-right:50px;'
                "
              >
                Export to Excel
              </button>
              <button
                @click="triggerCompanyTransactionPrint"
                type="button"
                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                :style="
                  $vuetify.breakpoint.name == 'xs' ||
                  $vuetify.breakpoint.name == 'sm'
                    ? ''
                    : 'margin-right:80px;'
                "
              >
                Generate PDF
              </button>
            </div>
            <div
              class="d-flex"
              :style="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? ''
                  : 'margin-right:36px;'
              "
            >
              <v-text-field
                v-model="companyTransactionsSearch"
                label="Search Remarks"
                solo
                style="cursor:pointer;"
                hide-details="true"
                placeholder="Company transactions"
                class="search-company transactions px-2 pe-auto"
              />
              <v-select
                v-model="selectedTransactionType"
                :items="['', 'Debit', 'Credit']"
                hide-details="true"
                solo
                label="Transaction Type"
                placeholder="Select transaction type"
                class="search-transaction-type pe-auto"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12"
        :class="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? 'px-0'
            : 'pr-7'
        "
      >
        <div class="card" style="border-radius: 10px;">
          <div class="card-body px-1 py-1 pt-0 pr-0">
            <div
              class="table-responsive"
              style="border-radius: 10px; height: 70vh;
                                          overflow-y: scroll;
                                          padding-top: 0;
                                          padding-bottom: 0;"
            >
              <table class="table table-hover" style="position:relative;">
                <thead>
                  <tr style="border: 2px solid #4440;">
                    <th scope="col" class="text-left">Date Sent</th>
                    <th scope="col" class="text-left">Remarks</th>

                    <th scope="col" class="text-left">Amount</th>
                    <th scope="col" class="text-left">Balance Before</th>
                    <th scope="col" class="text-left">Balance After</th>
                    <th scope="col" class="text-center">Type</th>

                    <th scope="col" class="text-left">Rate</th>

                    <th scope="col" class="text-left">Sent Amount</th>

                    <!-- <th scope="col" class="text-left">
                            Action
                          </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(p, i) in filteredTransactions.slice(
                      startIndex,
                      endIndex
                    )"
                    :key="'transaction-ref_index' + p.reference + i"
                  >
                    <td class="text-left">
                      {{ new Date(p.date).toUTCString().substring(0, 16) }}
                    </td>
                    <td class="text-left">{{ p.remarks }}</td>
                    <td class="text-left">
                      <span class="font-weight-bold"> {{ p.currency }}</span>
                      {{ p.amount }}
                    </td>
                    <td class="text-left">
                      <span class="font-weight-bold"> {{ p.currency }}</span>
                      {{ p.balance_before }}
                    </td>
                    <td class="text-left">
                      <span class="font-weight-bold"> {{ p.currency }}</span>
                      {{ p.balance_after }}
                    </td>

                    <td>
                      {{ p.type }}
                    </td>

                    <td>
                      {{ p.rate_used }}
                    </td>
                    <td>
                      <span class="font-weight-bold"> {{ p.currency }}</span>
                      {{ p.sent_amount }}
                    </td>

                    <!-- <td
                            style="padding-top: 25px;"
                            class="text-left employee-options"
                          >
                            <div class="row">
                              <template>
                                <i
                                  class="col-2 py-0 text-center fal fa-eye"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i>
                              </template>
                            </div>
                          </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="d-flex row justify-content-between align-item-center p-5"
            >
              <div>
                <button
                  @click="decreasePage"
                  type="button"
                  :class="
                    'btn btn-sm btn-secondary m-1 text-white btn-raised ripple'
                  "
                  :style="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                      ? ''
                      : 'margin-right:50px;'
                  "
                >
                  <i
                    class="col-2 py-0 text-center fa-solid fa-arrow-left"
                    style="color: #fff !important; color:white; "
                  ></i>
                </button>
                <div
                  class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                  style="background-color: #069"
                >
                  {{ currentPage }}
                </div>
                <button
                  @click="increasePage"
                  type="button"
                  :disabled="currentPage + 1 > totalItems / itemsPerPage"
                  class="
                    btn btn-sm btn-secondary m-1 text-white btn-raised ripple
                  "
                  :style="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                      ? ''
                      : 'margin-right:50px;'
                  "
                >
                  <i
                    class="col-2 py-0 text-center fa-solid fa-arrow-right"
                    :style="
                      ` font-size: 125%; cursor: pointer; 'color: #fff !important;'
                      `
                    "
                  ></i>
                </button>
              </div>

              <div>
                <v-select
                  v-model="itemsPerPage"
                  @change="itemsPerPageChange"
                  hide-details="true"
                  solo
                  style="cursor:pointer;"
                  :items="[5, 10, 15, 20]"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="printCompanyTransaction"
      class="d-none d-print-block card my-3"
      style="border-radius: 10px;"
    >
      <div class="card-header px-3" style="background: #fff;">
        <v-row>
          <v-col cols="12" md="4"></v-col>
          <v-col cols="12" md="4" class="text-left text-md-center mb-4">
            <img
              :src="
                authCompanyData.logo
                  ? host_url + authCompanyData.logo
                  : '/img/kylian-logo-2.png'
              "
              alt=""
              class="w-50"
              style="height: inherit;"
            />
          </v-col>
          <!-- <v-col cols="12" md="4">
                                                <h3 class="text-left text-md-right">{{
                                                authCompanyData.company_name }}</h3>
                                            </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4>Company Transactions</h4>
          </v-col>
          <v-col cols="12" md="9">
            <h6 class="mt-2 mb-3">
              <span style="font-weight: bold;">As at: {{ dateNow }}</span>
            </h6>
          </v-col>
          <v-col cols="12" md="3">
            <h3 class="text-left text-md-right">
              {{ authCompanyData.company_name }}
            </h3>
          </v-col>
        </v-row>
      </div>
      <div class="card-body px-1 py-1 pt-0 pr-0">
        <div
          :style="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? '/*height: 30vh;*/'
              : '/*height: 30vh;*/'
          "
          style="border-radius: 10px;
                                            padding-top: 0;
                                            padding-bottom: 0;"
        >
          <table class="table">
            <thead>
              <tr>
                <th class="text-left" style="border-top: none;">S/N</th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Type
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Amount
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Currency
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Balance Before
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Balance After
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Date
                </th>

                <th class="text-left" style="border-top: none;" scope="col">
                  Remarks
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Sent Currency
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Rate Used
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Sent Amount
                </th>
                <th class="text-left" style="border-top: none;" scope="col">
                  Statement Type
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(e, index) in authCompanyTransactions"
                :key="'transaction' + index"
              >
                <th class="text-left">{{ index + 1 }}</th>
                <td class="text-left">{{ e.type }}</td>
                <td class="text-left">₦{{ e.amount }}</td>
                <td class="text-left">{{ e.currency }}</td>
                <td class="text-left">₦{{ e.balance_before }}</td>
                <td class="text-left">₦{{ e.balance_after }}</td>
                <td class="text-left">
                  {{ e.date }}
                </td>
                <td class="text-left">{{ e.remarks }}</td>

                <td class="text-left">
                  {{ e.sent_currency }}
                </td>
                <td class="text-left">
                  {{ e.rate_used }}
                </td>
                <td class="text-left">₦{{ e.sent_amount }}</td>
                <td class="text-left">
                  {{ e.statement_type }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ASSETS_URL } from "@/main";

export default {
  data() {
    return {
      selectedTransactionType: "",
      // companyTransactions: [],
      TypeMap: { d: "debit", c: "credit" },
      currentPage: 1,
      companyTransactionsSearch: "",
      itemsPerPage: 5,

      dateNow: "",
      companyTransactionExcelSheetName: "Company Transactions"
    };
  },
  mounted() {
    this.fetch_company_transactions();
  },
  computed: {
    filteredTransactions() {
      let filtered = this.authCompanyTransactions;
      if (this.companyTransactionsSearch.trim()) {
        const searchTerm = this.companyTransactionsSearch.trim().toLowerCase();
        filtered = filtered.filter((transaction) =>
          transaction.remarks.toLowerCase().includes(searchTerm)
        );
      }

      if (this.selectedTransactionType) {
        const transactionType = this.selectedTransactionType.toLowerCase();
        filtered = filtered.filter((transaction) => {
          return (
            this.TypeMap[transaction.type.toLowerCase()] === transactionType
          );
        });
      }

      if (filtered) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties

        return filtered;
      } else return this.authCompanyTransactions;
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    totalItems() {
      return this.filteredTransactions.length;
    },
    endIndex() {
      return this.startIndex + this.itemsPerPage;
    },
    host_url() {
      return ASSETS_URL;
    },
    ...mapGetters(["authCompanyData", "authCompanyTransactions"]),
    json_companytransactions_data: function() {
      return this.authCompanyTransactions;
    },

    json_companytransactions_fields: function() {
      return {
        "Transaction Type": "type",
        Amount: "amount",
        Currency: "currency",
        "Balance Before": "balance_before",
        "Balance After": "balance_after",
        Date: "date",
        Remarks: "remarks",
        "Sent Currency": "sent_currency",
        "Rate Used": "rate_used",
        "Sent Amount": "sent_amount",
        "Statement Type": "statement_type"
      };
    }
  },
  methods: {
    increasePage() {
      const a = this.currentPage + 1 > this.totalItems / this.itemsPerPage;
      if (!a) {
        this.currentPage = this.currentPage + 1;
      } else {
        return;
      }
    },
    decreasePage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    itemsPerPageChange() {
      this.currentPage = 1;
    },
    ...mapActions(["fetch_company_transactions"]),
    exportCompanyPaymentsToExcel() {
      let _this = this;
      var currentDate = new Date().toUTCString().substring(0, 16);

      setTimeout(() => {
        _this.companyTransactionExcelSheetName =
          "Company Transactions as at " + currentDate;

        setTimeout(() => {
          document.getElementById("export-company-transaction-button").click();
        }, 1000);
      }, 1000);
    },
    triggerCompanyTransactionPrint() {
      let _this = this;
      _this.dateNow = new Date().toUTCString().substring(0, 16);
      setTimeout(() => {
        _this.printCompanyTransaction();
      }, 500);
    },
    async printCompanyTransaction() {
      let _this = this;
      await _this.$htmlToPaper("printCompanyTransaction");
    }
  }
};
</script>

<style lang="scss" scoped></style>
